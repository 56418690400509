import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useI18n, usePortalError } from '@hooks';
import { ISharedModalProps } from '@hooks/useModal/types';
import { IIban } from '@schemas/opsPortal/types/iban';
import { postDataToEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { ChangeIbanForm } from './ChangeIbanForm';

interface IUseChangeIbanModalProps extends ISharedModalProps {
  iban: IIban;
  merchantUuid: string;
}

export const useChangeIbanModal = ({
  closeModal,
  iban,
  merchantUuid,
}: IUseChangeIbanModalProps) => {
  const { translate } = useI18n();
  const { handleAxiosError } = usePortalError();
  const { message } = useToast(translate);

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<
    unknown,
    AxiosError<IError>,
    unknown
  >(
    requestData =>
      postDataToEndpoint({
        endpoint: `${Endpoints.ChangeMerchantIban}/${merchantUuid}`,
        requestData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.IBAN, merchantUuid]);
        message.success('OPS_PORTAL.PAGE.MERCHANTS.IBAN_SUCCESSFULLY_CHANGED');
        if (closeModal) {
          closeModal();
        }
      },
      onError: error => {
        handleAxiosError(
          error,
          'OPS_PORTAL.PAGE.MERCHANTS.UNABLE_TO_CHANGE_IBAN',
        );
      },
    },
  );

  const handleSubmit = useCallback(
    values => {
      mutate(values);
    },
    [mutate],
  );

  const formID = 'change-iban';

  const modalContent = (
    <ChangeIbanForm formID={formID} handleSubmit={handleSubmit} iban={iban} />
  );

  return {
    formID,
    isLoading,
    modalContent,
    title: translate('OPS_PORTAL.LABEL.CHANGE_IBAN'),
  };
};
