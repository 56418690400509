import { Box } from '@material-ui/core';
import { ValueOf } from '@appTypes';
import { IImageLogo } from '@hooks/useConfig/configs/types';
import { LogoVariants } from '@utils/constants';

export const ImageLogo = ({
  altTxt,
  url,
  variant,
}: IImageLogo & { variant: ValueOf<typeof LogoVariants> }) => (
  <Box display="flex" flexDirection="row" justifyContent="left">
    <img alt={altTxt} src={url.replace('<§:variant§>', variant)} width={180} />
  </Box>
);
