import { DetailsCardlet } from '@components';
import { GridRow } from '@components/Common.styles';
import { useI18n, usePortal } from '@hooks';
import {
  IAddress,
  ICardDetails,
} from '@schemas/consumerPortal/types/ordersDetails';
import { PaymentMethods } from '@utils/constants';
import { CreditCardDetails } from './CreditCardDetails';
import { PaymentMethodValue } from '.';

export interface IBillingInfoProps {
  address?: IAddress;
  bankAccountNumber: string;
  cardDetails?: ICardDetails;
  email?: string;
  name?: string;
  paymentMethod: PaymentMethodValue;
  phone?: string;
}

export const BillingInfo = ({
  address = { country_alpha_2: '' },
  bankAccountNumber,
  cardDetails,
  email,
  name,
  paymentMethod,
  phone,
}: IBillingInfoProps) => {
  const { translate } = useI18n();
  const { isConsumerPortal, isMerchantPortal } = usePortal();

  const customerDetails = (
    isMerchantPortal ? [name, email] : [name, email, phone]
  ).filter(Boolean) as string[];

  const line1 = address?.address_line_1;
  const line2 = address?.address_line_2;
  const city = address?.city;
  const country = address?.country_alpha_2;
  const postalCode = address?.zip_code;

  const addressDetails = [line1, line2, city, postalCode, country].filter(
    Boolean,
  ) as string[];

  const showAddressDetails = isConsumerPortal && addressDetails.length;
  const hasCardDetails = Boolean(
    cardDetails?.last_four_digits &&
      cardDetails.expiry_month &&
      cardDetails.expiry_year &&
      cardDetails.scheme,
  );

  return (
    <GridRow>
      {customerDetails.length ? (
        <DetailsCardlet
          details={customerDetails}
          title={translate('TITLE.CUSTOMER_DETAILS')}
        />
      ) : null}
      {showAddressDetails ? (
        <DetailsCardlet
          details={addressDetails}
          title={translate('TITLE.BILLING_ADDRESS')}
        />
      ) : null}
      {isConsumerPortal ? (
        <>
          {hasCardDetails ? (
            <CreditCardDetails
              expiryMonth={cardDetails?.expiry_month}
              expiryYear={cardDetails?.expiry_year}
              lastFourDigits={cardDetails?.last_four_digits}
              paymentMethod={paymentMethod}
              scheme={cardDetails?.scheme}
              translate={translate}
            />
          ) : null}
          {bankAccountNumber ? (
            <DetailsCardlet
              details={[bankAccountNumber]}
              status={
                paymentMethod === PaymentMethods.DIRECT_DEBIT
                  ? 'Active'
                  : undefined
              }
              title={translate('TITLE.BANK_ACCOUNT_NUMBER')}
            />
          ) : null}
        </>
      ) : null}
    </GridRow>
  );
};
