import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { useI18n, usePortalError } from '@hooks';
import { postDataToEndpoint } from '@utils';
import { Endpoints } from '@utils/enums';

interface IUseTriggerDocumentUploadQuery {
  contractDataQueryKey?: QueryKey;
  contractUuid?: string;
}

export const useTriggerDocumentUploadQuery = ({
  contractDataQueryKey,
  contractUuid,
}: IUseTriggerDocumentUploadQuery) => {
  const { translate } = useI18n();
  const { message } = useToast(translate);
  const { handleAxiosError } = usePortalError();
  const queryClient = useQueryClient();

  const {
    isLoading: isTriggeringDocumentUpload,
    mutate: triggerDocumentUpload,
  } = useMutation<unknown, AxiosError<IError>>(
    () => {
      return postDataToEndpoint({
        endpoint: `${Endpoints.TriggerCompassDocumentUpload}/${contractUuid}`,
        requestData: {},
      });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([contractDataQueryKey]);
        message.success(
          'OPS_PORTAL.STATUS.COMPASS_DOCUMENT_UPLOAD_SUCCESSFULLY_TRIGGERED',
        );
      },
      onError: error => {
        handleAxiosError(
          error,
          'OPS_PORTAL.STATUS.UNABLE_TO_TRIGGER_COMPASS_DOCUMENT_UPLOAD',
        );
      },
    },
  );

  return {
    isTriggeringDocumentUpload,
    triggerDocumentUpload,
  };
};
