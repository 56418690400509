import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useI18n } from '@hooks';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { IFormModalProps, IModalProps } from '@hooks/useModal/types';
import { getErrorMessage, postDataToEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { AddNoteForm } from './AddNoteForm';

interface IAddNoteModalProps extends IFormModalProps {
  uuid: string;
}

export const useAddNoteModal = ({
  formStatusMessage,
  resolveForm,
  uuid,
}: IAddNoteModalProps): IModalProps => {
  const { translate } = useI18n();

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(
    requestData =>
      postDataToEndpoint({
        endpoint: `${Endpoints.AddContractNote}/${uuid}`,
        requestData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.NOTES_DATA, uuid]);
        resolveForm(
          translate('OPS_PORTAL.PAGE.CONTRACTS.NOTE_SUCCESSFULLY_ADDED'),
        );
      },
      onError: (error: AxiosError) => {
        resolveForm(
          getErrorMessage({
            error,
            genericErrorMessageKey:
              'OPS_PORTAL.PAGE.CONTRACTS.UNABLE_TO_ADD_NOTE',
            translate,
          }),
        );
      },
    },
  );

  const handleSubmit = useCallback(
    values => {
      mutate(values);
    },
    [mutate],
  );

  const formID = 'add-note';

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={isLoading}
    >
      <AddNoteForm formID={formID} handleSubmit={handleSubmit} />
    </ModalStatusRouter>
  );

  return {
    formID,
    isLoading,
    modalContent,
    title: translate('OPS_PORTAL.TITLE.ADD_NOTE'),
  };
};
