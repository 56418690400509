import { useI18n } from '@hooks';

interface ILoading {
  messageKey?: string;
}

export const Loading = ({ messageKey }: ILoading) => {
  const { translate } = useI18n();
  return <div>{translate(messageKey || 'STATUS.LOADING')}</div>;
};
