import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import { IPendingAllocation } from '@schemas/opsPortal/types/pendingAllocations';
import { addPlaceholderForEmptyValues } from '@utils';
import { IPendingAllocationTableColumns } from './types';

interface IMapOverpaidContractTableData {
  data?: IPendingAllocation[];
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

const translationPrefix = 'OPS_PORTAL.LABEL.BANK_ACCOUNT_RECONCILIATIONS';

export const mapPendingAllocationsTableData = ({
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
  translate,
}: IMapOverpaidContractTableData): IPendingAllocationTableColumns[] =>
  data?.map((dataRow: IPendingAllocation) => {
    const {
      allocation_type: allocationType,
      amount,
      created,
      payment_plan_reference: paymentPlanReference,
      statement_line_date: statementLineDate,
      statement_line_reference: statementLineReference,
      uuid,
    } = dataRow;

    return addPlaceholderForEmptyValues({
      allocationType: translate(`${translationPrefix}.${allocationType}`),
      amount: getLocaleCurrencyValue({
        currency: amount.currency,
        value: amount.amount,
      }),
      created: getLocaleDate({ date: created }),
      paymentPlanReference,
      statementLineDate:
        statementLineDate && getLocaleDate({ date: statementLineDate }),
      statementLineReference,
      uuid,
    });
  }) ?? [];
