import { useMemo } from 'react';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Card, ClientsideTable, QueryStateRouter } from '@components';
import { useI18n } from '@hooks';
import { IOpsPortalActionLogs } from '@schemas/opsPortal/types/actionLogs';
import { fetchDataFromEndpoint } from '@utils';
import { QueryKeys, TableNames } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { getActionLogsColumns } from './getActionLogsColumns';
import { mapActionLogsData } from './mapActionLogsData';

interface IActionLogsProps {
  uuid?: string;
}

export const ActionLogs = ({ uuid }: IActionLogsProps) => {
  const { getLocaleDate, translate } = useI18n();

  const { data, error, isLoading } = useQuery<IOpsPortalActionLogs, AxiosError>(
    [QueryKeys.ACTION_LOGS, uuid],
    () =>
      fetchDataFromEndpoint(`${Endpoints.FetchApplicationActionLogs}/${uuid}`),
  );

  const columns = useMemo(() => getActionLogsColumns(translate), [translate]);
  const mappedData = mapActionLogsData({ data, getLocaleDate });

  return (
    <Card title={translate('OPS_PORTAL.TITLE.ACTION_LOGS')}>
      <QueryStateRouter error={error} isLoading={isLoading}>
        <ClientsideTable
          columns={columns}
          data={mappedData}
          tableName={TableNames.ACTION_LOGS}
        />
      </QueryStateRouter>
    </Card>
  );
};
