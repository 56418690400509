import { GetLocaleDate } from '@hooks/useI18n';
import { IMerchants } from '@schemas/opsPortal/types/merchants';
import { addPlaceholderForEmptyValues } from '@utils';
import { IMerchantsTableColumns } from './getMerchantsTableColumns';

interface IPrepareMerchantsTableDataProps {
  data: IMerchants;
  getLocaleDate: GetLocaleDate;
}

export const prepareMerchantsTableData = ({
  data,
  getLocaleDate,
}: IPrepareMerchantsTableDataProps): IMerchantsTableColumns[] =>
  data.map(dataRow => {
    const {
      country,
      created_date: createdDate,
      external_uuid: externalUUID,
      iban,
      industry_name: industryName,
      short_name: merchantName,
    } = dataRow;

    return addPlaceholderForEmptyValues({
      merchantName,
      country,
      iban,
      industryName,
      createdDate: createdDate && getLocaleDate({ date: createdDate }),
      externalUUID,
    });
  });
