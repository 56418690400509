import splashImage from '@assets/images/login-splash-img.jpg';
import * as svgLogos from '@assets/svg';
import { getNavData } from '@hooks/useConfig/utils';
import { HotjarIds, LogoTypes, OriginatorDisplayNames } from '@utils/constants';
import { ConfigFunc } from './types';

const { NavItems, NavSectionHeadingKeys } = getNavData();

export const configOpsHeidipay: ConfigFunc = () => {
  return Object.freeze({
    canChangePassword: true,
    canEditAddress: false,
    canEditName: false,
    canEditPhone: false,
    hotjarId: HotjarIds.HEIDIPAY,
    logo: {
      component: svgLogos.HeidiPay,
      showPoweredBy: false,
      title: OriginatorDisplayNames.HEIDIPAY,
      type: LogoTypes.SVG,
    },
    navigation: [
      {
        heading: NavSectionHeadingKeys.APPLICATIONS,
        items: [
          NavItems.APPLICATIONS,
          NavItems.API_CALLS,
          NavItems.BLOCKED_APPLICANTS,
        ],
      },
      {
        heading: NavSectionHeadingKeys.CONTRACTS,
        items: [
          NavItems.ALL_CONTRACTS,
          NavItems.OVERPAID_CONTRACTS,
          NavItems.PAUSED_CONTRACTS,
          NavItems.TERMINABLE_CONTRACTS,
          NavItems.AWAITING_DISPATCH,
          NavItems.COLLECTIONS,
          NavItems.UTILITIES,
        ],
      },
      {
        heading: NavSectionHeadingKeys.BANKING,
        items: [
          NavItems.BANK_ACCOUNT_RECONCILIATIONS,
          NavItems.SUSPENSE,
          NavItems.PENDING_ALLOCATIONS,
          NavItems.UNBALANCED_ALLOCATIONS,
        ],
      },
      {
        heading: NavSectionHeadingKeys.VIEWS,
        items: [NavItems.MERCHANTS, NavItems.CONSUMERS],
      },
      {
        heading: NavSectionHeadingKeys.ACCOUNT_MANAGEMENT,
        items: [NavItems.ACCOUNT_SETTINGS, NavItems.LOGOUT],
      },
    ],
    showCompassOrders: false,
    showFooter: true,
    showFooterPrivacyLink: true,
    showInvoiceDetails: false,
    showLanguageDropdown: false,
    showProfilePicker: true,
    theme: {
      bootstrapColors: {
        name: 'primary',
      },
      colors: {
        primary: '#52c3c2',
      },
      components: {
        loginSidePanel: `
          background-position: 65% 10%;
          background-size: 70%;
          background-size: cover;
        `,
      },
      splashImage,
    },
  });
};
