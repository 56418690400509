import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useOriginator } from '@hooks';
import { PathnamePrefix } from '@hooks/useOriginator/utils/getPathnamePrefixForOriginator';
import { originatorActions } from '@redux/originatorRedux';

interface IOriginatorHomeRouteNavigatorProps {
  pathnamePrefix: PathnamePrefix;
}

export const OriginatorHomeRouteNavigator = ({
  pathnamePrefix,
}: IOriginatorHomeRouteNavigatorProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { originator, setOriginator } = useOriginator();

  useEffect(() => {
    // This ensures that, if originator was got from
    // pathname instead of state, the state is updated
    // before the path is changed so that redirection
    // to auth pages doesn't break.
    dispatch(originatorActions.setPathnamePrefix(pathnamePrefix));
    setOriginator(originator);

    navigate('/', { replace: true });
  }, [dispatch, navigate, originator, pathnamePrefix, setOriginator]);

  return null;
};
