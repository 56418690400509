import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { CheckboxFieldRHF, TextFieldRHF } from '@components';
import { IHandleSendEmailProps } from './types';

interface ICheckboxAmount {
  amountLabel: string;
  checkboxLabel: string;
  isInArrears: boolean;
  methods: UseFormReturn<IHandleSendEmailProps>;
}

export const CheckboxAmount = ({
  amountLabel,
  checkboxLabel,
  isInArrears,
  methods,
}: ICheckboxAmount) => {
  const { setValue, watch } = methods;
  const fullAmountChecked = watch('full_amount');

  const [showAmountField, setShowAmountField] = useState(
    isInArrears ? !fullAmountChecked : true,
  );

  useEffect(() => {
    if (fullAmountChecked) {
      setValue('amount', '');
    }
    if (isInArrears) {
      setShowAmountField(!fullAmountChecked);
    }
  }, [fullAmountChecked, isInArrears, setValue]);

  return (
    <>
      {isInArrears ? (
        <CheckboxFieldRHF
          className="mb-5"
          label={checkboxLabel}
          name="full_amount"
        />
      ) : null}
      {showAmountField ? (
        <TextFieldRHF
          id="amount"
          label={amountLabel}
          name="amount"
          step="0.01"
          type="number"
        />
      ) : null}
    </>
  );
};
