import { useCallback, useMemo } from 'react';
import { AxiosError } from 'axios';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { QueryStateRouter } from '@components';
import {
  useI18n,
  useOriginator,
  usePortalError,
  useQueryOnClick,
} from '@hooks';
import { IModalProps, ISharedModalProps } from '@hooks/useModal/types';
import { fetchDataFromEndpointCsv } from '@utils';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { DownloadSettlementsReportForm } from './DownloadSettlementsReportForm';
import { ICsvRequest } from './types';

export const useDownloadSettlementsReportModal = ({
  closeModal,
}: ISharedModalProps): IModalProps => {
  const { selectedLanguage, translate } = useI18n();
  const { originator } = useOriginator();
  const { handleAxiosError } = usePortalError();

  const useQueryCallbacks = useMemo(
    () => ({
      onSuccess: closeModal,
      onError: (getDocumentsError: AxiosError<IError>) =>
        handleAxiosError(getDocumentsError),
    }),
    [closeModal, handleAxiosError],
  );

  const { execute, isLoading } = useQueryOnClick<void, ICsvRequest>(
    [QueryKeys.SETTLEMENT_CSV_DOWNLOAD],
    ({ endDate, startDate }) => {
      const fromDate = startDate.toISOString().split('T')[0];
      const toDate = endDate.toISOString().split('T')[0];
      return fetchDataFromEndpointCsv(
        Endpoints.DownloadAllSettlements,
        {
          query: {
            fromDate,
            toDate,
            lang: selectedLanguage,
            originator,
          },
        },
        `settlement_transations_${fromDate}_${toDate}`,
      );
    },
    useQueryCallbacks,
  );

  const handleSubmit = useCallback(
    (request: ICsvRequest) => execute(request),
    [execute],
  );

  const formID = 'settlementsDownload';

  const modalContent = (
    <QueryStateRouter isLoading={isLoading}>
      <DownloadSettlementsReportForm
        formID={formID}
        handleSubmit={handleSubmit}
      />
    </QueryStateRouter>
  );

  return {
    formID,
    isLoading: false,
    allowoverflow: true,
    modalContent,
    submitText: translate('BUTTON.DOWNLOAD'),
    title: translate('TITLE.DOWNLOAD_SETTLEMENTS_REPORT'),
  };
};
