import { dictionary, getDisplayKeys } from '@utils';

export const getDisplayDataList = ({ translate, userData }) => {
  const keys = getDisplayKeys({ data: userData, category: 'user' });
  const { user } = dictionary;

  return keys.map(key => ({
    key: translate(user[key]),
    value: userData[key] || '—',
  }));
};
