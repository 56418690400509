import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Row } from '@tanstack/react-table';
import { Card, NoDataAvailable, QueryStateRouter } from '@components';
import { useI18n, useQueryState } from '@hooks';
import {
  CommunicationType,
  ICommunicationsTableData,
} from '@pages/ConsumerDetails/types';
import { IOpsPortalCommunications } from '@schemas/opsPortal/types/communications';
import { fetchDataFromEndpoint } from '@utils';
import { QueryKeys, TableNames } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { CardDisplay } from './CardDisplay';

export const CommunicationsCard = () => {
  const { translate } = useI18n();
  const { consumerUuid } = useParams();

  const {
    activateQueryStateHook,
    getFilters,
    queryParams,
    queryState,
    resetFilters,
    stringifiedQueryParams,
    updateQueryParams,
  } = useQueryState(TableNames.COMMUNICATIONS);

  const [selectedEventUUID, setSelectedEventUUID] = useState('');
  const [selectedCommunicationType, setSelectedCommunicationType] =
    useState<CommunicationType>('');

  const { data, error, isLoading } = useQuery<
    IOpsPortalCommunications,
    AxiosError
  >([QueryKeys.COMMUNICATIONS_EVENTS, stringifiedQueryParams], () =>
    fetchDataFromEndpoint(`${Endpoints.FetchConsumerEvents}/${consumerUuid}`, {
      query: queryParams,
    }),
  );

  // Reset pagination and selection when filters change.
  useEffect(() => {
    setSelectedEventUUID('');
    setSelectedCommunicationType('');
  }, [queryState.columnFilters, queryState.globalFilter]);

  const selectorChangeHandler = useCallback(
    (row: Row<ICommunicationsTableData>) => {
      setSelectedEventUUID(row?.original.eventUUID || '');
      setSelectedCommunicationType(row?.original.communicationType);
    },
    [],
  );

  const filters = getFilters();

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  return (
    <Card title={translate('OPS_PORTAL.TITLE.COMMUNICATIONS')}>
      <QueryStateRouter error={error} isLoading={isLoading}>
        {data ? (
          <CardDisplay
            filters={filters}
            limit={queryParams.limit}
            listData={data}
            manageControlledState={updateQueryParams}
            queryState={queryState}
            resetFilters={resetFilters}
            selectedCommunicationType={selectedCommunicationType}
            selectedEventUUID={selectedEventUUID}
            selectorChangeHandler={selectorChangeHandler}
          />
        ) : (
          <NoDataAvailable />
        )}
      </QueryStateRouter>
    </Card>
  );
};
