import { createColumnHelper } from '@tanstack/react-table';
import { Translate } from '@hooks/useI18n';
import { filterDateRange } from '@utils';
import { SortingFunctions } from '@utils/constants';
import { INotesTableDataRow } from './types';

export const getNotesColumns = (translate: Translate) => {
  const columnHelper = createColumnHelper<INotesTableDataRow>();

  return [
    columnHelper.accessor('createdDate', {
      header: translate('OPS_PORTAL.TITLE.CREATED_DATE'),
      filterFn: (row, _, filterValue) =>
        filterDateRange({ accessorKey: 'createdDate', filterValue, row }),
      sortingFn: SortingFunctions.DATETIME,
    }),
    columnHelper.accessor('createdBy', {
      header: translate('OPS_PORTAL.TITLE.CREATED_BY'),
      sortingFn: SortingFunctions.ALPHANUMERIC,
    }),
    columnHelper.accessor('noteType', {
      header: translate('TITLE.TYPE'),
    }),
    columnHelper.accessor('message', {
      header: translate('OPS_PORTAL.TITLE.MESSAGE'),
    }),
  ];
};
