import { createColumnHelper } from '@tanstack/react-table';
import { Translate } from '@hooks/useI18n';
import { IPaymentScheduleTableData } from './types';

const renderCell = (data: string, amountDuePresent = false) => {
  const cellClass = amountDuePresent ? 'amount-due-entry' : '';
  return <span className={cellClass}>{data}</span>;
};

export const getPaymentScheduleTableColumns = <T,>(
  translate: Translate,
  isScheduleHistory = false,
) => {
  const columnHelper = createColumnHelper<IPaymentScheduleTableData>();

  const amountDueColumnHelper = columnHelper.accessor('amountDue', {
    header: translate('OPS_PORTAL.LABEL.AMOUNT_DUE'),
    cell: ({ row }) => {
      return renderCell(row.original.amountDue);
    },
  });

  const paymentTypeColumnHelper = columnHelper.accessor('paymentType', {
    header: translate('TITLE.TYPE'),
    cell: ({ row }) => {
      return renderCell(row.original.paymentType);
    },
  });

  return [
    columnHelper.accessor('paymentDate', {
      header: translate('TITLE.DATE'),
      cell: ({ row }) => {
        return renderCell(
          row.original.paymentDate,
          row.original.amountDue === '—' || row.original.focused,
        );
      },
    }),
    ...(isScheduleHistory
      ? [
          amountDueColumnHelper,
          paymentTypeColumnHelper,
          columnHelper.accessor('scheduleVersion', {
            header: 'Schedule version',
            cell: ({ row }) => {
              return renderCell(row.original.scheduleVersion);
            },
          }),
        ]
      : [
          paymentTypeColumnHelper,
          amountDueColumnHelper,
          columnHelper.accessor('amountPaid', {
            header: translate('OPS_PORTAL.LABEL.AMOUNT_PAID'),
            cell: ({ row }) => {
              return renderCell(row.original.amountPaid);
            },
          }),
          columnHelper.accessor('arrears', {
            header: translate('OPS_PORTAL.LABEL.ARREARS_AMOUNT'),
            cell: ({ row }) => {
              return renderCell(row.original.arrears);
            },
          }),
        ]),
  ] as T;
};
