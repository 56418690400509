import { DD, DL, DT } from '@components/Common.styles';
import { useI18n } from '@hooks';

export const TooltipContent = () => {
  const { translate } = useI18n();

  return (
    <DL>
      <DT>{translate('GROUPS.SUPER_ADMIN')}</DT>
      <DD>{translate('GROUPS.SUPER_ADMIN_DESCRIPTION')}</DD>
      <DT>{translate('GROUPS.ADMIN')}</DT>
      <DD>{translate('GROUPS.ADMIN_DESCRIPTION')}</DD>
      <DT>{translate('GROUPS.SALES_ASSISTANT')}</DT>
      <DD>{translate('GROUPS.SALES_ASSISTANT_DESCRIPTION')}</DD>
      <DT>{translate('GROUPS.SALES_ASSISTANT_LIMITED')}</DT>
    </DL>
  );
};
