import { dictionary } from '@utils';

type GetTranslatedDataProps = {
  dict?: Record<string, string>;
  term?: string;
  translate: (key: string) => string;
};

export const getTranslatedData = ({
  dict = dictionary?.api_label,
  term,
  translate,
}: GetTranslatedDataProps) => {
  return term ? translate(dict[term] || term) : undefined;
};
