import { useCallback } from 'react';
import { ICardCTA } from '@appTypes';
import { CardCTA } from '@components';

interface IConfirmDispatchButtonProps {
  ctaProps: ICardCTA;
  setDispatchedUuid: (uuid: string) => void;
  uuid: string;
}

export const ConfirmDispatchButton = ({
  ctaProps,
  setDispatchedUuid,
  uuid,
}: IConfirmDispatchButtonProps) => {
  const clickHandler = useCallback(() => {
    setDispatchedUuid(uuid);
    ctaProps?.clickHandler();
  }, [ctaProps, setDispatchedUuid, uuid]);

  const updatedCTAProps = { ...ctaProps, clickHandler };

  return <CardCTA {...updatedCTAProps} />;
};
