import { fetchDataFromEndpoint } from '@utils';
import { Endpoints, Environments } from '@utils/enums';

export const getEnvVariables = async () => {
  try {
    return await fetchDataFromEndpoint(Endpoints.Env);
  } catch (err) {
    return { NODE_ENV: Environments.Dev };
  }
};
