import { Button } from '@components/Common.styles';
import { useI18n } from '@hooks';
import { ButtonVariant, ButtonType } from '@utils/enums';
import { ICustomModalButton } from './types';

interface IModalFormButtonsProps {
  cancelText?: string;
  clickHandler?: () => void;
  closeText?: string;
  customButtons?: ICustomModalButton[];
  formID?: string;
  hideModal?: () => void;
  isDangerousSubmit?: boolean;
  isFormProcessing?: boolean;
  primaryButtonType?: ButtonType;
  showFormButtons?: boolean;
  submitDisabled?: boolean;
  submitText?: string;
}

export const ModalFormButtons = ({
  cancelText,
  clickHandler,
  closeText,
  customButtons,
  formID,
  hideModal,
  isDangerousSubmit = false,
  isFormProcessing = false,
  primaryButtonType = ButtonType.Submit,
  showFormButtons = false,
  submitDisabled = false,
  submitText,
}: IModalFormButtonsProps) => {
  const { translate } = useI18n();

  if (isFormProcessing) {
    return null;
  }

  if (showFormButtons) {
    return (
      <>
        {customButtons ? (
          <>
            <Button
              onClick={hideModal}
              type={ButtonType.Button}
              variant={ButtonVariant.Light}
            >
              {closeText ?? translate('BUTTON.CLOSE')}
            </Button>
            {customButtons.map(
              ({ clickHandler: onClick, text, type, variant }) => (
                <Button
                  form={formID}
                  key={text}
                  onClick={onClick}
                  type={type}
                  variant={variant}
                >
                  {text}
                </Button>
              ),
            )}
          </>
        ) : (
          <>
            <Button
              onClick={hideModal}
              type={ButtonType.Button}
              variant={ButtonVariant.Light}
            >
              {cancelText ?? translate('BUTTON.CANCEL')}
            </Button>
            <Button
              disabled={submitDisabled}
              form={formID}
              onClick={clickHandler}
              type={primaryButtonType}
              variant={
                isDangerousSubmit ? ButtonVariant.Danger : ButtonVariant.Primary
              }
            >
              {submitText ?? translate('BUTTON.SUBMIT')}
            </Button>
          </>
        )}
      </>
    );
  }

  return (
    <Button
      onClick={hideModal}
      type={ButtonType.Button}
      variant={ButtonVariant.Primary}
    >
      {closeText ?? translate('BUTTON.CLOSE')}
    </Button>
  );
};
