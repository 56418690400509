import { useSelector } from 'react-redux';
import { Permissions } from '@appTypes';
import { selectPermissions } from '@selectors/index';
import { validatePermissions } from '@utils';

export const useHasPermission = (scopes?: Permissions) => {
  const permissions = useSelector(selectPermissions);
  if (!scopes) {
    return false;
  }
  return validatePermissions({ permissions, scopes });
};
