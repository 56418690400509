import { useCallback, useState } from 'react';
import { Tab, Tabs as TabsBS } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import { ClientsideTable, DataDisplay, QueryStateRouter } from '@components';
import { TabEventKeys } from '@utils/constants';
import { ITabs, ITabData } from './types';

export const Tabs = ({
  defaultScheduleVersion,
  initialTabKey,
  scheduleVersionOptions,
  tabsData,
  tabsId,
}: ITabs) => {
  const location = useLocation();

  const targetedTabId = location.state?.activeKey;

  const [activeKey, setActiveKey] = useState(targetedTabId || initialTabKey);

  const selectTabKey = useCallback(
    key => {
      location.state = { activeKey: key };
      setActiveKey(key);
    },
    [location],
  );

  const renderTabContent = (tab: ITabData) => {
    if (tab.component) {
      return tab.component;
    }

    if (tab.dataList) {
      return <DataDisplay dataList={tab.dataList} />;
    }

    return tab.columns && tab.data && tab.tableName ? (
      <ClientsideTable
        className={tab.className}
        columns={tab.columns as ColumnDef<unknown, unknown>[]}
        data={tab.data}
        isInTab={true}
        rowClasses={tab.rowClasses}
        showClearFiltersButton={
          tab.eventKey !== TabEventKeys.CONTRACT_PAYMENT_SCHEDULE_HISTORY
        }
        tableName={tab.tableName}
        tableSpecificProps={{
          defaultScheduleVersion,
          scheduleVersionOptions,
        }}
      />
    ) : null;
  };

  return (
    <TabsBS
      activeKey={activeKey}
      className="mb-8"
      id={tabsId}
      onSelect={selectTabKey}
    >
      {tabsData.map((tab: ITabData) => (
        <Tab eventKey={tab.eventKey} key={tab.eventKey} title={tab.title}>
          <QueryStateRouter error={tab?.error} isLoading={tab?.isLoading}>
            {renderTabContent(tab)}
          </QueryStateRouter>
        </Tab>
      ))}
    </TabsBS>
  );
};
