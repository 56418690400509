import * as Yup from 'yup';
import { Translate } from '@hooks/useI18n';
import { ValidationErrorKeys } from '@utils/enums';

interface IGetValidationSchema {
  maxAmount: string;
  translate: Translate;
}

export const getValidationSchema =
  ({ maxAmount, translate }: IGetValidationSchema) =>
  () =>
    Yup.object({
      amount: Yup.number()
        .typeError(translate(ValidationErrorKeys.AmountMustBeNumber))
        .required(translate(ValidationErrorKeys.Required))
        .max(
          Number(maxAmount),
          translate(ValidationErrorKeys.AmountCannotExceedContractValue),
        )
        .min(0.5, translate(ValidationErrorKeys.MinNumX, { min: 0.5 })),
    });
