import { useCallback } from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useI18n } from '@hooks';
import { ModalStatusRouter } from '@hooks/useModal/components';
import {
  IFormModalProps,
  IModalProps,
  ISharedModalProps,
} from '@hooks/useModal/types';
import { postDataToEndpoint } from '@utils';
import { Endpoints } from '@utils/enums';
import { AdjustContractValueModalContent } from './AdjustContractValueModalContent';

interface IUseAdjustContractValueModal
  extends IFormModalProps,
    ISharedModalProps {
  amount: string;
  currency: string;
  isCancel?: boolean;
  queryKey: string;
  uuid: string;
}

export const useAdjustContractValueModal = ({
  amount,
  currency,
  formStatusMessage,
  isCancel = false,
  queryKey,
  resolveForm,
  uuid,
}: IUseAdjustContractValueModal): IModalProps => {
  const { translate } = useI18n();

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(
    requestData =>
      postDataToEndpoint({
        endpoint: isCancel
          ? `${Endpoints.CancelContract}/${uuid}`
          : `${Endpoints.AdjustContract}/${uuid}`,
        requestData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey, uuid]);
        resolveForm(
          translate(
            isCancel
              ? 'PAGE.ORDERS.ORDER_SUCCESSFULLY_CANCELLED'
              : 'PAGE.ORDERS.CONTRACT_VALUE_SUCCESSFULLY_ADJUSTED',
          ),
        );
      },
      onError: () => {
        resolveForm(
          translate(
            isCancel
              ? 'PAGE.ORDERS.UNABLE_TO_CANCEL_ORDER'
              : 'PAGE.ORDERS.UNABLE_TO_ADJUST_CONTRACT_VALUE',
          ),
        );
      },
    },
  );

  const handleSubmit = useCallback(values => mutate(values), [mutate]);

  const formID = 'adjustContractValueForm';

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={isLoading}
    >
      <AdjustContractValueModalContent
        amount={amount}
        currency={currency}
        formID={formID}
        handleSubmit={handleSubmit}
        isCancel={isCancel}
      />
    </ModalStatusRouter>
  );

  return {
    formID,
    isDangerousSubmit: true,
    isFormProcessing: isLoading,
    isLoading,
    modalContent,
    title: translate(
      isCancel ? 'TITLE.CANCEL_ORDER' : 'TITLE.ADJUST_CONTRACT_VALUE',
    ),
  };
};
