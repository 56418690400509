import { useEffect, useMemo, useState } from 'react';
import { Translate } from '@heidi-pay/heidi-common-fe/types';
import { QrCode } from '@components/QrCode';
import { GetLocaleCurrencyValue } from '@hooks/useI18n';
import { IMerchantSettingObservationViewConfigSetting } from '@schemas/merchantPortal/types/accountSettings';
import { IMerchantPortalTracking } from '@schemas/merchantPortal/types/tracking';
import { TrackingGridItem } from './Tracking.styles';
import {
  defaultQrCodeFields,
  getQrTransform,
} from './transforms/qrCodeTransform';
import { getIsApplicationSuccessful } from './utils/getIsApplicationSuccessful';

interface ITrackingQrCode {
  config?: IMerchantSettingObservationViewConfigSetting;
  data: IMerchantPortalTracking;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  translate: Translate;
}

export const TrackingQrCode = ({
  config,
  data,
  getLocaleCurrencyValue,
  translate,
}: ITrackingQrCode) => {
  const [qrString, setQrString] = useState('');

  const qrCode = config?.qrCode ?? defaultQrCodeFields;

  const transform = useMemo(
    () =>
      getQrTransform(
        qrCode,
        {
          getLocaleCurrencyValue,
        },
        translate,
      ),
    [getLocaleCurrencyValue, qrCode, translate],
  );

  useEffect(() => {
    if (data.contract_uuid) {
      const qrStr = transform(data);
      setQrString(qrStr);
    }
  }, [data, transform]);

  return getIsApplicationSuccessful(data.statusLabel) && qrString ? (
    <TrackingGridItem align="right" item={true} md={6}>
      <QrCode altText="QR Code of order tracking details" text={qrString} />
    </TrackingGridItem>
  ) : null;
};
