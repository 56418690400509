import splashImage from '@assets/images/login-splash-img.jpg';
import * as svgLogos from '@assets/svg';
import { getNavData } from '@hooks/useConfig/utils';
import { HotjarIds, LogoTypes, OriginatorDisplayNames } from '@utils/constants';
import { ConfigFunc } from './types';

const { NavItems, NavSectionHeadingKeys } = getNavData();

export const configConsumerHeidipay: ConfigFunc = () => {
  return Object.freeze({
    canChangePassword: true,
    canEditAddress: true,
    canEditName: false,
    canEditPhone: true,
    hotjarId: HotjarIds.HEIDIPAY,
    logo: {
      component: svgLogos.HeidiPay,
      showPoweredBy: false,
      title: OriginatorDisplayNames.HEIDIPAY,
      type: LogoTypes.SVG,
    },
    navigation: [
      {
        heading: NavSectionHeadingKeys.CUSTOMERS,
        items: [NavItems.ORDERS, NavItems.DOCUMENTS],
      },
      {
        heading: NavSectionHeadingKeys.HELP,
        items: [NavItems.CARD_CHANGE_GUIDE],
      },
      {
        heading: NavSectionHeadingKeys.ACCOUNT_MANAGEMENT,
        items: [NavItems.ACCOUNT_SETTINGS, NavItems.LOGOUT],
      },
    ],
    showCompassOrders: false,
    showFooter: true,
    showFooterPrivacyLink: true,
    showInvoiceDetails: false,
    showLanguageDropdown: true,
    showProfilePicker: true,
    supportEmail: 'support@heidipay.com',
    theme: {
      bootstrapColors: {
        name: 'primary',
      },
      colors: {
        primary: '#52c3c2',
      },
      components: {
        loginSidePanel: `
          background-position: 65% 10%;
          background-size: 70%;
          background-size: cover;
        `,
      },
      splashImage,
    },
  });
};
