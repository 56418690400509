import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Card, NoDataAvailable, QueryStateRouter } from '@components';
import { DL, DT } from '@components/Common.styles';
import { useI18n, useOriginator } from '@hooks';
import { IMerchantPortalApiKeys } from '@schemas/merchantPortal/types/apiKeys';
import { IOpsPortalApiKeys } from '@schemas/opsPortal/types/apiKeys';
import { selectOriginatorUuid } from '@selectors/index';
import { fetchDataFromEndpoint } from '@utils';
import { Originators, QueryKeys } from '@utils/constants';
import { Endpoints, ApiKeys as Keys } from '@utils/enums';
import { KeyDisplay } from './KeyDisplay';

type IApiKeys = IMerchantPortalApiKeys | IOpsPortalApiKeys;

export const ApiKeys = () => {
  const { translate } = useI18n();
  const { originator } = useOriginator();
  const { uuid: merchantUuid } = useParams();
  const originatorUuid = useSelector(selectOriginatorUuid);

  const endpoint = merchantUuid
    ? `${Endpoints.FetchMerchantsAPIKeys}/${merchantUuid}`
    : Endpoints.FetchMerchantsAPIKeys;

  const query = {
    ...(merchantUuid ? { merchant_uuid: merchantUuid } : {}),
    ...(originatorUuid ? { originator_uuid: originatorUuid } : {}),
  };

  const { data, error, isLoading } = useQuery<IApiKeys, AxiosError>(
    [QueryKeys.API_KEYS, originatorUuid],
    () =>
      fetchDataFromEndpoint(endpoint, {
        query,
      }),
    { enabled: Boolean(originatorUuid) },
  );

  const filteredData = data
    ? Object.keys(data)
        .filter(key => data[key as keyof IApiKeys])
        .map(key => ({
          keyType: key.toUpperCase(),
          keyValue: data[key as keyof IApiKeys] as string,
        }))
    : [];

  const getPrivateKeyLabel = (keyType: string) => {
    if (keyType === Keys.Public) {
      return 'LABEL.PUBLIC_KEY';
    }

    if (originator === Originators.PAGOLIGHTPRO) {
      return 'LABEL.PRIVATE_KEY_PRO';
    }

    if (originator === Originators.PAGOLIGHT) {
      return 'LABEL.PRIVATE_KEY_BNPL';
    }

    return 'LABEL.PRIVATE_KEY';
  };

  return (
    <Card title={translate('TITLE.API_KEYS')}>
      <QueryStateRouter error={error} isLoading={isLoading}>
        {filteredData.length ? (
          <DL>
            {filteredData.map(dataRow => (
              <Fragment key={dataRow.keyType}>
                <DT>{translate(getPrivateKeyLabel(dataRow.keyType))}</DT>
                <KeyDisplay apiKey={dataRow.keyValue} />
              </Fragment>
            ))}
          </DL>
        ) : (
          <NoDataAvailable />
        )}
      </QueryStateRouter>
    </Card>
  );
};
