import { useMemo } from 'react';
import { StaticTable } from '@components';
import { useI18n } from '@hooks';
import { getRiskTestsTableColumns } from './getRiskTestsTableColumns';
import { mapRiskTestsData } from './mapRiskTestsData';
import { IRiskDataProps } from './types';

export const RisksCardContent = ({ data }: IRiskDataProps) => {
  const { translate } = useI18n();

  const riskTestsColumns = useMemo(
    () => getRiskTestsTableColumns(translate),
    [translate],
  );

  const riskTestsTableData = mapRiskTestsData(data);

  return <StaticTable columns={riskTestsColumns} data={riskTestsTableData} />;
};
