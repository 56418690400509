import * as React from 'react';
import { useCallback } from 'react';
import { IconButton, Snackbar, SnackbarProps } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { ValueOf } from '@appTypes';
import { AlertVariant } from '@utils/constants';

interface IAlertToastProps {
  autoHideDuration?: number;
  message: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  snackbarProps?: SnackbarProps;
  variant?: ValueOf<typeof AlertVariant>;
}

export function AlertToast({
  autoHideDuration,
  message,
  open,
  setOpen,
  snackbarProps = {},
  variant,
}: IAlertToastProps) {
  const handleClose = useCallback(
    (_: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      setOpen(false);
    },
    [setOpen],
  );

  const action = (
    <IconButton
      aria-label="close"
      color="inherit"
      onClick={handleClose}
      size="small"
    >
      <Close />
    </IconButton>
  );

  return (
    <Snackbar
      action={action}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
      autoHideDuration={autoHideDuration}
      ContentProps={{
        className: `alert alert-${variant}`,
      }}
      message={message}
      onClose={handleClose}
      open={open}
      {...snackbarProps}
    />
  );
}
