import { useCallback, useEffect, useState, useMemo } from 'react';
import { TextField } from '@components';
import { useI18n } from '@hooks';
import { loadAutocomplete } from './loadAutocomplete';

interface IAddressFinderProps {
  address: string;
  country: string;
  setAddress: (address: google.maps.places.PlaceResult) => void;
}

export const AddressFinder = ({
  address,
  country,
  setAddress,
}: IAddressFinderProps) => {
  const { selectedLanguage, translate } = useI18n();

  const inputId = 'address';

  const [query, setQuery] = useState(address);
  const [autoComplete, setAutoComplete] =
    useState<google.maps.places.Autocomplete | null>(null);

  const options = useMemo(
    () => ({
      componentRestrictions: { country },
      language: selectedLanguage,
      types: ['address'],
    }),
    [country, selectedLanguage],
  );

  useEffect(() => {
    loadAutocomplete({
      inputId,
      options,
      setAddress,
      setAutoComplete,
      setQuery,
    });
  }, [options, setAddress]);

  useEffect(
    () => autoComplete?.setOptions(options),
    [autoComplete, country, options, selectedLanguage],
  );

  const handleAddressQuery = useCallback(
    e => setQuery(e.target.value),
    [setQuery],
  );

  return (
    <TextField
      containerClassName="mb-2"
      id={inputId}
      label={translate('LABEL.ENTER_ADDRESS')}
      name={inputId}
      onChange={handleAddressQuery}
      placeholder=""
      value={query}
    />
  );
};
