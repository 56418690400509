import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CheckboxFieldRHF, TextFieldRHF } from '@components';
import { useI18n } from '@hooks';
import { ValidationErrorKeys } from '@utils/enums';
import { IPaymentLinkFormProps } from './types';

export const PaymentLinkForm = ({
  formID,
  handleSubmit,
  remainingBalance,
}: IPaymentLinkFormProps) => {
  const { translate } = useI18n();

  const defaultValues = {
    specify_amount: false,
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(
      Yup.object({
        specify_amount: Yup.boolean(),
        amount: Yup.number()
          .nullable()
          .when('specify_amount', {
            is: true,
            then: schema =>
              schema
                .required(translate(ValidationErrorKeys.Required))
                .max(
                  Number(remainingBalance.amount),
                  translate(
                    ValidationErrorKeys.AmountCannotExceedRemainingBalance,
                  ),
                )
                .typeError(translate(ValidationErrorKeys.AmountMustBeNumber))
                .moreThan(0, translate(ValidationErrorKeys.MinNum1)),
          }),
      }),
    ),
    mode: 'onChange',
  });

  const specifyAmount = methods.watch('specify_amount');

  return (
    <FormProvider {...methods}>
      <form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id={formID}
        onSubmit={methods.handleSubmit(handleSubmit)}
      >
        <CheckboxFieldRHF
          label={translate('OPS_PORTAL.LABEL.PAYMENT_LINK_SPECIFY_AMOUNT')}
          name="specify_amount"
        />
        {specifyAmount ? (
          <TextFieldRHF
            id="amount"
            label={`${translate('OPS_PORTAL.LABEL.PAYMENT_LINK_AMOUNT')} (${
              remainingBalance.currency
            })`}
            name="amount"
          />
        ) : null}
      </form>
    </FormProvider>
  );
};
