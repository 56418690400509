import { useField } from 'formik';
import { ValidationError } from '@components';
import { InputCheckbox, Label } from '@components/Common.styles';

export const CheckboxField = ({ id, label, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' });

  return (
    <>
      <Label checkbox={true} htmlFor={id}>
        <InputCheckbox id={id} {...field} {...props} /> {label}
      </Label>
      {meta.touched && meta.error ? (
        <ValidationError error={meta.error} />
      ) : null}
    </>
  );
};
