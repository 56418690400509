import { useEffect, useMemo } from 'react';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import {
  Card,
  NoDataAvailable,
  QueryStateRouter,
  ServersideTable,
} from '@components';
import { ModalKeys, useI18n, useModal, useQueryState } from '@hooks';
import { IMerchantPortalSettlements } from '@schemas/merchantPortal/types/settlements';
import { fetchDataFromEndpoint } from '@utils';
import { QueryKeys, TableNames } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { getSettlementsTableColumns } from './getSettlementsTableColumns';
import { prepareSettlementsTableData } from './prepareSettlementsTableData';

export const Settlements = () => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  const {
    activateQueryStateHook,
    getFilters,
    queryParams,
    queryState,
    resetFilters,
    stringifiedQueryParams,
    updateQueryParams,
  } = useQueryState(TableNames.SETTLEMENTS);

  const { data, error, isLoading } = useQuery<
    IMerchantPortalSettlements,
    AxiosError
  >([QueryKeys.SETTLEMENTS_TABLE_DATA, stringifiedQueryParams], () =>
    fetchDataFromEndpoint(Endpoints.FetchSettlements, {
      query: queryParams,
    }),
  );

  const columns = useMemo(
    () => getSettlementsTableColumns(translate),
    [translate],
  );

  const filters = getFilters();

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  const settlementsTableData = prepareSettlementsTableData({
    data: data?.results,
    getLocaleCurrencyValue,
    getLocaleDate,
    translate,
  });

  const downloadSettlementsCsvModal = useModal(
    ModalKeys.DownloadSettlementsReport,
    {},
  );

  return (
    <Card
      buttons={[downloadSettlementsCsvModal?.ctaProps].filter(Boolean)}
      title={translate('TITLE.SETTLEMENTS')}
    >
      <QueryStateRouter error={error} isLoading={isLoading}>
        {data && settlementsTableData?.length ? (
          <ServersideTable
            columns={columns}
            data={settlementsTableData}
            filters={filters}
            manageControlledState={updateQueryParams}
            pagination={{
              limit: queryParams.limit,
              totalItems: data.count,
            }}
            resetFilters={resetFilters}
            tableState={queryState}
          />
        ) : (
          <NoDataAvailable />
        )}
      </QueryStateRouter>
      {downloadSettlementsCsvModal?.Modal}
    </Card>
  );
};
