import { useParams } from 'react-router-dom';
import { ValueOf } from '@appTypes';
import { Card, ShoppingCartDetails } from '@components';
import { IShoppingCartOrderDetails } from '@components/ShoppingCartDetails/types';
import { ModalKeys, useConfig, useI18n, useOriginator } from '@hooks';
import { IMerchantPortalMediobancaSettlements } from '@schemas/merchantPortal/types/mediobancaSettlements';
import { IMerchantPortalOrderDetails } from '@schemas/merchantPortal/types/orderDetails';
import { PaymentTypes, QueryKeys } from '@utils/constants';
import {
  checkPropertiesExist,
  getBillingInfoProps,
  getMerchantOrderSummaryProps,
} from '../utils';
import { BillingInfo, OrderSummaryCard, InvoiceDetails } from '.';

interface IOrderDetailsDisplayMerchant {
  mediobancaSettlementsData?: IMerchantPortalMediobancaSettlements;
  orderDetailsData: IMerchantPortalOrderDetails;
  queryKey: ValueOf<typeof QueryKeys>;
}

export const OrderDetailsDisplayMerchant = ({
  mediobancaSettlementsData,
  orderDetailsData,
  queryKey,
}: IOrderDetailsDisplayMerchant) => {
  const {
    config: { showInvoiceDetails },
  } = useConfig();
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();
  const {
    config: { showCompassOrders },
  } = useConfig();
  const { isPagolightPro } = useOriginator();
  const { uuid } = useParams();

  const dataObj = orderDetailsData[0];

  const { hasProducts, permissions } = checkPropertiesExist({
    data: dataObj,
    isPagolightPro,
  });

  const billingInfoProps = getBillingInfoProps(dataObj);

  const orderSummaryProps = getMerchantOrderSummaryProps({
    getLocaleCurrencyValue,
    getLocaleDate,
    isPagolightPro,
    orderDetailsData: dataObj,
    showCompassOrders,
  });

  return (
    <>
      <OrderSummaryCard
        canShowPaymentButton={true}
        orderDetailsData={dataObj}
        orderSummaryProps={orderSummaryProps}
        paymentModalKey={ModalKeys.OrderRefund}
        paymentType={PaymentTypes.REFUND}
        permissions={permissions}
        queryKey={queryKey}
        uuid={uuid as string}
      />

      {hasProducts ? (
        <Card title={translate('TITLE.SHOPPING_CART_DETAILS')}>
          <ShoppingCartDetails
            orderDetails={dataObj.order_details as IShoppingCartOrderDetails}
          />
        </Card>
      ) : null}

      <Card title={translate('TITLE.BILLING_INFORMATION')}>
        <BillingInfo {...billingInfoProps} />
      </Card>

      {showInvoiceDetails ? (
        <Card title={translate('TITLE.INVOICE_INFORMATION')}>
          <InvoiceDetails
            invoiceData={mediobancaSettlementsData}
            orderData={dataObj}
          />
        </Card>
      ) : null}
    </>
  );
};
