import { Environments } from '@utils/enums';
import { getEnvVariables } from '@utils/getEnvVariables';

export const getEnv = async () => {
  if (!window.env) {
    window.env = await getEnvVariables();
  }

  const nodeEnv = window.env.NODE_ENV;
  // This is in case the environment is something unrecognised like "local"
  const environment = Object.values(Environments).includes(nodeEnv)
    ? nodeEnv
    : Environments.Dev;

  return {
    dsn: window.env.CLIENT_SENTRY_DSN,
    isDev: environment === Environments.Dev,
    isProd: environment === Environments.Prod,
    isQA: environment === Environments.QA,
    isSandbox: environment === Environments.Sandbox,
    sentryEnv: window.env.SENTRY_ENV_NAME,
  };
};
