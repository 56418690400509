import { Card } from '@components';
import { useI18n } from '@hooks';

export const PagolightPro = () => {
  const { translate } = useI18n();

  return (
    <Card title={translate('TITLE.MERCHANT_PRIVACY_POLICY')}>
      <p>
        Compass Banca S.p.A., tratta i suoi dati conformemente
        all&apos;informativa privacy resa in occasione della stipula del
        convenzioni vigenti, a cui interamente si rimanda.
      </p>
      <p>
        Le raccomandiamo di usare correttamente il presente applicativo
        informatico, nonché di custodire e tenere segrete, per prevenirne
        l&apos;uso improprio o non autorizzato da parte di terzi, le credenziali
        personali di accesso fornite da Compass Banca S.p.A..
      </p>
    </Card>
  );
};
