import { ThemeProvider } from 'styled-components';
import { useConfig } from '@hooks';

export const StyledComponentsTheme = ({ children }) => {
  const {
    config: { theme },
  } = useConfig();

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
