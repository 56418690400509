import { dictionary } from '@utils';

export const getDropdownOptions = (groups, translate) => [
  { id: '', name: '—' },
  ...(groups
    ? groups.filter(Boolean).map(group => ({
        id: group.id.toString(),
        name: translate(dictionary.groups[group.name] || group.name),
      }))
    : []),
];
