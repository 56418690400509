import { ColumnDef } from '@tanstack/react-table';
import { IApplicationsTableData } from '@appTypes';
import { Translate } from '@hooks/useI18n';
import { createCSV, mapArrayOfMapsTo2dArray, syncDataToCols } from '@utils';

interface ICreateApplicationsCSVProps {
  columns: ColumnDef<IApplicationsTableData, string>[];
  data: IApplicationsTableData[];
  translate: Translate;
}

export const createApplicationsCSV = ({
  columns,
  data,
  translate,
}: ICreateApplicationsCSVProps) => {
  const colsWithContractId = [
    ...columns.slice(0, 1),
    {
      accessorKey: 'contractId',
      header: translate('OPS_PORTAL.TITLE.CONTRACT_ID'),
    },
    ...columns.slice(1),
  ];
  const headers = colsWithContractId
    .filter(col => typeof col.header === 'string')
    .map(col => col.header);
  const syncedData = syncDataToCols({ columns: colsWithContractId, data });
  const arrayedData = mapArrayOfMapsTo2dArray(syncedData);
  return createCSV({ arrayedData, headers });
};
