import { Card, NoDataAvailable, QueryStateRouter } from '@components';
import { useI18n } from '@hooks';
import { RisksCardContent } from './RisksCardContent';
import { IRiskTestsBreakdownProps } from './types';

export const RiskTestsBreakdown = ({
  data,
  error,
  isLoading,
}: IRiskTestsBreakdownProps) => {
  const { translate } = useI18n();

  return (
    <Card title={translate('OPS_PORTAL.TITLE.RISK_TESTS_BREAKDOWN')}>
      <QueryStateRouter error={error} isLoading={isLoading}>
        {data ? <RisksCardContent data={data} /> : <NoDataAvailable />}
      </QueryStateRouter>
    </Card>
  );
};
