import { formatDaysAgo } from '@utils';
import { ContractStatuses, TableNames } from '@utils/constants';
import { ITableDefaults, ITableDefaultsProps } from './types';

const {
  ACTIVE,
  AWAITING_DISPATCH,
  CANCELLED,
  CLOSED,
  PENDING_CLOSURE,
  TERMINATED,
  TERMINATED_AND_PAID,
  WRITTEN_OFF,
  WRITTEN_OFF_AND_PAID,
} = ContractStatuses;

const today = formatDaysAgo(0);

const tableDefaults: ITableDefaults = {
  [TableNames.API_CALLS]: () => ({
    columnFilters: [
      {
        id: 'timestamp',
        value: [formatDaysAgo(1), today],
      },
    ],
  }),
  [TableNames.AWAITING_DISPATCH]: () => ({
    columnFilters: [{ id: 'activityStatus', value: AWAITING_DISPATCH }],
  }),
  [TableNames.BANK_ACCOUNT_RECONCILIATIONS]: () => ({
    columnFilters: [
      {
        id: 'transactionDate',
        value: [formatDaysAgo(1), today],
      },
    ],
  }),
  [TableNames.CONTRACTS]: () => ({
    columnFilters: [
      {
        id: 'activityStatus',
        value: [
          ACTIVE,
          AWAITING_DISPATCH,
          CANCELLED,
          CLOSED,
          PENDING_CLOSURE,
          TERMINATED,
          TERMINATED_AND_PAID,
          WRITTEN_OFF,
          WRITTEN_OFF_AND_PAID,
        ].join(','),
      },
    ],
  }),
  [TableNames.MEDIOBANCA_RECONCILIATIONS]: () => ({
    columnFilters: [
      {
        id: 'settlementDate',
        value: [formatDaysAgo(7), today],
      },
    ],
  }),
  [TableNames.PAYMENT_SCHEDULE_HISTORY]: tableSpecificDefaults => ({
    columnVisibility: { scheduleVersion: false },
    columnFilters: [
      {
        id: 'scheduleVersion',
        value: tableSpecificDefaults?.defaultScheduleVersion ?? '',
      },
    ],
  }),
  [TableNames.UNBALANCED_ALLOCATIONS]: () => ({
    columnFilters: [
      { id: 'pending', value: 'false' },
      { id: 'unmatched', value: 'true' },
    ],
  }),
};

export const getTableDefaults = ({
  initialQueryState,
  tableName,
  tableSpecificDefaults,
}: ITableDefaultsProps) => ({
  ...initialQueryState,
  ...(tableDefaults[tableName]?.(tableSpecificDefaults) ?? {}),
});
