import styled from 'styled-components';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button } from '@components';
import { NoWrap } from '@components/Common.styles';
import { useI18n } from '@hooks';
import { ButtonVariant } from '@utils/enums';
import { EmailUserForm } from './EmailUserForm';
import { SelectTemplateForm } from './SelectTemplateForm';
import { IEmailUserModalContentProps } from './types';

const Row = styled.div`
  display: flex;
  gap: 2rem;
`;

export const EmailUserModalContent = ({
  currency,
  formID,
  goBack,
  handleSelectTemplate,
  handleSendEmail,
  isInArrears,
  isInternalOpsUser,
  language,
  selectedTemplate,
  templates,
  triggerId,
}: IEmailUserModalContentProps) => {
  const { translate } = useI18n();
  const showCustomAmountNotification =
    selectedTemplate?.trigger_id === 'FAILED_PAYMENTS_AND_QR_CODES_IN_PORTAL' &&
    !isInArrears;

  return selectedTemplate ? (
    <>
      <Row>
        <Button
          onClick={goBack}
          variant={[ButtonVariant.Secondary, ButtonVariant.Sm]}
        >
          <NoWrap>
            <ChevronLeftIcon />
            {translate('OPS_PORTAL.BUTTON.GO_BACK')}
          </NoWrap>
        </Button>
        {showCustomAmountNotification ? (
          <p>
            {translate(
              'OPS_PORTAL.NOTIFICATION.CUSTOMER_NOT_IN_ARREARS_CUSTOM_AMOUNT_NEEDED',
            )}
          </p>
        ) : null}
      </Row>
      <EmailUserForm
        currency={currency}
        formID={formID}
        handleSendEmail={handleSendEmail}
        isInArrears={isInArrears}
        isInternalOpsUser={isInternalOpsUser}
        selectedTemplate={selectedTemplate}
      />
    </>
  ) : (
    <SelectTemplateForm
      formID={formID}
      handleSelectTemplate={handleSelectTemplate}
      language={language}
      templates={templates}
      triggerId={triggerId}
    />
  );
};
