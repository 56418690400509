import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PrivacyPolicySplashScreen } from '@components';
import { usePortal } from '@hooks';
import { LayoutSplashScreen } from '@metronic/layout';
import {
  selectAgreedToPrivacyPolicyAt,
  selectHasAuthToken,
  selectIsAuthed,
} from '@selectors/index';
import { getUserByToken } from './authCrud';
import { actions } from './authRedux';

interface IAuthInitProps {
  children: JSX.Element;
}

export const AuthInit = ({ children }: IAuthInitProps) => {
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  const { hasAuthToken } = useSelector(selectHasAuthToken);
  const isAuthed = useSelector(selectIsAuthed);
  const agreedToPrivacyPolicyAt = useSelector(selectAgreedToPrivacyPolicyAt);
  const { isMerchantPortal } = usePortal();

  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  const { fulfillUser, logout } = actions;

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const { data: user } = await getUserByToken();
          dispatch(fulfillUser(user));
        }
      } catch (error) {
        if (!didRequest.current) {
          dispatch(logout());
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => {
        didRequest.current = true;
      };
    };

    if (hasAuthToken) {
      requestUser();
    } else {
      dispatch(fulfillUser(undefined));
      setShowSplashScreen(false);
    }
  }, [dispatch, fulfillUser, hasAuthToken, logout]);

  useEffect(() => {
    setShowPrivacyPolicy(
      isMerchantPortal && isAuthed && !agreedToPrivacyPolicyAt,
    );
  }, [agreedToPrivacyPolicyAt, isAuthed, isMerchantPortal]);

  if (showPrivacyPolicy) {
    return <PrivacyPolicySplashScreen />;
  }

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};
