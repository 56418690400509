import axios, { AxiosRequestConfig } from 'axios';

interface IOptions {
  endpoint: string;
  requestData: AxiosRequestConfig;
}

export const deleteDataFromEndpoint = async ({
  endpoint,
  requestData,
}: IOptions) => {
  const { data } = await axios.delete(`/api/${endpoint}/`, requestData);
  return data;
};
