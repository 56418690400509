import { useMemo } from 'react';
import { Tabs } from '@components';
import { useHasPermission, useI18n } from '@hooks';
import {
  generateTabData,
  getPaymentScheduleTableColumns,
  getPaymentsTableColumns,
  mapContractDetailsData,
  mapContractPerformanceData,
  mapCustomerDetailsData,
  mapPaymentScheduleData,
  mapPaymentsData,
} from '@pages/ContractDetails/utils';
import { IOpsPortalAssignmentHistory } from '@schemas/opsPortal/types/assignmentHistory';
import { IOpsPortalBlockExternalCollectionsReferral } from '@schemas/opsPortal/types/blockExternalCollectionsReferral';
import { IOpsPortalCollectionsDetails } from '@schemas/opsPortal/types/collectionsDetails';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails';
import {
  IBalance,
  IOpsPortalMonetaContract,
} from '@schemas/opsPortal/types/monetaContract';
import { IOpsPortalPauseCommunications } from '@schemas/opsPortal/types/pauseCommunications';
import { TabEventKeys } from '@utils/constants';
import {
  useAssignmentHistoryTab,
  useCertificatesOfInterestTab,
  useContractDocumentsTab,
  useContractOverviewTab,
} from '../hooks';
import { getScheduleVersions } from '../utils/getScheduleVersions';
import {
  ContractPaymentScheduleColumns,
  ContractPaymentScheduleHistoryColumns,
} from '../utils/types';

interface ITabsComponentProps {
  assignmentHistoryData?: IOpsPortalAssignmentHistory;
  collectionsBlockAutoReferralData?: IOpsPortalBlockExternalCollectionsReferral;
  collectionsData?: IOpsPortalCollectionsDetails;
  contractData: IContractDetails;
  contractUuid?: string;
  externalContractUUID?: string;
  isArrearsToBeManagedByHeidi: boolean;
  isCollectionsDataError: boolean;
  isCompass: boolean;
  isInternalOpsUser: boolean;
  monetaContractData?: IOpsPortalMonetaContract;
  pauseCommunicationsData?: IOpsPortalPauseCommunications;
}

export const TabsComponent = ({
  assignmentHistoryData,
  collectionsBlockAutoReferralData,
  collectionsData,
  contractData,
  contractUuid,
  externalContractUUID,
  isArrearsToBeManagedByHeidi,
  isCollectionsDataError,
  isCompass,
  isInternalOpsUser,
  monetaContractData,
  pauseCommunicationsData,
}: ITabsComponentProps) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  const { assignmentHistoryColumns, assignmentHistoryTableData } =
    useAssignmentHistoryTab(assignmentHistoryData);

  const contractActivePaymentScheduleColumns = useMemo(
    () =>
      getPaymentScheduleTableColumns<ContractPaymentScheduleColumns>(translate),
    [translate],
  );

  const contractPaymentScheduleHistoryColumns = useMemo(
    () =>
      getPaymentScheduleTableColumns<ContractPaymentScheduleHistoryColumns>(
        translate,
        true,
      ),
    [translate],
  );

  const contractPaymentsColumns = useMemo(
    () => getPaymentsTableColumns(translate),
    [translate],
  );

  const contractDetailsData = mapContractDetailsData({
    data: contractData,
    getLocaleCurrencyValue,
  });
  const contractPerformanceData = mapContractPerformanceData({
    collectionsData,
    contractData,
    getLocaleCurrencyValue,
    getLocaleDate,
    isCollectionsDataError,
    translate,
  });
  const customerDetailsData = mapCustomerDetailsData({
    data: contractData,
    getLocaleDate,
  });

  const contractPaymentScheduleData = mapPaymentScheduleData({
    data: monetaContractData,
    getLocaleCurrencyValue,
    getLocaleDate,
    translate,
  });

  // In schedule history we tab we don't want to show manual payments/refunds
  // i.e. where there wasn't an amount due at the that time
  const monetaContractDataWithoutManualPayments = monetaContractData?.balances
    ? {
        ...monetaContractData,
        balances: monetaContractData.balances.filter(
          (balance: IBalance) => Number(balance.amount_due) !== 0,
        ),
      }
    : monetaContractData;

  const contractPaymentScheduleHistoryData = mapPaymentScheduleData({
    data: monetaContractDataWithoutManualPayments,
    getLocaleCurrencyValue,
    getLocaleDate,
    translate,
    isScheduleHistory: true,
  });

  const uniqueScheduleVersionsLabels = getScheduleVersions(monetaContractData);

  const defaultScheduleVersion = uniqueScheduleVersionsLabels.length
    ? uniqueScheduleVersionsLabels[0].id
    : '';

  const contractPaymentsData = mapPaymentsData({
    data: monetaContractData,
    getLocaleCurrencyValue,
    getLocaleDate,
    translate,
  });

  const tabs = generateTabData({
    assignmentHistoryColumns,
    assignmentHistoryTableData,
    collectionsBlockAutoReferralData,
    contractActivePaymentScheduleColumns,
    contractDetailsData,
    contractPaymentScheduleHistoryColumns,
    contractPaymentScheduleHistoryData,
    contractPaymentsColumns,
    contractPaymentScheduleData,
    contractPaymentsData,
    contractPerformanceData,
    customerDetailsData,
    isArrearsToBeManagedByHeidi,
    isCompass,
    pauseCommunicationsData,
    translate,
  });

  const opsCanViewContractDocumentsTab = useHasPermission([
    'ops_portal.can_view_customer_agreements',
  ]);

  const opsCanViewCertificateOfInterestTab = useHasPermission([
    'ops_portal.list_certificate_of_interests',
  ]);

  const contractDocumentsTab = useContractDocumentsTab({
    canViewContractDocumentsTab: opsCanViewContractDocumentsTab,
    contractUuid: contractData.contract_uuid,
    externalContractUUID,
  });

  const certificatesOfInterestTab = useCertificatesOfInterestTab({
    externalContractUUID,
  });

  const contractOverviewTab = useContractOverviewTab({
    collectionsBlockAutoReferralData,
    contractData,
    contractUuid,
    isArrearsToBeManagedByHeidi,
    isCompass,
    isInternalOpsUser,
    pauseCommunicationsData,
  });

  const tabDataArray = [
    contractOverviewTab,
    ...tabs,
    ...(opsCanViewContractDocumentsTab ? [contractDocumentsTab] : []),
    ...(opsCanViewCertificateOfInterestTab ? [certificatesOfInterestTab] : []),
  ];

  return (
    <Tabs
      defaultScheduleVersion={defaultScheduleVersion}
      initialTabKey={TabEventKeys.CONTRACT_OVERVIEW}
      scheduleVersionOptions={uniqueScheduleVersionsLabels}
      tabsData={tabDataArray}
      tabsId="contract-drilldown-tabs"
    />
  );
};
