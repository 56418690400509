import { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { ValidationError } from '@components';
import { FormGroup, InputNumber, Label } from '@components/Common.styles';
import { useI18n } from '@hooks';

interface INumberFieldProps {
  containerClassName?: string;
  id: string;
  label: string;
  max?: number;
  min?: number;
  name: string;
  readOnly?: boolean;
}

export const NumberFieldRHF = ({
  containerClassName = '',
  id,
  label,
  max,
  min,
  name,
  readOnly = false,
}: INumberFieldProps) => {
  const { translate } = useI18n();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({ name, control });

  const error = errors[name]?.message;

  const handler = useCallback(
    number => {
      onChange(number);
    },
    [onChange],
  );

  return (
    <FormGroup extraClassName={containerClassName}>
      <Label htmlFor={id}>
        {label + (readOnly ? ` (${translate('LABEL.CANNOT_BE_EDITED')})` : '')}
      </Label>
      <InputNumber
        id={id}
        max={max}
        min={min}
        onChange={handler}
        readOnly={readOnly}
        value={value}
      />
      {error ? <ValidationError error={error} /> : null}
    </FormGroup>
  );
};
