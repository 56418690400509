import { Tabs } from '@components';
import { useCollectionAgencies, useI18n, usePortal } from '@hooks';
import { mapTabData } from '@pages/ConsumerDetails/utils';
import { IApplications } from '@schemas/opsPortal/types/applications';
import { IOpsPortalConsumerDetails } from '@schemas/opsPortal/types/consumerDetails';
import { TabEventKeys } from '@utils/constants';

interface ICardDisplayProps {
  applications?: IApplications;
  data: IOpsPortalConsumerDetails;
}

export const CardDisplay = ({ applications, data }: ICardDisplayProps) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();
  const { isMerchantPortal } = usePortal();

  const { collectionAgencies } = useCollectionAgencies();

  const tabsData = mapTabData({
    applications,
    collectionAgencies,
    data,
    getLocaleCurrencyValue,
    getLocaleDate,
    translate,
    isMerchantPortal,
  });

  return (
    <Tabs
      initialTabKey={TabEventKeys.CONSUMER_DETAILS}
      tabsData={tabsData}
      tabsId="consumer-details-tabs"
    />
  );
};
