/* eslint-disable import/no-extraneous-dependencies */
import { Translate } from '@heidi-pay/heidi-common-fe/types';
import { Player } from '@lottiefiles/react-lottie-player';
import { Box, Grid, Grow } from '@mui/material';
import { IMerchantPortalTracking } from '@schemas/merchantPortal/types/tracking';
import { SuccessMessage } from './Tracking.styles';
import { getIsApplicationSuccessful } from './utils/getIsApplicationSuccessful';

const lottie = {
  src: '/lotties/check.json',
};

interface IFinancingRequestApproved {
  data: IMerchantPortalTracking;
  translate: Translate;
}

export const FinancingRequestApproved = ({
  data,
  translate,
}: IFinancingRequestApproved) => (
  <>
    {getIsApplicationSuccessful(data.statusLabel) ? (
      <Grow in={true} timeout={1000}>
        <SuccessMessage>
          <Grid
            alignItems="center"
            container={true}
            flexDirection="column"
            justifyContent="center"
          >
            <Grid display="flex" item={true} md={6} sm={8} xs={12}>
              <Box aria-hidden="true">
                <Player
                  autoplay={true}
                  keepLastFrame={true}
                  loop={false}
                  speed={1}
                  src={lottie.src}
                />
              </Box>
            </Grid>
            <Grid display="flex" item={true}>
              {translate('TITLE.FINANCING.REQUEST.APPROVED')}
            </Grid>
          </Grid>
        </SuccessMessage>
      </Grow>
    ) : null}
  </>
);
