import { Formik, Form } from 'formik';
import { TextField } from '@components';
import { useI18n } from '@hooks';
import { getValidationSchema } from './getValidationSchema';

export const EditNameForm = ({ data, formID, handleSubmit }) => {
  const { translate } = useI18n();

  const { email, first_name: firstName, last_name: lastName } = data;

  // I realise this looks hideous, but it is a necessary evil, as
  // default values in destructuring only replace undefined, not null.
  const initialFormikValues = {
    email,
    first_name: firstName ?? '',
    last_name: lastName ?? '',
  };

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema(translate)(initialFormikValues)}
    >
      <Form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id={formID}
      >
        <TextField
          id="first_name"
          label={translate('LABEL.FIRST_NAME')}
          name="first_name"
        />
        <TextField
          id="last_name"
          label={translate('LABEL.LAST_NAME')}
          name="last_name"
        />
      </Form>
    </Formik>
  );
};
