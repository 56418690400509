import { Translate } from '@heidi-pay/heidi-common-fe/types';
import { createColumnHelper } from '@tanstack/react-table';
import { StatusPill } from '@components';
import { NoWrap } from '@components/Common.styles';
import { ISettlementTransactionColumns } from './types';

export const getTransactionsTableColumns = (translate: Translate) => {
  const columnHelper = createColumnHelper<ISettlementTransactionColumns>();

  return [
    columnHelper.accessor('transactionID', {
      header: translate('PAGE.SETTLEMENTS.TRANSACTION_ID'),
    }),
    columnHelper.accessor('date', {
      header: translate('TITLE.DATE'),
    }),
    columnHelper.accessor('type', {
      header: translate('TITLE.TYPE'),
      cell: ({ row }) => {
        const { rawStatus, status } = row.original;
        return <StatusPill rawStatus={rawStatus} status={status} />;
      },
    }),
    columnHelper.accessor('merchantOrderId', {
      header: translate('TITLE.YOUR_INVOICE_NUMBER'),
    }),
    columnHelper.accessor('paymentPlanID', {
      header: translate('TITLE.PAYMENT_PLAN_ID'),
    }),
    columnHelper.accessor('signedAmount', {
      header: translate('LABEL.AMOUNT'),
      cell: ({ row }) => <NoWrap>{row.original.signedAmount}</NoWrap>,
    }),
  ];
};
