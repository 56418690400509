import { useCallback, useState } from 'react';
import { Button, DD, DL, DT } from '@components/Common.styles';
import { useI18n } from '@hooks';
import { ButtonVariant } from '@utils/enums';
import { ManualAddressForm } from './ManualAddressForm';
import { SearchAddressForm } from './SearchAddressForm';
import { IEditAddressFormProps } from './types';

export const EditAddressForm = ({
  data,
  formID,
  handleSubmit,
}: IEditAddressFormProps) => {
  const { translate } = useI18n();

  const initialAddress = data.data ?? {};

  const [address, setAddress] = useState({
    country_alpha_2: initialAddress?.country_alpha_2 ?? '',
    formatted_address: initialAddress?.formatted_address ?? '',
    address_components: initialAddress?.address_components ?? [],
  });

  const [isAddressFormVisible, setIsAddressFormVisible] = useState(false);

  const toggleEditAddressForm = useCallback(
    () => setIsAddressFormVisible(!isAddressFormVisible),
    [isAddressFormVisible],
  );

  const submitAddress = useCallback(
    () =>
      handleSubmit({
        address: [
          {
            address_components: address.address_components,
            formatted_address: address.formatted_address,
          },
        ],
      }),
    [address.address_components, address.formatted_address, handleSubmit],
  );

  const onAddressChange = useCallback(newAddress => {
    setAddress(currentValue => ({ ...currentValue, ...newAddress }));
  }, []);

  const submitManualAddress = useCallback(
    manualAddress => {
      setAddress(currentValue => ({ ...currentValue, ...manualAddress }));
      submitAddress();
    },
    [submitAddress],
  );

  const buttonText = translate(
    isAddressFormVisible ? 'BUTTON.SEARCH_ADDRESS' : 'BUTTON.MANUAL_ADDRESS',
  );

  const isButtonVisible = address.address_components?.length;

  return (
    <>
      {isAddressFormVisible ? (
        <ManualAddressForm
          addressComponents={address.address_components}
          formattedAddress={address.formatted_address}
          formID={formID}
          handleChange={onAddressChange}
          handleSubmit={submitManualAddress}
          initialFormattedAddress={initialAddress?.formatted_address}
        />
      ) : (
        <SearchAddressForm
          address={address}
          formID={formID}
          handleChange={onAddressChange}
          handleSubmit={submitAddress}
          initialAddress={initialAddress}
        />
      )}
      {isButtonVisible ? (
        <Button
          className="px-0 pb-0"
          onClick={toggleEditAddressForm}
          type="button"
          variant={ButtonVariant.Link}
        >
          {buttonText}
        </Button>
      ) : null}
      <DL>
        <DT id="currentAddress">{translate('LABEL.CURRENT_ADDRESS')}</DT>
        <DD aria-labelledby="currentAddress">
          {initialAddress?.formatted_address ?? ''}
        </DD>
        <DT id="newAddress">{translate('LABEL.NEW_ADDRESS')}</DT>
        <DD aria-labelledby="newAddress">{address.formatted_address}</DD>
      </DL>
    </>
  );
};
