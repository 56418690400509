import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useHasPermission } from '@hooks';
import { IOpsPortalBlockExternalCollectionsReferral } from '@schemas/opsPortal/types/blockExternalCollectionsReferral';
import { fetchDataFromEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';

interface IUseGetCollectionsBlockAutoReferralQuery {
  externalContractUuid: string | undefined;
  isArrearsToBeManagedByHeidi: boolean;
  isInArrears: boolean;
}

export const useGetCollectionsBlockAutoReferralQuery = ({
  externalContractUuid,
  isArrearsToBeManagedByHeidi,
  isInArrears,
}: IUseGetCollectionsBlockAutoReferralQuery) => {
  const canFetchBlockAutoReferral = useHasPermission([
    'ops_portal.can_block_collections_external_auto_referral',
  ]);

  const canFetchCollectionBlockAutoReferrals =
    canFetchBlockAutoReferral &&
    Boolean(externalContractUuid) &&
    isArrearsToBeManagedByHeidi &&
    isInArrears;

  const {
    data: collectionsBlockAutoReferralData,
    error: collectionsBlockAutoReferralError,
    isInitialLoading: isAutoReferralLoading,
  } = useQuery<IOpsPortalBlockExternalCollectionsReferral, AxiosError>(
    [QueryKeys.COLLECTIONS_BLOCK_AUTO_REFERRAL, externalContractUuid],
    () =>
      fetchDataFromEndpoint(
        `${Endpoints.BlockExternalCollectionsReferral}/${externalContractUuid}`,
      ),
    {
      enabled: canFetchCollectionBlockAutoReferrals,
    },
  );

  return {
    collectionsBlockAutoReferralData,
    collectionsBlockAutoReferralError,
    isAutoReferralLoading,
  };
};
