import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { QueryStateRouter } from '@components';
import { useI18n } from '@hooks';
import { IModalProps, ISharedModalProps } from '@hooks/useModal/types';
import { IOpsPortalApiCallDetails } from '@schemas/opsPortal/types/apiCallDetails';
import { fetchDataFromEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { ViewRawDataContent } from './ViewRawDataContent';

interface IViewRawDataModalProps extends ISharedModalProps {
  uuid: string;
}

export const useViewRawDataModal = ({
  isModalShowing,
  uuid,
}: IViewRawDataModalProps): IModalProps => {
  const { translate } = useI18n();

  const { data, error, isLoading } = useQuery<
    IOpsPortalApiCallDetails,
    AxiosError
  >(
    [QueryKeys.API_CALLS, uuid],
    () =>
      fetchDataFromEndpoint(Endpoints.FetchApiRequests, {
        search: uuid,
      }),
    { enabled: isModalShowing },
  );

  const modalContent = (
    <QueryStateRouter error={error} isLoading={isLoading}>
      <ViewRawDataContent data={data} />
    </QueryStateRouter>
  );

  return {
    isLoading,
    modalContent,
    title: translate('OPS_PORTAL.TITLE.RAW_DATA'),
  };
};
