import splashImage from '@assets/images/login-splash-compass.png';
import { getNavData } from '@hooks/useConfig/utils';
import { LogoTypes, OriginatorDisplayNames } from '@utils/constants';
import { ConfigFunc } from './types';

const { NavItems, NavSectionHeadingKeys } = getNavData();

export const configOpsPagolightpro: ConfigFunc = () => {
  return Object.freeze({
    canChangePassword: true,
    canEditAddress: false,
    canEditName: false,
    canEditPhone: false,
    logo: {
      altTxt: OriginatorDisplayNames.PAGOLIGHTPRO,
      showPoweredBy: true,
      type: LogoTypes.PNG,
      url: '/media/logos/logo-pagolightpro-<§:variant§>.png',
    },
    navigation: [
      {
        heading: NavSectionHeadingKeys.APPLICATIONS,
        items: [
          NavItems.APPLICATIONS,
          NavItems.API_CALLS,
          NavItems.BLOCKED_APPLICANTS,
        ],
      },
      {
        heading: NavSectionHeadingKeys.CONTRACTS,
        items: [
          NavItems.ALL_CONTRACTS,
          NavItems.OVERPAID_CONTRACTS,
          NavItems.TERMINABLE_CONTRACTS,
          NavItems.PAUSED_CONTRACTS,
          NavItems.AWAITING_DISPATCH,
          NavItems.COLLECTIONS,
          NavItems.UTILITIES,
        ],
      },
      {
        heading: NavSectionHeadingKeys.BANKING,
        items: [
          NavItems.BANK_ACCOUNT_RECONCILIATIONS,
          NavItems.SUSPENSE,
          NavItems.PENDING_ALLOCATIONS,
          NavItems.UNBALANCED_ALLOCATIONS,
        ],
      },
      {
        heading: NavSectionHeadingKeys.VIEWS,
        items: [NavItems.MERCHANTS, NavItems.CONSUMERS],
      },
      {
        heading: NavSectionHeadingKeys.ACCOUNT_MANAGEMENT,
        items: [NavItems.ACCOUNT_SETTINGS, NavItems.LOGOUT],
      },
    ],
    showCompassOrders: true,
    showFooter: false,
    showFooterPrivacyLink: false,
    showInvoiceDetails: false,
    showLanguageDropdown: false,
    showProfilePicker: true,
    theme: {
      bootstrapColors: {
        name: 'mediobanca',
      },
      colors: {
        primary: '#f70203',
      },
      components: {
        loginSidePanel: `
          background-position: 75% 10%;
          background-size: 65%;
          background-color: #e6e7e8;
          background-size: cover;
        `,
      },
      splashImage,
    },
  });
};
