import {
  IApplicantData,
  IMapApplicantDataProps,
} from '@pages/ApplicationDetails/types';
import { addPlaceholderForEmptyValues, getFullName } from '@utils';

export const mapApplicantData = ({
  data,
  getLocaleDate,
  translate,
}: IMapApplicantDataProps) => {
  const {
    applicant_uuid: applicantUUID,
    consumer_uuid: consumerUUID,
    dob: dateOfBirth,
    email,
    first_name: firstName,
    formatted_billing_address: billingAddress,
    formatted_shipping_address: shippingAddress,
    language_code: language,
    last_name: lastName,
    lex_digital_id: lexDigitalId,
    phone_number: phoneNumber,
  } = data;

  const customerAddress = shippingAddress || billingAddress;

  return addPlaceholderForEmptyValues<IApplicantData>({
    consumerUUID: {
      linkTo: `/consumers/${consumerUUID}`,
      text: consumerUUID,
    },
    applicantUUID,
    applicantName: getFullName({ firstName, lastName }),
    email,
    customerAddress,
    phoneNumber,
    language:
      language && translate(`OPS_PORTAL.LABEL.${language.toUpperCase()}`),
    dateOfBirth: dateOfBirth && getLocaleDate({ date: dateOfBirth }),
    lexDigitalId,
  });
};
