import { CheckboxField } from '@components';
import { Fieldset } from '@components/Common.styles';

export const CheckboxGroup = ({ fields, legend, name, ...props }) => (
  <Fieldset>
    <legend>{legend}</legend>
    {fields.map(field => {
      const key = `${name}-${field.id}`;
      return (
        <CheckboxField
          id={key}
          key={key}
          label={field.name}
          name={name}
          value={field.id}
          {...props}
        />
      );
    })}
  </Fieldset>
);
