import { useMemo, useRef } from 'react';
import { AxiosError } from 'axios';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useQuery } from '@tanstack/react-query';
import { Card, ClientsideTable, QueryStateRouter } from '@components';
import { ITableRefObject } from '@components/Table/types';
import { useI18n, usePortalError, useQueryOnClick } from '@hooks';
import { IConsumerPortalDocuments } from '@schemas/consumerPortal/types/documents';
import {
  downloadFile,
  fetchDataFromEndpoint,
  getDocumentsTableColumns,
  mapDocumentsTableData,
} from '@utils';
import { QueryKeys, TableNames } from '@utils/constants';

import { Endpoints } from '@utils/enums';
import { IDocumentsTableColumn } from './types';

export const Documents = () => {
  const { getLocaleDate, translate } = useI18n();
  const { handleAxiosError } = usePortalError();
  const refToDataTable = useRef<ITableRefObject<IDocumentsTableColumn>>(null);

  const { data, error, isLoading } = useQuery<
    IConsumerPortalDocuments,
    AxiosError
  >([QueryKeys.DOCUMENTS], () =>
    fetchDataFromEndpoint(Endpoints.FetchAllDocuments),
  );

  const useQueryCallbacks = useMemo(
    () => ({
      onSuccess: (url: string) => downloadFile(url),
      onError: (getDocumentsError: AxiosError<IError>) =>
        handleAxiosError(getDocumentsError),
    }),
    [handleAxiosError],
  );

  const { execute } = useQueryOnClick<string, IDocumentsTableColumn>(
    [QueryKeys.DOCUMENT],
    requestObj =>
      fetchDataFromEndpoint(
        `${Endpoints.FetchSingleDocument}/${requestObj.uuid}`,
        {
          query: { documentType: requestObj.documentType },
        },
      ),
    useQueryCallbacks,
  );

  const columns = useMemo(
    () =>
      getDocumentsTableColumns({
        isConsumerPortal: true,
        setSelectedRow: execute,
        translate,
      }),
    [execute, translate],
  );

  const ordersTableData = mapDocumentsTableData({
    data: data?.documents,
    getLocaleDate,
    translate,
  });

  return (
    <QueryStateRouter error={error} isLoading={isLoading}>
      <Card title={translate('TITLE.DOCUMENTS')}>
        <ClientsideTable
          columns={columns}
          data={ordersTableData}
          ref={refToDataTable}
          tableName={TableNames.DOCUMENTS}
        />
      </Card>
    </QueryStateRouter>
  );
};
