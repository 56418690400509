import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import * as Yup from 'yup';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DatePickerRHF } from '@components';
import { useI18n, usePortalError } from '@hooks';
import { useCertificatesOfInterestTab } from '@pages/ContractDetails/hooks';
import {
  IContractDetails,
  IDateTime,
} from '@schemas/opsPortal/types/contractDetails';
import { postDataToEndpoint } from '@utils';
import { Endpoints } from '@utils/enums';

const formatDate = (date: IDateTime) => {
  const dateObj = new Date(date as string);
  return dateObj.toISOString().split('T')[0];
};

interface IUseAddCertificateOfInterestModal {
  closeModal: () => void;
  contractData: IContractDetails;
  externalContractUUID: string;
}

interface IAddCertificateOfInterestForm {
  end_date: IDateTime;
  start_date: IDateTime;
}

export const useAddCertificateOfInterestModal = ({
  closeModal,
  contractData,
  externalContractUUID,
}: IUseAddCertificateOfInterestModal) => {
  const formID = 'addCertificateOfInterest';
  const { translate } = useI18n();
  const { message } = useToast();
  const { handleAxiosError } = usePortalError();
  const queryClient = useQueryClient();

  const schema = Yup.object().shape({
    start_date: Yup.date().required(
      'OPS_PORTAL.VALIDATION.START_DATE_REQUIRED',
    ),
    end_date: Yup.date().required('OPS_PORTAL.VALIDATION.END_DATE_REQUIRED'),
  });

  const { queryKey } = useCertificatesOfInterestTab({ externalContractUUID });
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset } = methods;

  const { isLoading, mutate } = useMutation<
    void,
    AxiosError<IError>,
    IAddCertificateOfInterestForm
  >(
    requestData =>
      postDataToEndpoint({
        endpoint: `${Endpoints.GenerateCertificateOfInterest}/${externalContractUUID}`,
        requestData: {
          ...requestData,
          start_date: formatDate(requestData.start_date),
          end_date: formatDate(requestData.end_date),
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
        message.success(
          translate('OPS_PORTAL.SUCCESSFULLY_GENERATED_CERTIFICATE'),
        );
        closeModal();
      },
      onError: error =>
        handleAxiosError(error, 'OPS_PORTAL.ERROR_GENERATING_CERTIFICATE'),
    },
  );

  const handleGenerateCertificate = useCallback(data => mutate(data), [mutate]);

  useEffect(() => {
    if (contractData?.finalised_at) {
      const finalisedAt = formatDate(contractData.finalised_at);
      reset({
        start_date: finalisedAt,
      });
    }
  }, [contractData, reset]);

  return {
    allowoverflow: true,
    modalContent: (
      <FormProvider {...methods}>
        <form id={formID} onSubmit={handleSubmit(handleGenerateCertificate)}>
          <DatePickerRHF
            isClearable={false}
            label={translate('LABEL.START_DATE')}
            name="start_date"
            showPopperArrow={false}
          />
          <DatePickerRHF
            isClearable={false}
            label={translate('LABEL.END_DATE')}
            name="end_date"
            showPopperArrow={false}
          />
        </form>
      </FormProvider>
    ),
    submitText: translate('BUTTON.SUBMIT'),
    title: translate('OPS_PORTAL.TITLE.ADD_CERTIFICATE_OF_INTEREST'),
    formID,
    isLoading,
  };
};
