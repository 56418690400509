import { useField } from 'formik';
import { ValidationError } from '@components';
import { FormGroup, InputNumber, Label } from '@components/Common.styles.tsx';
import { useI18n } from '@hooks';

export const NumberField = ({
  containerClassName,
  label,
  readOnly,
  ...props
}) => {
  const [field, meta] = useField(props);
  const { translate } = useI18n();

  return (
    <FormGroup className={containerClassName}>
      <Label htmlFor={props.id}>
        {label + (readOnly ? ` (${translate('LABEL.CANNOT_BE_EDITED')})` : '')}
      </Label>
      <InputNumber readOnly={readOnly} {...field} {...props} />
      {meta.touched && meta.error ? (
        <ValidationError error={meta.error} />
      ) : null}
    </FormGroup>
  );
};
