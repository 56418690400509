import { Permissions } from '@appTypes';
import { ModalKeys } from './enums';

const permissionCanViewAcctInfo = 'merchant_portal.can_view_account_info';
const permissionChangeConsumerUser = 'consumer_portal.change_consumeruser';
const permissionRecordPayment = 'ops_portal.record_payment';
const permissionViewMerchant = 'ops_portal.view_merchant';
const permissionViewContract = 'ops_portal.view_contract';

export const modalScopes: Record<ModalKeys, Permissions> = {
  [ModalKeys.AddCertificateOfInterest]: [
    'ops_portal.generate_certificate_of_interests',
  ],
  [ModalKeys.AddNote]: [permissionViewContract],
  [ModalKeys.AdjustContractValue]: [
    'merchant_portal.can_adjust_contract_value',
  ],
  [ModalKeys.AllocationDetails]: ['ops_portal.can_reconcile_bank_account'],
  [ModalKeys.CancelContract]: ['merchant_portal.can_cancel_contract'],
  [ModalKeys.ChangeCardDetails]: [
    'consumer_portal.can_view_payment_plans',
    'merchant_portal.can_view_payment_plans',
  ],
  [ModalKeys.ChangeIban]: ['ops_portal.can_edit_merchant_iban'],
  [ModalKeys.ChangePaymentDate]: ['ops_portal.contract_date_change'],
  [ModalKeys.ConfirmDispatch]: [
    'merchant_portal.can_dispatch_order',
    'ops_portal.can_dispatch_order',
  ],
  [ModalKeys.ContractAdjustments]: [permissionRecordPayment],
  [ModalKeys.ContractManualPayments]: [permissionRecordPayment],
  [ModalKeys.ContractPrepayment]: ['ops_portal.execute_prepayment'],
  [ModalKeys.ContractReimbursement]: [permissionRecordPayment],
  [ModalKeys.CreateApplicantBlock]: ['ops_portal.can_block_applicant'],
  [ModalKeys.CreateUser]: ['merchant_portal.can_edit_user_settings'],
  [ModalKeys.DownloadCustomerAgreement]: [
    'merchant_portal.can_upload_signed_agreement',
  ],
  [ModalKeys.DownloadSettlementsReport]: [
    'merchant_portal.can_view_reconciliation',
  ],
  [ModalKeys.DownloadQrPdfModal]: [permissionViewContract],
  [ModalKeys.DeleteUser]: ['merchant_portal.can_merchant_deactivate_user'],
  [ModalKeys.EarlySettlementsCreateQuote]: [
    'ops_portal.create_early_settlement',
  ],
  [ModalKeys.EarlySettlementsDisplay]: ['ops_portal.fetch_early_settlement'],
  [ModalKeys.EditAddress]: [
    permissionChangeConsumerUser,
    permissionCanViewAcctInfo,
    permissionViewMerchant,
  ],
  [ModalKeys.EditName]: [
    permissionChangeConsumerUser,
    permissionCanViewAcctInfo,
    permissionViewMerchant,
  ],
  [ModalKeys.EditPhone]: [
    permissionChangeConsumerUser,
    permissionCanViewAcctInfo,
    permissionViewMerchant,
  ],
  [ModalKeys.EditUser]: ['merchant_portal.can_edit_user_settings'],
  [ModalKeys.EmailUser]: ['ops_portal.can_send_email_to_consumer'],
  [ModalKeys.FileUpload]: ['merchant_portal.can_upload_signed_agreement'],
  [ModalKeys.ModifyApplicantBlock]: ['ops_portal.can_block_applicant'],
  [ModalKeys.OrderPrepayment]: ['consumer_portal.execute_prepayment'],
  [ModalKeys.OrderRefund]: ['merchant_portal.can_refund_payment_plans'],
  [ModalKeys.Password]: [
    permissionChangeConsumerUser,
    permissionCanViewAcctInfo,
    permissionViewMerchant,
  ],
  [ModalKeys.PausePayments]: [
    'ops_portal.can_toggle_pause_payment_processing',
    'ops_portal.can_pause_payment_processing_till_date',
  ],
  [ModalKeys.PaymentLink]: ['ops_portal.view_contract'],
  [ModalKeys.RemoveApplicantBlock]: ['ops_portal.can_unblock_applicant'],
  [ModalKeys.TriggerPayment]: ['ops_portal.trigger_active_payment'],
  [ModalKeys.UpdateArrearsManagedBy]: [
    'ops_portal.can_update_contract_arrears_managed_by',
  ],
  [ModalKeys.ViewCommunication]: ['ops_portal.can_view_communication_events'],
  [ModalKeys.ViewRawData]: ['ops_portal.view_checkout_api_requests'],
  [ModalKeys.DeleteFees]: ['ops_portal.can_remove_late_fees'],
};
