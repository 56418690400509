/* eslint-disable sonarjs/cognitive-complexity */
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { CollectionAgencies, ICardCTA, ValueOf } from '@appTypes';
import { ConfirmDispatchButton, StatusPill } from '@components';
import { Link } from '@components/Common.styles';
import { Translate } from '@hooks/useI18n';
import { IContractTableData } from '@pages/Contracts/types.d';
import { Views } from '@utils/constants';

interface IGetContractsTableColumns {
  collectionAgencies: CollectionAgencies;
  ctaProps?: ICardCTA;
  isCompass?: boolean;
  isConsumer?: boolean;
  isInternalOpsUser?: boolean;
  setDispatchedUuid?: (uuid: string) => void;
  translate: Translate;
  view: ValueOf<typeof Views>;
}

export const getContractsTableColumns = ({
  collectionAgencies,
  ctaProps,
  isCompass = false,
  isConsumer = false,
  // Defaulting to true so that we don't have to update the call from
  // the consumer details page, which agency users can't see anyway.
  isInternalOpsUser = true,
  setDispatchedUuid,
  translate,
  view,
}: IGetContractsTableColumns) => {
  const isAwaitingDispatch = view === Views.AWAITING_DISPATCH;
  const isContractsByUuid = view === Views.CONTRACTS_BY_UUIDS;

  const columnHelper = createColumnHelper<IContractTableData>();

  const paymentPlanIdColumn = columnHelper.accessor('paymentPlanId', {
    header: translate('TITLE.PAYMENT_PLAN_ID'),
    cell: ({ row }) => (
      <Link
        to={
          isConsumer || isContractsByUuid
            ? `/all_contracts/${row.original.contractId}`
            : row.original.contractId
        }
      >
        {row.original.paymentPlanId}
      </Link>
    ),
  });

  const praticaIdColumn = columnHelper.accessor('praticaId', {
    header: translate('OPS_PORTAL.TITLE.PRATICA_ID'),
    cell: ({ row }) => (
      <Link
        to={
          isConsumer
            ? `/all_contracts/${row.original.contractId}`
            : row.original.contractId
        }
      >
        {row.original.praticaId}
      </Link>
    ),
    enableSorting: false,
  });

  const allColumns = [
    ...(isCompass
      ? [paymentPlanIdColumn, praticaIdColumn]
      : [paymentPlanIdColumn]),
    ...(isInternalOpsUser
      ? [
          columnHelper.accessor('createdDate', {
            header: translate('OPS_PORTAL.TITLE.CREATED_DATE'),
          }),
        ]
      : []),
    columnHelper.accessor('customerName', {
      header: translate('TITLE.CUSTOMER_NAME'),
      enableSorting: false,
    }),
    columnHelper.accessor('merchantName', {
      header: translate('OPS_PORTAL.LABEL.MERCHANT_NAME'),
    }),
    ...(isInternalOpsUser
      ? [
          columnHelper.accessor('activityStatus', {
            header: translate('OPS_PORTAL.LABEL.ACTIVITY_STATUS'),
            cell: ({ row }) => {
              const { activityStatus, rawActivityStatus } = row.original;
              return (
                <StatusPill
                  rawStatus={rawActivityStatus}
                  status={activityStatus}
                />
              );
            },
          }),
          columnHelper.accessor('performanceStatus', {
            header: translate('OPS_PORTAL.LABEL.PERFORMANCE_STATUS'),
            cell: ({ row }) => {
              const { performanceStatus, rawPerformanceStatus } = row.original;
              return (
                <StatusPill
                  rawStatus={rawPerformanceStatus}
                  status={performanceStatus}
                />
              );
            },
            enableSorting: false,
          }),
        ]
      : []),
    columnHelper.accessor('originalAmount', {
      header: translate('OPS_PORTAL.LABEL.ORIGINAL_AMOUNT'),
    }),
    columnHelper.accessor('outstandingBalance', {
      header: translate('TITLE.BALANCE'),
      enableSorting: false,
    }),
    columnHelper.accessor('originationChannel', {
      header: translate('OPS_PORTAL.TITLE.ORIGINATION'),
      enableSorting: false,
    }),
    columnHelper.accessor('arrearsManagedBy', {
      cell: ({ row }) => {
        const { arrearsManagedBy } = row.original;
        return collectionAgencies[arrearsManagedBy] ?? 'unknown';
      },
      header: translate('OPS_PORTAL.TITLE.ARREARS_MANAGED_BY'),
      enableSorting: false,
    }),
    columnHelper.accessor('crifScore', {
      header: translate('OPS_PORTAL.TITLE.CRIF_SCORE'),
      enableSorting: false,
    }),
  ] as ColumnDef<IContractTableData, string>[];

  if (!(isCompass || isConsumer)) {
    allColumns.splice(
      -1,
      0,
      columnHelper.accessor('originator', {
        header: translate('OPS_PORTAL.TITLE.ORIGINATOR'),
        enableSorting: false,
      }),
    );
  }

  if (isConsumer) {
    return allColumns.filter(column =>
      'accessorKey' in column
        ? column.accessorKey !== 'customerName' &&
          column.accessorKey !== 'applicationOutcome' &&
          column.accessorKey !== 'crifScore' &&
          column.accessorKey !== 'arrearsManagedBy'
        : false,
    );
  }

  if (isAwaitingDispatch && ctaProps && setDispatchedUuid) {
    return [
      ...allColumns.filter(column =>
        'accessorKey' in column
          ? column.accessorKey !== 'performanceStatus' &&
            column.accessorKey !== 'activityStatus' &&
            column.accessorKey !== 'applicationOutcome' &&
            column.accessorKey !== 'crifScore'
          : false,
      ),
      columnHelper.display({
        cell: ({ cell }) => (
          <ConfirmDispatchButton
            ctaProps={ctaProps}
            setDispatchedUuid={setDispatchedUuid}
            uuid={cell.row.original.contractId}
          />
        ),
        id: 'ctaColumn',
      }),
    ];
  }

  return allColumns.filter(column =>
    'accessorKey' in column
      ? column.accessorKey !== 'applicationOutcome' &&
        column.accessorKey !== 'crifScore'
      : false,
  );
};
