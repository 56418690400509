import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import { IOpsPortalMonetaContract } from '@schemas/opsPortal/types/monetaContract';
import {
  addPlaceholderForEmptyValues,
  convertBooleanToYesOrNo,
  getTranslatedData,
} from '@utils';
import { TransactionEntityTypes } from '@utils/constants';
import { IMapPaymentData } from './types';

interface IMapPaymentDataProps {
  data?: IOpsPortalMonetaContract;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

export const mapPaymentsData = ({
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
  translate,
}: IMapPaymentDataProps): IMapPaymentData[] =>
  data?.settlements?.map(dataRow => {
    const {
      amount,
      payee,
      prepayment,
      settlement_created_date: settlementCreatedDate,
      settlement_date: settlementDate,
      settlement_reference: settlementReference,
      transacted_amount: transactedAmount,
      transaction_invalidation_date: transactionInvalidationDate,
      type: settlementType,
    } = dataRow;

    const transactedAmountString = getLocaleCurrencyValue({
      currency: transactedAmount?.currency,
      value: transactedAmount?.amount,
    });
    const amountString =
      amount.amount !== transactedAmount.amount ? `/${amount.amount}` : '';

    return addPlaceholderForEmptyValues({
      amount: `${transactedAmountString}${amountString}`,
      customerRefund: convertBooleanToYesOrNo(
        payee.type === TransactionEntityTypes.CONSUMER,
        translate,
      ),
      invalidated: convertBooleanToYesOrNo(
        transactionInvalidationDate !== null,
        translate,
      ),
      prepayment: convertBooleanToYesOrNo(prepayment, translate),
      settlementCreatedDate:
        settlementCreatedDate && getLocaleDate({ date: settlementCreatedDate }),
      settlementDate: settlementDate && getLocaleDate({ date: settlementDate }),
      settlementReference,
      settlementType: getTranslatedData({ term: settlementType, translate }),
      transactionInvalidationDate:
        transactionInvalidationDate &&
        getLocaleDate({ date: transactionInvalidationDate }),
    });
  }) ?? [];
