import { useCallback } from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useI18n, usePortalError } from '@hooks';
import { ModalStatusRouter } from '@hooks/useModal/components/index.ts';
import { postDataToEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { ConfirmDispatchModalContent } from './ConfirmDispatchModalContent';

export const useConfirmDispatchModal = ({
  formStatusMessage,
  resolveForm,
  uuid,
}) => {
  const { translate } = useI18n();

  const queryClient = useQueryClient();
  const { handleAxiosError } = usePortalError();

  const { isLoading, mutate } = useMutation(
    requestData =>
      postDataToEndpoint({
        endpoint: 'orders/confirm-dispatch',
        requestData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.ORDER_DETAILS, uuid]);
        resolveForm(translate('PAGE.ORDERS.DISPATCH_SUCCESSFULLY_CONFIRMED'));
      },
      onError: error => {
        handleAxiosError(error);
      },
    },
  );

  const handleSubmit = useCallback(values => mutate(values), [mutate]);

  const formID = 'confirmDispatchForm';

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={isLoading}
    >
      <ConfirmDispatchModalContent
        formID={formID}
        handleSubmit={handleSubmit}
        uuid={uuid}
      />
    </ModalStatusRouter>
  );

  return {
    formID,
    isDangerousSubmit: true,
    isFormProcessing: isLoading,
    isLoading,
    modalContent,
    submitText: translate('BUTTON.CONFIRM'),
    title: translate('TITLE.CONFIRM_DISPATCH'),
  };
};
