import { DataDisplay } from '@components';
import { useI18n, useOriginator, usePortal } from '@hooks';
import { getDataAndLabels } from '@pages/OrderDetails/utils';
import { getDataList } from '@utils';

export const OrderSummary = (props: Record<string, unknown>) => {
  const { translate } = useI18n();
  const { isPagolightPro } = useOriginator();
  const { isMerchantPortal } = usePortal();

  const { data, labels } = getDataAndLabels({
    isMerchantPortal,
    isPagolightPro,
    props,
    translate,
  });

  const dataList = getDataList({ data, labels });
  return <DataDisplay dataList={dataList} />;
};
