import { Fragment } from 'react';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { ModalKeys, useHasPermission, useI18n, useModal } from '@hooks';
import { IModal } from '@hooks/useModal/types';
import { BlockedApplicant } from '@schemas/opsPortal/types/blockedApplicants';
import {
  IApplicantBlockStatus,
  IOpsPortalBlockedStatus,
} from '@schemas/opsPortal/types/blockedStatus';
import { fetchDataFromEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { ApplicantBlockActions, Endpoints, PageRoutes } from '@utils/enums';
import { getNotification } from './getNotification';

interface IApplicantBlockingProps {
  applicantUuid?: string;
  consumerUuid?: string;
  contractUuid?: string;
}

export const useApplicantBlocking = ({
  applicantUuid = '',
  consumerUuid = '',
  contractUuid = '',
}: IApplicantBlockingProps) => {
  const { getLocaleDate, translate } = useI18n();

  const uuid = applicantUuid || consumerUuid || contractUuid;

  const canViewBlockStatus = useHasPermission([
    'ops_portal.can_view_blocked_applicants',
  ]);

  const isFetchEnabled = canViewBlockStatus && Boolean(uuid);

  const {
    data: blockStatusData,
    error: blockStatusError,
    isLoading: blockStatusIsLoading,
  } = useQuery<IOpsPortalBlockedStatus, AxiosError>(
    [QueryKeys.IS_BLOCKED, uuid],
    () =>
      fetchDataFromEndpoint(Endpoints.FetchApplicantBlockStatus, {
        query: {
          applicant: applicantUuid,
          consumer: consumerUuid,
          contract: contractUuid,
        },
      }),
    {
      enabled: isFetchEnabled,
    },
  );

  const blockStatus = blockStatusData?.hasOwnProperty('is_blocked')
    ? (blockStatusData as IApplicantBlockStatus)
    : undefined;
  const isBlocked = Boolean(blockStatus?.is_blocked);
  const isPrimaryBlock = Boolean(isBlocked && blockStatus?.primary_block);
  const associations =
    (blockStatus?.block_associations &&
      Object.keys(blockStatus.block_associations).join(', ')) ??
    '';

  const isBlockedApplicantsListPage =
    window.location.pathname === PageRoutes.BlockedApplicants;

  const {
    data: blockDetails,
    error: blockDetailsError,
    isLoading: blockDetailsIsLoading,
  } = useQuery<BlockedApplicant, AxiosError>(
    [QueryKeys.BLOCKED_APPLICANTS, uuid],
    () =>
      fetchDataFromEndpoint(Endpoints.FetchBlockedApplicantDetails, {
        query: {
          applicant_uuid: applicantUuid,
          consumer_uuid: consumerUuid,
          contract_uuid: contractUuid,
        },
      }),
    {
      enabled: isPrimaryBlock && !isBlockedApplicantsListPage,
    },
  );

  const createApplicantBlockModal = useModal(ModalKeys.CreateApplicantBlock, {
    action: ApplicantBlockActions.Create,
    applicantUuid,
    consumerUuid,
    contractUuid,
    dependencies: [Boolean(blockStatus), !isBlocked],
  });

  const modifyApplicantBlockModal = useModal(ModalKeys.ModifyApplicantBlock, {
    action: ApplicantBlockActions.Modify,
    applicantUuid,
    consumerUuid,
    contractUuid,
    dependencies: [isBlocked && isPrimaryBlock],
  });

  const removeApplicantBlockModal = useModal(ModalKeys.RemoveApplicantBlock, {
    action: ApplicantBlockActions.Remove,
    applicantUuid,
    consumerUuid,
    contractUuid,
    dependencies: [isBlocked && isPrimaryBlock],
  });

  const modals = [
    createApplicantBlockModal,
    modifyApplicantBlockModal,
    removeApplicantBlockModal,
  ].filter((val): val is IModal => Boolean(val));

  const notification = getNotification({
    associations,
    blockDetails,
    getLocaleDate,
    id: uuid,
    isBlocked,
    isPrimaryBlock,
    translate,
  });

  return {
    applicantBlockModalCtaProps: modals.map(modal => modal.ctaProps),
    applicantBlockModals: modals.map(modal => (
      <Fragment key={modal.ctaProps.key}>{modal.Modal}</Fragment>
    )),
    error: blockStatusError ?? blockDetailsError,
    isLoading:
      (isFetchEnabled && blockStatusIsLoading) ||
      (isPrimaryBlock && blockDetailsIsLoading),
    notification,
  };
};
