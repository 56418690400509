import { capitalize } from '@utils';
import { ArrearsManagedByKeys } from '@utils/enums';

export const getCollectionAgenciesFromEnum = () =>
  Object.values(ArrearsManagedByKeys).reduce(
    (agencies, agency) => ({
      ...agencies,
      [agency]: agency
        .split(/\s+/)
        .map(word => capitalize(word))
        .join(' '),
    }),
    {},
  );
