import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useI18n } from '@hooks';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { getErrorMessage, postDataToEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { TriggerPaymentForm } from './TriggerPaymentForm';
import {
  IUseTriggerPaymentModalProps,
  IPayload,
  ISubmitHandlerProps,
  IMutationResponse,
} from './types';

export const useTriggerPaymentModal = ({
  formStatusMessage,
  remainderAmount,
  resolveForm,
  uuid,
}: IUseTriggerPaymentModalProps) => {
  const { translate } = useI18n();

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<
    IMutationResponse,
    AxiosError<IError>,
    IPayload
  >(
    data => {
      const endpoint = `${Endpoints.TriggerSinglePayment}/${uuid}`;
      return postDataToEndpoint({ endpoint, requestData: data });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.CONTRACT_DETAILS_DATA, uuid]);
        queryClient.invalidateQueries([QueryKeys.MONETA_CONTRACT_DATA, uuid]);
        resolveForm(
          translate('OPS_PORTAL.STATUS.PAYMENT_SUCCESSFULLY_TRIGGERED'),
        );
      },
      onError: error => {
        resolveForm(
          getErrorMessage({
            error,
            genericErrorMessageKey:
              'OPS_PORTAL.STATUS.UNABLE_TO_TRIGGER_PAYMENT',
            translate,
          }),
        );
      },
    },
  );

  const { amount: maxAmount, currency } = remainderAmount ?? {};

  const handleSubmit = useCallback(
    (data: ISubmitHandlerProps) => {
      mutate({ partial_payment: { amount: data.amount, currency } });
    },
    [currency, mutate],
  );

  const formID = 'trigger-active-payment';

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={isLoading}
    >
      <TriggerPaymentForm
        currency={currency}
        formID={formID}
        handleSubmit={handleSubmit}
        maxAmount={maxAmount}
      />
    </ModalStatusRouter>
  );

  return {
    formID,
    isFormProcessing: isLoading,
    isLoading,
    modalContent,
    title: translate('OPS_PORTAL.TITLE.TRIGGER_PAYMENT'),
  };
};
