import { DataDisplay } from '@components';
import { useI18n } from '@hooks';
import { getDataList } from '@utils';
import { SummaryData, SummaryDataKey } from './types';

export const SettlementSummary = (summaryData: Partial<SummaryData>) => {
  const { translate } = useI18n();

  const fields = {
    settlementClawback: translate('PAGE.SETTLEMENTS.TOTAL_CLAWBACK'),
    settlementDate: translate('PAGE.SETTLEMENTS.SETTLEMENT_DATE'),
    settlementFees: translate('PAGE.SETTLEMENTS.TOTAL_FEES'),
    settlementId: translate('PAGE.SETTLEMENTS.SETTLEMENT_ID'),
    settlementReference: translate('PAGE.SETTLEMENTS.SETTLEMENT_REFERENCE'),
    settlementRefund: translate('PAGE.SETTLEMENTS.TOTAL_REFUND_VALUE'),
    settlementSales: translate('PAGE.SETTLEMENTS.ORDER_VALUE_SALES'),
    totalSettled: translate('PAGE.SETTLEMENTS.TOTAL_SETTLED'),
  };

  const { data, labels } = (
    Object.keys(summaryData) as SummaryDataKey[]
  ).reduce(
    (obj, key) => ({
      data: { ...obj.data, [key]: summaryData[key] },
      labels: { ...obj.labels, [key]: fields[key] },
    }),
    { data: {}, labels: {} },
  );

  const dataList = getDataList({ data, labels });

  return <DataDisplay dataList={dataList} />;
};
