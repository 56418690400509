import { Card, DataDisplay } from '@components';
import { IDataRow } from '@components/DataDisplay/DataDisplay';
import { useI18n } from '@hooks';
import { IOpsPortalUnbalancedAllocationDetails } from '@schemas/opsPortal/types/unbalancedAllocationDetails';
import { addPlaceholderForEmptyValues } from '@utils';

interface ICardContentProps {
  data: IOpsPortalUnbalancedAllocationDetails;
}

export const DetailsCard = ({ data }: ICardContentProps) => {
  const { getLocaleCurrencyValue, translate } = useI18n();

  const {
    allocation_balance: allocationBalance,
    days_minus_one_balance: dayMinusOneBalance,
    moneta_balance: monetaBalance,
    par_group_reference: parGroup,
    payment_plan_reference: paymentPlanReference,
    real_balance: realBalance,
  } = data;

  const dataList = [
    {
      key: translate('OPS_PORTAL.LABEL.PAR_GROUP'),
      value: parGroup,
    },
    {
      key: translate('OPS_PORTAL.LABEL.AGREEMENT'),
      value: {
        linkTo: `/all_contracts/${data.contract_uuid}`,
        text: paymentPlanReference,
      },
    },
    {
      key: translate('OPS_PORTAL.LABEL.MONETA_BALANCE'),
      value:
        monetaBalance &&
        getLocaleCurrencyValue({
          currency: monetaBalance.currency,
          value: monetaBalance.amount,
        }),
    },
    {
      key: translate('OPS_PORTAL.LABEL.ALLOCATION_BALANCE'),
      value:
        allocationBalance &&
        getLocaleCurrencyValue({
          currency: allocationBalance.currency,
          value: allocationBalance.amount,
        }),
    },
    {
      key: translate('OPS_PORTAL.LABEL.REAL_BALANCE'),
      value:
        realBalance &&
        getLocaleCurrencyValue({
          currency: realBalance.currency,
          value: realBalance.amount,
        }),
    },
    {
      key: translate('OPS_PORTAL.LABEL.DAY_MINUS_ONE_BALANCE'),
      value:
        dayMinusOneBalance &&
        getLocaleCurrencyValue({
          currency: dayMinusOneBalance.currency,
          value: dayMinusOneBalance.amount,
        }),
    },
  ].map(val => addPlaceholderForEmptyValues<IDataRow>(val));

  return (
    <Card title={translate('OPS_PORTAL.LABEL.PAR_GROUP_DETAILS')}>
      <DataDisplay dataList={dataList} />
    </Card>
  );
};
