import { useEffect, useRef, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useI18n, useSearchParamsState } from '@hooks';
import { deepEquals } from '@utils';
import { initialQueryState } from '@utils/constants';
import {
  checkIsStringBase64Encoded,
  createSearchParamsObject,
  getFilters,
  getStateFromQueryParams,
  getTableDefaults,
  getTableParams,
  getTableUsesCursorPagination,
  getUnencodedSearchParams,
  getUpdatedQueryParams,
  normaliseQueryUpdateState,
} from './utils';
import {
  IFilter,
  IQueryState,
  ITableNames,
  ITableSpecificDefaults,
} from './utils/types';

export const useQueryState = (
  tableName: ITableNames,
  tableSpecificDefaults?: ITableSpecificDefaults,
) => {
  const { translate } = useI18n();

  // This is used to ensure that the useEffects aren't triggered
  // by the initial state configuration.
  const didMountRef = useRef(false);

  const activateQueryStateHook = () => {
    didMountRef.current = true;
  };

  // Get default state, needed even if overridden by search params.
  const initialStateWithDefaults = useMemo(
    () =>
      getTableDefaults({
        initialQueryState: getTableUsesCursorPagination(tableName)
          ? { ...initialQueryState, cursor: '' }
          : initialQueryState,
        tableName,
        tableSpecificDefaults,
      }),
    [tableName, tableSpecificDefaults],
  );

  // Get state from search params.
  const [searchParamsState, setSearchParamsState] =
    useSearchParamsState(tableName);

  // Putting this in for backwards-compatibility,
  // in case anyone is saving/sharing/using encoded urls.
  const isQueryEncoded = searchParamsState
    ? checkIsStringBase64Encoded(searchParamsState)
    : false;
  const searchParamsObject = useMemo(
    () =>
      createSearchParamsObject(
        isQueryEncoded
          ? getUnencodedSearchParams(searchParamsState)
          : searchParamsState,
      ),
    [isQueryEncoded, searchParamsState],
  );

  // Pass the initial state, which will be the initial value
  // of the accumulator in the reduce method.
  const stateFromQueryParams = getStateFromQueryParams({
    initialState: initialStateWithDefaults,
    searchParams: searchParamsObject,
  });

  const [queryState, setQueryState] = useState(stateFromQueryParams);

  const { pathname, search } = useLocation();

  useEffect(() => {
    if (didMountRef.current) {
      const currentQueryState = getStateFromQueryParams({
        initialState: initialStateWithDefaults,
        searchParams: searchParamsObject,
      });

      setQueryState(currentQueryState);
    }
  }, [initialStateWithDefaults, pathname, search, searchParamsObject]);

  const queryParams = getTableParams({ queryState, tableName });

  const updatePaginationState = (newQueryState: IQueryState) => {
    if (
      !deepEquals(newQueryState.columnFilters, queryState.columnFilters) ||
      newQueryState.globalFilter !== queryState.globalFilter
    ) {
      return {
        ...newQueryState,
        pagination: {
          pageIndex: initialStateWithDefaults.pagination.pageIndex,
          pageSize:
            newQueryState.pagination?.pageSize ?? newQueryState.pageSize,
        },
      };
    }

    return newQueryState;
  };

  const updateQueryParams = (newQueryState: object) => {
    if (didMountRef.current) {
      const resolvedState = normaliseQueryUpdateState({
        newQueryState,
        queryState,
      });

      // reset pagination to defaults if column or global filtering changes
      const repaginatedQueryState = updatePaginationState(resolvedState);

      const updatedParams = getUpdatedQueryParams({
        defaultState: initialStateWithDefaults,
        queryState: repaginatedQueryState,
      });

      setSearchParamsState(updatedParams);
    }
  };

  const resetFilters = () => {
    updateQueryParams(initialStateWithDefaults);
  };

  const stringifiedQueryParams = new URLSearchParams(
    Object.entries(queryParams).map(([key, value]) => [key, String(value)]),
  ).toString();

  return {
    activateQueryStateHook,
    getFilters: (props?: Partial<IFilter>) =>
      getFilters({ queryState, tableName, queryParams, translate, ...props }),
    queryState,
    queryParams,
    resetFilters,
    stringifiedQueryParams,
    updateQueryParams,
  };
};
