import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { ValueOf } from '@appTypes';
import { selectPortal } from '@selectors/index';
import { getIsAuthPage } from '@utils';
import { Portals } from '@utils/constants';
import { Endpoints } from '@utils/enums';

type Portal = ValueOf<typeof Portals> | undefined;

export const usePortal = () => {
  const { pathname } = useLocation();
  const isAuthPage = getIsAuthPage(pathname);

  // Because of the rules of hooks, we have to use the selectors regardless,
  // though they will return null in auth pages.
  const portalFromSelector = useSelector(selectPortal);

  const getPortalFromEnv = async () => {
    const response = await axios.get(`/${Endpoints.FetchPortal}`);
    return response.data.portal;
  };

  const getPortalFromHostname = () => {
    const { hostname } = window.location;
    const re = /(consumer|merchant|ops)-portal/;
    return re.exec(hostname)?.[1] as Portal;
  };

  const [portal, setPortal] = useState<Portal>(portalFromSelector);

  useEffect(() => {
    const setPortalFromAuthPage = async () => {
      try {
        const portalFromEnv = await getPortalFromEnv();
        setPortal(portalFromEnv);
      } catch (_) {
        // This isn't ideal, but I think it's preferable to parse
        // the host name than return a nullish value for portal.
        // The reason it isn't ideal is because there was some
        // discussion a few years ago that we might have portals
        // that do not include the portal name in the host name,
        // so this potentially is brittle. However, that hasn't
        // happened yet, and we're only doing this when the fetch
        // from /portal fails.
        const portalFromHostname = getPortalFromHostname();
        setPortal(portalFromHostname);
      }
    };

    if (isAuthPage) {
      setPortalFromAuthPage();
    } else {
      setPortal(portalFromSelector);
    }
  }, [isAuthPage, portalFromSelector]);

  return {
    isConsumerPortal: portal === Portals.CONSUMER,
    isMerchantPortal: portal === Portals.MERCHANT,
    isOpsPortal: portal === Portals.OPS,
    portal,
  };
};
