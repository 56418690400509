import { GetLocaleDate } from '@hooks/useI18n';
import { IEditUserGroups } from '@schemas/merchantPortal/types/editUserGroups';
import { addPlaceholderForEmptyValues, getFullName } from '@utils';
import { IGetUserTableData } from './types';

interface IGetUsersTableData {
  getLocaleDate: GetLocaleDate;
  users?: IEditUserGroups;
}

export const getUsersTableData = ({
  getLocaleDate,
  users,
}: IGetUsersTableData) =>
  users?.map(user =>
    addPlaceholderForEmptyValues<IGetUserTableData>({
      ctaColumn: { ...user },
      group: user.group,
      lastLogin:
        user.last_login &&
        getLocaleDate({ date: user.last_login, includeTime: true }),
      phoneNumber: user?.phone_number,
      userID: user.email,
      userName: getFullName({
        firstName: user.first_name,
        lastName: user.last_name,
      }),
    }),
  );
