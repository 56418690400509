import { CollectionAgencies } from '@appTypes';
import { ITabData } from '@components/Tabs/types';
import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import { IApplications } from '@schemas/opsPortal/types/applications';
import { IOpsPortalConsumerDetails } from '@schemas/opsPortal/types/consumerDetails';
import {
  getApplicationsTableColumns,
  getContractsTableColumns,
  mapApplicationsTableData,
  mapContractsTableData,
} from '@utils';
import {
  ContractStatuses,
  Portals,
  TabEventKeys,
  TableNames,
  Views,
} from '@utils/constants';
import { getDataList } from './getDataList';

interface IMapTabDataProps {
  applications?: IApplications;
  collectionAgencies: CollectionAgencies;
  data: IOpsPortalConsumerDetails;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  isMerchantPortal: boolean;
  translate: Translate;
}

export const mapTabData = ({
  applications,
  collectionAgencies,
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
  isMerchantPortal,
  translate,
}: IMapTabDataProps): ITabData[] => {
  const {
    consumer_details: { contracts },
  } = data;

  const contractsColumns = getContractsTableColumns({
    collectionAgencies,
    isConsumer: true,
    translate,
    view: Views.CONTRACTS,
  });

  const applicationsColumns = getApplicationsTableColumns({
    portal: Portals.CONSUMER,
    translate,
  });

  const activeContracts = contracts?.filter(contract =>
    [ContractStatuses.ACTIVE, ContractStatuses.AWAITING_DISPATCH].some(
      status => status === contract.status,
    ),
  );
  const closedContracts = contracts?.filter(contract =>
    (
      [
        ContractStatuses.CLOSED,
        ContractStatuses.TERMINATED,
        ContractStatuses.TERMINATED_AND_PAID,
        ContractStatuses.WRITTEN_OFF,
        ContractStatuses.WRITTEN_OFF_AND_PAID,
      ] as string[]
    ).includes(contract.status),
  );

  return [
    {
      dataList: getDataList({ data, getLocaleDate, translate }),
      eventKey: TabEventKeys.CONSUMER_DETAILS,
      title: translate('OPS_PORTAL.TITLE.CONSUMER_DETAILS'),
    },
    {
      columns: contractsColumns,
      data: mapContractsTableData({
        data: activeContracts,
        getLocaleCurrencyValue,
        getLocaleDate,
        translate,
      }),
      eventKey: TabEventKeys.CONSUMER_ACTIVE_CONTRACTS,
      tableName: TableNames.CONSUMER_ACTIVE_CONTRACTS,
      title: translate('OPS_PORTAL.TITLE.ACTIVE_CONTRACTS'),
    },
    {
      columns: contractsColumns,
      data: mapContractsTableData({
        data: closedContracts,
        getLocaleCurrencyValue,
        getLocaleDate,
        translate,
      }),
      eventKey: TabEventKeys.CONSUMER_CLOSED_CONTRACTS,
      tableName: TableNames.CONSUMER_CLOSED_CONTRACTS,
      title: translate('OPS_PORTAL.TITLE.CLOSED_CONTRACTS'),
    },
    {
      columns: applicationsColumns,
      data: mapApplicationsTableData({
        data: applications,
        getLocaleCurrencyValue,
        getLocaleDate,
        translate,
        isMerchantPortal,
      }),
      eventKey: TabEventKeys.CONSUMER_APPLICATIONS,
      tableName: TableNames.CONSUMER_APPLICATIONS,
      title: translate('TITLE.APPLICATIONS'),
    },
  ];
};
