import { useEffect, useState, useCallback } from 'react';
import QRCode from 'qrcode';
import { Loading } from '@components/Loading';

interface IQrCode {
  altText: string;
  size?: number;
  text: string;
}

export function QrCode({ altText, size = 200, text }: IQrCode) {
  const [dataUrl, setDataUrl] = useState('');

  const generateQrCode = useCallback(() => {
    if (text.length) {
      QRCode.toDataURL(text).then(url => setDataUrl(url));
    }
  }, [text]);

  useEffect(generateQrCode, [generateQrCode]);

  if (dataUrl === '') {
    return <Loading />;
  }

  return <img alt={altText} height={size} src={dataUrl} width={size} />;
}
