import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/';
import { ReduxPersistKeys } from '@utils/constants';
import { filterUserProfilesByLoginPage } from './filterUserProfilesByLoginPage';

export const actionTypes = {
  Logout: 'LOGOUT',
  SetOriginatorFromPath: 'SET_ORIGINATOR_FROM_PATH',
  SetOriginatorFromPicker: 'SET_ORIGINATOR_FROM_PICKER',
  SetOriginatorFromUser: 'SET_ORIGINATOR_FROM_USER',
  SetPathnamePrefix: 'SET_PATHNAME_PREFIX',
  SetUserProfiles: 'SET_USER_PROFILES',
};

const initialOriginatorState = {
  originatorFromPath: '',
  originatorFromUser: '',
  pathnamePrefix: null,
  userProfiles: [],
};

const persistSettings = {
  storage,
  key: ReduxPersistKeys.ORIGINATOR,
};

export const originatorReducer = persistReducer(
  persistSettings,
  (state = initialOriginatorState, action = {}) => {
    if (action.type === actionTypes.Logout) {
      return {
        ...state,
        originatorFromUser: initialOriginatorState.originatorFromUser,
        userProfiles: initialOriginatorState.userProfiles,
      };
    }

    if (action.type === actionTypes.SetOriginatorFromPath) {
      const { originatorFromPath } = action.payload;
      return { ...state, originatorFromPath };
    }

    if (action.type === actionTypes.SetOriginatorFromPicker) {
      const { originatorFromPicker } = action.payload;
      // The profile picker will overwrite the user originator, not the path originator.
      return { ...state, originatorFromUser: originatorFromPicker };
    }

    if (action.type === actionTypes.SetOriginatorFromUser) {
      const { profiles } = action.payload;
      const originatorFromUser = filterUserProfilesByLoginPage({
        loginPageOriginator: state.originatorFromPath,
        profiles,
      });

      return { ...state, originatorFromUser };
    }

    if (action.type === actionTypes.SetPathnamePrefix) {
      const { pathnamePrefix } = action.payload;
      return { ...state, pathnamePrefix };
    }

    if (action.type === actionTypes.SetUserProfiles) {
      const { userProfiles } = action.payload;
      return {
        ...state,
        userProfiles: userProfiles.map(profile => profile.originator_name),
      };
    }

    return state;
  },
);

export const originatorActions = {
  logout: () => ({
    type: actionTypes.Logout,
  }),

  setOriginatorFromPath: originatorFromPath => ({
    type: actionTypes.SetOriginatorFromPath,
    payload: { originatorFromPath },
  }),

  setOriginatorFromPicker: originatorFromPicker => ({
    type: actionTypes.SetOriginatorFromPicker,
    payload: { originatorFromPicker },
  }),

  setOriginatorFromUser: user => {
    const profiles = user?.user_profiles;

    return {
      type: actionTypes.SetOriginatorFromUser,
      payload: { profiles },
    };
  },

  setPathnamePrefix: pathnamePrefix => ({
    type: actionTypes.SetPathnamePrefix,
    payload: { pathnamePrefix },
  }),

  SetUserProfiles: user => {
    const userProfiles = user?.user_profiles || [];

    return {
      type: actionTypes.SetUserProfiles,
      payload: { userProfiles },
    };
  },
};
