import { useEffect, useMemo, useRef } from 'react';
import { AxiosError } from 'axios';
import { useQueries } from '@tanstack/react-query';
import { Card, QueryStateRouter, ServersideTable } from '@components';
import { ITableRefObject } from '@components/Table/types';
import {
  useCollectionAgencies,
  useI18n,
  useOriginator,
  useQueryState,
} from '@hooks';
import { IOpsPortalCollections } from '@schemas/opsPortal/types/collections';
import { IOpsPortalMerchantsList } from '@schemas/opsPortal/types/merchantsList';
import { fetchDataFromEndpoint, getMerchantsOptions } from '@utils';
import { QueryKeys, TableNames } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { getCollectionsTableColumns } from './getCollectionsTableColumns';
import { mapCollectionsTableData } from './mapCollectionsTableData';
import { ICollectionsTableData } from './types';

export const Collections = () => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();
  const { originator } = useOriginator();

  const {
    activateQueryStateHook,
    getFilters,
    queryParams,
    queryState,
    resetFilters,
    stringifiedQueryParams,
    updateQueryParams,
  } = useQueryState(TableNames.COLLECTIONS);

  const [
    {
      data: collectionsData,
      error: collectionsError,
      isLoading: isCollectionsLoading,
    },
    {
      data: merchantsData,
      error: merchantsError,
      isLoading: isMerchantsLoading,
    },
  ] = useQueries<
    [[IOpsPortalCollections, AxiosError], [IOpsPortalMerchantsList, AxiosError]]
  >({
    queries: [
      {
        queryKey: [QueryKeys.COLLECTIONS_TABLE_DATA, stringifiedQueryParams],
        queryFn: () =>
          fetchDataFromEndpoint(Endpoints.FetchCollections, {
            query: queryParams,
          }),
      },
      {
        queryKey: [QueryKeys.FULL_MERCHANT_LIST],
        queryFn: () =>
          fetchDataFromEndpoint(Endpoints.FetchMerchantsAll, {
            query: { originator },
          }),
      },
    ],
  });

  const { collectionAgencies, isCollectionAgenciesLoading } =
    useCollectionAgencies();

  const columns = useMemo(
    () => getCollectionsTableColumns(translate, collectionAgencies),
    [collectionAgencies, translate],
  );

  const collectionsTableData = mapCollectionsTableData({
    data: collectionsData?.results,
    getLocaleCurrencyValue,
    getLocaleDate,
  });

  const refToDataTable = useRef<ITableRefObject<ICollectionsTableData>>(null);

  const merchantsOptions = useMemo(
    () => getMerchantsOptions(merchantsData),
    [merchantsData],
  );

  const filters = getFilters({ merchantsOptions });

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  return (
    <Card title={translate('OPS_PORTAL.TITLE.COLLECTIONS')}>
      <QueryStateRouter
        error={collectionsError || merchantsError}
        isLoading={
          isCollectionsLoading ||
          isMerchantsLoading ||
          isCollectionAgenciesLoading
        }
      >
        <ServersideTable
          columns={columns}
          data={collectionsTableData}
          filters={filters}
          manageControlledState={updateQueryParams}
          pagination={{
            limit: queryParams.limit,
            totalItems: collectionsData?.count,
          }}
          ref={refToDataTable}
          resetFilters={resetFilters}
          tableState={queryState}
        />
      </QueryStateRouter>
    </Card>
  );
};
