import { Translate } from '@heidi-pay/heidi-common-fe/types';
import { IMerchantSettingObservationViewConfigSettingJsonQrCode } from '@schemas/merchantPortal/types/accountSettings';
import { IMerchantPortalTracking } from '@schemas/merchantPortal/types/tracking';
import { IFormatters, ISummaryField } from '../types';
import { fieldTransform } from './fieldTransform';

const keysToTranslate = ['contract-uuid'];

const translateKey = (key: string, translate: Translate) =>
  keysToTranslate.includes(key)
    ? translate(`ORDER_TRACKING.KEY.${key.toUpperCase().replace('-', '_')}`)
    : key;

export const constructedQrStr = (
  object: object,
  delimiter: string,
  translate: Translate,
) =>
  Object.entries(object)
    .map(([key, value]) => `${translateKey(key, translate)}:${value}`)
    .join(delimiter);

const transformQrCode = (
  data: IMerchantPortalTracking,
  fields: ISummaryField[],
  formatters: IFormatters,
  translate: Translate,
  delimiter = ', ',
) => {
  const obj: Record<string, unknown> = {};

  fields.forEach(({ key, options, value }) => {
    const transformed = fieldTransform(value, data, formatters, translate);

    if (transformed || !options?.hideIfEmpty) {
      obj[key] = transformed;
    }
  });

  return constructedQrStr(obj, delimiter, translate);
};

export const getQrTransform =
  (
    qrCodeConfig: IMerchantSettingObservationViewConfigSettingJsonQrCode,
    formatters: IFormatters,
    translate: Translate,
  ) =>
  (data: IMerchantPortalTracking) =>
    transformQrCode(
      data,
      qrCodeConfig.fields as ISummaryField[],
      formatters,
      translate,
      qrCodeConfig.delimiter,
    );

export const defaultQrCodeFields: IMerchantSettingObservationViewConfigSettingJsonQrCode =
  {
    delimiter: '; ',
    fields: [
      { key: 'contract-uuid', value: 'contract_uuid' },
      { key: 'phone-number', value: 'phoneNumber' },
      { key: 'first-name', value: 'firstName' },
      { key: 'last-name', value: 'lastName' },
      { key: 'street', value: 'street' },
      { key: 'house-number', value: 'streetNumber' },
      { key: 'zip-code', value: 'zipCode' },
      { key: 'city', value: 'city' },
      { key: 'language-code', value: 'languageCode' },
      { key: 'contract-duration', value: 'contractDuration' },
      { key: 'products', value: 'products', options: { hideIfEmpty: true } },
    ],
  };
