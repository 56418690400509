import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useI18n } from '@hooks';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { IFormModalProps, IModalProps } from '@hooks/useModal/types';
import {
  downloadPdfFromBlob,
  getErrorMessage,
  postDataToEndpoint,
} from '@utils';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { ArrearsAmountForm } from './ArrearsAmountForm';

interface IDownloadQrPdfModalProps extends IFormModalProps {
  uuid: string;
}

export const useDownloadQrPdfModal = ({
  formStatusMessage,
  resolveForm,
  uuid,
}: IDownloadQrPdfModalProps): IModalProps => {
  const { translate } = useI18n();

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<ArrayBuffer, AxiosError, void>(
    requestData =>
      postDataToEndpoint({
        endpoint: `${Endpoints.DownloadQrPdf}/${uuid}`,
        requestData,
        config: { responseType: 'arraybuffer' },
      }),
    {
      onSuccess: response => {
        downloadPdfFromBlob({
          filename: `${uuid}_QR_Payment`,
          pdfData: response,
        });
        queryClient.invalidateQueries([QueryKeys.DOWNLOAD_QR_PDF, uuid]);
        resolveForm(translate('OPS_PORTAL.PAGE.CONTRACTS.PDF_DOWNLOADED'));
      },
      onError: error => {
        resolveForm(
          getErrorMessage({
            error,
            genericErrorMessageKey: 'ERROR.FILE_DOWNLOAD',
            translate,
          }),
        );
      },
    },
  );

  const handleSubmit = useCallback(
    values => {
      mutate(values);
    },
    [mutate],
  );

  const formID = 'download-qr-pdf';

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={isLoading}
    >
      <ArrearsAmountForm formID={formID} handleSubmit={handleSubmit} />
    </ModalStatusRouter>
  );

  return {
    formID,
    isLoading,
    modalContent,
    title: translate('OPS_PORTAL.TITLE.DOWNLOAD_QR_PDF'),
  };
};
