import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import {
  ThemeProvider as ThemeProviderV5,
  createTheme as createV5Theme,
} from '@mui/material';

const theme = createTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    // direction: "rtl",
    typography: {
      fontFamily: ['Poppins'].join(','),
    },

    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#17c191',
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        main: '#3783e7',
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: '#f018a6',
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      tooltip: {
        light: '#f3f6f9',
        main: '#3f4254',
      },
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: false, // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1,
      },
    },
  },
);

const themeV5 = createV5Theme(theme);

export function MaterialThemeProvider(props) {
  const { children } = props;

  return (
    <ThemeProvider theme={theme}>
      <ThemeProviderV5 theme={themeV5}>{children}</ThemeProviderV5>
    </ThemeProvider>
  );
}
