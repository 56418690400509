import { AxiosRequestConfig } from 'axios';
import { EnhancedStore } from '@reduxjs/toolkit';
import { actions } from '@auth/_redux';
import { checkIfCanRedirect } from './checkIfCanRedirect';

interface IVerifySessionProps {
  config: AxiosRequestConfig;
  store: EnhancedStore;
}

export const verifySession = ({ config, store }: IVerifySessionProps) => {
  const {
    auth: { authToken },
  } = store.getState();

  if (authToken) {
    const decodedToken = JSON.parse(window.atob(authToken.split('.')[1]));

    const sessionExpiresAt = decodedToken.exp * 1000;
    const currentTime = Date.now();
    const isSessionExpired = sessionExpiresAt < currentTime;

    if (isSessionExpired && checkIfCanRedirect(config.url)) {
      const { dispatch } = store;
      dispatch(actions.logout());
    }
  }

  return config;
};
