import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { ValueOf } from '@appTypes';
import { QueryStateRouter } from '@components';
import { CommunicationIframe } from '@components/Common.styles';
import { useConfig } from '@hooks/useConfig';
import { ModalSize } from '@hooks/useModal/components/enums';
import {
  IFormModalProps,
  IModalProps,
  ISharedModalProps,
} from '@hooks/useModal/types';
import { IOpsPortalCommunicationsEvent } from '@schemas/opsPortal/types/communicationsEvent';
import { fetchDataFromEndpoint, getDisplayData } from '@utils';
import { CommunicationsTypes, QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';

interface IViewCommunicationModalProps
  extends IFormModalProps,
    ISharedModalProps {
  title: string;
  type: ValueOf<typeof CommunicationsTypes>;
  uuid: string;
}

export const useViewCommunicationModal = ({
  isModalShowing,
  title,
  type,
  uuid,
}: IViewCommunicationModalProps): IModalProps => {
  const { data, error, isLoading } = useQuery<
    IOpsPortalCommunicationsEvent,
    AxiosError
  >(
    [QueryKeys.COMMUNICATIONS_EVENT, type, uuid],
    () =>
      fetchDataFromEndpoint(Endpoints.FetchCommunicationsEvent, {
        search: uuid,
      }),
    { enabled: isModalShowing },
  );

  const {
    config: { canUseHeyLightBranding },
  } = useConfig();

  const displayData = data ? getDisplayData(data, canUseHeyLightBranding) : '';

  const isEmail = type === CommunicationsTypes.EMAIL;

  const modalContent = (
    <QueryStateRouter error={error} isLoading={isLoading}>
      <CommunicationIframe src={displayData} />
    </QueryStateRouter>
  );

  return {
    extratall: isEmail,
    width: isEmail ? ModalSize.Sm : undefined,
    isLoading,
    modalContent,
    showFormButtons: false,
    title,
  };
};
