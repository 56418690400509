import styled from 'styled-components';
import { ButtonType, ButtonVariant } from '@utils/enums';

interface ITheme {
  bootstrapColors: {
    name: string;
  };
}

const defaultTheme: ITheme = {
  bootstrapColors: {
    name: ButtonVariant.Primary,
  },
};

// Extracts the bootstrap color name from the theme and
// overrides the primary variant with the theme one
const getButtonVariant = (
  variant: ButtonVariant | ButtonVariant[],
  theme: ITheme = defaultTheme,
) => (variant === ButtonVariant.Primary ? theme.bootstrapColors.name : variant);

const getFloatClass = (align?: string) => (align ? `float-${align}` : '');

interface IButton {
  align?: string;
  as?: React.ElementType | keyof JSX.IntrinsicElements;
  theme?: ITheme;
  to?: string;
  type?: ButtonType;
  variant: ButtonVariant | ButtonVariant[];
}

export const Button = styled.button.attrs(
  ({ align, theme, type = ButtonType.Button, variant }: IButton) => ({
    className: `btn ${
      Array.isArray(variant)
        ? variant.map(v => `btn-${getButtonVariant(v, theme)}`).join(' ')
        : `btn-${getButtonVariant(variant, theme)}`
    } ${getFloatClass(align)}`,
    type,
  }),
)<IButton>`
  margin-block-end: 0.25rem;

  .filtersForm & {
    margin-inline-end: 1rem;
  }
  .card-toolbar &,
  button + & {
    margin-inline-start: 1rem;
  }
  .modal-body & {
    margin-block-end: 1.5rem;
  }
  @media print {
    &[data-html2canvas-ignore] {
      display: none;
    }
  }
`;
