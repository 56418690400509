import styled from 'styled-components';

const AlertWrapper = styled.div.attrs(({ variant }) => ({
  className: `alert ${
    Array.isArray(variant)
      ? variant.map(v => `alert-${v}`).join(' ')
      : `alert-${variant}`
  }`,
}))``;

export const Alert = ({ bold, variant, ...props }) => {
  const className = `alert-text ${bold ? 'font-weight-bold' : ''}`;
  return (
    <AlertWrapper variant={variant} {...props}>
      <div className={className}>{props.children}</div>
    </AlertWrapper>
  );
};
