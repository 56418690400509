import { AxiosError } from 'axios';
import { Loading } from '@components';
import { useI18n } from '@hooks';
import { getErrorMessage } from '@utils';

export interface IQueryStateRouterProps {
  children: JSX.Element | null;
  error?: AxiosError | null;
  isLoading?: boolean;
  loadingMessage?: string;
}

export const QueryStateRouter = ({
  children,
  error,
  isLoading = false,
  loadingMessage,
}: IQueryStateRouterProps) => {
  const { translate } = useI18n();

  if (error) {
    return <p>{getErrorMessage({ error, translate })}</p>;
  }

  if (isLoading) {
    return <Loading messageKey={loadingMessage} />;
  }

  return children;
};
