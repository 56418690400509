import { useLocation } from 'react-router-dom';
import { Button } from '@components/Common.styles';
import { useI18n } from '@hooks';
import { AuthPathnames } from '@utils/constants';
import { AuthPageHeader, SuccessPageWrapper } from './Auth.styles';
import { OriginatorRouterLink } from './components';

export const Success = () => {
  const { translate } = useI18n();
  const location = useLocation();

  return (
    <SuccessPageWrapper>
      <AuthPageHeader>
        <h2>{location.state?.heading}</h2>
        <p>{location.state?.text}</p>
      </AuthPageHeader>

      <Button
        as={OriginatorRouterLink}
        to={AuthPathnames.LOGIN}
        variant="primary"
      >
        {translate('BUTTON.RETURN_TO_LOGIN')}
      </Button>
    </SuccessPageWrapper>
  );
};
