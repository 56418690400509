import styled from 'styled-components';
import { useI18n } from '@hooks';
import { getColSpan } from './getColSpan';

const CenteredTD = styled.td`
  text-align: center;
  vertical-align: middle;
`;

export const NoTableData = ({ headerGroups }) => {
  const { translate } = useI18n();

  const colSpan = getColSpan(headerGroups);

  return (
    <tr>
      <CenteredTD colSpan={colSpan}>
        {translate('STATUS.NO_DATA_AVAILABLE')}
      </CenteredTD>
    </tr>
  );
};
