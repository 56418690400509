import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Card, CheckboxGroupRHF, NotificationBanner } from '@components';
import { ICheckboxGroupOptionsRHF } from '@components/CheckboxGroup/CheckboxGroupRHF';
import { INotification } from '@components/types';
import { useI18n } from '@hooks';
import { ICurrency, IUuid } from '@schemas/opsPortal/types/contracts';
import { ILateFees } from '@schemas/opsPortal/types/monetaContract';
import { AlertSeverity } from '@utils/enums';
import { DateFeeHeaders } from './DateFeeHeaders';
import { DateFeeLabel } from './DateFeeLabel';
import { DeleteFeesNotificationMessage } from './DeleteFeesNotificationMessage';

interface IBalancesObj {
  feesSelectedForDeletion: IUuid[];
  indexesOfFeesSelectedForDeletion: string[];
}

interface IDeleteFeeFormProps {
  currency: ICurrency;
  formID: string;
  handleIsFeeSelected: (disabled: boolean) => void;
  handleSubmit: (data: IBalancesObj) => void;
  lateFees: ILateFees;
}

interface IDeleteFeeFormValues {
  paidSelected: number;
  totalSelected: number;
  updatedFeesSelectedForDeletion: IUuid[];
}

export const DeleteFeeForm = ({
  currency,
  formID,
  handleIsFeeSelected,
  handleSubmit,
  lateFees,
}: IDeleteFeeFormProps) => {
  const { getLocaleCurrencyValue, translate } = useI18n();
  const [paidFeesSelected, setPaidFeesSelected] = useState(0);
  const [totalFeesSelected, setTotalFeesSelected] = useState(0);

  const options: ICheckboxGroupOptionsRHF[] = lateFees?.map(
    ({ due_date: dueDate, paid, total_amount: totalAmount }, index) => ({
      id: index.toString(),
      label: (
        <DateFeeLabel
          amountPaid={paid}
          dueDate={dueDate}
          totalAmount={totalAmount}
        />
      ),
    }),
  );

  const defaultValues = {
    feesSelectedForDeletion: [] as IUuid[],
    indexesOfFeesSelectedForDeletion: [],
  };

  const methods = useForm<IBalancesObj>({
    defaultValues,
  });

  const indexes = methods.watch('indexesOfFeesSelectedForDeletion');

  useEffect(() => {
    handleIsFeeSelected(!!indexes?.length);
    const calculatedValues = indexes.reduce(
      (acc: IDeleteFeeFormValues, index) => {
        const fee = lateFees[Number(index)];
        if (fee) {
          return {
            paidSelected: acc.paidSelected + Number(fee.paid?.amount) ?? 0,
            totalSelected:
              acc.totalSelected + Number(fee.total_amount?.amount) ?? 0,
            updatedFeesSelectedForDeletion: [
              ...acc.updatedFeesSelectedForDeletion,
              fee?.receivable_uuid,
            ],
          };
        }
        return acc;
      },
      { updatedFeesSelectedForDeletion: [], paidSelected: 0, totalSelected: 0 },
    );

    const { paidSelected, totalSelected, updatedFeesSelectedForDeletion } =
      calculatedValues;
    setPaidFeesSelected(paidSelected);
    setTotalFeesSelected(totalSelected);

    methods.setValue('feesSelectedForDeletion', updatedFeesSelectedForDeletion);
  }, [handleIsFeeSelected, indexes, lateFees, methods]);

  return (
    <FormProvider {...methods}>
      {options.length ? (
        <form id={formID} onSubmit={methods.handleSubmit(handleSubmit)}>
          <Card
            hasHiddenTitle={true}
            isSubsection={true}
            removePaddingBottom={true}
          >
            <CheckboxGroupRHF
              label={<DateFeeHeaders translate={translate} />}
              name="indexesOfFeesSelectedForDeletion"
              options={options}
            />
          </Card>
        </form>
      ) : (
        <p>{translate('STATUS.NO_DATA_AVAILABLE')}</p>
      )}
      {indexes?.length ? (
        <NotificationBanner
          notifications={[
            {
              id: 'LateFeesNotification',
              message: (
                <DeleteFeesNotificationMessage
                  currency={currency}
                  getLocaleCurrencyValue={getLocaleCurrencyValue}
                  paidFeesSelected={paidFeesSelected}
                  totalFeesSelected={totalFeesSelected}
                  translate={translate}
                />
              ),
              severity: AlertSeverity.Error,
            } as INotification,
          ]}
        />
      ) : null}
    </FormProvider>
  );
};
