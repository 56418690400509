import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '@utils';

export function ScrollTop() {
  return (
    <div className="scrolltop" id="kt_scrolltop">
      <span className="svg-icon">
        <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Up-2.svg')}></SVG>
      </span>{' '}
    </div>
  );
}
