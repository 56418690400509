import { createColumnHelper, Row } from '@tanstack/react-table';
import { ReactTableColumns } from '@appTypes';
import { Button } from '@components/Common.styles';
import { Translate } from '@hooks/useI18n';
import { ButtonVariant } from '@utils/enums';
import { IDocumentsTableColumn } from './types';

interface IGetDocumentsTableColumns {
  isConsumerPortal?: boolean;
  setSelectedRow: (row: IDocumentsTableColumn) => void;
  translate: Translate;
}

export const getDocumentsTableColumns = ({
  isConsumerPortal = false,
  setSelectedRow,
  translate,
}: IGetDocumentsTableColumns): ReactTableColumns<IDocumentsTableColumn> => {
  const columnHelper = createColumnHelper<IDocumentsTableColumn>();

  const handleClick = (row: Row<IDocumentsTableColumn>) => () =>
    setSelectedRow(row.original);

  return [
    columnHelper.accessor('timestamp', {
      header: translate('TITLE.DATE'),
    }),
    columnHelper.accessor('documentTypeDesc', {
      header: translate('TITLE.TYPE'),
    }),
    ...(isConsumerPortal
      ? []
      : [
          columnHelper.accessor('isSigned', {
            header: translate('OPS_PORTAL.TITLE.IS_SIGNED'),
            enableSorting: false,
          }),
        ]),
    columnHelper.display({
      cell: ({ row }) =>
        isConsumerPortal ||
        row.original.isSigned === translate('GENERAL.YES') ? (
          <Button
            onClick={handleClick(row)}
            variant={[ButtonVariant.Primary, ButtonVariant.Sm]}
          >
            {translate('BUTTON.DOWNLOAD')}
          </Button>
        ) : null,
      id: 'ctaColumn',
    }),
  ];
};
