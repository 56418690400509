import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useI18n, usePortalError } from '@hooks';
import { IModalProps, ISharedModalProps } from '@hooks/useModal/types';
import {
  IEditUserGroup,
  IUuid,
} from '@schemas/merchantPortal/types/editUserGroups';
import { putDataToEndpoint } from '@utils';
import { Endpoints } from '@utils/enums';
import { useConfirmModal } from '../useConfirmModal';

interface IDeleteUserProps extends ISharedModalProps {
  queryKey: string[];
  userProps: IEditUserGroup;
}

interface IDeleteUserRequest {
  external_uuid: IUuid;
}

export const useDeleteUserModal = ({
  closeModal,
  queryKey,
  userProps,
}: IDeleteUserProps): IModalProps => {
  const { translate } = useI18n();
  const { handleAxiosError } = usePortalError();
  const { message } = useToast(translate);
  const queryClient = useQueryClient();

  const { mutate } = useMutation<
    string,
    AxiosError<IError>,
    IDeleteUserRequest
  >(
    requestData =>
      putDataToEndpoint({
        endpoint: Endpoints.DeleteUser,
        requestData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
        message.success('PAGE.USERS.USER_SUCCESSFULLY_DELETED');
        if (closeModal) {
          closeModal();
        }
      },
      onError: error => {
        handleAxiosError(error);
        if (closeModal) {
          closeModal();
        }
      },
    },
  );

  const handleSubmit = useCallback(() => {
    const userObj = { external_uuid: userProps?.external_uuid };
    mutate(userObj);
  }, [mutate, userProps?.external_uuid]);

  return useConfirmModal({
    children: <>{translate('GENERAL.ARE_YOU_SURE')}</>,
    onCancel: closeModal,
    onConfirm: handleSubmit,
    title: translate('BUTTON.DELETE_USER'),
  });
};
