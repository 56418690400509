import { useCallback } from 'react';
import Picker, { registerLocale } from 'react-datepicker';
import { useFormContext, useController } from 'react-hook-form';
import { format as formatDate } from 'date-fns';
import { de, enGB, frCH, it } from 'date-fns/locale';

import { ValidationError } from '@components';
import { FormGroup, InputText, Label } from '@components/Common.styles';
import { useI18n } from '@hooks';

interface IDatePickerProps {
  disabled?: boolean;
  isClearable?: boolean;
  label?: string;
  maxDate?: Date;
  minDate?: Date;
  name: string;
  showPopperArrow?: boolean;
}

export const DatePickerRHF = ({
  disabled = false,
  isClearable = true,
  label,
  maxDate,
  minDate,
  name = 'date',
  showPopperArrow = true,
}: IDatePickerProps) => {
  const { selectedLanguage, translate } = useI18n();

  const locales = Object.freeze({
    de: 'de',
    en: 'en-GB',
    fr: 'fr-CH',
    it: 'it',
  });

  registerLocale(locales.de, de);
  registerLocale(locales.en, enGB);
  registerLocale(locales.fr, frCH);
  registerLocale(locales.it, it);

  const {
    control,
    formState: { errors },
  } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({ name, control });
  const pickedDate = (value && new Date(value)) || null;

  const handler = useCallback(
    date => {
      const formattedDate = date ? formatDate(date, 'yyyy-MM-dd') : '';
      onChange(formattedDate);
    },
    [onChange],
  );

  const error = errors[name]?.message;

  const options = {
    autoComplete: 'off',
    dateFormat: 'PP',
    disabled,
    id: name,
    isClearable,
    locale: locales[selectedLanguage],
    ...(minDate ? { minDate } : {}),
    ...(maxDate ? { maxDate } : {}),
    name,
    onChange: handler,
    selected: pickedDate,
    showPopperArrow,
  };

  const labelValue = label ?? translate('LABEL.DATE');

  return (
    <FormGroup>
      {label ? <Label htmlFor={name}>{labelValue}</Label> : null}

      <InputText
        aria-label={!label ? labelValue : undefined}
        as={Picker}
        {...options}
      />
      {error ? <ValidationError error={error} /> : null}
    </FormGroup>
  );
};
