import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '@utils';

interface IProgressSVG {
  stepState: string | null;
}

export const ProgressSVG = ({ stepState }: IProgressSVG) => (
  <span className="svg-icon menu-icon pr-3">
    <SVG
      src={toAbsoluteUrl(`/media/svg/shapes/${stepState ?? 'in-progress'}.svg`)}
      width="30px"
    ></SVG>
  </span>
);
