import { DataDisplay } from '@components';
import { GridRow } from '@components/Common.styles';
import { useI18n } from '@hooks';
import { getDataList } from '@utils';
import { IInvoiceDetailsProps } from '../types';
import { prepareInvoiceData } from './prepareInvoiceData';

export const InvoiceDetails = ({
  invoiceData,
  orderData,
}: IInvoiceDetailsProps) => {
  const { getLocaleCurrencyValue, translate } = useI18n();

  const labels = {
    consumerName: translate('TITLE.CUSTOMER_NAME'),
    installments: translate('LABEL.NUMBER_OF_INSTALMENTS'),
    netFee: translate('PAGE.MEDIOBANCA_RECONCILIATIONS.FEES_INCLUDING_TAX'),
    netAmount: translate('LABEL.NET_ORDER_AMOUNT'),
    grossAmount: translate('PAGE.ORDERS.ORDER_VALUE'),
    interest: translate('LABEL.INTEREST'),
    mediobancaExternalId: translate('TITLE.EXTERNAL_ID'),
  };

  const data = prepareInvoiceData({
    data: { invoiceData, orderData },
    getLocaleCurrencyValue,
  });
  const dataList = getDataList({ data, labels });

  return (
    <GridRow>
      <DataDisplay dataList={dataList} />
    </GridRow>
  );
};
