import { AxiosError } from 'axios';
import { PageRoutes } from '@utils/enums';
import { checkIfCanRedirect } from './checkIfCanRedirect';

export const handleAxiosError = (err: AxiosError) => {
  const status = err?.response?.status || err?.status;
  const notAuthed = status === 401;

  if (notAuthed && checkIfCanRedirect(err.config?.url as string)) {
    window.location.replace(PageRoutes.Logout);
  }

  return Promise.reject(err);
};
