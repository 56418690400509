export const getCartDetailsProps = ({ data, getLocaleCurrencyValue }) => {
  const { order_details: orderDetails } = data;

  const amount = orderDetails.amount || orderDetails.full_details.amount;
  const products = orderDetails.products || orderDetails.full_details.products;

  const currency = typeof amount === 'string' ? null : amount.currency;

  const total = getLocaleCurrencyValue({
    currency,
    value: currency ? amount.amount : amount,
  });

  return { currency, products, total };
};
