import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ValueOf } from '@appTypes';
import { useI18n, usePortal } from '@hooks';
import { usePaymentModal } from '@hooks/useModal/hooks';
import {
  IFormModalProps,
  IModalProps,
  ISharedModalProps,
} from '@hooks/useModal/types';
import { IFullOrderDetails } from '@schemas/merchantPortal/types/orderDetails.d';
import { IOpsPortalMonetaContract } from '@schemas/opsPortal/types/monetaContract';
import { getErrorMessage, postDataToEndpoint } from '@utils';
import { PaymentTypes, Portals } from '@utils/constants';
import { Endpoints } from '@utils/enums';

interface IOrderPaymentModalProps extends IFormModalProps, ISharedModalProps {
  data: IFullOrderDetails;
  isLoading: boolean;
  monetaData?: IOpsPortalMonetaContract;
  paymentType: ValueOf<typeof PaymentTypes>;
  queryKey: string;
  uuid: string;
}

export const useOrderPaymentModal = ({
  data,
  formStatusMessage,
  monetaData,
  paymentType,
  queryKey,
  resolveForm,
  uuid,
}: IOrderPaymentModalProps): IModalProps => {
  const { translate } = useI18n();
  const { portal } = usePortal();

  const queryClient = useQueryClient();

  const paymentEndpoint = {
    [Portals.CONSUMER]: Endpoints.PrePayOrder,
    [Portals.MERCHANT]: Endpoints.RefundOrder,
  };

  const errorMessage = {
    [Portals.CONSUMER]: 'LABEL.UNABLE_TO_PROCESS_PREPAYMENT',
    [Portals.MERCHANT]: 'PAGE.ORDERS.UNABLE_TO_PROCESS_REFUND',
  };

  const successMessage = {
    [Portals.CONSUMER]: 'NOTIFICATION.PREPAYMENT_SUCCESSFULLY_ISSUED',
    [Portals.MERCHANT]: 'NOTIFICATION.REFUND_SUCCESSFULLY_ISSUED',
  };

  const { isLoading, mutate } = useMutation(
    requestData =>
      postDataToEndpoint({
        endpoint: paymentEndpoint[portal as keyof typeof paymentEndpoint],
        requestData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey, uuid]);
        resolveForm(
          translate(successMessage[portal as keyof typeof successMessage]),
        );
      },
      onError: (error: AxiosError) => {
        resolveForm(
          getErrorMessage({
            error,
            genericErrorMessageKey:
              errorMessage[portal as keyof typeof errorMessage],
            translate,
          }),
        );
      },
    },
  );

  const handleSubmit = useCallback(
    values => {
      const { amount, max } = values;
      const adjustedValues = {
        ...values,
        ...(amount ? { amount } : { amount: max }),
      };
      delete adjustedValues.max;
      mutate(adjustedValues);
    },
    [mutate],
  );

  const submitTextKeys = {
    [PaymentTypes.PREPAYMENT]: 'BUTTON.PREPAY_NOW',
    [PaymentTypes.REFUND]: 'BUTTON.REFUND_NOW',
  };

  const submitText = translate(
    submitTextKeys[paymentType as keyof typeof submitTextKeys],
  );

  const titleKeys = {
    [PaymentTypes.PREPAYMENT]: 'TITLE.PREPAYMENT',
    [PaymentTypes.REFUND]: 'BUTTON.REFUND',
  };

  const title = translate(titleKeys[paymentType as keyof typeof titleKeys]);

  return usePaymentModal({
    data,
    formStatusMessage,
    handleSubmit,
    isPaymentLoading: isLoading,
    monetaData,
    paymentType,
    resolveForm,
    submitText,
    title,
  });
};
