import { useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { FileUpload } from '@components';
import { useI18n, useFileUploadQuery } from '@hooks';
import { ISharedModalProps } from '@hooks/useModal/types';
import { ValidationErrorKeys } from '@utils/enums';

export interface IModalContentProps {
  acceptedFiles: Array<
    'text/csv' | '.xml' | '.pdf' | '.jpeg' | '.jpg' | '.png'
  >;
  closeModal?: () => void;
  description?: string;
  endpoint: string;
  formId: string;
  id: string;
}

export interface IUseFileUploadModalProps
  extends ISharedModalProps,
    IModalContentProps {
  title: string;
}

const ModalContent = ({
  acceptedFiles,
  closeModal,
  description,
  endpoint,
  formId,
}: IModalContentProps) => {
  const { translate } = useI18n();
  const { message } = useToast(translate);

  const validationSchema = Yup.object({
    file: Yup.mixed<File[]>()
      .test('fileLength', translate(ValidationErrorKeys.Required), value =>
        Boolean(value && value.length > 0),
      )
      .required(translate(ValidationErrorKeys.Required)),
  });

  const methods = useForm({
    defaultValues: {
      file: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const { mutate } = useFileUploadQuery({
    endpoint,
    onError: () => message.error('ERROR.FILE_UPLOAD', { timeout: 10000 }),
    onSuccess: () => {
      message.success('GENERAL.FILE_UPLOAD_SUCCESS', { noTimeout: true });
      if (closeModal) {
        closeModal();
      }
    },
  });

  const handleFileUpload = useCallback(data => mutate(data.file), [mutate]);
  const acceptedFilesString = acceptedFiles.join(', ');
  const fileSelectorLabel = `${translate(
    'OPS_PORTAL.TITLE.CHOOSE_FILE',
  )} (${acceptedFilesString})`;

  return (
    <FormProvider {...methods}>
      <form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id={formId}
        onSubmit={methods.handleSubmit(handleFileUpload)}
      >
        {description ? (
          <p className="text-danger">
            <strong>{description}</strong>
          </p>
        ) : null}

        <FileUpload
          accept={acceptedFilesString}
          id="file"
          label={fileSelectorLabel}
          multiple={false}
          name="file"
        />
      </form>
    </FormProvider>
  );
};

export const useFileUploadModal = ({
  acceptedFiles,
  additionalButtonContent,
  closeModal,
  description,
  endpoint,
  id,
  title,
}: IUseFileUploadModalProps) => {
  const { translate } = useI18n();
  const formID = 'file-upload-form';

  return {
    additionalButtonContent,
    cancelText: translate('BUTTON.CLOSE'),
    formID,
    isDangerousSubmit: true,
    modalContent: (
      <ModalContent
        acceptedFiles={acceptedFiles}
        closeModal={closeModal}
        description={description}
        endpoint={endpoint}
        formId={formID}
        id={id}
      />
    ),
    showFormButtons: true,
    submitText: translate('BUTTON.UPLOAD'),
    title,
  };
};
