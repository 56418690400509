import { GetLocaleCurrencyValue } from '@hooks/useI18n';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails';
import { addPlaceholderForEmptyValues } from '@utils';
import { IContractDetailsDisplayData } from './types';

interface IMapContractDetailsDataProps {
  data: IContractDetails;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
}

export const mapContractDetailsData = ({
  data,
  getLocaleCurrencyValue,
}: IMapContractDetailsDataProps): IContractDetailsDisplayData => {
  const {
    amount: originalAmount,
    amount_refunded: amountRefunded,
    amount_reimbursed_to_customer: amountReimbursed,
    received: amountPaid,
    system_remainder: currentAmount,
    write_off_amount: amountWrittenOff,
  } = data;

  return addPlaceholderForEmptyValues({
    originalAmount:
      originalAmount?.amount &&
      getLocaleCurrencyValue({
        currency: originalAmount.currency,
        value: originalAmount.amount,
      }),
    currentAmount:
      currentAmount?.amount &&
      getLocaleCurrencyValue({
        currency: currentAmount.currency,
        value: currentAmount.amount,
      }),
    amountRefunded:
      amountRefunded?.amount &&
      getLocaleCurrencyValue({
        currency: amountRefunded.currency,
        value: amountRefunded.amount,
      }),
    amountReimbursedToCustomer:
      amountReimbursed?.amount &&
      getLocaleCurrencyValue({
        currency: amountReimbursed.currency,
        value: amountReimbursed.amount,
      }),
    amountPaid:
      amountPaid?.amount &&
      getLocaleCurrencyValue({
        currency: amountPaid.currency,
        value: amountPaid.amount,
      }),
    writeOffAmount:
      amountWrittenOff?.amount &&
      getLocaleCurrencyValue({
        currency: amountWrittenOff.currency,
        value: amountWrittenOff.amount,
      }),
  });
};
