import { useContext } from 'react';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { Logo } from '@components';
import { useConfig } from '@hooks';
import { toAbsoluteUrl } from '@utils';
import { LogoVariants } from '@utils/constants';

export const Toggle = () => {
  const {
    config: { canUseHeyLightBranding },
  } = useConfig();
  const {
    bootstrapColors: { name },
  } = useContext(ThemeContext);

  const logoFill = canUseHeyLightBranding
    ? LogoVariants.LIGHT
    : LogoVariants.DARK;

  return (
    <div className="brand flex-column-auto" id="kt_brand">
      <Link className="brand-logo" to="/">
        <Logo fill={logoFill} />
      </Link>

      <button
        className={`brand-toggle btn btn-sm btn-hover-icon-${name} px-0`}
        id="kt_aside_toggle"
      >
        <span className="svg-icon svg-icon-xl">
          <SVG
            src={toAbsoluteUrl(
              '/media/svg/icons/Navigation/Angle-double-left.svg',
            )}
          />
        </span>
      </button>
    </div>
  );
};
