import { useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useI18n } from '@hooks';
import { ILanguageSelectorButtonProps } from './types';

export const LanguageSelectorButton = ({
  language,
}: ILanguageSelectorButtonProps) => {
  const { setSelectedLanguage } = useI18n();

  const handler = useCallback(
    () => setSelectedLanguage(language.lang),
    [language.lang, setSelectedLanguage],
  );

  return (
    <Dropdown.Item as="button" className="navi-link" onClick={handler}>
      <span className="symbol symbol-20 mr-3">
        <img alt="" src={language.flag} />
      </span>
      <span className="navi-text">{language.name}</span>
    </Dropdown.Item>
  );
};
