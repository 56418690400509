import { useI18n } from '@hooks';
import { Nav } from './Menu.styled';
import { MenuList } from './MenuList';

export function Menu() {
  const { translate } = useI18n();

  return (
    <>
      <h2 className="sr-only">{translate('TITLE.NAVIGATION')}</h2>
      <Nav>
        <MenuList />
      </Nav>
    </>
  );
}
