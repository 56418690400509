import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import { getTranslatedTerminationStatuses } from '@pages/TerminableContracts/getTranslatedTerminationStatuses';
import { IOpsPortalCollectionsDetails } from '@schemas/opsPortal/types/collectionsDetails';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails';
import { addPlaceholderForEmptyValues, getTranslatedData } from '@utils';
import { IContractPerformanceData } from './types';

interface IMapContractPerformanceData {
  collectionsData?: IOpsPortalCollectionsDetails;
  contractData: IContractDetails;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  isCollectionsDataError: boolean;
  translate: Translate;
}

export const mapContractPerformanceData = ({
  collectionsData,
  contractData,
  getLocaleCurrencyValue,
  getLocaleDate,
  isCollectionsDataError,
  translate,
}: IMapContractPerformanceData): IContractPerformanceData => {
  const {
    contract_uuid: contractUUID,
    language,
    monthly_payment_amount: monthlyPaymentAmount,
    original_number_of_installments: numberOfInstallments,
    performance_status: performanceStatus,
    received: amountPaid,
    status: activityStatus,
    termination_date: terminationDate,
  } = contractData;

  const collectionsDetails = collectionsData ?? {};
  const {
    arrears_amount: arrearsAmount,
    days_in_arrears: daysInArrears,
    days_in_arrears_by_reconciliation_date: daysInArrearsByReconciliationDate,
    oldest_due_date: oldestPaymentDueDate,
    payments_down: paymentsDown,
  } = collectionsDetails as IOpsPortalCollectionsDetails;

  const email = contractData.consumer_details?.email;
  const translatedterminatedContractStatuses =
    getTranslatedTerminationStatuses(translate);
  const translatedActivityStatus = activityStatus
    ? getTranslatedData({
        term: activityStatus,
        translate,
      })
    : '';
  const translatedPerformanceStatus = performanceStatus
    ? getTranslatedData({
        term: performanceStatus,
        translate,
      })
    : '';

  const paymentDueDate = oldestPaymentDueDate
    ? getLocaleDate({
        date: oldestPaymentDueDate,
      })
    : null;

  const adjustedPaymentsDown =
    typeof paymentsDown !== 'number' ? 0 : paymentsDown;

  const terminationDateLocale = terminationDate
    ? getLocaleDate({ date: terminationDate })
    : '';

  return addPlaceholderForEmptyValues({
    activityStatus: translatedActivityStatus,
    terminationDate:
      translatedterminatedContractStatuses.includes(
        translatedActivityStatus ?? '',
      ) && terminationDateLocale,
    performanceStatus: translatedPerformanceStatus,
    contractUUID,
    numberOfInstallments,
    monthlyPaymentAmount:
      monthlyPaymentAmount?.amount &&
      getLocaleCurrencyValue({
        currency: monthlyPaymentAmount?.currency,
        value: monthlyPaymentAmount?.amount,
      }),
    amountPaid:
      amountPaid?.amount &&
      getLocaleCurrencyValue({
        currency: amountPaid?.currency,
        value: amountPaid?.amount,
      }),
    paymentDueDate: isCollectionsDataError ? '' : paymentDueDate,
    daysInArrears: isCollectionsDataError ? '' : daysInArrears,
    daysInArrearsByReconciliationDate: isCollectionsDataError
      ? ''
      : daysInArrearsByReconciliationDate,
    paymentsDown: isCollectionsDataError ? '' : adjustedPaymentsDown,
    arrearsAmount:
      arrearsAmount?.amount &&
      getLocaleCurrencyValue({
        currency: arrearsAmount?.currency,
        value: arrearsAmount?.amount,
      }),
    email,
    language,
  });
};
