import { CollectionAgencies } from '@appTypes';
import { INotification } from '@components/types';
import { Translate } from '@hooks/useI18n';

import { IOpsPortalBlockExternalCollectionsReferral } from '@schemas/opsPortal/types/blockExternalCollectionsReferral';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails';
import { IOpsPortalPauseCommunications } from '@schemas/opsPortal/types/pauseCommunications';
import { AlertSeverity, ArrearsManagedByKeys } from '@utils/enums';
import { INotificationConditions } from './types';

interface IGetContractDetailsNotifications {
  collectionAgencies?: CollectionAgencies;
  collectionsBlockAutoReferralData?: IOpsPortalBlockExternalCollectionsReferral;
  contractData?: IContractDetails;
  isArrearsCurrentlyAssignedToHeidi: boolean;
  pauseCommunicationsData?: IOpsPortalPauseCommunications;
  translate: Translate;
}

export const getContractDetailsNotifications = ({
  collectionAgencies,
  collectionsBlockAutoReferralData,
  contractData,
  isArrearsCurrentlyAssignedToHeidi,
  pauseCommunicationsData,
  translate,
}: IGetContractDetailsNotifications): INotification[] => {
  if (!(contractData && collectionAgencies)) {
    return [];
  }

  const arrearsToBeManagedByAgency = contractData?.arrears_managed_by;
  const isArrearsManagedByHeidiPay =
    arrearsToBeManagedByAgency === ArrearsManagedByKeys.HeidiPay;
  const showAutomatedReferralNotification =
    (collectionsBlockAutoReferralData?.block_external_collections_auto_referral &&
      isArrearsManagedByHeidiPay) ??
    false;

  const showExternalManagementNotification =
    arrearsToBeManagedByAgency && !isArrearsManagedByHeidiPay;

  const showPauseCommunicationNotification = Boolean(
    pauseCommunicationsData?.pause_communications,
  );

  const notificationConditions: INotificationConditions[] = [
    {
      condition: showAutomatedReferralNotification,
      severity: AlertSeverity.Info,
      titleKey: 'OPS_PORTAL.LABEL.AUTOMATED_REFERRAL_BLOCKED',
      messageKey: 'OPS_PORTAL.LABEL.CONTRACT_NOT_SENT_TO_EXTERNAL_COLLECTOR',
    },
    {
      condition: showPauseCommunicationNotification,
      severity: AlertSeverity.Info,
      titleKey: 'OPS_PORTAL.LABEL.PAUSE_COMMUNICATION_BLOCKED',
      messageKey: 'OPS_PORTAL.LABEL.NO_AUTOMATED_EMAILS',
    },
    {
      condition: showExternalManagementNotification,
      severity: AlertSeverity.Warning,
      titleKey: 'OPS_PORTAL.LABEL.CONTRACT_MANAGEMENT_ENGAGED',
      messageKey: 'OPS_PORTAL.LABEL.CONTRACT_ARREARS_MANAGED_BY',
      messageParams: {
        arrearsManagedBy: collectionAgencies[arrearsToBeManagedByAgency],
      },
    },
    {
      condition: isArrearsCurrentlyAssignedToHeidi,
      severity: AlertSeverity.Info,
      titleKey: 'OPS_PORTAL.LABEL.CONTRACT_ASSIGNED_TO_HEIDIPAY',
      messageKey: 'OPS_PORTAL.LABEL.CONTRACT_ARREARS_HEIDIPAY',
    },
  ];

  return notificationConditions
    .filter(({ condition }) => condition)
    .map(({ messageKey, messageParams, severity, titleKey }) => {
      const title = translate(titleKey);
      const message = translate(messageKey, messageParams);
      const id = `notif-${severity}-${title}-${message}`.replace(/\s+/g, '-');
      return { severity, title, message, id };
    });
};
