import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { red, green, grey } from '@mui/material/colors';
import { Translate } from '@hooks/useI18n';
import { IAllocation } from '@schemas/opsPortal/types/allocationDetails';
import { RecordedStatus } from './enums';

interface IPostedInMonetaProps {
  allocation: IAllocation;
  translate: Translate;
}

const Complete = styled(CheckCircleIcon)`
  color: ${green[400]};
`;
const Pending = styled(RemoveCircleIcon)`
  color: ${grey[400]};
`;
const Failure = styled(ErrorIcon)`
  color: ${red[400]};
`;

export const PostedInMoneta = ({
  allocation,
  translate,
}: IPostedInMonetaProps) => {
  const translationKey = {
    check:
      'OPS_PORTAL.LABEL.BANK_ACCOUNT_RECONCILIATIONS.SUCCESSFULLY_POSTED_TO_MONETA',
    pending:
      'OPS_PORTAL.LABEL.BANK_ACCOUNT_RECONCILIATIONS.PENDING_POSTED_TO_MONETA',
    cross:
      'OPS_PORTAL.LABEL.BANK_ACCOUNT_RECONCILIATIONS.FAILED_TO_POST_TO_MONETA',
  };

  const icons = {
    check: (
      <Tooltip title={translate(translationKey.check)}>
        <Complete />
      </Tooltip>
    ),
    pending: (
      <Tooltip title={translate(translationKey.pending)}>
        <Pending />
      </Tooltip>
    ),
    cross: (
      <Tooltip title={translate(translationKey.cross)}>
        <Failure />
      </Tooltip>
    ),
  };

  const icon = {
    [RecordedStatus.Complete]: icons.check,
    [RecordedStatus.Failure]: icons.cross,
    [RecordedStatus.Pending]: icons.pending,
    [RecordedStatus.Skipped]: icons.check,
  };

  return <>{icon[allocation.recorded_status]}</>;
};
