import { useCallback } from 'react';
import {
  ColumnDef,
  Row,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Filters, Pagination } from '@components';
import { NoTranslateWrapper } from '@components/Common.styles';
import { IPaginationProps } from '@components/Pagination/types';
import { IFilter } from '@hooks/useQueryState/utils/types';
import { TableBody, TableHeader } from './components';
import { Table, TableWrapper } from './Table.styles';
import { handleFiltering, sortCurrency, sortNumeric } from './utils';

interface IHybridTableProps<TData, TCell> {
  className?: string;
  columns: ColumnDef<TData, TCell>[];
  data: TData[];
  enableRowSelection?: boolean;
  enableSorting?: boolean;
  filters?: IFilter[];
  handleRowSelect?: (row: Row<TData>) => void;
  isCursorPagination?: boolean;
  manageControlledState?: (newQueryState: object) => void;
  pagination: IPaginationProps;
  resetFilters: () => void;
  tableState: object;
  tfoot?: string[];
}

// A hybrid table is one that is paginated and sorted
// on the clientside but filtered on the serverside.
export const HybridTable = <TData, TCell>({
  columns,
  data,
  enableSorting = true,
  filters = [],
  manageControlledState,
  resetFilters,
  tableState,
}: IHybridTableProps<TData, TCell>) => {
  const tableOptions = {
    autoResetPageIndex: false,
    columns,
    data,
    enableMultiSort: true,
    enableSorting: enableSorting && data.length > 1,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onStateChange: manageControlledState,
    sortingFns: { sortCurrency, sortNumeric },
    state: tableState,
  };

  const tableInstance = useReactTable(tableOptions);

  const {
    getCanNextPage,
    getCanPreviousPage,
    getHeaderGroups,
    getPageCount,
    getPageOptions,
    getPaginationRowModel: getPageModel,
    getRowModel,
    getState,
    nextPage,
    previousPage,
    setPageIndex,
    setPageSize,
    setState,
  } = tableInstance;

  const filterHandler = useCallback(
    values => handleFiltering({ filters, setState, state: getState(), values }),
    [filters, getState, setState],
  );

  const headerGroups = getHeaderGroups();

  return (
    <>
      <Filters
        filterHandler={filterHandler}
        filters={filters}
        resetFilters={resetFilters}
      />

      <NoTranslateWrapper>
        <TableWrapper>
          <Table>
            <caption>
              <span className="sr-only">
                Column headers with buttons are sortable.
              </span>
            </caption>
            <TableHeader flexRender={flexRender} headerGroups={headerGroups} />
            <TableBody
              flexRender={flexRender}
              headerGroups={headerGroups}
              rows={getRowModel().rows}
            />
          </Table>
        </TableWrapper>

        <Pagination
          canNextPage={getCanNextPage()}
          canPreviousPage={getCanPreviousPage()}
          gotoPage={setPageIndex}
          nextPage={nextPage}
          pageCount={getPageCount()}
          pageIndex={getState().pagination.pageIndex}
          pageOptions={getPageOptions()}
          pageSize={getState().pagination.pageSize}
          previousPage={previousPage}
          rowCount={getPageModel().rows.length}
          setPageSize={setPageSize}
          totalItems={data.length}
        />
      </NoTranslateWrapper>
    </>
  );
};
