import { useCallback, useEffect, useMemo, useRef } from 'react';
import { AxiosError } from 'axios';
import { useQueries } from '@tanstack/react-query';
import { IApplicationsTableData } from '@appTypes';
import {
  Card,
  NoDataAvailable,
  QueryStateRouter,
  ServersideTable,
} from '@components';
import { CSVLink } from '@components/Common.styles';
import { ITableRefObject } from '@components/Table/types';
import { useI18n, useOriginator, usePortal, useQueryState } from '@hooks/index';
import { IOpsPortalApplications } from '@schemas/opsPortal/types/applications';
import { IOpsPortalMerchantsList } from '@schemas/opsPortal/types/merchantsList';
import {
  downloadCSV,
  fetchDataFromEndpoint,
  getApplicationsTableColumns,
  getCursorPaginationCallbacks,
  getMerchantsOptions,
  mapApplicationsTableData,
} from '@utils';
import { QueryKeys, TableNames } from '@utils/constants';
import { Endpoints, OriginationChannel } from '@utils/enums';
import { createApplicationsCSV } from './createApplicationsCSV';

interface IApplications {
  isMyApplications?: boolean;
}

export const Applications = ({ isMyApplications = false }: IApplications) => {
  const { isMerchantPortal, isOpsPortal, portal } = usePortal();
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();
  const { isCompass, isPagolightPro, originator } = useOriginator();
  const tableName = TableNames.APPLICATIONS;
  const originationChannel = isMerchantPortal ? OriginationChannel.InStore : '';

  const {
    activateQueryStateHook,
    getFilters,
    queryParams,
    queryState,
    resetFilters,
    stringifiedQueryParams,
    updateQueryParams,
  } = useQueryState(tableName);

  const [
    {
      data: applicationsData,
      error: applicationsError,
      isLoading: isApplicationsLoading,
    },
    {
      data: merchantsData,
      error: merchantsError,
      isLoading: isMerchantsLoading,
    },
  ] = useQueries<
    [
      [IOpsPortalApplications, AxiosError],
      [IOpsPortalMerchantsList, AxiosError],
    ]
  >({
    queries: [
      {
        queryKey: [
          QueryKeys.APPLICATIONS_TABLE_DATA,
          stringifiedQueryParams,
          originator,
          isMerchantPortal,
          isMyApplications,
        ],
        queryFn: () =>
          fetchDataFromEndpoint(Endpoints.FetchApplications, {
            query: {
              ...queryParams,
              originator,
              originationChannel,
              isMerchantPortal: isMerchantPortal.toString(),
              isMyApplications: isMyApplications.toString(),
            },
          }),
      },
      {
        queryKey: [QueryKeys.FULL_MERCHANT_LIST, originator],
        queryFn: () =>
          fetchDataFromEndpoint(Endpoints.FetchMerchantsAll, {
            query: { originator },
          }),
        enabled: isOpsPortal,
      },
    ],
  });

  const merchantsOptions = useMemo(
    () => getMerchantsOptions(merchantsData),
    [merchantsData],
  );

  const filters = getFilters({ merchantsOptions, isOpsPortal });

  const columns = useMemo(
    () =>
      getApplicationsTableColumns({
        isCompass,
        translate,
        isPagolightPro,
        portal,
      }),
    [isCompass, translate, isPagolightPro, portal],
  );

  const tableData =
    applicationsData &&
    mapApplicationsTableData({
      data: applicationsData.results,
      getLocaleCurrencyValue,
      getLocaleDate,
      isMerchantPortal,
      translate,
    });

  const refToCSVLink = useRef<HTMLAnchorElement>(null);
  const refToDataTable = useRef<ITableRefObject<IApplicationsTableData>>(null);

  const handleDownloadClick = useCallback(() => {
    if (refToDataTable.current) {
      const { columns: cols, rows } = refToDataTable.current;
      const csv = createApplicationsCSV({
        columns: cols,
        data: rows,
        translate,
      });
      downloadCSV({ csv, refToCSVLink });
    }
  }, [translate]);

  const csvButtonText = translate('OPS_PORTAL.BUTTON.DOWNLOAD_APPLICATIONS');

  const buttons = isOpsPortal
    ? [
        {
          clickHandler: handleDownloadClick,
          key: 'downloadCSV',
          text: csvButtonText,
        },
      ]
    : [];

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  const { gotoNextPage, gotoPrevPage } = getCursorPaginationCallbacks({
    nextCursor: applicationsData?.next_cursor,
    prevCursor: applicationsData?.previous_cursor,
    queryState,
    updateQueryParams,
  });

  return (
    <Card
      buttons={buttons}
      title={translate(
        isMyApplications ? 'TITLE.MY_APPLICATIONS' : 'TITLE.APPLICATIONS',
      )}
    >
      <QueryStateRouter
        error={applicationsError || merchantsError}
        isLoading={isApplicationsLoading || (isOpsPortal && isMerchantsLoading)}
      >
        {tableData ? (
          <>
            {isOpsPortal ? (
              <CSVLink download={tableName} ref={refToCSVLink} />
            ) : null}
            <ServersideTable
              columns={columns}
              data={tableData}
              filters={filters}
              isCursorPagination={true}
              manageControlledState={updateQueryParams}
              pagination={{
                gotoNextPage,
                gotoPrevPage,
                pageSize: queryParams.pageSize,
                totalItems: applicationsData?.count,
              }}
              ref={refToDataTable}
              resetFilters={resetFilters}
              tableState={queryState}
            />
          </>
        ) : (
          <NoDataAvailable />
        )}
      </QueryStateRouter>
    </Card>
  );
};
