import { useCallback, useMemo } from 'react';
import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useI18n, usePortalError } from '@hooks';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { ICustomModalButton } from '@hooks/useModal/components/types';
import { IModalProps } from '@hooks/useModal/types';
import { postDataToEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { ButtonType, ButtonVariant, Endpoints } from '@utils/enums';
import { PaymentLinkForm } from './PaymentLinkForm';
import {
  IPaymentFormSubmit,
  IPaymentLinkModalProps,
  IPaymentLinkRequest,
} from './types';

export const usePaymentLinkModal = ({
  closeModal,
  contractUuid,
  formStatusMessage,
  paymentLinkData,
  remainingBalance = {
    amount: '',
    currency: '',
  },
}: IPaymentLinkModalProps): IModalProps => {
  const { translate } = useI18n();
  const { handleAxiosError } = usePortalError();
  const { message } = useToast(translate);
  const queryClient = useQueryClient();

  const { isLoading: createIsLoading, mutate: createMutate } = useMutation<
    void,
    AxiosError<IError>,
    IPaymentLinkRequest
  >(
    requestData =>
      postDataToEndpoint({
        endpoint: `${Endpoints.CreatePaymentLink}/${contractUuid}`,
        requestData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.PAYMENT_LINK, contractUuid]);
        message.success('OPS_PORTAL.STATUS.PAYMENT_LINK_CREATED_SUCCESSFULLY');
        closeModal?.();
      },
      onError: error =>
        handleAxiosError(
          error,
          'OPS_PORTAL.STATUS.PAYMENT_LINK_CREATED_FAILED',
        ),
    },
  );

  const { isLoading: cancelIsLoading, mutate: cancelMutate } = useMutation<
    void,
    AxiosError<IError>
  >(
    () =>
      postDataToEndpoint({
        endpoint: `${Endpoints.CancelPaymentLink}/${contractUuid}`,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.PAYMENT_LINK, contractUuid]);
        message.success(
          'OPS_PORTAL.STATUS.PAYMENT_LINK_CANCELLED_SUCCESSFULLY',
        );
        closeModal?.();
      },
      onError: error =>
        handleAxiosError(
          error,
          'OPS_PORTAL.STATUS.PAYMENT_LINK_CANCELLED_FAILED',
        ),
    },
  );

  const handleCancelClick = useCallback(() => {
    cancelMutate();
  }, [cancelMutate]);

  const handleSubmit = useCallback(
    ({ amount }: IPaymentFormSubmit) => {
      createMutate({
        preset_amount: amount
          ? {
              amount: amount.toFixed(2),
              currency: remainingBalance.currency,
            }
          : undefined,
      });
    },
    [createMutate, remainingBalance.currency],
  );

  const cancelButton: ICustomModalButton[] = useMemo(
    () =>
      paymentLinkData?.payment_link
        ? [
            {
              text: translate('OPS_PORTAL.BUTTON.CANCEL_PAYMENT_LINK'),
              clickHandler: handleCancelClick,
              variant: ButtonVariant.Danger,
            },
          ]
        : [],
    [handleCancelClick, paymentLinkData, translate],
  );

  const submitButton: ICustomModalButton[] = useMemo(
    () => [
      {
        text: translate('OPS_PORTAL.TITLE.CREATE_PAYMENT_LINK'),
        variant: ButtonVariant.Primary,
        type: ButtonType.Submit,
      },
    ],
    [translate],
  );

  const modalButtons: ICustomModalButton[] = useMemo(
    () => [...cancelButton, ...submitButton],
    [submitButton, cancelButton],
  );

  const formID = 'paymentLinkForm';

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={createIsLoading || cancelIsLoading}
    >
      <PaymentLinkForm
        contractUuid={contractUuid}
        formID={formID}
        handleSubmit={handleSubmit}
        remainingBalance={remainingBalance}
      />
    </ModalStatusRouter>
  );

  return {
    formID,
    isDangerousSubmit: true,
    isFormProcessing: createIsLoading,
    isLoading: createIsLoading,
    customButtons: modalButtons,
    modalContent,
    title: translate('OPS_PORTAL.LABEL.PAYMENT_LINK'),
  };
};
