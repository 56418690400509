import { Originators } from '@utils/constants';

const pathSections = {
  pagolight: Originators.PAGOLIGHT,
  pagolightpro: Originators.PAGOLIGHTPRO,
};

const pathRegEx = /^\/([a-z]*?)(\/auth\/login)?$/;

export const getOriginatorByPath = () => {
  const pathSection = window.location.pathname
    .replace(pathRegEx, '$1')
    .toLowerCase();

  return (
    pathSections[pathSection as keyof typeof pathSections] ||
    Originators.HEIDIPAY
  );
};
