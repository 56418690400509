import { Card, NoDataAvailable, QueryStateRouter } from '@components';
import {
  ModalKeys,
  useApplicantBlocking,
  useI18n,
  useModal,
  useOriginator,
} from '@hooks';
import { IQueryResults } from '@pages/ApplicationDetails/types';
import { TabsComponent } from './TabsComponent';

export const DetailsCard = ({
  data,
  error,
  isLoading,
  nextPayment,
}: IQueryResults) => {
  const { translate } = useI18n();
  const { isCompass } = useOriginator();

  const hasUuid = Boolean(
    data?.order_details?.external_uuid || data?.external_contract_uuid,
  );

  const applicantUuid = data?.applicant_uuid;

  const {
    applicantBlockModalCtaProps,
    applicantBlockModals,
    error: blockStatusError,
    isLoading: isBlockStatusLoading,
    notification,
  } = useApplicantBlocking({
    applicantUuid,
  });

  const emailUserModal = useModal(ModalKeys.EmailUser, {
    contractData: data,
    dependencies: [hasUuid],
  });

  const buttons = [
    ...applicantBlockModalCtaProps,
    ...(emailUserModal ? [emailUserModal?.ctaProps] : []),
  ];

  const cardTitle = translate('OPS_PORTAL.TITLE.APPLICATION_DETAILS');

  return (
    <>
      <Card buttons={buttons} title={cardTitle}>
        <QueryStateRouter
          error={error || blockStatusError}
          isLoading={isLoading || isBlockStatusLoading}
        >
          <>
            {notification}
            {data ? (
              <TabsComponent
                data={data}
                isCompass={isCompass}
                nextPayment={nextPayment}
              />
            ) : (
              <NoDataAvailable />
            )}
          </>
        </QueryStateRouter>
      </Card>
      {applicantBlockModals}
      {emailUserModal?.Modal ?? null}
    </>
  );
};
