import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { DD } from '@components/Common.styles';
import { useI18n } from '@hooks';
import { obfuscateText } from '@utils';

interface IKeyDisplayProps {
  apiKey: string;
}

const FlexedDD = styled(DD)`
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
`;

const MonospacedSpan = styled.span`
  font-family: monospace;
`;

export const KeyDisplay = ({ apiKey }: IKeyDisplayProps) => {
  const { translate } = useI18n();
  const { message } = useToast(translate);
  const [showKey, setShowKey] = useState(false);
  const toggleShowKey = useCallback(() => setShowKey(!showKey), [showKey]);
  const copyKey = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(apiKey);
      message.success('STATUS.KEY_SUCCESSFULLY_COPIED');
    } catch (err) {
      message.error('STATUS.UNABLE_TO_COPY_KEY');
    }
  }, [apiKey, message]);

  return (
    <FlexedDD>
      <MonospacedSpan>
        {obfuscateText({ showText: showKey, text: apiKey })}
      </MonospacedSpan>
      <IconButton
        aria-label={translate(showKey ? 'BUTTON.HIDE_KEY' : 'BUTTON.SHOW_KEY')}
        onClick={toggleShowKey}
      >
        {showKey ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </IconButton>
      <IconButton aria-label={translate('BUTTON.COPY_KEY')} onClick={copyKey}>
        <ContentCopyIcon />
      </IconButton>
    </FlexedDD>
  );
};
