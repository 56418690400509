import * as Sentry from '@sentry/react';
import { getEnv } from '@utils';

export async function initSentry() {
  const { dsn, isDev, sentryEnv } = await getEnv();

  if (!isDev) {
    Sentry.init({
      dsn,
      environment: sentryEnv,
      replaysSessionSampleRate: 1,
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        new Sentry.Replay({
          maskAllText: false,
          blockAllMedia: false,
          maskAllInputs: true,
        }),
      ],
    });
  }
}
