import { Translate } from '@hooks/useI18n';
import { getTranslatedData } from '@utils';
import { missingValuePlaceholder } from '@utils/constants';

interface IGetDataAndLabels {
  isMerchantPortal: boolean;
  isPagolightPro: boolean;
  props: Record<string, unknown>;
  translate: Translate;
}

export const getDataAndLabels = ({
  isMerchantPortal,
  isPagolightPro,
  props,
  translate,
}: IGetDataAndLabels) => {
  const fields = {
    adjustedOrderValue: translate('PAGE.ORDERS.ADJUSTED_ORDER_VALUE'),
    amountRefunded: translate('PAGE.ORDERS.AMOUNT_REFUNDED'),
    annualisedInterestRate: translate('LABEL.INTEREST_RATE'),
    arrearsAmount: translate('OPS_PORTAL.LABEL.ARREARS_AMOUNT'),
    balance: translate('TITLE.BALANCE'),
    customerName: translate(
      isPagolightPro
        ? 'PAGOLIGHT_PRO.TITLE.CUSTOMER_NAME'
        : 'TITLE.CUSTOMER_NAME',
    ),
    date: translate('TITLE.DATE'),
    feeAmount: translate('LABEL.FEE_AMOUNT'),
    finalPayment: translate('LABEL.FINAL_INSTALMENT'),
    interest: translate('LABEL.INTEREST'),
    isInArrears: translate('OPS_PORTAL.LABEL.IS_IN_ARREARS'),
    lateFeeTotal: translate('OPS_PORTAL.LABEL.ADDITIONAL_FEES_INCURRED'),
    mediobancaExternalId: translate('TITLE.EXTERNAL_ID'),
    merchant: translate('TITLE.MERCHANT'),
    merchantOrderId:
      isMerchantPortal && !isPagolightPro
        ? translate('TITLE.YOUR_INVOICE_NUMBER')
        : translate('TITLE.INVOICE_NUMBER'),
    monthlyPaymentAmount: translate('LABEL.MONTHLY_INSTALMENT'),
    netOrderAmount: translate('LABEL.NET_ORDER_AMOUNT'),
    nextPaymentAmount: translate('TITLE.NEXT_PAYMENT'),
    nextPaymentDate: translate('TITLE.NEXT_PAYMENT_DATE'),
    numberOfInstalments: translate('LABEL.NUMBER_OF_INSTALMENTS'),
    orderBalance: translate('PAGE.ORDERS.ORDER_BALANCE'),
    orderDescription: translate('PAGE.ORDERS.ORDER_DESCRIPTION'),
    orderValue: translate(
      isPagolightPro
        ? 'PAGE.ORDERS.INITIAL_ORDER_VALUE'
        : 'PAGE.ORDERS.ORDER_VALUE',
    ),
    paid: translate('TITLE.PAID'),
    paymentPlanId: translate('TITLE.PAYMENT_PLAN_ID'),
    status: translate('TITLE.STATUS'),
    totalRefund: translate('PAGE.ORDERS.TOTAL_REFUND'),
  };
  const listKeys = Object.keys(props).filter(Boolean);

  return listKeys.reduce(
    (obj, key) => {
      return {
        data: {
          ...obj.data,
          [key]:
            key === 'status'
              ? getTranslatedData({ term: props[key] as string, translate })
              : props[key] || missingValuePlaceholder,
        },
        labels: {
          ...obj.labels,
          [key]: fields[key as keyof typeof fields],
        },
      };
    },
    { data: {}, labels: {} },
  );
};
