import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import {
  ApiKeys,
  AccessController,
  Card,
  DataDisplay,
  NoDataAvailable,
  QueryStateRouter,
} from '@components';
import {
  ModalKeys,
  useHasPermission,
  useI18n,
  useModal,
  useOriginator,
} from '@hooks';
import { IOpsPortalIban } from '@schemas/opsPortal/types/iban';
import { IOpsPortalMerchantDetails } from '@schemas/opsPortal/types/merchantDetails';
import { fetchDataFromEndpoint, postDataToEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { getDataList } from './getDataList';

export const MerchantDetails = () => {
  const canViewIban = useHasPermission(['ops_portal.view_merchant_iban']);
  const { getLocaleDate, translate } = useI18n();
  const { originator } = useOriginator();
  const { uuid } = useParams();
  const queryClient = useQueryClient();
  const { message } = useToast(translate);

  const [
    {
      data: dataMerchantDetails,
      error: errorMerchantDetails,
      isLoading: isLoadingMerchantDetails,
    },
    { data: dataIban, isLoading: isLoadingIban },
  ] = useQueries<
    [[IOpsPortalMerchantDetails, AxiosError], [IOpsPortalIban, AxiosError]]
  >({
    queries: [
      {
        queryFn: () =>
          fetchDataFromEndpoint(`${Endpoints.FetchMerchants}/${uuid}`, {
            query: { originator },
          }),
        queryKey: [QueryKeys.MERCHANT_DETAILS, uuid, originator],
      },
      {
        queryFn: () =>
          fetchDataFromEndpoint(`${Endpoints.FetchMerchantsIban}/${uuid}`),
        queryKey: [QueryKeys.IBAN, uuid],
        enabled: canViewIban,
      },
    ],
  });

  // Recent changes to React Query have a query as fetching
  // right from the start, even if not enabled. :(
  const isActuallyLoadingIban = canViewIban && isLoadingIban;

  const dataList =
    dataMerchantDetails &&
    getDataList({
      canViewIban,
      data: dataMerchantDetails,
      getLocaleDate,
      translate,
      ...(canViewIban && dataIban ? { iban: dataIban.iban } : {}),
    });

  const postValue = !dataMerchantDetails?.merchant_settlement_paused;
  const errorMsgPartial = dataMerchantDetails?.merchant_settlement_paused
    ? 'UNPAUSE'
    : 'PAUSE';

  const { mutate: pauseOrUnpauseMerchantSettlement } = useMutation<
    void,
    AxiosError
  >(
    () =>
      postDataToEndpoint({
        endpoint: `${Endpoints.PauseMerchantSettlement}/${uuid}`,
        requestData: { paused: postValue },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.MERCHANT_DETAILS, uuid]);
      },
      onError: () => {
        message.error(
          `STATUS.UNABLE_TO_${errorMsgPartial}_MERCHANT_SETTLEMENT`,
        );
      },
    },
  );

  const changeIbanModal = useModal(ModalKeys.ChangeIban, {
    dependencies: [canViewIban],
    iban: dataIban?.iban,
    merchantUuid: dataMerchantDetails?.external_uuid,
  });

  const canPauseMerchantSettlement = useHasPermission([
    'ops_portal.can_pause_merchant_settlement',
  ]);

  const pauseMerchantSettlementButton = dataMerchantDetails && {
    clickHandler: pauseOrUnpauseMerchantSettlement,
    key: 'pauseMerchantSettlement',
    text: dataMerchantDetails.merchant_settlement_paused
      ? translate('OPS_PORTAL.BUTTON.UNPAUSE_MERCHANT_SETTLEMENT')
      : translate('OPS_PORTAL.BUTTON.PAUSE_MERCHANT_SETTLEMENT'),
  };

  const buttons = [
    ...(dataMerchantDetails && canPauseMerchantSettlement
      ? [pauseMerchantSettlementButton]
      : []),
    ...(changeIbanModal ? [changeIbanModal.ctaProps] : []),
  ];

  return (
    <>
      <Card
        buttons={buttons}
        title={translate('OPS_PORTAL.TITLE.MERCHANT_DETAILS')}
      >
        <QueryStateRouter
          error={errorMerchantDetails}
          isLoading={isLoadingMerchantDetails ?? isActuallyLoadingIban}
        >
          {dataList ? <DataDisplay dataList={dataList} /> : <NoDataAvailable />}
        </QueryStateRouter>
      </Card>
      <AccessController scopes={['ops_portal.can_view_merchants_keys']}>
        <ApiKeys />
      </AccessController>
      {changeIbanModal?.Modal}
    </>
  );
};
