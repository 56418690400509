import { DetailsCardlet } from '@components';
import { Translate } from '@hooks/useI18n';
import {
  ICardDetailsExpiryMonth,
  ICardDetailsExpiryYear,
  ICardDetailsLastFourDigits,
  ICardDetailsScheme,
} from '@schemas/consumerPortal/types/ordersDetails';
import { PaymentMethods } from '@utils/constants';

interface ICreditCardDetails {
  expiryMonth?: ICardDetailsExpiryMonth;
  expiryYear?: ICardDetailsExpiryYear;
  lastFourDigits?: ICardDetailsLastFourDigits;
  paymentMethod: string;
  scheme?: ICardDetailsScheme;
  translate: Translate;
}

export const CreditCardDetails = ({
  expiryMonth,
  expiryYear,
  lastFourDigits,
  paymentMethod,
  scheme,
  translate,
}: ICreditCardDetails) => {
  const formattedExpirtyMonth = expiryMonth?.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  });
  const cardInfo = `${scheme?.toUpperCase()}**** ${lastFourDigits}`;
  const cardExpiry = `${translate(
    'LABEL.CARD_DETAILS_EXPIRY',
  )}: ${formattedExpirtyMonth}/${expiryYear}`;

  return (
    <DetailsCardlet
      details={[cardInfo, cardExpiry]}
      status={paymentMethod === PaymentMethods.CARD ? 'Active' : undefined}
      title={translate('TITLE.CARD_DETAILS')}
    />
  );
};
