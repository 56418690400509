import { useSelector } from 'react-redux';
import { useFeatureFlags, useMerchantSettingGroup } from '@hooks';
import { useOriginator } from '@hooks/useOriginator';
import { selectPortal } from '@selectors/index';
import { capitalize } from '@utils';
import { Portals } from '@utils/constants';
import * as configs from './configs';
import { ConfigFunc } from './configs/types';
import { applyHeyLightBranding } from './utils/applyHeyLightBranding';

export const useConfig = () => {
  const { originator } = useOriginator();
  // We can't use the usePortal hook because it uses useLocation,
  // which can't be used outside of a <Router> component context,
  // and config.hotjarid is used outside of that context.
  const portal = useSelector(selectPortal);
  const featureFlags = useFeatureFlags();
  const merchantSettingGroup = useMerchantSettingGroup();

  const getConfig = configs[
    `config${capitalize(portal || Portals.MERCHANT)}${capitalize(
      originator,
    )}` as keyof typeof configs
  ] as ConfigFunc;

  const config = {
    ...configs.configShared,
    ...getConfig({ featureFlags, merchantSettingGroup }),
  };

  const { canUseHeyLightBranding, canUseNewLoginPage } = featureFlags;

  return applyHeyLightBranding(
    { config },
    { canUseHeyLightBranding, canUseNewLoginPage },
  );
};
