import { createColumnHelper } from '@tanstack/react-table';
import { IApplicationsTableData } from '@appTypes';
import { StatusPill } from '@components';
import { Link } from '@components/Common.styles';
import { Portals } from '@utils/constants';
import { ApplicationReferenceLink } from './ApplicationReferenceLink';
import { PaymentPlanLink } from './PaymentPlanLink';
import { IGetApplicationsTableColumnsProps } from './types';

const getPaymentLink = (
  isMerchantPortal: boolean,
  contractId: string,
  paymentPlanId: string,
) =>
  isMerchantPortal ? (
    <PaymentPlanLink contractId={contractId} paymentPlanId={paymentPlanId} />
  ) : (
    <span>{paymentPlanId}</span>
  );

const getPracticaLink = (
  isMerchantPortal: boolean,
  praticaId: string,
  contractId: string,
) =>
  isMerchantPortal ? (
    <span>{praticaId}</span>
  ) : (
    <Link to={contractId}>{praticaId}</Link>
  );

export const getApplicationsTableColumns = ({
  isCompass = false,
  isPagolightPro = false,
  portal,
  translate,
}: IGetApplicationsTableColumnsProps) => {
  const columnHelper = createColumnHelper<IApplicationsTableData>();
  const isConsumer = portal === Portals.CONSUMER;
  const isMerchantPortal = portal === Portals.MERCHANT;

  const amountColumn = columnHelper.accessor('amount', {
    header: translate('LABEL.AMOUNT'),
  });

  const applicationReferenceColumn = columnHelper.accessor(
    'applicationReference',
    {
      header: translate('OPS_PORTAL.TITLE.APPLICATION_REFERENCE'),
      cell: ({ row }) => (
        <ApplicationReferenceLink data={row.original} isConsumer={isConsumer} />
      ),
    },
  );

  const applicationUuidColumn = columnHelper.accessor('applicationUuid', {
    header: translate('OPS_PORTAL.TITLE.APPLICATION_UUID'),
    enableSorting: false,
  });

  const paymentPlanIdColumn = columnHelper.accessor('paymentPlanId', {
    header: translate('TITLE.PAYMENT_PLAN_ID'),
    enableSorting: false,
    cell: ({
      row: {
        original: { contractId, paymentPlanId },
      },
    }) => getPaymentLink(isMerchantPortal, contractId, paymentPlanId),
  });

  const createdDateColumn = columnHelper.accessor('createdDate', {
    header: translate('OPS_PORTAL.TITLE.CREATED_DATE'),
  });

  const crifScoreColumn = columnHelper.accessor('crifScore', {
    header: translate('OPS_PORTAL.TITLE.CRIF_SCORE'),
    enableSorting: false,
  });

  const customerNameColumn = columnHelper.accessor('customerName', {
    header: translate('TITLE.CUSTOMER_NAME'),
    enableSorting: false,
  });

  const languageColumn = columnHelper.accessor('language', {
    header: translate('LABEL.LANGUAGE'),
  });

  const merchantNameColumn = columnHelper.accessor('merchant', {
    header: translate('OPS_PORTAL.LABEL.MERCHANT_NAME'),
  });

  const merchantOrderReferenceColumn = columnHelper.accessor(
    'merchantOrderReference',
    {
      header: translate(
        isMerchantPortal && !isPagolightPro
          ? 'TITLE.YOUR_INVOICE_NUMBER'
          : 'TITLE.INVOICE_NUMBER',
      ),
      enableSorting: false,
    },
  );

  const originationChannelColumn = columnHelper.accessor('originationChannel', {
    header: translate('OPS_PORTAL.TITLE.ORIGINATION'),
    enableSorting: false,
  });

  const originatorColumn = columnHelper.accessor('originator', {
    header: translate('OPS_PORTAL.TITLE.ORIGINATOR'),
    enableSorting: false,
  });

  const praticaIdColumn = columnHelper.accessor('praticaId', {
    header: translate('TITLE.EXTERNAL_ID'),
    cell: ({
      row: {
        original: { contractId, praticaId },
      },
    }) => getPracticaLink(isMerchantPortal, praticaId, contractId),
    enableSorting: false,
  });

  const statusColumn = columnHelper.accessor('activityStatus', {
    header: translate('TITLE.STATUS'),
    cell: ({ row }) => {
      const { activityStatus, rawStatus } = row.original;
      return <StatusPill rawStatus={rawStatus} status={activityStatus} />;
    },
  });

  const columns = {
    [Portals.CONSUMER]: [
      applicationReferenceColumn,
      ...(isCompass ? [praticaIdColumn] : []),
      createdDateColumn,
      merchantNameColumn,
      statusColumn,
      amountColumn,
      originationChannelColumn,
      ...(isCompass ? [] : [originatorColumn]),
      crifScoreColumn,
    ],
    [Portals.MERCHANT]: [
      paymentPlanIdColumn,
      ...(isCompass ? [praticaIdColumn] : []),
      merchantOrderReferenceColumn,
      createdDateColumn,
      customerNameColumn,
      statusColumn,
      amountColumn,
    ],
    [Portals.OPS]: [
      applicationReferenceColumn,
      ...(isCompass ? [praticaIdColumn] : []),
      applicationUuidColumn,
      paymentPlanIdColumn,
      createdDateColumn,
      customerNameColumn,
      merchantNameColumn,
      statusColumn,
      amountColumn,
      originationChannelColumn,
      ...(isCompass ? [] : [originatorColumn]),
      crifScoreColumn,
      languageColumn,
    ],
  };

  return portal ? columns[portal] : [];
};
