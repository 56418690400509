export const createCSV = ({ arrayedData, headers }) => {
  const joinedHeaders = headers.join(',');
  const joinedData = arrayedData
    .map(arr =>
      arr
        .map(d => `"${d?.replace('\u00a0', ' ').replace('—', '-')}"`)
        .join(','),
    )
    .join('\n');

  return `${joinedHeaders}\n${joinedData}\n`;
};
