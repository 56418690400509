import { ValueOf } from '@appTypes';
import { OrderView } from '@pages/enums';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';

export interface IViewConfig {
  endpoint: string;
  queryKey: ValueOf<typeof QueryKeys>;
}

export const viewConfig: Record<OrderView, IViewConfig> = {
  [OrderView.AwaitingDispatch]: {
    endpoint: Endpoints.FetchOrdersAwaitingDispatch,
    queryKey: QueryKeys.AWAITING_DISPATCH_TABLE_DATA,
  },
  [OrderView.MyOrder]: {
    endpoint: Endpoints.FetchMyOrders,
    queryKey: QueryKeys.MY_ORDERS_TABLE_DATA,
  },
  [OrderView.Order]: {
    endpoint: Endpoints.FetchOrders,
    queryKey: QueryKeys.ORDERS_TABLE_DATA,
  },
};
