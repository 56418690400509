import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useI18n } from '@hooks';
import { ModalStatusRouter } from '@hooks/useModal/components/index.ts';
import { getErrorMessage, putDataToEndpoint } from '@utils';
import { PasswordForm } from './PasswordForm';

export const usePasswordModal = ({ formStatusMessage, resolveForm }) => {
  const { translate } = useI18n();

  const { isLoading, mutate } = useMutation(
    requestData =>
      putDataToEndpoint({
        endpoint: 'user/settings/password-change',
        requestData,
      }),
    {
      onSuccess: () => {
        resolveForm(translate('ACCOUNT.PASSWORD_SUCCESSFULLY_CHANGED'));
      },
      onError: error => {
        resolveForm(
          getErrorMessage({
            error,
            genericErrorMessageKey: 'ACCOUNT.UNABLE_TO_CHANGE_PASSWORD',
            translate,
          }),
        );
      },
    },
  );

  const handleSubmit = useCallback(
    values => {
      mutate(values);
    },
    [mutate],
  );

  const formID = 'changePassword';

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={isLoading}
    >
      <PasswordForm formID={formID} handleSubmit={handleSubmit} />
    </ModalStatusRouter>
  );

  return {
    formID,
    isLoading,
    modalContent,
    title: translate('ACCOUNT.CHANGE_PASSWORD'),
  };
};
