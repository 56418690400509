import { useMemo } from 'react';
import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { Grid } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DataDisplay, QueryStateRouter, ScheduledPayments } from '@components';
import { useHasPermission, useI18n, usePortalError } from '@hooks';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { ModalSize } from '@hooks/useModal/components/enums';
import { ICustomModalButton } from '@hooks/useModal/components/types';
import { IModalProps } from '@hooks/useModal/types';
import { IOpsPortalEarlySettlement } from '@schemas/opsPortal/types/earlySettlement';
import { fetchDataFromEndpoint, postDataToEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { ButtonVariant, Endpoints } from '@utils/enums';
import { EarlySettlementActionType, EarlySettlementStatus } from './enums';
import { getEarlySettlementDisplayDataList } from './getEarlySettlementDisplayDataList';
import { EarlySettlementDataGrid, NewSettlementScheduleGrid } from './styles';
import {
  IEarlySettlementDisplayModal,
  IEarlySettlementDisplayModalRequestData,
} from './types';

export const useEarlySettlementQuoteDisplayModal = ({
  closeModal,
  earlySettlementQuoteUuid,
  externalContractUUID,
}: IEarlySettlementDisplayModal): IModalProps => {
  const hasAcceptPermission = useHasPermission([
    'ops_portal.accept_early_settlement',
  ]);
  const hasCancelPermission = useHasPermission([
    'ops_portal.cancel_early_settlement',
  ]);

  const { handleAxiosError } = usePortalError();
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();
  const queryClient = useQueryClient();
  const { message } = useToast(translate);

  const {
    data: earlySettlement,
    error: earlySettlementsDisplayError,
    isLoading: isEarlySettlementsDisplayLoading,
  } = useQuery<IOpsPortalEarlySettlement, AxiosError>(
    [QueryKeys.EARLY_SETTLEMENT_DISPLAY, earlySettlementQuoteUuid],
    () =>
      fetchDataFromEndpoint(
        `${Endpoints.FetchEarlySettlement}/${earlySettlementQuoteUuid}`,
      ),
    {
      enabled: Boolean(earlySettlementQuoteUuid),
    },
  );

  const mutationEndpoints = {
    [EarlySettlementActionType.Accept]: Endpoints.AcceptEarlySettlement,
    [EarlySettlementActionType.Cancel]: Endpoints.CancelEarlySettlement,
  };

  const useEarlySettlementMutation = (
    endpoint: EarlySettlementActionType,
    successMessage: string,
  ) =>
    useMutation<
      unknown,
      AxiosError<IError>,
      IEarlySettlementDisplayModalRequestData
    >(
      () =>
        postDataToEndpoint({
          endpoint: `${mutationEndpoints[endpoint]}/${earlySettlementQuoteUuid}`,
        }),
      {
        onSuccess: () => {
          message.success(successMessage);
          queryClient.invalidateQueries([QueryKeys.EARLY_SETTLEMENTS]);
          queryClient.invalidateQueries([
            QueryKeys.MONETA_CONTRACT_DATA,
            externalContractUUID,
          ]);
          if (closeModal) {
            closeModal();
          }
        },
        onError: error => {
          handleAxiosError(error);
        },
      },
    );

  const { isLoading: isSubmittingAccepted, mutate: handleAcceptClick } =
    useEarlySettlementMutation(
      EarlySettlementActionType.Accept,
      'OPS_PORTAL.STATUS.EARLY_SETTLEMENT_QUOTE_SUCCESSFULLY_ACCEPTED',
    );

  const { isLoading: isSubmittingCancelled, mutate: handleCancelClick } =
    useEarlySettlementMutation(
      EarlySettlementActionType.Cancel,
      'OPS_PORTAL.STATUS.EARLY_SETTLEMENT_QUOTE_SUCCESSFULLY_CANCELLED',
    );

  const cancelButton: ICustomModalButton[] = useMemo(
    () =>
      earlySettlement?.quote_status === EarlySettlementStatus.Open &&
      hasCancelPermission
        ? [
            {
              text: translate('OPS_PORTAL.BUTTON.CANCEL_QUOTE'),
              clickHandler: handleCancelClick,
              variant: ButtonVariant.Danger,
            },
          ]
        : [],
    [
      earlySettlement?.quote_status,
      handleCancelClick,
      hasCancelPermission,
      translate,
    ],
  );

  const acceptButton: ICustomModalButton[] = useMemo(
    () =>
      earlySettlement?.quote_status === EarlySettlementStatus.Open &&
      hasAcceptPermission
        ? [
            {
              text: translate('OPS_PORTAL.BUTTON.ACCEPT_QUOTE'),
              clickHandler: handleAcceptClick,
              variant: ButtonVariant.Primary,
            },
          ]
        : [],
    [
      earlySettlement?.quote_status,
      handleAcceptClick,
      hasAcceptPermission,
      translate,
    ],
  );

  const modalButtons: ICustomModalButton[] = useMemo(
    () => [...cancelButton, ...acceptButton],
    [acceptButton, cancelButton],
  );

  const formID = 'earlySettlementForm';

  const dataList = earlySettlement
    ? getEarlySettlementDisplayDataList({
        earlySettlement,
        getLocaleCurrencyValue,
        getLocaleDate,
        translate,
      })
    : [];

  const modalContent = (
    <ModalStatusRouter
      isLoading={isSubmittingAccepted || isSubmittingCancelled}
    >
      <QueryStateRouter
        error={earlySettlementsDisplayError}
        isLoading={isEarlySettlementsDisplayLoading}
        loadingMessage="STATUS.LOADING"
      >
        <Grid container={true}>
          <EarlySettlementDataGrid
            item={true}
            lg={earlySettlement?.new_schedule ? 8 : 12}
            xs={12}
          >
            <h3>{translate('OPS_PORTAL.TITLE.QUOTE_DETAILS')}</h3>
            <DataDisplay dataList={dataList} />
          </EarlySettlementDataGrid>
          {earlySettlement?.new_schedule ? (
            <NewSettlementScheduleGrid item={true} lg={4} xs={12}>
              <h3>{translate('OPS_PORTAL.LABEL.NEW_SCHEDULE')}</h3>
              <ScheduledPayments
                payments={
                  earlySettlement?.new_schedule.map(
                    ({ amount: { amount, currency }, due_date: dueDate }) => ({
                      dueDate: getLocaleDate({
                        date: dueDate,
                      }),
                      paymentAmount:
                        getLocaleCurrencyValue({
                          value: amount,
                          currency,
                        }) ?? '',
                    }),
                  ) ?? []
                }
              />
            </NewSettlementScheduleGrid>
          ) : null}
        </Grid>
      </QueryStateRouter>
    </ModalStatusRouter>
  );

  return {
    formID,
    width: earlySettlement?.new_schedule ? ModalSize.Md : ModalSize.Sm,
    isDangerousSubmit: true,
    isLoading: false,
    modalContent,
    customButtons: modalButtons,
    title: translate('OPS_PORTAL.TITLE.EARLY_SETTLEMENT_QUOTE'),
  };
};
