import { Suspense } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { Permissions } from '@appTypes';
import { AccessController, Card } from '@components';
import { useI18n, useOriginator } from '@hooks';
import { Layout, LayoutSplashScreen } from '@metronic/layout';
import { selectIsAuthed } from '@selectors';
import { setCached } from '@utils';
import { AuthPathnames } from '@utils/constants';

interface IProtectedRouteProps {
  children: JSX.Element | null;
  scopes: Permissions;
}

export const ProtectedRoute = ({ children, scopes }: IProtectedRouteProps) => {
  const { translate } = useI18n();
  const { pathnamePrefix } = useOriginator();

  const location = useLocation();

  const isAuthed = useSelector(selectIsAuthed, shallowEqual);

  const unauthorisedView = (
    <Card title={translate('AUTH.UNAUTHORISED_ACCESS')}>
      <p>
        {translate(
          'AUTH.YOU_DO_NOT_HAVE_THE_NECESSARY_PERMISSIONS_TO_VIEW_THIS_PAGE',
        )}
      </p>
    </Card>
  );

  if (isAuthed) {
    return (
      <Layout>
        <Suspense fallback={<LayoutSplashScreen />}>
          <AccessController scopes={scopes} unauthedView={unauthorisedView}>
            {children}
          </AccessController>
        </Suspense>
      </Layout>
    );
  }

  setCached({
    cache: sessionStorage,
    label: 'portalCachedPath',
    item: location.pathname + location.search,
  });

  const path = pathnamePrefix + AuthPathnames.LOGIN;

  return <Navigate replace={true} to={path} />;
};
