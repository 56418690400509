import { Translate } from '@hooks/useI18n';
import {
  IAllocationStatus,
  IAllocationType,
} from '@schemas/opsPortal/types/bankAccountReconciliations';
import { formatDaysAgo } from '@utils';
import { FilterTypes } from '@utils/constants';
import { AllocationStatus, AllocationType } from '@utils/enums';
import { IFilterOptions, QueryParams } from './types';

const allocationStatus: IAllocationStatus[] = Object.values(AllocationStatus);

const allocationType: IAllocationType[] = Object.values(AllocationType);

const translationPrefix = 'OPS_PORTAL.LABEL.BANK_ACCOUNT_RECONCILIATIONS';

interface IGetBankAccountTransactionFiltersProps {
  queryParams?: QueryParams;
  translate: Translate;
}

interface IArrayProps {
  name: string;
  translate: Translate;
  value?: string;
}

interface IGetDropdownFromArray extends IArrayProps {
  accessorKey: 'allocationStatus' | 'allocationDetails' | 'allocationType';
  optionsArray: string[];
}

interface IGetBooleanDropdown extends IArrayProps {
  accessorKey: 'pending' | 'unmatched';
}

const getDropdownFromArray = ({
  accessorKey,
  name,
  optionsArray,
  translate,
  value,
}: IGetDropdownFromArray) => {
  const options: IFilterOptions[] = [
    { id: '', name: '-' },
    ...optionsArray.map(type => ({
      id: type,
      name: translate(`${translationPrefix}.${type}`),
    })),
  ];

  return {
    accessorKey,
    id: accessorKey,
    label: translate(name),
    options,
    type: FilterTypes.DROPDOWN,
    value: String(value),
  };
};

const getBooleanDropdown = ({
  accessorKey,
  name,
  translate,
  value,
}: IGetBooleanDropdown) => ({
  accessorKey,
  id: accessorKey,
  label: translate(name),
  options: [
    { id: 'true', name: translate('GENERAL.YES') },
    { id: 'false', name: translate('GENERAL.NO') },
  ],
  type: FilterTypes.DROPDOWN,
  // Needs to use ||, not ??, so that empty string can be replaced with 'false'.
  value: String(value || 'false'),
});

const getUnbalancedAllocationsDateFilter = (
  translate: Translate,
  queryParams?: QueryParams,
) => {
  const allDates = JSON.stringify(['', '']);
  const today = formatDaysAgo(0);
  const last7 = JSON.stringify([formatDaysAgo(7), today]);
  const last30 = JSON.stringify([formatDaysAgo(30), today]);
  const olderThan30 = JSON.stringify(['', formatDaysAgo(31)]);

  const fromValue = queryParams?.latestTestDateFrom ?? '';
  const toValue = queryParams?.latestTestDateTo ?? '';
  const dropdownValue = JSON.stringify([fromValue, toValue]);

  return {
    accessorKey: 'latestTestDate',
    from: {
      id: 'latestTestDateFrom',
      value: fromValue,
    },
    id: 'latestTestDate',
    label: translate('OPS_PORTAL.LABEL.LATEST_TEST_DATE'),
    options: [
      { id: allDates, name: translate('OPS_PORTAL.LABEL.ALL_DATES') },
      {
        id: last7,
        name: translate('OPS_PORTAL.LABEL.LAST_X_DAYS', { x: 7 }),
      },
      {
        id: last30,
        name: translate('OPS_PORTAL.LABEL.LAST_X_DAYS', { x: 30 }),
      },
      {
        id: olderThan30,
        name: translate('OPS_PORTAL.LABEL.OLDER_THAN_X_DAYS', { x: 30 }),
      },
    ],
    to: {
      id: 'latestTestDateTo',
      value: toValue,
    },
    type: FilterTypes.PRESET_DATE_RANGE,
    value: dropdownValue,
  };
};

export const getBankAccountTransactionFilters = ({
  queryParams,
  translate,
}: IGetBankAccountTransactionFiltersProps) => {
  const allocationStatusDropdown = getDropdownFromArray({
    translate,
    optionsArray: allocationStatus,
    accessorKey: 'allocationStatus',
    name: 'OPS_PORTAL.LABEL.ALLOCATION_STATUS',
    value: queryParams?.allocationStatus ?? '',
  });

  const allocationTypeDropdown = getDropdownFromArray({
    translate,
    optionsArray: allocationType,
    accessorKey: 'allocationType',
    name: 'OPS_PORTAL.LABEL.ALLOCATION_TYPE',
    value: queryParams?.allocationType ?? '',
  });

  const pendingDropdown = getBooleanDropdown({
    translate,
    accessorKey: 'pending',
    name: 'OPS_PORTAL.LABEL.PENDING',
    value: queryParams?.pending,
  });

  const unmatchedDropdown = getBooleanDropdown({
    translate,
    accessorKey: 'unmatched',
    name: 'OPS_PORTAL.LABEL.UNMATCHED',
    value: queryParams?.unmatched,
  });

  const unbalancedAllocationsDateFilter = getUnbalancedAllocationsDateFilter(
    translate,
    queryParams,
  );

  return {
    allocationStatusDropdown,
    allocationTypeDropdown,
    pendingDropdown,
    unmatchedDropdown,
    unbalancedAllocationsDateFilter,
  };
};
