import { useCallback } from 'react';
import { ICardCTA } from '@appTypes';
import { CardCTA } from '@components';

interface IViewEarlySettlementButtonProps {
  ctaProps: ICardCTA;
  earlySettlementQuoteUuid: string;
  setEarlySettlementQuoteUuid: (earlySettlementQuoteUuid: string) => void;
}

export const ViewEarlySettlementButton = ({
  ctaProps,
  earlySettlementQuoteUuid,
  setEarlySettlementQuoteUuid,
}: IViewEarlySettlementButtonProps) => {
  const clickHandler = useCallback(() => {
    setEarlySettlementQuoteUuid(earlySettlementQuoteUuid);
    ctaProps.clickHandler();
  }, [ctaProps, earlySettlementQuoteUuid, setEarlySettlementQuoteUuid]);

  return <CardCTA {...ctaProps} clickHandler={clickHandler} />;
};
