import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { ValueOf } from '@appTypes';
import {
  AccessController,
  ApiKeys,
  Card,
  DataDisplay,
  QueryStateRouter,
} from '@components';
import { ModalKeys, useConfig, useI18n, useModal, usePortal } from '@hooks';
import { IConsumerPortalAccountSettings } from '@schemas/consumerPortal/types/accountSettings';
import { IConsumerPortalAddressData } from '@schemas/consumerPortal/types/addressData';
import { IMerchantPortalAccountSettings } from '@schemas/merchantPortal/types/accountSettings';
import { IOpsPortalAccountSettings } from '@schemas/opsPortal/types/accountSettings';
import { selectTimeToAuthExpiry } from '@selectors/index';
import { fetchDataFromEndpoint } from '@utils';
import { QueryKeys, Portals } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { getModalKeys, getDisplayDataList } from './utils';

export const AccountSettings = () => {
  const { config } = useConfig();
  const { isConsumerPortal, portal } = usePortal();

  const { translate } = useI18n();

  const msUntilTokenExpiry = useSelector(selectTimeToAuthExpiry);

  const {
    data: accountData,
    error: accountSettingsError,
    isLoading: isAccountSettingsLoading,
  } = useQuery<
    | IConsumerPortalAccountSettings
    | IMerchantPortalAccountSettings
    | IOpsPortalAccountSettings,
    AxiosError
  >(
    [QueryKeys.ACCOUNT_SETTINGS],
    () => fetchDataFromEndpoint(Endpoints.FetchUserSettings),
    {
      cacheTime: msUntilTokenExpiry,
      staleTime: msUntilTokenExpiry,
    },
  );

  const {
    data: addressData,
    error: addressError,
    isInitialLoading: isAddressLoading,
  } = useQuery<IConsumerPortalAddressData, AxiosError>(
    [QueryKeys.CONSUMER_ADDRESS],
    () => fetchDataFromEndpoint(Endpoints.FetchUserAddress),
    {
      cacheTime: msUntilTokenExpiry,
      enabled: isConsumerPortal,
      staleTime: msUntilTokenExpiry,
    },
  );

  const modals = {
    editAddressModal: useModal(ModalKeys.EditAddress, {
      data: addressData,
    }),
    editNameModal: useModal(ModalKeys.EditName, { data: accountData }),
    editPhoneModal: useModal(ModalKeys.EditPhone, { data: accountData }),
    passwordModal: useModal(ModalKeys.Password),
  };

  const modalKeys = getModalKeys(config);
  const buttons = modalKeys
    .map(modalKey => modals[modalKey as keyof typeof modals]?.ctaProps)
    .filter(Boolean);

  const userData = {
    [Portals.CONSUMER]: {
      ...accountData,
      address: addressData?.data?.formatted_address,
      country: addressData?.data?.country_alpha_2,
    },
    [Portals.MERCHANT]: {
      ...accountData,
    } as Partial<IMerchantPortalAccountSettings>,
    [Portals.OPS]: { ...accountData },
  };

  delete userData[Portals.MERCHANT].phone_number;

  const dataList = getDisplayDataList({
    userData: userData[portal as ValueOf<typeof Portals>],
    translate,
  });

  return (
    <QueryStateRouter
      error={accountSettingsError || addressError}
      isLoading={isAccountSettingsLoading || isAddressLoading}
    >
      <>
        <Card buttons={buttons} title={translate('TITLE.ACCOUNT_SETTINGS')}>
          <DataDisplay dataList={dataList} />
        </Card>
        <AccessController scopes={['merchant_portal.can_view_merchant_keys']}>
          <ApiKeys />
        </AccessController>
        {modalKeys.map(modalKey => (
          <Fragment key={modalKey}>
            {modals[modalKey as keyof typeof modals]?.Modal}
          </Fragment>
        ))}
      </>
    </QueryStateRouter>
  );
};
