import { Translate } from '@heidi-pay/heidi-common-fe/types';
import { Row, createColumnHelper } from '@tanstack/react-table';
import { ReactTableColumns } from '@appTypes';
import { Button } from '@components';
import { ButtonVariant } from '@utils/enums';
import { ICertificatesOfInterestTableData } from '../useCertificatesOfInterestTab';

export interface IGetCertificateInterestColumns {
  handleDownload: (row: Row<ICertificatesOfInterestTableData>) => () => void;
  handleSendEmail: (row: Row<ICertificatesOfInterestTableData>) => () => void;
  translate: Translate;
}

export const getCertificateOfInterestColumns = ({
  handleDownload,
  handleSendEmail,
  translate,
}: IGetCertificateInterestColumns): ReactTableColumns<ICertificatesOfInterestTableData> => {
  const columnHelper = createColumnHelper<ICertificatesOfInterestTableData>();

  return [
    columnHelper.accessor('createdDate', {
      header: translate('OPS_PORTAL.TITLE.DATE'),
    }),
    columnHelper.accessor('accountingBalance', {
      header: translate('OPS_PORTAL.TITLE.BALANCE'),
      enableSorting: false,
    }),
    columnHelper.accessor('debitInterest', {
      header: translate('OPS_PORTAL.TITLE.INTEREST'),
      enableSorting: false,
    }),
    columnHelper.display({
      cell: ({ row }) => (
        <>
          <Button
            onClick={handleDownload(row)}
            variant={[ButtonVariant.Primary, ButtonVariant.Sm]}
          >
            {translate('BUTTON.DOWNLOAD')}
          </Button>
          {row.original.status === 'pdf_generated' ? (
            <Button
              onClick={handleSendEmail(row)}
              variant={[ButtonVariant.Primary, ButtonVariant.Sm]}
            >
              {translate('OPS_PORTAL.TITLE.SEND_EMAIL_TO_CUSTOMER')}
            </Button>
          ) : null}
        </>
      ),
      id: 'actionsColumn',
    }),
  ];
};
