import { GetLocaleCurrencyValue } from '@hooks/useI18n';
import { IMerchantPortalMediobancaSettlements } from '@schemas/merchantPortal/types/mediobancaSettlements';
import { IOrderDetail } from '@schemas/merchantPortal/types/ordersDetails';
import { PaymentMethods } from '@utils/constants';

export type PaymentMethod = keyof typeof PaymentMethods;
export type PaymentMethodValue = (typeof PaymentMethods)[PaymentMethod];

export interface IInvoiceDetailsProps {
  invoiceData?: IMerchantPortalMediobancaSettlements;
  orderData: IOrderDetail;
}

export interface IPrepareInvoiceDataProps {
  data: IInvoiceDetailsProps;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
}

export interface IScheduledPayment {
  dueDate: string;
  paymentAmount: string;
}
