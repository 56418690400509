import { FilterTypes, TableNames } from '@utils/constants';

const searchLabel = 'LABEL.SEARCH';

export const getSearchField = ({ queryParams, tableName, translate }) => {
  const labels = {
    [TableNames.COMMUNICATIONS]: `${translate(searchLabel)} (${translate(
      'LABEL.SUBJECT_LINE_ONLY',
    )})`,
    [TableNames.CONSUMERS]: `${translate(searchLabel)} (${translate(
      'LABEL.EMAILS_AND_NAMES_ONLY',
    )})`,
    [TableNames.MERCHANTS]: `${translate(searchLabel)} (${translate(
      'LABEL.MERCHANT_NAME_ONLY',
    )})`,
  };

  return {
    id: 'search',
    label: labels[tableName] || translate(searchLabel),
    type: FilterTypes.SEARCH,
    value: queryParams?.search,
  };
};
