import splashImageHeyLight from '@assets/images/login-splash-compass.png';
import { IConfig, IImageLogo } from '@hooks/useConfig/configs/types';
import { resourcesBucketUrl } from '@utils/constants';

interface IHeyLightBrandingProps {
  canUseHeyLightBranding: boolean;
  canUseNewLoginPage: boolean;
}

const themeHeyLight = {
  bootstrapColors: {
    name: 'heylight',
  },
  colors: {
    primary: '#ea1c00',
  },
};

export const applyHeyLightBranding = (
  { config }: IConfig,
  { canUseHeyLightBranding, canUseNewLoginPage }: IHeyLightBrandingProps,
) => {
  const applyBranding = canUseHeyLightBranding || canUseNewLoginPage;

  return {
    config: {
      ...config,
      canUseHeyLightBranding: applyBranding,
      ...(applyBranding
        ? {
            logo: {
              altTxt: 'HeyLight',
              showPoweredBy: false,
              url: `${resourcesBucketUrl}logos/heylight_<§:variant§>_standard.svg`,
            } as IImageLogo,
          }
        : {}),
      theme: {
        ...config.theme,
        ...(applyBranding
          ? { ...themeHeyLight, splashImage: splashImageHeyLight }
          : {}),
      },
    },
  };
};
