import { supportedLanguages } from '@utils/constants';

export type Translate = (
  id: string,
  interpolations?: Record<
    string,
    PrimitiveType | FormatXMLElementFn<string, string>
  >,
) => string;

interface IGetLocaleDateOptions {
  date: string;
  formatOverride?: Intl.DateTimeFormatOptions;
  includeTime?: boolean;
}

export type GetLocaleDate = (options: IGetLocaleDateOptions) => string;

interface IGetLocaleCurrencyValueOptions {
  currency?: string;
  value?: string;
}

export type GetLocaleCurrencyValue = (
  options: IGetLocaleCurrencyValueOptions,
) => string | undefined;

export type SupportedLanguage = (typeof supportedLanguages)[number];
