import { useMemo } from 'react';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { Spinner } from '@auth/pages/Auth.styles';
import { useI18n, useQueryOnClick } from '@hooks';
import { downloadFile, fetchDataFromEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { ButtonVariant, Endpoints } from '@utils/enums';

export const useDownloadSignedCustomerAgreement = (
  externalApplicationUuid: string | undefined,
) => {
  const { translate } = useI18n();

  const { message } = useToast(translate);

  const useQueryDownloadAgreementCallbacks = useMemo(
    () => ({
      onSuccess: (url: string) => downloadFile(url),
      onError: () => {
        message.error('ERROR.FILE_DOWNLOAD', { timeout: 10000 });
      },
    }),
    [message],
  );

  const {
    execute: executeDownloadAgreement,
    isLoading: isLoadingDownloadAgreement,
  } = useQueryOnClick<string, unknown>(
    [QueryKeys.DOWNLOAD_SIGNED_CUSTOMER_AGREEMENT],
    () =>
      fetchDataFromEndpoint(
        `${Endpoints.CustomerAgreementSigned}/${externalApplicationUuid}`,
        {},
      ),
    useQueryDownloadAgreementCallbacks,
  );

  if (!externalApplicationUuid) {
    return null;
  }

  return {
    ...(isLoadingDownloadAgreement
      ? { additionalButtonContent: <Spinner extraClassName="pr-5" /> }
      : {}),
    clickHandler: () => executeDownloadAgreement(null),
    key: 'printPage',
    text: translate('BUTTON.DOWNLOAD_CUSTOMER_AGREEMENT'),
    variant: [ButtonVariant.Primary, ButtonVariant.Sm],
  };
};
