import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useConfig } from '@hooks';
import { getEnv } from '@utils';

const hotjarRegistrationDelay = 3000;

export const HotjarFeedbackController = () => {
  const {
    config: { hotjarId },
  } = useConfig();

  const { isProd } = getEnv();

  const [shouldRegisterHotjarWidget, setShouldRegisterHotjarWidget] =
    useState(false);

  // We will wait for the timeout to fire before checking if we should register hotjar.
  // Like this, we avoid unwanted registration due to heidipay being the default originator
  // by waiting for the originator in the state to be updated from the path or user settings.
  useEffect(() => {
    const timer = setTimeout(() => {
      if (isProd && hotjarId) {
        setShouldRegisterHotjarWidget(true);
      }
    }, hotjarRegistrationDelay);

    return () => clearTimeout(timer);
  }, [hotjarId, isProd]);

  return (
    <Helmet>
      {shouldRegisterHotjarWidget ? (
        <script>
          {`
            (function (h, o, t, j, a, r) {
              h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
              h._hjSettings = { hjid: ${hotjarId}, hjsv: 6 };
              a = o.getElementsByTagName('head')[0];
              r = o.createElement('script'); r.async = 1;
              r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
              a.appendChild(r);
            })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
          `}
        </script>
      ) : null}
    </Helmet>
  );
};
