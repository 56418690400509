import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { requestRegistrationEmail } from '@auth/_redux';
import { useI18n, useOriginator } from '@hooks';
import { Originators } from '@utils/constants';
import { AuthPageHeader, ContentInnerWrapper } from './Auth.styles';
import { AuthForm } from './components';

export const RequestRegistrationEmail = () => {
  const { selectedLanguage, translate } = useI18n();
  const { originator } = useOriginator();
  const navigate = useNavigate();

  const originatorThemeMapping = {
    [Originators.HEIDIPAY]: 'heidipay',
    [Originators.PAGOLIGHT]: 'mediobanca',
    [Originators.PAGOLIGHTPRO]: 'pagolight_pro',
  };

  const handleSubmit = useCallback(
    (values, { setStatus, setSubmitting }) => {
      requestRegistrationEmail(values)
        .then(() => {
          navigate('/auth/success', {
            state: {
              heading: translate('AUTH.REGISTRATION_REQUESTED_SUBMITTED'),
              text: translate(
                'AUTH.PLEASE_CHECK_YOUR_EMAIL_FOR_FURTHER_INSTRUCTIONS',
              ),
            },
            replace: true,
          });
        })
        .catch(() => {
          setStatus(translate('ERROR.UNEXPECTED_ERROR'));
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [navigate, translate],
  );

  return (
    <ContentInnerWrapper>
      <AuthPageHeader>
        <h2>{translate('AUTH.REQUEST_REGISTRATION_EMAIL')}</h2>
        <p>
          {translate(
            'AUTH.ENTER_YOUR_EMAIL_ADDRESS_TO_RECEIVE_YOUR_REGISTRATION_EMAIL',
          )}
        </p>
      </AuthPageHeader>

      <AuthForm
        fields={['email']}
        handleSubmit={handleSubmit}
        initialValues={{
          email: '',
          language: selectedLanguage,
          theme: originatorThemeMapping[originator],
        }}
      />
    </ContentInnerWrapper>
  );
};
