import { useCallback, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Card } from '@components';
import { useI18n } from '@hooks';
import { BulkUpload } from './BulkUpload';

const TabEventKeys = Object.freeze({
  BULK_UPLOAD: 'csvUploads',
});

export const Utilities = () => {
  const { translate } = useI18n();

  const [activeKey, setActiveKey] = useState(TabEventKeys.BULK_UPLOAD);

  const selectTabKey = useCallback(key => {
    setActiveKey(key);
  }, []);

  return (
    <Card title={translate('OPS_PORTAL.TITLE.UTILITIES')}>
      <Tabs activeKey={activeKey} id="utilities-tabs" onSelect={selectTabKey}>
        <Tab
          eventKey={TabEventKeys.BULK_UPLOAD}
          key={TabEventKeys.BULK_UPLOAD}
          title={translate('OPS_PORTAL.TITLE.BULK_UPLOAD')}
        >
          <BulkUpload />
        </Tab>
      </Tabs>
    </Card>
  );
};
