import styled from 'styled-components';

const colorPicker = {
  blue: '#53c3c2',
  dark: '#040707',
  light: '#fdf9f9',
};

const Logo = styled.svg.attrs(({ title }) => ({ title }))`
  .st0 {
    fill: ${({ fill }) => colorPicker[fill]};
  }
`;

export const SVGLogo = ({ component, fill, title }) => (
  <Logo as={component} fill={fill} title={title} />
);
