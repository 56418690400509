import { useQuery } from '@tanstack/react-query';
import { getIsInArrears } from '@pages/ContractDetails/utils';
import { IOpsPortalCollectionsDetails } from '@schemas/opsPortal/types/collectionsDetails';
import { fetchDataFromEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';

interface IUseGetCollectionsQuery {
  contractUuid?: string;
}

export const useGetCollectionsQuery = ({
  contractUuid,
}: IUseGetCollectionsQuery) => {
  const {
    data: collectionsData,
    isError: isCollectionsDataError,
    isInitialLoading: isCollectionsDataLoading,
  } = useQuery<IOpsPortalCollectionsDetails>(
    [QueryKeys.COLLECTIONS_DATA, contractUuid],
    () =>
      fetchDataFromEndpoint(`${Endpoints.FetchCollections}/${contractUuid}`),
    { retry: false, enabled: Boolean(contractUuid) },
  );
  const isInArrears = getIsInArrears(collectionsData);

  return {
    collectionsData,
    isCollectionsDataError,
    isCollectionsDataLoading,
    isInArrears,
  };
};
