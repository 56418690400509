import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useI18n } from '@hooks';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { IModalProps } from '@hooks/useModal/types';
import { getErrorMessage, postDataToEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import {
  IArrearsManagedByRequest,
  IUpdateArrearsManagedByModal,
} from './types';
import { UpdateArrearsManagedByForm } from './UpdateArrearsManagedByForm';

export const useUpdateArrearsManagedByModal = ({
  arrearsManagedBy,
  collectionAgencies,
  contractDataQueryKey,
  externalContractUUID,
  formStatusMessage,
  resolveForm,
}: IUpdateArrearsManagedByModal): IModalProps => {
  const { translate } = useI18n();

  const queryClient = useQueryClient();

  const collectionsQueryKey = [
    QueryKeys.COLLECTIONS_BLOCK_AUTO_REFERRAL,
    externalContractUUID,
  ];

  const {
    isLoading: isUpdatingArrearsManagedBy,
    mutate: updateArrearsManagedBy,
  } = useMutation<unknown, AxiosError, IArrearsManagedByRequest>(
    requestData => {
      return postDataToEndpoint({
        endpoint: `${Endpoints.UpdateArrearsManagedBy}/${externalContractUUID}`,
        requestData,
      });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(contractDataQueryKey);
        await queryClient.invalidateQueries(collectionsQueryKey);
        resolveForm(
          translate('OPS_PORTAL.STATUS.UPDATE_ARREARS_MANAGED_BY_SUCCESSFUL'),
        );
      },
      onError: error => {
        resolveForm(
          getErrorMessage({
            error,
            genericErrorMessageKey:
              'OPS_PORTAL.STATUS.UNABLE_TO_UPDATE_ARREARS_MANAGED_BY',
            translate,
          }),
        );
      },
    },
  );

  const handleSubmit = useCallback(
    data => updateArrearsManagedBy(data),
    [updateArrearsManagedBy],
  );

  const formID = 'updateArrearsManagedBy';

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={isUpdatingArrearsManagedBy}
    >
      <UpdateArrearsManagedByForm
        arrearsManagedBy={arrearsManagedBy}
        collectionAgencies={collectionAgencies}
        formID={formID}
        handleSubmit={handleSubmit}
      />
    </ModalStatusRouter>
  );

  return {
    allowoverflow: true,
    formID,
    isDangerousSubmit: true,
    isLoading: isUpdatingArrearsManagedBy,
    modalContent,
    title: translate('OPS_PORTAL.LABEL.UPDATE_ARREARS_MANAGED_BY'),
  };
};
