import { useMemo } from 'react';
import { useConfig, useI18n } from '@hooks';
import { useHtmlClassService } from '@metronic/layout/_core/MetronicLayout';

export function Footer() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(
    () => ({
      footerClasses: uiService.getClasses('footer', true),
      footerContainerClasses: uiService.getClasses('footer_container', true),
    }),
    [uiService],
  );

  const { translate } = useI18n();
  const {
    config: { showFooter, showFooterPrivacyLink },
  } = useConfig();

  const footerClasses =
    'd-flex flex-column flex-md-row align-items-center justify-content-between';

  return (
    <div
      className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      {showFooter ? (
        <div
          className={`${layoutProps.footerContainerClasses} ${footerClasses}`}
        >
          <div className="text-dark order-2 order-md-1">
            &copy;{' '}
            <span className="text-muted font-weight-bold mr-2">
              2020 - {today.toString()}
            </span>
            <a
              className="text-dark-75 text-hover-primary"
              href="https://heidipay.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              Heidi Pay AG
            </a>
          </div>
          <div className="nav nav-dark order-1 order-md-2">
            <a
              className="nav-link pr-3 pl-0"
              href="https://www.heidipay.com/en-ch/terms-conditions/"
              rel="noopener noreferrer"
              target="_blank"
            >
              {translate('LINK.TERMS')}
            </a>
            {showFooterPrivacyLink ? (
              <a
                className="nav-link px-3"
                href="https://www.heidipay.com/en-ch/privacy-policy/"
                rel="noopener noreferrer"
                target="_blank"
              >
                {translate('LINK.PRIVACY')}
              </a>
            ) : null}
            <a
              className="nav-link pl-3 pr-0"
              href="https://help.heidipay.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              {translate('LINK.HELP')}
            </a>
          </div>
        </div>
      ) : null}
    </div>
  );
}
