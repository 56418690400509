import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Heading = styled.h3`
  height: 40px;
  line-height: 40px;
  font-size: 0.9em;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  color: #7e8299;

  @media (min-width: 992px) {
    .aside-minimize:not(.aside-minimize-hover) & {
      visibility: hidden;
    }
  }
`;

export const IconWrapper = styled.span`
  display: flex;
  flex: 0 0 35px;
  font-size: 1.3em;
  color: #b5b5c3;

  svg {
    width: 23px;
    height: 23px;
    margin-inline-start: -2px;

    g [fill] {
      fill: #b5b5c3;
    }

    .menu-item-active &,
    a:hover & {
      color: ${({ theme }) => theme.colors.primary};
      g [fill] {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export const Item = styled.li`
  margin-inline-start: -20px;
  margin-inline-end: -20px;
  &.menu-item-active,
  &:hover {
    background-color: #f3f6f9;
  }
`;

export const Link = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 9px 25px;
  min-height: 44px;
`;

export const List = styled.ul`
  padding: 0;
  list-style: none;
`;

export const Nav = styled.nav.attrs(() => ({
  className: 'aside-menu my-4',
  'data-menu-vertical': '1',
  id: 'kt_aside_menu',
}))`
  overflow-y: auto;
`;

export const NavWrapper = styled.div.attrs({
  className: 'aside-menu-wrapper',
  id: 'kt_aside_menu_wrapper',
})`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`;

export const PoweredByLogoWrapper = styled.div`
  position: relative;
  padding-left: 25px;
  height: 40px;
`;

export const StyledSection = styled.section`
  margin-block-start: 20px;
  padding-inline-end: 20px;
  padding-inline-start: 20px;

  .profile_picker + .aside-menu-wrapper &:first-of-type {
    margin-block-start: 10px;
  }
`;

export const Text = styled.span`
  color: #3f4254;

  .menu-item-active &,
  a:hover & {
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (min-width: 992px) {
    .aside-minimize:not(.aside-minimize-hover) & {
      display: none;
    }
  }
`;
