import {
  ChangeCardDetailsSubkeys,
  ModalKeys,
  PausePaymentsSubkeys,
} from './enums';
import { ModalKeyTranslations } from './types';

export const modalTriggerTranslationKeys: ModalKeyTranslations = {
  [ModalKeys.AddCertificateOfInterest]:
    'OPS_PORTAL.TITLE.ADD_CERTIFICATE_OF_INTEREST',
  [ModalKeys.AddNote]: 'OPS_PORTAL.TITLE.ADD_NOTE',
  [ModalKeys.AdjustContractValue]: 'TITLE.ADJUST_CONTRACT_VALUE',
  [ModalKeys.AllocationDetails]:
    'OPS_PORTAL.LABEL.BANK_ACCOUNT_RECONCILIATIONS.ALLOCATE',
  [ModalKeys.CancelContract]: 'TITLE.CANCEL_ORDER',
  [ModalKeys.ChangeCardDetails]: {
    [ChangeCardDetailsSubkeys.CardChange]: 'BUTTON.CARD_CHANGE',
    [ChangeCardDetailsSubkeys.ChangeDebitCard]: 'BUTTON.CHANGE_DEBIT_CARD',
    [ChangeCardDetailsSubkeys.SwitchPaymentMethod]:
      'BUTTON.SWITCH_TO_DEBIT_CARD',
    [ChangeCardDetailsSubkeys.ManagePaymentMethod]:
      'BUTTON.MANAGE_PAYMENT_METHOD',
  },
  [ModalKeys.ChangeIban]: 'OPS_PORTAL.LABEL.CHANGE_IBAN',
  [ModalKeys.ChangePaymentDate]: 'OPS_PORTAL.TITLE.CHANGE_PAYMENT_DATE',
  [ModalKeys.ConfirmDispatch]: 'TITLE.CONFIRM_DISPATCH',
  [ModalKeys.ContractAdjustments]: 'OPS_PORTAL.BUTTON.ADJUSTMENTS',
  [ModalKeys.ContractManualPayments]: 'OPS_PORTAL.BUTTON.MAKE_MANUAL_PAYMENT',
  [ModalKeys.ContractPrepayment]: 'BUTTON.PREPAY',
  [ModalKeys.ContractReimbursement]: 'OPS_PORTAL.BUTTON.REIMBURSEMENT',
  [ModalKeys.CreateApplicantBlock]: 'OPS_PORTAL.TITLE.CREATE_APPLICANT_BLOCK',
  [ModalKeys.CreateUser]: 'PAGE.USERS.CREATE_A_NEW_USER',
  [ModalKeys.DeleteUser]: 'BUTTON.DELETE_USER',
  [ModalKeys.DownloadCustomerAgreement]: 'BUTTON.DOWNLOAD_CUSTOMER_AGREEMENT',
  [ModalKeys.DownloadSettlementsReport]: 'TITLE.DOWNLOAD_SETTLEMENTS_REPORT',
  [ModalKeys.DownloadQrPdfModal]: 'OPS_PORTAL.TITLE.DOWNLOAD_QR_PDF',
  [ModalKeys.EarlySettlementsCreateQuote]: 'OPS_PORTAL.BUTTON.CREATE_QUOTE',
  [ModalKeys.EarlySettlementsDisplay]: 'OPS_PORTAL.BUTTON.VIEW',
  [ModalKeys.EditAddress]: 'PAGE.ACCOUNT_SETTINGS.EDIT_ADDRESS',
  [ModalKeys.EditName]: 'PAGE.ACCOUNT_SETTINGS.EDIT_NAME',
  [ModalKeys.EditPhone]: 'PAGE.ACCOUNT_SETTINGS.EDIT_PHONE_NUMBER',
  [ModalKeys.EditUser]: 'BUTTON.EDIT',
  [ModalKeys.EmailUser]: 'OPS_PORTAL.BUTTON.EMAIL_CUSTOMER',
  [ModalKeys.FileUpload]: 'BUTTON.UPLOAD_CUSTOMER_AGREEMENT',
  [ModalKeys.ModifyApplicantBlock]: 'OPS_PORTAL.TITLE.MODIFY_APPLICANT_BLOCK',
  [ModalKeys.OrderPrepayment]: 'BUTTON.PREPAY',
  [ModalKeys.OrderRefund]: 'BUTTON.REFUND',
  [ModalKeys.Password]: 'ACCOUNT.CHANGE_PASSWORD',
  [ModalKeys.PausePayments]: {
    [PausePaymentsSubkeys.NotPaused]: 'OPS_PORTAL.LABEL.PAUSE_PAYMENTS',
    [PausePaymentsSubkeys.Paused]: 'OPS_PORTAL.LABEL.RESUME_PAYMENTS',
  },
  [ModalKeys.PaymentLink]: 'OPS_PORTAL.LABEL.PAYMENT_LINK',
  [ModalKeys.RemoveApplicantBlock]: 'OPS_PORTAL.TITLE.REMOVE_APPLICANT_BLOCK',
  [ModalKeys.TriggerPayment]: 'OPS_PORTAL.TITLE.TRIGGER_PAYMENT',
  [ModalKeys.UpdateArrearsManagedBy]:
    'OPS_PORTAL.LABEL.UPDATE_ARREARS_MANAGED_BY',
  [ModalKeys.ViewCommunication]: 'OPS_PORTAL.BUTTON.VIEW_COMMUNICATION',
  [ModalKeys.ViewRawData]: 'OPS_PORTAL.BUTTON.VIEW_RAW_DATA',
  [ModalKeys.DeleteFees]: 'OPS_PORTAL.TITLE.DELETE_FEES',
};
