import { Formik, Form } from 'formik';
import { TextField } from '@components';
import { useI18n } from '@hooks';
import { getValidationSchema } from './getValidationSchema';

export const PasswordForm = ({ formID, handleSubmit }) => {
  const { translate } = useI18n();

  const initialFormikValues = {
    old_password: '',
    new_password: '',
    new_password_confirm: '',
  };

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema(translate)()}
    >
      <Form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id={formID}
      >
        <TextField
          id="old_password"
          label={translate('ACCOUNT.OLD_PASSWORD')}
          name="old_password"
          type="password"
        />
        <TextField
          id="new_password"
          label={translate('ACCOUNT.NEW_PASSWORD')}
          name="new_password"
          type="password"
        />
        <TextField
          id="new_password_confirm"
          label={translate('ACCOUNT.CONFIRM_NEW_PASSWORD')}
          name="new_password_confirm"
          type="password"
        />
      </Form>
    </Formik>
  );
};
