import * as Yup from 'yup';
import { Translate } from '@hooks/useI18n';
import { validateConditionalRequired } from '@utils';
import { ValidationErrorKeys } from '@utils/enums';

const conditionallyRequired = 'conditionally-required';

export const getValidationSchema = (
  {
    billing_address: billingAddress,
    industry,
    legal_name: legalName,
    primary_colour: colour,
    primary_language: language,
    short_name: shortName,
    sms_sender_id: smsId,
    vat_number: vatNumber,
  }: Record<string, string>,
  translate: Translate,
) =>
  Yup.object({
    billing_address: Yup.string().test(
      conditionallyRequired,
      translate(ValidationErrorKeys.Required),
      value =>
        validateConditionalRequired({
          current: value,
          original: billingAddress,
        }),
    ),
    industry: Yup.number()
      .typeError(translate(ValidationErrorKeys.AmountMustBeNumber))
      .test(
        conditionallyRequired,
        translate(ValidationErrorKeys.Required),
        value =>
          validateConditionalRequired({ current: value, original: industry }),
      ),
    legal_name: Yup.string().test(
      conditionallyRequired,
      translate(ValidationErrorKeys.Required),
      value =>
        validateConditionalRequired({ current: value, original: legalName }),
    ),
    primary_colour: Yup.string().test(
      conditionallyRequired,
      translate(ValidationErrorKeys.Required),
      value =>
        validateConditionalRequired({ current: value, original: colour }),
    ),
    primary_language: Yup.string().test(
      conditionallyRequired,
      translate(ValidationErrorKeys.Required),
      value =>
        validateConditionalRequired({
          current: value,
          original: language,
        }),
    ),
    short_name: Yup.string().test(
      conditionallyRequired,
      translate(ValidationErrorKeys.Required),
      value =>
        validateConditionalRequired({ current: value, original: shortName }),
    ),
    sms_sender_id: Yup.string()
      .max(10, translate(ValidationErrorKeys.Max10Characters))
      .test(
        conditionallyRequired,
        translate(ValidationErrorKeys.Required),
        value =>
          validateConditionalRequired({
            current: value,
            original: smsId,
          }),
      ),
    vat_number: Yup.string().test(
      conditionallyRequired,
      translate(ValidationErrorKeys.Required),
      value =>
        validateConditionalRequired({ current: value, original: vatNumber }),
    ),
  });
