import styled from 'styled-components';
import { Box } from '@material-ui/core';
import * as SVGLogos from '@assets/svg';
import { SVGLogo } from '@components/Logo';
import { useI18n } from '@hooks';

const Span = styled.span`
  font-size: 10px;
  margin-right: 5px;
  color: ${props => props.color || props.theme.colors.primary};
`;

const HeidipayLogoContainer = styled.div`
  width: 100px;
`;

export const PoweredByLogo = ({ fill = 'dark', justifyContent = 'center' }) => {
  const { translate } = useI18n();
  const textColor = fill === 'dark' ? 'black' : 'white';

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent={justifyContent}
      mb={0.5}
    >
      <Span color={textColor}>{translate('GENERAL.POWERED_BY')}</Span>
      <HeidipayLogoContainer>
        <SVGLogo component={SVGLogos.HeidiPay} fill={fill} />
      </HeidipayLogoContainer>
    </Box>
  );
};
