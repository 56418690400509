const styles = [
  'font-family: Poppins, Helvetica, sans-serif',
  'font-size: 16px',
  'font-weight: 400',
  'line-height: 1.5',
];

const heyLightStyles = [
  'font-family: HeyLight Sans, sans-serif',
  'font-size: 16px',
  'font-weight: 400',
  'line-height: 1.5',
];

export const wrapDisplayData = (
  string: string,
  canUseHeyLightBranding: boolean,
) =>
  `<body style="${(canUseHeyLightBranding ? heyLightStyles : styles).join(
    ';',
  )}">${string}</body>`;
