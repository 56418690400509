import { Formik, Form } from 'formik';
import { Dropdown, TextField } from '@components';
import { useI18n } from '@hooks';
import {
  IEditUserGroup,
  IUserGroupIds,
} from '@schemas/merchantPortal/types/editUserGroups';
import { IGroup } from '@schemas/merchantPortal/types/groups';
import { getDropdownOptions } from './getDropdownOptions';
import { getValidationSchema } from './getValidationSchema';
import { TooltipContent } from './TooltipContent';

interface IUserForm {
  formID: string;
  groups: IGroup[];
  handleSubmit: (values: IEditUserGroup) => void;
  values: IEditUserGroup;
}

export const UserForm = ({
  formID,
  groups,
  handleSubmit,
  values,
}: IUserForm) => {
  const { translate } = useI18n();

  // I realise this looks hideous, but it is a necessary evil, as
  // default values in destructuring only replace undefined, not null.
  const initialFormikValues = {
    email: values?.email ?? '',
    external_uuid: values?.external_uuid,
    first_name: values?.first_name ?? '',
    group: values?.group ?? ('' as unknown as IUserGroupIds),
    last_login: values?.last_login,
    last_name: values?.last_name ?? '',
    phone_number: values?.phone_number ?? '',
  };

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema(translate)}
    >
      <Form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id={formID}
      >
        <TextField
          id="email"
          label={translate('LABEL.EMAIL_ADDRESS')}
          name="email"
        />
        <TextField
          id="first_name"
          label={translate('LABEL.FIRST_NAME')}
          name="first_name"
        />
        <TextField
          id="last_name"
          label={translate('LABEL.LAST_NAME')}
          name="last_name"
        />
        <TextField
          id="phone_number"
          label={translate('LABEL.PHONE_NUMBER')}
          name="phone_number"
        />
        <Dropdown
          id="group"
          label={translate('PAGE.USERS.PERMISSIONS_GROUP')}
          name="group"
          options={getDropdownOptions(groups, translate)}
          tooltip={{
            content: <TooltipContent />,
            label: translate('PAGE.USERS.PERMISSIONS_GROUP'),
          }}
        />
      </Form>
    </Formik>
  );
};
