import { useQueryClient } from '@tanstack/react-query';
import { ICardCTA, ValueOf } from '@appTypes';
import { Card } from '@components';
import { ModalKeys, useI18n, useModal } from '@hooks';

import { IOrderDetail as IConsumerOrderDetails } from '@schemas/consumerPortal/types/ordersDetails';
import { IOrderDetail as IMerchantOrderDetails } from '@schemas/merchantPortal/types/orderDetails';
import { PaymentTypes, QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { ICheckPermissions, IOrderSummaryProps } from '../utils/types';
import { OrderSummary } from './OrderSummary';

interface IOrderSummaryCard {
  canShowPaymentButton: boolean;
  orderDetailsData: IConsumerOrderDetails | IMerchantOrderDetails;
  orderSummaryProps: IOrderSummaryProps;
  paymentModalKey: ModalKeys;
  paymentType: ValueOf<typeof PaymentTypes>;
  permissions: ICheckPermissions;
  queryKey: ValueOf<typeof QueryKeys>;
  uuid: string;
}

export const OrderSummaryCard = ({
  canShowPaymentButton,
  orderDetailsData,
  orderSummaryProps,
  paymentModalKey,
  paymentType,
  permissions,
  queryKey,
  uuid,
}: IOrderSummaryCard) => {
  const { translate } = useI18n();

  const queryClient = useQueryClient();

  const {
    canAdjustContractValue,
    canCancelOrder,
    canConfirmDispatch,
    canMakePayment,
  } = permissions;

  const { amount = '', currency = '' } = orderDetailsData.amount ?? {};

  const {
    external_uuid: externalUuid,
    manual_agreement_signing_overview: manualAgreementSigningOverview,
  } = orderDetailsData as IMerchantOrderDetails;

  const {
    has_downloaded_agreement: hasDownloadedAgreement = false,
    has_uploaded_signed_agreement: hasUploadedSignedAgreement = false,
    is_manual_signing_required: isManualSigningRequired = false,
  } = manualAgreementSigningOverview ?? {};

  const adjustContractValueModal = useModal(ModalKeys.AdjustContractValue, {
    amount,
    currency,
    dependencies: [canAdjustContractValue],
    queryKey,
    uuid,
  });

  const cancelContractModal = useModal(ModalKeys.CancelContract, {
    amount,
    currency,
    dependencies: [canCancelOrder],
    isCancel: true,
    queryKey,
    uuid,
  });

  const confirmDispatchModal = useModal(ModalKeys.ConfirmDispatch, {
    dependencies: [
      canConfirmDispatch,
      'delay_finalisation' in orderDetailsData &&
        orderDetailsData.delay_finalisation,
    ],
    uuid,
  });

  const paymentModal = useModal(paymentModalKey, {
    data: orderDetailsData,
    dependencies: [canMakePayment, canShowPaymentButton],
    paymentType,
    queryKey,
    uuid,
  });

  const fileUploadModal = useModal(ModalKeys.FileUpload, {
    acceptedFiles: ['.pdf', '.jpeg', '.jpg'],
    dependencies: [
      isManualSigningRequired,
      hasDownloadedAgreement,
      !hasUploadedSignedAgreement,
    ],
    description: translate('PAGE.ORDERS.UPLOAD_CUSTOMER_AGREEMENT_DESCRIPTION'),
    endpoint: `${Endpoints.CustomerAgreement}/${externalUuid}`,
    queryKey,
    onClose: () => queryClient.invalidateQueries([queryKey]),
  });

  const fileDownloadModal = useModal(ModalKeys.DownloadCustomerAgreement, {
    dependencies: [isManualSigningRequired, !hasUploadedSignedAgreement],
    externalApplicationUuid: externalUuid,
    queryKey,
  });

  const manualAgreementSigningButtons = [
    ...(isManualSigningRequired
      ? [fileDownloadModal?.ctaProps, fileUploadModal?.ctaProps]
      : []),
  ];

  const buttons = [
    adjustContractValueModal?.ctaProps,
    confirmDispatchModal?.ctaProps,
    cancelContractModal?.ctaProps,
    paymentModal?.ctaProps,
    ...manualAgreementSigningButtons,
  ].filter(Boolean) as ICardCTA[];

  return (
    <Card buttons={buttons} title={translate('TITLE.ORDER_SUMMARY')}>
      <OrderSummary {...orderSummaryProps} />
      {confirmDispatchModal?.Modal}
      {adjustContractValueModal?.Modal}
      {cancelContractModal?.Modal}
      {paymentModal?.Modal}
      {fileUploadModal?.Modal}
      {fileDownloadModal?.Modal}
    </Card>
  );
};
