import { useEffect, useMemo } from 'react';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Card, QueryStateRouter, ServersideTable } from '@components';
import {
  useHasPermission,
  useI18n,
  useOriginator,
  useQueryState,
} from '@hooks';
import { IOpsPortalMerchants } from '@schemas/opsPortal/types/merchants';
import { fetchDataFromEndpoint } from '@utils';
import { QueryKeys, TableNames } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { getMerchantsTableColumns } from './getMerchantsTableColumns';
import { prepareMerchantsTableData } from './prepareMerchantsTableData';

export const Merchants = () => {
  const canViewIban = useHasPermission(['ops_portal.view_merchant_iban']);
  const { getLocaleDate, translate } = useI18n();
  const { originator } = useOriginator();

  const {
    activateQueryStateHook,
    getFilters,
    queryParams,
    queryState,
    resetFilters,
    stringifiedQueryParams,
    updateQueryParams,
  } = useQueryState(TableNames.MERCHANTS);

  const queryUrl = canViewIban
    ? Endpoints.FetchMerchantsIban
    : Endpoints.FetchMerchants;

  const { data, error, isLoading } = useQuery<IOpsPortalMerchants, AxiosError>(
    [QueryKeys.MERCHANTS_TABLE_DATA, stringifiedQueryParams, originator],
    () =>
      fetchDataFromEndpoint(queryUrl, {
        query: { ...queryParams, originator },
      }),
  );

  const columns = useMemo(
    () => getMerchantsTableColumns({ canViewIban, translate }),
    [canViewIban, translate],
  );

  const filters = getFilters();

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  const merchantsTableData = prepareMerchantsTableData({
    data: data?.results ?? [],
    getLocaleDate,
  });

  return (
    <QueryStateRouter error={error} isLoading={isLoading}>
      {data ? (
        <Card title={translate('OPS_PORTAL.TITLE.MERCHANTS')}>
          <ServersideTable
            columns={columns}
            data={merchantsTableData}
            filters={filters}
            manageControlledState={updateQueryParams}
            pagination={{
              limit: queryParams.limit,
              totalItems: data?.count,
            }}
            resetFilters={resetFilters}
            tableState={queryState}
          />
        </Card>
      ) : null}
    </QueryStateRouter>
  );
};
