import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useQueryClient, useMutation, QueryKey } from '@tanstack/react-query';
import { useI18n, useHasPermission } from '@hooks';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { IFormModalProps } from '@hooks/useModal/types';
import { getErrorMessage, postDataToEndpoint } from '@utils';
import { Endpoints } from '@utils/enums';
import { PausePaymentForm } from './PausePaymentForm';

interface IPauseData {
  date: string;
  pause_payment_processing: boolean;
}

interface IPauseResponse {
  message: string;
}

interface IUsePausePaymentsModalProps extends IFormModalProps {
  contractDataQueryKey: QueryKey;
  isPaused: boolean;
  paymentsPausedTillDate: string;
  uuid: string;
}

export const usePausePaymentsModal = ({
  contractDataQueryKey,
  formStatusMessage,
  isPaused,
  paymentsPausedTillDate,
  resolveForm,
  uuid,
}: IUsePausePaymentsModalProps) => {
  const { translate } = useI18n();

  const queryClient = useQueryClient();

  const hasFullToggleAccess = useHasPermission([
    'ops_portal.can_toggle_pause_payment_processing',
  ]);

  const endpointForUnpausing =
    hasFullToggleAccess && !paymentsPausedTillDate
      ? Endpoints.ResumePayments
      : Endpoints.ResumePaymentsTillDate;

  const { isLoading: isRequestingPause, mutate: pausePayment } = useMutation<
    IPauseResponse,
    AxiosError<IError>,
    IPauseData
  >(
    requestData => {
      if (requestData.pause_payment_processing) {
        return postDataToEndpoint({
          endpoint: `${Endpoints.PausePaymentIndefinitely}/${uuid}`,
        });
      }
      return postDataToEndpoint({
        endpoint: `${Endpoints.PausePaymentsTillDate}/${uuid}`,
        requestData,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(contractDataQueryKey);
        resolveForm(translate('OPS_PORTAL.STATUS.PAUSE_PAYMENTS_SUCCESSFUL'));
      },
      onError: error => {
        resolveForm(
          getErrorMessage({
            error,
            genericErrorMessageKey:
              'OPS_PORTAL.STATUS.UNABLE_TO_PAUSE_PAYMENTS',
            translate,
          }),
        );
      },
    },
  );

  const { isLoading: isRequestingUnpause, mutate: unpausePayment } =
    useMutation<IPauseResponse, AxiosError<IError>>(
      () => postDataToEndpoint({ endpoint: `${endpointForUnpausing}/${uuid}` }),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(contractDataQueryKey);
          resolveForm(
            translate('OPS_PORTAL.STATUS.RESUME_PAYMENTS_SUCCESSFUL'),
          );
        },
        onError: error => {
          resolveForm(
            getErrorMessage({
              error,
              genericErrorMessageKey:
                'OPS_PORTAL.STATUS.UNABLE_TO_RESUME_PAYMENTS',
              translate,
            }),
          );
        },
      },
    );

  const handleSubmit = useCallback(data => pausePayment(data), [pausePayment]);
  const handleClick = useCallback(() => unpausePayment(), [unpausePayment]);
  const formID = 'pausePayment';

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={isRequestingPause || isRequestingUnpause}
    >
      {isPaused ? (
        <p>{translate('OPS_PORTAL.PAGE.CONFIRM_RESUME_PAYMENTS')}</p>
      ) : (
        <PausePaymentForm formID={formID} handleSubmit={handleSubmit} />
      )}
    </ModalStatusRouter>
  );

  return {
    allowoverflow: true,
    formID,
    ...(isPaused ? { clickHandler: handleClick } : {}),
    isDangerousSubmit: true,
    isLoading: isRequestingPause || isRequestingUnpause,
    modalContent,
    submitText: isPaused
      ? translate('BUTTON.CONFIRM')
      : translate('BUTTON.SUBMIT'),
    title: translate(
      isPaused
        ? 'OPS_PORTAL.LABEL.RESUME_PAYMENTS'
        : 'OPS_PORTAL.LABEL.PAUSE_PAYMENTS',
    ),
  };
};
