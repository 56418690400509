import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useI18n } from '@hooks';
import { IMerchantPortalTracking } from '@schemas/merchantPortal/types/tracking';
import { fetchDataFromEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';

interface IUseTrackingDataOptions {
  handleError?: () => void;
  handleSuccess?: (data: IMerchantPortalTracking) => void;
  isOpsEndpoint?: boolean;
  refetchInterval?: number;
}

export interface IUseTrackingDataProps {
  contractId?: string;
  options: IUseTrackingDataOptions;
}

export const useTrackingData = ({
  contractId,
  options,
}: IUseTrackingDataProps) => {
  const {
    handleError,
    handleSuccess,
    isOpsEndpoint = false,
    refetchInterval,
  } = options;
  const { selectedLanguage } = useI18n();
  const queryKey = [QueryKeys.ORDER_TRACKING, contractId, selectedLanguage];

  return useQuery<IMerchantPortalTracking, AxiosError>(
    [queryKey],
    () =>
      fetchDataFromEndpoint(
        isOpsEndpoint ? Endpoints.TrackOrderOps : Endpoints.TrackOrder,
        {
          search: contractId,
          query: { lang: selectedLanguage },
        },
      ),
    {
      refetchInterval,
      enabled: Boolean(contractId),
      onError: handleError,
      onSuccess: handleSuccess,
    },
  );
};
