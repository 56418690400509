import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { StaticTable } from '@components';
import { useI18n } from '@hooks';
import { getUnitPrice } from '@utils';
import { IUnitPrice } from '@utils/types';
import { mapShoppingCartData } from './mapShoppingCartData';
import { IShoppingCartDetailsProps } from './types';

export const ShoppingCartTable = ({
  orderDetails,
}: IShoppingCartDetailsProps) => {
  const { getLocaleCurrencyValue, translate } = useI18n();

  const { currency, products, total } = mapShoppingCartData({
    orderDetails,
    getLocaleCurrencyValue,
  });

  const columnHelper = createColumnHelper<IUnitPrice>();

  const columns = [
    columnHelper.accessor('item', {
      header: translate('LABEL.ITEM'),
    }),
    columnHelper.accessor('sku', {
      header: translate('LABEL.SKU'),
    }),
    columnHelper.accessor('quantity', {
      header: translate('LABEL.QUANTITY'),
    }),
    columnHelper.accessor('unitPrice', {
      header: translate('LABEL.UNIT_PRICE'),
    }),
    columnHelper.accessor('linePrice', {
      header: translate('LABEL.TOTAL_PRICE'),
    }),
  ] as ColumnDef<IUnitPrice, unknown>[];

  const data: IUnitPrice[] = products.map(item =>
    getUnitPrice({ currency, getLocaleCurrencyValue, item }),
  );

  return (
    <StaticTable
      columns={columns}
      data={data}
      tfoot={[translate('LABEL.TOTAL'), total as string]}
    />
  );
};
