import { useMemo } from 'react';
import { Card, StaticTable } from '@components';
import { useI18n } from '@hooks';
import { missingValuePlaceholder } from '@utils/constants';
import { getMonetaTransactionsColumns } from './getMonetaTransactionsColumns';
import { mapMonetaTransactionsTableData } from './mapMonetaTransactionsTableData';
import { IMonetaTransactionsProps } from './types';

export const MonetaTransactionsCard = ({
  balance,
  transactions,
}: IMonetaTransactionsProps) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  const columns = useMemo(
    () => getMonetaTransactionsColumns(translate),
    [translate],
  );

  const tableData = mapMonetaTransactionsTableData({
    data: transactions,
    getLocaleCurrencyValue,
    getLocaleDate,
    translate,
  });

  const transactionsTotal =
    (balance &&
      getLocaleCurrencyValue({
        currency: balance.currency,
        value: balance.amount,
      })) ??
    missingValuePlaceholder;

  return (
    <Card title={translate('OPS_PORTAL.LABEL.MONETA_TRANSACTIONS')}>
      <StaticTable
        columns={columns}
        data={tableData}
        tfoot={[translate('LABEL.TOTAL'), transactionsTotal]}
      />
    </Card>
  );
};
