import {
  FooterLink,
  LoginFooter,
  LoginFooterNav,
  LoginSidePanel,
  SidePanelInnerWrapper,
} from '@auth/pages/Auth.styles';
import { useConfig, useFeatureFlags, useI18n } from '@hooks';
import { Copyright } from './Copyright';
import { LoginGreeting } from './LoginGreeting';

export const SidePanel = () => {
  const { translate } = useI18n();
  const {
    config: { showFooter },
  } = useConfig();
  const { canUseNewLoginPage } = useFeatureFlags();

  return (
    <LoginSidePanel>
      <SidePanelInnerWrapper>
        <LoginGreeting />

        {showFooter && !canUseNewLoginPage ? (
          <LoginFooter>
            <Copyright />
            <LoginFooterNav>
              <FooterLink href="https://www.heidipay.com/en-ch/privacy-policy/">
                {translate('LINK.PRIVACY')}
              </FooterLink>
              <FooterLink href="https://www.heidipay.com/en-ch/terms-conditions/">
                {translate('LINK.TERMS')}
              </FooterLink>
              <FooterLink href="https://help.heidipay.com/">
                {translate('LINK.HELP')}
              </FooterLink>
            </LoginFooterNav>
          </LoginFooter>
        ) : null}
      </SidePanelInnerWrapper>
    </LoginSidePanel>
  );
};
