import { useMemo } from 'react';
import { AxiosError } from 'axios';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { ITabData } from '@components/Tabs/types';
import { useI18n, usePortalError, useQueryOnClick } from '@hooks';
import {
  downloadFile,
  fetchDataFromEndpoint,
  getDocumentsTableColumns,
  mapDocumentsTableData,
} from '@utils';
import { QueryKeys, TabEventKeys, TableNames } from '@utils/constants';
import { IDocumentsTableColumn } from '@utils/documentsTableHelpers/types';
import { Endpoints } from '@utils/enums';
import { useGetContractDocumentsQuery } from '../queries';

interface IUseContractDocumentsTab {
  canViewContractDocumentsTab: boolean;
  contractUuid: string;
  externalContractUUID?: string;
}

export const useContractDocumentsTab = ({
  canViewContractDocumentsTab,
  contractUuid,
}: IUseContractDocumentsTab): ITabData => {
  const { getLocaleDate, translate } = useI18n();
  const { handleAxiosError } = usePortalError();

  const { data, error, isLoading } = useGetContractDocumentsQuery({
    canViewContractDocumentsTab,
    contractUuid,
  });

  const useQueryCallbacks = useMemo(
    () => ({
      onSuccess: (url: string) => downloadFile(url),
      onError: (getDocumentsError: AxiosError<IError>) =>
        handleAxiosError(getDocumentsError),
    }),
    [handleAxiosError],
  );

  const { execute } = useQueryOnClick<string, IDocumentsTableColumn>(
    [QueryKeys.DOCUMENT],
    requestObj =>
      fetchDataFromEndpoint(
        `${Endpoints.FetchContractDocument}/${requestObj.uuid}`,
        {},
      ),
    useQueryCallbacks,
  );

  const contractDocumentsColumns = useMemo(
    () => getDocumentsTableColumns({ translate, setSelectedRow: execute }),
    [execute, translate],
  );

  const contractDocumentsData = mapDocumentsTableData({
    data: data?.documents,
    getLocaleDate,
    translate,
  });

  return {
    columns: contractDocumentsColumns,
    data: contractDocumentsData,
    eventKey: TabEventKeys.CONTRACT_DOCUMENTS,
    error,
    isLoading,
    tableName: TableNames.DOCUMENTS,
    title: translate('OPS_PORTAL.TITLE.CONTRACT_DOCUMENTS'),
  };
};
