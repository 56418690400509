import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useI18n, useOriginator, usePortalError } from '@hooks';
import {
  IFormModalProps,
  IModalProps,
  ISharedModalProps,
} from '@hooks/useModal/types';
import { IEditUserGroup } from '@schemas/merchantPortal/types/editUserGroups';
import { IGroup } from '@schemas/merchantPortal/types/groups';
import { postDataToEndpoint, putDataToEndpoint } from '@utils';
import { Endpoints } from '@utils/enums';
import { UserForm } from './UserForm';

interface IUserModalProps extends ISharedModalProps, IFormModalProps {
  groups: IGroup[];
  isCreate?: boolean;
  queryKey: string[];
  userProps: IEditUserGroup;
}

export const useUserModal = ({
  closeModal,
  groups,
  isCreate = false,
  queryKey,
  userProps,
}: IUserModalProps): IModalProps => {
  const { translate } = useI18n();
  const { originator } = useOriginator();
  const { handleAxiosError } = usePortalError();
  const { message } = useToast(translate);
  const queryClient = useQueryClient();

  const sendData = isCreate ? postDataToEndpoint : putDataToEndpoint;

  const { isLoading, mutate } = useMutation<
    unknown,
    AxiosError<IError>,
    IEditUserGroup
  >(
    requestData => {
      const payload = { ...requestData, originator };
      return sendData({
        endpoint: Endpoints.EditUser,
        requestData: isCreate ? payload : [payload],
      });
    },
    {
      onSuccess: () => {
        // TODO: remove timeout when endpoint issues are sorted out.
        // https://heidi-pay.atlassian.net/browse/HC-8406
        window.setTimeout(() => {
          queryClient.invalidateQueries(queryKey);
        }, 500);
        message.success(
          isCreate
            ? 'PAGE.USERS.USER_SUCCESSFULLY_ADDED'
            : 'PAGE.USERS.USER_SUCCESSFULLY_EDITED',
        );
        if (closeModal) {
          closeModal();
        }
      },
      onError: error => {
        handleAxiosError(
          error,
          isCreate
            ? 'PAGE.USERS.UNABLE_TO_ADD_USER'
            : 'PAGE.USERS.UNABLE_TO_EDIT_USER',
        );
      },
    },
  );

  const handleSubmit = useCallback(
    (values: IEditUserGroup) => {
      mutate(values);
    },
    [mutate],
  );

  const formID = isCreate
    ? 'createUser'
    : `edit-${userProps?.external_uuid ?? ''}`;

  const modalContent = (
    <UserForm
      formID={formID}
      groups={groups}
      handleSubmit={handleSubmit}
      values={userProps}
    />
  );

  return {
    formID,
    isFormProcessing: isLoading,
    isLoading,
    loadingOverlay: true,
    modalContent,
    showFormButtons: !isLoading,
    title: isCreate
      ? translate('PAGE.USERS.CREATE_A_NEW_USER')
      : translate('PAGE.USERS.EDIT_USER'),
  };
};
