import { Modal, ModalFormButtons } from '@hooks/useModal/components';
import { ModalKeys } from './enums';
import { hooks } from './hooks';
import { IModalProps } from './types';

interface ISelectedModalProps {
  modalKey: ModalKeys;
  modalProps: IModalProps;
}

export const SelectedModal = ({
  modalKey,
  modalProps,
}: ISelectedModalProps) => {
  const { closeModal, formStatusMessage, isModalShowing, ...additionalProps } =
    modalProps;

  const modalOptions: IModalProps | null = hooks[modalKey]({
    closeModal,
    formStatusMessage,
    isModalShowing,
    ...additionalProps,
  });

  if (!modalOptions) {
    return null;
  }

  const {
    allowoverflow = false,
    cancelText,
    clickHandler,
    customButtons,
    extratall = false,
    formID,
    fullscreen = false,
    hasHeader,
    hideModal,
    isDangerousSubmit = false,
    isFormProcessing = false,
    isLoading = false,
    loadingOverlay = false,
    loadingOverlayText,
    modalContent,
    showFormButtons,
    submitDisabled = false,
    submitText,
    title,
    width,
  } = modalOptions;

  return (
    <Modal
      allowoverflow={allowoverflow}
      extratall={extratall}
      footer={
        <ModalFormButtons
          cancelText={cancelText}
          clickHandler={clickHandler}
          customButtons={customButtons}
          formID={formID}
          hideModal={hideModal ?? closeModal}
          isDangerousSubmit={isDangerousSubmit}
          isFormProcessing={isFormProcessing}
          showFormButtons={showFormButtons ?? !formStatusMessage}
          submitDisabled={submitDisabled}
          submitText={submitText}
        />
      }
      fullscreen={fullscreen}
      hasHeader={hasHeader}
      hideModal={closeModal}
      isLoading={isLoading}
      isModalShowing={isModalShowing}
      key={modalKey}
      loadingOverlay={loadingOverlay}
      loadingOverlayText={loadingOverlayText}
      title={title}
      width={width}
    >
      {modalContent}
    </Modal>
  );
};
