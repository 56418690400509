import { ReactNode } from 'react';
import { CardCTAs, RawStatus } from '@appTypes';
import { StatusPill } from '@components';
import { H2, H3 } from '@components/Common.styles';
import {
  CardBody,
  CardContainer,
  CardHeader,
  CardToolbar,
} from './Card.styles';
import { CardButtons } from './CardButtons';

export interface IAlert {
  rawStatus: RawStatus;
  status: string;
}

export interface ICard {
  alerts?: IAlert[];
  buttons?: CardCTAs;
  children: ReactNode | ReactNode[];
  color?: string;
  hasHiddenTitle?: boolean;
  isSubsection?: boolean;
  removePaddingBottom?: boolean;
  title?: string;
}

export const Card = ({
  alerts,
  buttons,
  children,
  color,
  hasHiddenTitle = false,
  isSubsection = false,
  removePaddingBottom = false,
  title,
}: ICard) => {
  if (buttons?.some(button => !button?.key)) {
    throw new Error(
      'A button passed to @components/Card is missing the `key` property',
    );
  }

  const Header = isSubsection ? H3 : H2;

  return (
    <CardContainer color={color} isSubsection={isSubsection}>
      <CardHeader>
        <Header extraClassName={hasHiddenTitle ? 'sr-only' : ''}>
          {title}
        </Header>
        <>
          {alerts?.map((alert: IAlert) => (
            <StatusPill
              isAlert={true}
              key={alert.rawStatus}
              rawStatus={alert.rawStatus}
              status={alert.status}
            />
          ))}
        </>
        {buttons?.length ? (
          <CardToolbar>
            <CardButtons buttons={buttons} />
          </CardToolbar>
        ) : null}
      </CardHeader>
      <CardBody removePaddingBottom={removePaddingBottom}>{children}</CardBody>
    </CardContainer>
  );
};
