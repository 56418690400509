import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { ThemeContext } from 'styled-components';
import { AccessController } from '@components';
import { useConfig, useI18n } from '@hooks';
import { DropdownTopbarItemToggler } from '@metronic/_partials/dropdowns';
import { selectUser } from '@selectors';

export function UserProfileDropdown() {
  const user = useSelector(selectUser);
  const {
    config: { supportEmail },
  } = useConfig();
  const { translate } = useI18n();
  const {
    bootstrapColors: { name },
  } = useContext(ThemeContext);
  const menuClasses =
    'p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl';

  return (
    <Dropdown drop="down">
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            'btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2'
          }
        >
          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
            {translate('GENERAL.HI')},
          </span>{' '}
          <span className={`symbol symbol-35 symbol-light-${name}`}>
            <span className="symbol-label font-size-h5 font-weight-bold">
              {user?.first_name?.[0]}
            </span>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className={menuClasses}>
        <div className={`navi navi-spacer-x-0 pt-5 navi-${name}`}>
          <AccessController scopes={['merchant_portal.can_view_account_info']}>
            <Link
              className="navi-item px-8 cursor-pointer"
              to="/account_settings"
            >
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <span className={`flaticon2-calendar-3 text-${name}`} />
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold cursor-pointer">
                    {translate('TITLE.ACCOUNT_SETTINGS')}
                  </div>
                  <div className="text-muted">
                    {translate('LINK.PASSWORD_AND_PERSONAL_DETAILS')}
                  </div>
                </div>
              </div>
            </Link>
          </AccessController>

          {supportEmail ? (
            <a
              className="navi-item px-8"
              href={`mailto:${supportEmail}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <span className={`flaticon2-hourglass text-${name}`}></span>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">
                    {translate('LINK.CONTACT')}
                  </div>
                  <div className="text-muted">
                    {translate('LINK.CONTACT_THE_HEIDI_TEAM')}
                  </div>
                </div>
              </div>
            </a>
          ) : null}
          <div className="navi-separator mt-3" />

          <div className="navi-footer  px-8 py-5">
            <Link className="btn btn-light font-weight-bold" to="/logout">
              {translate('LINK.SIGN_OUT')}
            </Link>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
