import { useState, useCallback } from 'react';
import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryStateRouter } from '@components';
import { useI18n } from '@hooks';
import { Translate } from '@hooks/useI18n';
import { IModalProps, ISharedModalProps } from '@hooks/useModal/types';
import { fetchDataFromEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { ChangeCardDetailsModalContent } from './ChangeCardDetailsModalContent';

interface IChangeCardDetailsModalProps extends ISharedModalProps {
  hasHeader: boolean;
  isDirectDebit: boolean;
  isHeidiPay: boolean;
  isPagolightPro: boolean;
  orderId: string;
}

interface IGetHeaderTitle {
  hasHeader: boolean;
  isDirectDebit: boolean;
  isHeidiPay: boolean;
  translate: Translate;
}

const getHeaderTitle = ({
  hasHeader,
  isDirectDebit,
  isHeidiPay,
  translate,
}: IGetHeaderTitle) => {
  if (!hasHeader) {
    return '';
  }

  if (isDirectDebit && !isHeidiPay) {
    return translate('BUTTON.SWITCH_TO_DEBIT_CARD');
  }

  return translate('BUTTON.CARD_CHANGE');
};

export const useChangeCardDetailsModal = ({
  closeModal,
  hasHeader = true,
  isDirectDebit,
  isHeidiPay,
  isModalShowing,
  orderId,
}: IChangeCardDetailsModalProps): IModalProps => {
  const [initDataFetched, setInitDataFetched] = useState(false);
  const { translate } = useI18n();
  const { message } = useToast(translate);
  const queryClient = useQueryClient();

  const title = getHeaderTitle({
    hasHeader,
    isDirectDebit,
    isHeidiPay,
    translate,
  });

  const hideModal = useCallback(() => {
    if (closeModal) {
      closeModal();
    }
    setInitDataFetched(false);
  }, [closeModal]);

  const {
    data,
    error,
    isInitialLoading: isLoading,
  } = useQuery<{ redirect_url: string }, AxiosError>(
    [QueryKeys.INIT_CARD_CHANGE],
    () =>
      fetchDataFromEndpoint(Endpoints.InitCardChange, {
        search: orderId,
      }),
    {
      enabled: !initDataFetched && isModalShowing,
      onSuccess: () => setInitDataFetched(true),
    },
  );

  const hideModalClickHandler = useCallback(() => {
    hideModal();
    queryClient.invalidateQueries([QueryKeys.ORDER_DETAILS, orderId]);
    queryClient.invalidateQueries([QueryKeys.MY_ORDER_DETAILS, orderId]);
    message.success('LABEL.CARD_CHANGE_SUCCESS');
  }, [hideModal, message, orderId, queryClient]);

  const modalContent = (
    <QueryStateRouter error={error} isLoading={isLoading}>
      <ChangeCardDetailsModalContent
        closeModal={hideModalClickHandler}
        data={data}
        externalUuid={orderId}
      />
    </QueryStateRouter>
  );

  return {
    fullscreen: true,
    hasHeader,
    isLoading,
    modalContent,
    showFormButtons: false,
    title,
  };
};
