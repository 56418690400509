import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useI18n } from '@hooks';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { IModalProps } from '@hooks/useModal/types';
import { IConsumerPortalAddressData } from '@schemas/consumerPortal/types/addressData';
import { getErrorMessage, postDataToEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { EditAddressForm } from './EditAddressForm';

interface IUseEditAddressModalProps {
  data: IConsumerPortalAddressData;
  formStatusMessage: string;
  resolveForm: (message: string) => string;
}

export const useEditAddressModal = ({
  data,
  formStatusMessage,
  resolveForm,
}: IUseEditAddressModalProps): IModalProps => {
  const { translate } = useI18n();

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<unknown, AxiosError>(
    requestData =>
      postDataToEndpoint({
        endpoint: Endpoints.FetchUserAddress,
        requestData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.CONSUMER_ADDRESS]);
        resolveForm(
          translate('PAGE.ACCOUNT_SETTINGS.ADDRESS_SUCCESSFULLY_EDITED'),
        );
      },
      onError: error => {
        resolveForm(
          getErrorMessage({
            error,
            genericErrorMessageKey:
              'PAGE.ACCOUNT_SETTINGS.UNABLE_TO_EDIT_ADDRESS',
            translate,
          }),
        );
      },
    },
  );

  const handleSubmit = useCallback(
    values => {
      mutate(values);
    },
    [mutate],
  );

  const formID = 'edit-address';

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={isLoading}
    >
      <EditAddressForm
        data={data}
        formID={formID}
        handleSubmit={handleSubmit}
      />
    </ModalStatusRouter>
  );

  return {
    formID,
    isFormProcessing: isLoading,
    isLoading,
    modalContent,
    title: translate('PAGE.ACCOUNT_SETTINGS.EDIT_ADDRESS'),
  };
};
