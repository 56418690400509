import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFeatureFlags, useOriginator } from '@hooks';
import { originatorActions } from '@redux/originatorRedux';
import {
  AuthPageOuterWrapper,
  AuthPageInnerWrapper,
  Content,
  ContentOuterWrapper,
} from './Auth.styles';
import { AuthRoutes } from './AuthRoutes';
import { MobileFooter, SidePanel } from './components';
import '@metronic/_assets/sass/pages/login/classic/login-1.scss';

export const AuthPage = () => {
  const dispatch = useDispatch();
  const { originatorFromPath, pathnamePrefix, setOriginator } = useOriginator();
  const { canUseNewLoginPage } = useFeatureFlags();

  useEffect(() => {
    // This ensures that, if originator was got from
    // pathname instead of state, the state is updated
    // before the path is changed so that redirection
    // to auth pages doesn't break.
    dispatch(
      originatorActions.setPathnamePrefix(
        canUseNewLoginPage ? '' : pathnamePrefix,
      ),
    );
    setOriginator(originatorFromPath);
  }, [
    canUseNewLoginPage,
    dispatch,
    originatorFromPath,
    pathnamePrefix,
    setOriginator,
  ]);

  return (
    <AuthPageOuterWrapper>
      <AuthPageInnerWrapper>
        <SidePanel />
        <ContentOuterWrapper>
          <Content>
            <AuthRoutes />
          </Content>
          <MobileFooter />
        </ContentOuterWrapper>
      </AuthPageInnerWrapper>
    </AuthPageOuterWrapper>
  );
};
