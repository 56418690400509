import { createCSV, mapArrayOfMapsTo2dArray, syncDataToCols } from '@utils';

export const createContractsCSV = ({ columns, data, translate }) => {
  columns.splice(1, 0, {
    accessorKey: 'contractId',
    header: translate('OPS_PORTAL.TITLE.CONTRACT_ID'),
  });
  const headers = columns
    .filter(col => typeof col.header === 'string')
    .map(col => col.header);
  const syncedData = syncDataToCols({ columns, data });
  const arrayedData = mapArrayOfMapsTo2dArray(syncedData);
  return createCSV({ arrayedData, headers });
};
