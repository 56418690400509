import { GetLocaleCurrencyValue, GetLocaleDate } from '@hooks/useI18n';
import { IMerchantPortalSettlementSummary } from '@schemas/merchantPortal/types/settlementSummary';
import { addPlaceholderForEmptyValues } from '@utils';
import { SummaryData, TransactionsTableData } from './types';

interface IPrepareSummaryDataProps {
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  summaryData: IMerchantPortalSettlementSummary;
  transactionsTableData: TransactionsTableData;
}

export const prepareSummaryData = ({
  getLocaleCurrencyValue,
  getLocaleDate,
  summaryData,
  transactionsTableData,
}: IPrepareSummaryDataProps): SummaryData => {
  const { settlement, totals } = summaryData;

  const {
    amount: { amount = '0.00' },
    settlement_date: settlementDate,
    settlement_reference: settlementReference,
    uuid,
  } = settlement;

  const { clawback, fees, refund, sales } = totals;

  const { currency } = transactionsTableData[0];

  return addPlaceholderForEmptyValues({
    settlementId: uuid,
    settlementReference,
    settlementDate: settlementDate && getLocaleDate({ date: settlementDate }),
    settlementSales: getLocaleCurrencyValue({
      currency,
      value: Number(sales).toFixed(2),
    }),
    settlementRefund: getLocaleCurrencyValue({
      currency,
      value: Number(refund).toFixed(2),
    }),
    settlementClawback: getLocaleCurrencyValue({
      currency,
      value: Number(clawback).toFixed(2),
    }),
    settlementFees: getLocaleCurrencyValue({
      currency,
      value: Number(fees).toFixed(2),
    }),
    totalSettled: getLocaleCurrencyValue({
      currency,
      value: amount,
    }),
  });
};
