import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils';
import { GetLocaleCurrencyValue } from '@hooks/useI18n';
import { IBalances } from '@schemas/opsPortal/types/monetaContract';
import { IChangePaymentDateColumns } from './types';

interface IGetPaymentDateData {
  currency: string;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  paymentSchedule: IBalances;
}

export const getPaymentDateData = ({
  currency,
  getLocaleCurrencyValue,
  paymentSchedule,
}: IGetPaymentDateData) =>
  paymentSchedule.map(item => {
    return addPlaceholderForEmptyValues<IChangePaymentDateColumns>({
      date: item.payment_date,
      amount: getLocaleCurrencyValue({
        currency,
        value: item.amount_due,
      }),
    });
  });
