import { Formik, Form } from 'formik';
import { TextField } from '@components';
import { useI18n } from '@hooks';
import { AccountSettingsFormNames } from '@utils/constants';
import { getValidationSchema } from './getValidationSchema';

export const VerificationForm = ({ formID, handleSubmit }) => {
  const { translate } = useI18n();

  const initialFormikValues = {
    code: '',
  };

  const form = AccountSettingsFormNames.CODE;

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema({ form, translate })()}
    >
      <Form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id={formID}
      >
        <TextField
          id="code"
          label={translate('LABEL.VERIFICATION_CODE')}
          name="code"
        />
      </Form>
    </Formik>
  );
};
