import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { yupResolver } from '@hookform/resolvers/yup';
import { Days } from '@appTypes';
import {
  AccessController,
  CheckboxFieldRHF,
  NotificationBanner,
  NumberFieldRHF,
  RadioGroupRHF,
  StaticTable,
} from '@components';
import { INotification } from '@components/types';
import { useI18n } from '@hooks';
import { AlertSeverity } from '@utils/enums';
import {
  customPaymentDateId,
  defaultDateOptions,
  threeWeeks,
} from './constants';
import { generatePaymentDateOptions } from './generatePaymentDateOptions';
import { getDefaultRequestPaymentDay } from './getDefaultRequestPaymentDay';
import { getPaymentDateColumns } from './getPaymentDateColumns';
import { getPaymentDateData } from './getPaymentDateData';
import { getValidationSchema } from './getValidationSchema';
import {
  DaysAndZero,
  IChangePaymentDateForm,
  IChangePaymentRequest,
} from './types';

const ChangePaymentDateContent = styled.div`
  table td {
    padding: 0 0.75rem;
    width: 50%;
  }

  .form-group {
    margin: 2px 0;
  }

  .form-control {
    padding: 0.25rem 1rem;
    height: 32px;
  }
`;

export const ChangePaymentDateForm = ({
  canSelectCustomPaymentDate = false,
  currency,
  formID,
  handleSubmit,
  latestPaymentDate = new Date(Date.now()),
  paymentDate,
  paymentSchedule,
}: IChangePaymentDateForm) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  const latestPaymentDateWith3WeekBuffer = useMemo(() => {
    const bufferedDate = new Date(latestPaymentDate.getTime());
    bufferedDate.setDate(bufferedDate.getDate() + threeWeeks);
    return bufferedDate;
  }, [latestPaymentDate]);

  const paymentDateOptions = useMemo(
    () =>
      generatePaymentDateOptions({
        // There has to be a minimum 21 day gap between the latest payment per BE Core logic
        date: latestPaymentDateWith3WeekBuffer,
        disabledOptionDay: paymentDate,
        getLocaleDate,
      }),
    [getLocaleDate, latestPaymentDateWith3WeekBuffer, paymentDate],
  );

  const defaultRequestPaymentDay = useMemo(
    () =>
      getDefaultRequestPaymentDay({
        paymentDate,
        options: paymentDateOptions,
      }),
    [paymentDate, paymentDateOptions],
  );

  if (canSelectCustomPaymentDate) {
    paymentDateOptions.push({
      id: customPaymentDateId,
      name: translate('OPS_PORTAL.LABEL.SELECT_CUSTOM_DATE'),
      disabled: false,
    });
  }

  const defaultValues = {
    requested_payment_day: defaultRequestPaymentDay as Days,
    custom_payment_day: 1 as Days,
    uuid: uuid(),
    payment_dates: paymentSchedule.map(x => x.payment_date),
    override_dates: false,
  };

  const validationSchema = getValidationSchema(translate);

  const methods = useForm<IChangePaymentRequest>({
    defaultValues,
    resolver: yupResolver(validationSchema({ currentPaymentDay: paymentDate })),
  });

  const watchedDates = paymentSchedule.map((_, i) =>
    methods.watch(`payment_dates[${i}]` as 'payment_dates.0'),
  );

  const columns = getPaymentDateColumns({
    translate,
    watchedDates,
  });

  const data = useMemo(
    () =>
      getPaymentDateData({ currency, paymentSchedule, getLocaleCurrencyValue }),
    [currency, getLocaleCurrencyValue, paymentSchedule],
  );

  const overrideDates = methods.watch('override_dates');
  const requestedPaymentDay = methods.watch('requested_payment_day');

  useEffect(() => {
    methods.setValue(
      'requested_payment_day',
      defaultRequestPaymentDay as DaysAndZero,
    );
  }, [defaultRequestPaymentDay, methods, overrideDates]);

  useEffect(() => {
    if (requestedPaymentDay !== 0) {
      methods.setValue('custom_payment_day', 1);
    }
  }, [methods, requestedPaymentDay]);

  const customDateNotification: INotification[] = [
    {
      id: 'custom-payment-date-notification',
      message: translate(
        'OPS_PORTAL.NOTIFICATION.CUSTOM_PAYMENT_DATE_WARNING',
        {
          options: JSON.stringify(defaultDateOptions).replaceAll(',', ', '),
        },
      ),
      severity: AlertSeverity.Warning,
    },
  ];

  return (
    <FormProvider {...methods}>
      <form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id={formID}
        onSubmit={methods.handleSubmit(handleSubmit)}
      >
        {!overrideDates ? (
          <>
            <RadioGroupRHF
              defaultValue={defaultRequestPaymentDay}
              id="requested_payment_day"
              label={translate('OPS_PORTAL.LABEL.PAYMENT_DATE')}
              name="requested_payment_day"
              options={paymentDateOptions}
            />
            {Number(requestedPaymentDay) === customPaymentDateId ? (
              <>
                <NotificationBanner notifications={customDateNotification} />
                <NumberFieldRHF
                  id="custom_payment_day"
                  label={translate('OPS_PORTAL.LABEL.CUSTOM_PAYMENT_DATE')}
                  max={31}
                  min={1}
                  name="custom_payment_day"
                />
              </>
            ) : null}
          </>
        ) : null}

        <AccessController
          scopes={['ops_portal.contract_date_change_individual']}
        >
          <>
            <CheckboxFieldRHF
              label={translate(
                'OPS_PORTAL.LABEL.OVERRIDE_INDIVIDUAL_PAYMENT_DATES',
              )}
              name="override_dates"
            />

            {overrideDates ? (
              <ChangePaymentDateContent>
                <StaticTable columns={columns} data={data} />
              </ChangePaymentDateContent>
            ) : null}
          </>
        </AccessController>
      </form>
    </FormProvider>
  );
};
