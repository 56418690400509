import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Card, QueryStateRouter } from '@components';
import {
  ModalKeys,
  useDownloadSignedCustomerAgreement,
  useI18n,
  useModal,
} from '@hooks';
import { useMerchantSettingGroup } from '@hooks/useMerchantSettingGroup/useMerchantSettingGroup';
import { IMerchantPortalTracking } from '@schemas/merchantPortal/types/tracking';
import { QueryKeys } from '@utils/constants';
import { ButtonVariant, Endpoints } from '@utils/enums';
import { FinancingRequestApproved } from './FinancingRequestApproved';
import { useTrackingData } from './hooks/useTrackingData';
import { StepList } from './StepList';
import { TrackingGridContainer, TrackingGridItem } from './Tracking.styles';
import { TrackingQrCode } from './TrackingQrCode';
import { TrackingSummary } from './TrackingSummary';
import { getIsApplicationSuccessful } from './utils/getIsApplicationSuccessful';

export const Tracking = () => {
  const { getLocaleCurrencyValue, selectedLanguage, translate } = useI18n();
  const [refetchInterval, setRefetchInterval] = useState(1000);

  const { getMerchantSetting } = useMerchantSettingGroup();
  const config = getMerchantSetting('ObservationViewConfig');
  const { contractId } = useParams();

  const queryClient = useQueryClient();
  const queryKey = [QueryKeys.ORDER_TRACKING, contractId, selectedLanguage];

  const handleSuccess = useCallback(data => {
    if (getIsApplicationSuccessful(data?.statusLabel)) {
      setRefetchInterval(0);
    }
  }, []);

  const { data, error, isLoading } = useTrackingData({
    contractId,
    options: {
      refetchInterval,
      handleError: () => setRefetchInterval(0),
      handleSuccess,
    },
  });

  const isApproved = data && getIsApplicationSuccessful(data?.statusLabel);

  const {
    has_downloaded_agreement: hasDownloadedAgreement,
    has_uploaded_signed_agreement: hasUploadedSignedAgreement,
    is_manual_signing_required: isManualSigningRequired,
  } = data?.manualAgreementSigningOverview ?? {};

  const clickHandler = useCallback(() => window.print(), []);

  const fileDownloadModal = useModal(ModalKeys.DownloadCustomerAgreement, {
    dependencies: [
      isApproved,
      isManualSigningRequired,
      !hasUploadedSignedAgreement,
    ],
    externalApplicationUuid: contractId,
    queryKey,
  });

  const fileUploadModal = useModal(ModalKeys.FileUpload, {
    acceptedFiles: ['.pdf', '.jpeg', '.jpg'],
    dependencies: [
      isApproved,
      isManualSigningRequired,
      hasDownloadedAgreement,
      !hasUploadedSignedAgreement,
    ],
    description: translate('PAGE.ORDERS.UPLOAD_CUSTOMER_AGREEMENT_DESCRIPTION'),
    endpoint: `${Endpoints.CustomerAgreement}/${contractId}`,
    queryKey,
    onClose: () => queryClient.invalidateQueries([queryKey]),
  });

  const downloadSignedAgreementButton =
    useDownloadSignedCustomerAgreement(contractId);

  const shouldShowDownloadSignedAgreement =
    downloadSignedAgreementButton &&
    isManualSigningRequired &&
    hasUploadedSignedAgreement;

  const buttons = [
    ...(config?.showPrintButton
      ? [
          {
            clickHandler,
            'data-html2canvas-ignore': true,
            key: 'printPage',
            text: translate('BUTTON.PRINT'),
            variant: [ButtonVariant.Primary, ButtonVariant.Sm],
          },
        ]
      : []),
    fileDownloadModal?.ctaProps,
    fileUploadModal?.ctaProps,
    ...(shouldShowDownloadSignedAgreement
      ? [downloadSignedAgreementButton]
      : []),
  ].filter(Boolean);

  return (
    <QueryStateRouter error={error} isLoading={isLoading}>
      {data ? (
        <Card
          buttons={buttons}
          title={translate('PAGE.TRACKING.TRACK_CHECKOUT')}
        >
          <TrackingSummary config={config} data={data} />
          <FinancingRequestApproved data={data} translate={translate} />
          <TrackingGridContainer container={true}>
            <TrackingGridItem item={true} md={6}>
              <StepList
                progressData={
                  data?.progressData as IMerchantPortalTracking['progressData']
                }
              />
            </TrackingGridItem>
            {config?.qrCode ? (
              <TrackingQrCode
                config={config}
                data={data}
                getLocaleCurrencyValue={getLocaleCurrencyValue}
                translate={translate}
              />
            ) : null}
          </TrackingGridContainer>
          {fileDownloadModal?.Modal}
          {fileUploadModal?.Modal}
        </Card>
      ) : null}
    </QueryStateRouter>
  );
};
