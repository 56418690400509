import { useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useConfirm, useI18n, usePortalError } from '@hooks';
import { IModalProps } from '@hooks/useModal/types';
import { IOpsPortalTemplateList } from '@schemas/opsPortal/types/templateList';
import { fetchDataFromEndpoint, postDataToEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { EmailUserModalContent } from './EmailUserModalContent';
import {
  IEmailTemplate,
  IEmailUserModalProps,
  IHandleSelectTemplateProps,
  IHandleSendEmailProps,
  ISelectTemplateProps,
  ISupportedLanguage,
} from './types';

export const useEmailUserModal = ({
  closeModal,
  contractData,
  isInternalOpsUser,
  isModalShowing,
}: IEmailUserModalProps): IModalProps | null => {
  const { translate } = useI18n();
  const { handleAxiosError } = usePortalError();
  const { message } = useToast(translate);

  // Sadly, default values only work with undefined, and the data can be null.
  const data = contractData ?? {};

  const {
    arrears_amount: arrearsAmount,
    currency,
    external_contract_uuid: externalContractUUID,
    external_uuid: externalUUID,
  } = data;

  const uuid = externalUUID ?? externalContractUUID;

  const isInArrears = parseFloat(arrearsAmount?.amount) > 0;

  const confirm = useConfirm();

  const [selectedTemplate, setSelectedTemplate] = useState<
    IEmailTemplate | undefined
  >(undefined);
  const [language, setLanguage] = useState<ISupportedLanguage>('');
  const [triggerId, setTriggerId] = useState('');

  const actAfterConfirmation = useCallback(
    async (prompt: string, callback: () => void) => {
      if (await confirm?.({ confirmation: prompt })) {
        callback();
      }
    },
    [confirm],
  );

  const goBack = useCallback(async () => {
    actAfterConfirmation(
      translate('OPS_PORTAL.NOTIFICATION.CHANGES_WILL_BE_LOST'),
      () => setSelectedTemplate(undefined),
    );
  }, [actAfterConfirmation, translate]);

  const hideModal = useCallback(() => {
    setLanguage('');
    setTriggerId('');
    setSelectedTemplate(undefined);

    if (closeModal) {
      closeModal();
    }
  }, [closeModal]);

  const queryClient = useQueryClient();

  const { isLoading: isSendingEmail, mutate: sendEmail } = useMutation<
    unknown,
    AxiosError<IError>,
    IHandleSendEmailProps
  >(
    requestData =>
      postDataToEndpoint({
        endpoint: Endpoints.SendCustomerEmail,
        requestData,
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.COMMUNICATIONS_EVENTS]);
        message.success('OPS_PORTAL.STATUS.EMAIL_SUCCESSFULLY_SENT');
        hideModal();
      },
      onError: error => {
        handleAxiosError(error, 'OPS_PORTAL.STATUS.UNABLE_TO_SEND_EMAIL');
      },
    },
  );

  const handleSendEmail = useCallback(
    async (values: IHandleSendEmailProps) => {
      actAfterConfirmation(
        translate('OPS_PORTAL.PAGE.CONFIRM_SEND_EMAIL'),
        () => {
          const { body_html: bodyHtml, subject, ...templateFields } = values;

          const parameters = Object.keys(templateFields).length
            ? { parameters: { ...templateFields } }
            : {};

          sendEmail({
            body_html: bodyHtml,
            contract_external_uuid: uuid,
            language,
            subject,
            trigger_id: triggerId,
            ...parameters,
          });
        },
      );
    },
    [actAfterConfirmation, language, sendEmail, translate, triggerId, uuid],
  );

  const { data: templatesList, isLoading: isTemplatesListLoading } = useQuery<
    IOpsPortalTemplateList,
    AxiosError<IError>
  >(
    [QueryKeys.EMAIL_TEMPLATES_LIST],
    () => fetchDataFromEndpoint(Endpoints.FetchCustomerEmailTemplatesList),
    {
      onError: error => {
        handleAxiosError(
          error,
          'OPS_PORTAL.ERROR.UNABLE_TO_FETCH_EMAIL_TEMPLATES',
        );
      },
      enabled: isModalShowing,
    },
  );

  const {
    data: template,
    isLoading: isSelectedTemplateLoading,
    mutate: selectTemplate,
  } = useMutation<IEmailTemplate, AxiosError<IError>, ISelectTemplateProps>(
    requestData =>
      postDataToEndpoint({
        endpoint: Endpoints.SelectEmailTemplate,
        requestData,
      }),
    {
      onError: error => {
        handleAxiosError(error, 'OPS_PORTAL.STATUS.UNABLE_TO_FETCH_TEMPLATE');
      },
    },
  );

  useEffect(() => {
    setSelectedTemplate(template);
  }, [template]);

  const handleSelectTemplate = useCallback(
    (values: IHandleSelectTemplateProps) => {
      setLanguage(values.language);
      setTriggerId(values.template);
      selectTemplate({
        contract_external_uuid: uuid,
        language: values.language,
        trigger_id: values.template,
      });
    },
    [selectTemplate, uuid],
  );

  const formID = 'send-customer-email';

  const isLoading = isSelectedTemplateLoading || isTemplatesListLoading;
  const isBusy = isLoading || isSendingEmail;

  const modalContent = (
    <EmailUserModalContent
      currency={currency}
      formID={formID}
      goBack={goBack}
      handleSelectTemplate={handleSelectTemplate}
      handleSendEmail={handleSendEmail}
      isInArrears={isInArrears}
      isInternalOpsUser={isInternalOpsUser}
      language={language}
      selectedTemplate={selectedTemplate}
      templates={templatesList}
      triggerId={triggerId}
    />
  );

  const loadingOverlayText = isLoading ? 'STATUS.LOADING' : undefined;

  return uuid
    ? {
        formID,
        isFormProcessing: isBusy,
        isLoading: isBusy,
        loadingOverlay: true,
        loadingOverlayText,
        modalContent,
        showFormButtons: !isBusy,
        title: translate('OPS_PORTAL.TITLE.SEND_EMAIL_TO_CUSTOMER'),
      }
    : null;
};
