import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IOpsPortalContractDocuments } from '@schemas/opsPortal/types/contractDocuments';
import { fetchDataFromEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';

interface IUseContractDocumentsTab {
  canViewContractDocumentsTab: boolean;
  contractUuid: string;
}

export const useGetContractDocumentsQuery = ({
  canViewContractDocumentsTab,
  contractUuid,
}: IUseContractDocumentsTab) => {
  const { data, error, isLoading } = useQuery<
    IOpsPortalContractDocuments,
    AxiosError
  >(
    [QueryKeys.CONTRACT_DOCUMENTS, contractUuid],
    () =>
      fetchDataFromEndpoint(
        `${Endpoints.FetchContractDocuments}/${contractUuid}`,
        {},
      ),
    {
      enabled: canViewContractDocumentsTab && Boolean(contractUuid),
    },
  );

  return { data, error, isLoading };
};
