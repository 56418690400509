import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextFieldRHF } from '@components';
import { useI18n } from '@hooks';
import { ValidationErrorKeys } from '@utils/enums';

interface IArrearsAmountFormProps {
  formID: string;
  handleSubmit: (values: { amount: string }) => void;
}

export const ArrearsAmountForm = ({
  formID,
  handleSubmit,
}: IArrearsAmountFormProps) => {
  const { translate } = useI18n();

  const defaultValues = { amount: '' };

  const validationSchema = Yup.object({
    amount: Yup.string()
      .required(translate(ValidationErrorKeys.Required))
      .test('is-number', translate(ValidationErrorKeys.InvalidAmount), value =>
        Boolean(value.length && !Number.isNaN(Number(value))),
      ),
  });

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  return (
    <FormProvider {...methods}>
      <form id={formID} onSubmit={methods.handleSubmit(handleSubmit)}>
        <TextFieldRHF
          id="amount"
          label={translate('LABEL.AMOUNT')}
          name="amount"
        />
      </form>
    </FormProvider>
  );
};
