import { useCallback } from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useI18n } from '@hooks';
import { ModalStatusRouter } from '@hooks/useModal/components/index.ts';
import { getErrorMessage, putDataToEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { EditNameForm } from './EditNameForm';

export const useEditNameModal = ({ data, formStatusMessage, resolveForm }) => {
  const { translate } = useI18n();

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(
    requestData =>
      putDataToEndpoint({
        endpoint: 'user/settings',
        requestData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.ACCOUNT_SETTINGS]);
        resolveForm(
          translate('PAGE.ACCOUNT_SETTINGS.NAME_SUCCESSFULLY_EDITED'),
        );
      },
      onError: error => {
        resolveForm(
          getErrorMessage({
            error,
            genericErrorMessageKey: 'PAGE.ACCOUNT_SETTINGS.UNABLE_TO_EDIT_NAME',
            translate,
          }),
        );
      },
    },
  );

  const handleSubmit = useCallback(
    values => {
      mutate(values);
    },
    [mutate],
  );

  const formID = 'edit-name';

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={isLoading}
    >
      <EditNameForm data={data} formID={formID} handleSubmit={handleSubmit} />
    </ModalStatusRouter>
  );

  return {
    formID,
    isLoading,
    modalContent,
    title: translate('PAGE.ACCOUNT_SETTINGS.EDIT_NAME'),
  };
};
