import { useCallback } from 'react';
import { ICardCTA } from '@appTypes';
import { CardCTA } from '@components';
import { useI18n } from '@hooks';
import { CommunicationsTypes } from '@utils/constants';
import { ICommunication } from './types';

interface IViewCommunicationButtonProps {
  communicationProps: ICommunication;
  ctaProps: ICardCTA;
  setCommunicationProps: (communicationProps: ICommunication) => void;
}

export const ViewCommunicationButton = ({
  communicationProps,
  ctaProps,
  setCommunicationProps,
}: IViewCommunicationButtonProps) => {
  const { translate } = useI18n();
  const clickHandler = useCallback(() => {
    setCommunicationProps(communicationProps);
    ctaProps.clickHandler();
  }, [communicationProps, ctaProps, setCommunicationProps]);

  const updatedCTAProps = {
    ...ctaProps,
    clickHandler,
    text: translate(
      communicationProps.type === CommunicationsTypes.EMAIL
        ? 'OPS_PORTAL.BUTTON.VIEW_EMAIL'
        : 'OPS_PORTAL.BUTTON.VIEW_TEXT_MESSAGE',
    ),
  };

  return <CardCTA {...updatedCTAProps} />;
};
