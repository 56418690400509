import styled from 'styled-components';
import {
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatUnderlined,
  Link,
} from '@material-ui/icons';
import {
  getPluginType,
  ELEMENT_OL,
  ELEMENT_UL,
  HeadingToolbar,
  LinkToolbarButton,
  ListToolbarButton,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
  MarkToolbarButton,
  PlateId,
  usePlateEditorRef,
} from '@udecode/plate';
import { Value, Editor } from './types';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/animations/scale.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/dist/tippy.css';

const tooltip = (content: string) => ({
  content,
});

const StyledToolbar = styled(HeadingToolbar)`
  margin: 0;
  padding: 0;
  background-color: #f3f6f9;

  .form-control & {
    margin: -0.65rem -1rem 0;
  }
`;

const useTypedPlateEditorRef = (id?: PlateId) =>
  usePlateEditorRef<Value, Editor>(id);

const BasicMarkToolbarButtons = () => {
  const editor = useTypedPlateEditorRef();

  return (
    <>
      <MarkToolbarButton
        icon={<FormatBold />}
        tooltip={tooltip('Bold (⌘+B)')}
        type={getPluginType(editor, MARK_BOLD)}
      />
      <MarkToolbarButton
        icon={<FormatItalic />}
        tooltip={tooltip('Italic (⌘+I)')}
        type={getPluginType(editor, MARK_ITALIC)}
      />
      <MarkToolbarButton
        icon={<FormatUnderlined />}
        tooltip={tooltip('Underline (⌘+U)')}
        type={getPluginType(editor, MARK_UNDERLINE)}
      />
    </>
  );
};

const ListToolbarButtons = () => {
  const editor = usePlateEditorRef();

  return (
    <>
      <ListToolbarButton
        icon={<FormatListBulleted />}
        tooltip={tooltip('Bullet list')}
        type={getPluginType(editor, ELEMENT_UL)}
      />
      <ListToolbarButton
        icon={<FormatListNumbered />}
        tooltip={tooltip('Ordered list')}
        type={getPluginType(editor, ELEMENT_OL)}
      />
    </>
  );
};

const ToolbarButtons = () => (
  <>
    <ListToolbarButtons />
    <BasicMarkToolbarButtons />
    <LinkToolbarButton icon={<Link />} tooltip={tooltip('Link (⌘+K)')} />
  </>
);

export const TopToolbar = () => (
  <StyledToolbar>
    <ToolbarButtons />
  </StyledToolbar>
);
