import { useCallback, useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import { ValueOf } from '@appTypes';
import { useI18n } from '@hooks';
import { ISettlement } from '@schemas/opsPortal/types/monetaContract';
import { PaymentTypes } from '@utils/constants';
import { FormFields } from './formFields/FormFields';
import { PaymentSubmitRequest } from './types';
import { getInitialValues, getValidationSchema } from './utils';

interface IPaymentFormProps {
  contractSettlements?: ISettlement[];
  contractUuid?: string;
  currency: string;
  formID: string;
  handleSettlementChanged: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  handleSubmit: (value: PaymentSubmitRequest) => void;
  max?: string;
  paymentPlanId?: string;
  paymentType: ValueOf<typeof PaymentTypes>;
  settlementAmountValue?: string;
  settlementUuid?: string;
  uuid?: string;
}

export const PaymentForm = ({
  contractSettlements,
  contractUuid,
  currency,
  formID,
  handleSettlementChanged,
  handleSubmit,
  max,
  paymentPlanId,
  paymentType,
  settlementAmountValue,
  settlementUuid,
  uuid,
}: IPaymentFormProps) => {
  const { getLocaleDate, translate } = useI18n();

  const [isFullRefund, setIsFullRefund] = useState(
    paymentType === PaymentTypes.REFUND,
  );

  const toggleRefundAmount: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(e => setIsFullRefund(e.target.checked), []);

  const initialFormikValues = useMemo(
    () =>
      getInitialValues({
        contractUuid,
        currency,
        max,
        paymentType,
        settlementAmountValue,
        settlementUuid,
        uuid,
      }),
    [
      contractUuid,
      currency,
      max,
      paymentType,
      settlementAmountValue,
      settlementUuid,
      uuid,
    ],
  );

  const validationSchema = useMemo(
    () =>
      getValidationSchema({
        isFullRefund,
        max,
        paymentPlanId,
        paymentType,
        translate,
      }),
    [isFullRefund, max, paymentPlanId, paymentType, translate],
  );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialFormikValues as PaymentSubmitRequest}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id={formID}
      >
        <FormFields
          contractSettlements={contractSettlements}
          currency={currency}
          getLocaleDate={getLocaleDate}
          handleSettlementChanged={handleSettlementChanged}
          isFullRefund={isFullRefund}
          paymentType={paymentType}
          toggleRefundAmount={toggleRefundAmount}
          translate={translate}
        />
      </Form>
    </Formik>
  );
};
