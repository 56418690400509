import { useI18n } from '@hooks';
import { AdjustContractValueForm } from './AdjustContractValueForm';
import { IAdjustContractValueFormProps } from './types';

export const AdjustContractValueModalContent = ({
  amount,
  currency,
  formID,
  handleSubmit,
  isCancel,
}: IAdjustContractValueFormProps) => {
  const { translate } = useI18n();

  return (
    <>
      {isCancel ? null : (
        <p>
          {translate(
            'PAGE.ORDERS.CANCEL_ALL_OR_PART_OF_AN_ORDER_BY_ADJUSTING_THE_CONTRACT_VALUE',
          )}
        </p>
      )}
      <p className="text-danger text-center">
        <strong>{translate('GENERAL.ARE_YOU_SURE')}</strong>
      </p>
      <AdjustContractValueForm
        amount={amount}
        currency={currency}
        formID={formID}
        handleSubmit={handleSubmit}
        isCancel={isCancel}
      />
    </>
  );
};
