import { useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { QueryStateRouter } from '@components';
import { CommunicationIframe } from '@components/Common.styles';
import { useConfig, useI18n } from '@hooks';
import { IOpsPortalCommunicationsEvent } from '@schemas/opsPortal/types/communicationsEvent';
import { IUuid } from '@schemas/opsPortal/types/consumerDetails';
import { selectTimeToAuthExpiry } from '@selectors/index';
import { fetchDataFromEndpoint, getDisplayData } from '@utils';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';

interface IEventViewProps {
  selectedEventUUID: IUuid;
}

export const EventView = ({ selectedEventUUID }: IEventViewProps) => {
  const { translate } = useI18n();

  const msUntilTokenExpiry = useSelector(selectTimeToAuthExpiry);

  const {
    data,
    error,
    isInitialLoading: isLoading,
  } = useQuery<IOpsPortalCommunicationsEvent, AxiosError>(
    [QueryKeys.COMMUNICATIONS_EVENT, selectedEventUUID],
    () =>
      fetchDataFromEndpoint(
        `${Endpoints.FetchConsumerEvent}/${selectedEventUUID}`,
      ),
    {
      cacheTime: msUntilTokenExpiry,
      enabled: Boolean(selectedEventUUID),
      staleTime: msUntilTokenExpiry,
    },
  );

  const {
    config: { canUseHeyLightBranding },
  } = useConfig();

  return (
    <QueryStateRouter error={error} isLoading={isLoading}>
      {data ? (
        <CommunicationIframe
          src={getDisplayData(data, canUseHeyLightBranding)}
        />
      ) : (
        <p>
          {translate(
            'OPS_PORTAL.PAGE.COMMUNICATIONS.NO_COMMUNICATION_SELECTED',
          )}
        </p>
      )}
    </QueryStateRouter>
  );
};
