import { CoreRow } from '@tanstack/react-table';
import { getMillisecondsToDateAtMidnight } from '@utils';

interface IFilterDateRangeProps<T> {
  accessorKey: keyof T;
  filterValue: [string, string];
  row: CoreRow<T>;
}

export const filterDateRange = <T>({
  accessorKey,
  filterValue,
  row,
}: IFilterDateRangeProps<T>) => {
  const fromDate = filterValue[0];
  const toDate = filterValue[1];

  if (!(fromDate || toDate)) {
    return true;
  }

  const createdDateHours = getMillisecondsToDateAtMidnight(
    row.original[accessorKey],
  );
  const fromDateHours = getMillisecondsToDateAtMidnight(filterValue[0]);
  const toDateHours = getMillisecondsToDateAtMidnight(filterValue[1]);

  if (!fromDate) {
    return createdDateHours <= toDateHours;
  }

  if (!toDate) {
    return fromDateHours <= createdDateHours;
  }

  return fromDateHours <= createdDateHours && createdDateHours <= toDateHours;
};
