import { CopyText } from '@components';
import { useI18n } from '@hooks';
import { IOpsPortalPaymentLink } from '@schemas/opsPortal/types/paymentLink';
import { getFormattedAmount } from '@utils';
import { missingValuePlaceholder } from '@utils/constants';

interface IPaymentLink {
  paymentLinkData?: IOpsPortalPaymentLink;
}

export const PaymentLink = ({ paymentLinkData }: IPaymentLink) => {
  const { getLocaleCurrencyValue, translate } = useI18n();

  return paymentLinkData?.payment_link ? (
    <CopyText
      copyLabel={translate('OPS_PORTAL.LABEL.PAYMENT_LINK_COPY', {
        presetAmount: getFormattedAmount({
          ...paymentLinkData.preset_amount,
          getLocaleCurrencyValue,
        }),
      })}
      copyText={paymentLinkData.payment_link}
      errorMessage={translate('OPS_PORTAL.LABEL.PAYMENT_LINK_FAILED_TO_COPY')}
      successMessage={translate(
        'OPS_PORTAL.LABEL.PAYMENT_LINK_SUCCESSFULLY_COPIED',
      )}
    />
  ) : (
    <>{missingValuePlaceholder}</>
  );
};
