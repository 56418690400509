import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import { IEarlySettlementQuote } from '@schemas/opsPortal/types/earlySettlements';
import { addPlaceholderForEmptyValues } from '@utils';
import { IEarlySettlementsTableColumn } from './types';

interface IMapOverpaidContractTableData {
  data?: IEarlySettlementQuote[];
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

export const mapEarlySettlementsTableData = ({
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
  translate,
}: IMapOverpaidContractTableData): IEarlySettlementsTableColumn[] =>
  data?.map((dataRow: IEarlySettlementQuote) => {
    const {
      created_at: createdAt,
      early_settlement_quote_uuid: earlySettlementQuoteUuid,
      expiry_date: expiryDate,
      quote_status: quoteStatus,
      quote_type: quoteType,
      settlement_amount: settlementAmount,
    } = dataRow;

    return addPlaceholderForEmptyValues<IEarlySettlementsTableColumn>({
      createdAt: getLocaleDate({ date: createdAt }),
      earlySettlementQuoteUuid,
      expiryDate: getLocaleDate({ date: expiryDate }),
      quoteStatus: translate(`OPS_PORTAL.STATUS.${quoteStatus.toUpperCase()}`),
      quoteType: translate(`OPS_PORTAL.STATUS.${quoteType}`),
      settlementAmount: getLocaleCurrencyValue({
        currency: settlementAmount.currency,
        value: settlementAmount.amount,
      }),
    });
  }) ?? [];
