import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { NoDataAvailable, QueryStateRouter } from '@components';
import { IOpsPortalUnbalancedAllocationDetails } from '@schemas/opsPortal/types/unbalancedAllocationDetails';
import { fetchDataFromEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { AllocationsCard } from './AllocationsCard';
import { DetailsCard } from './DetailsCard';
import { MonetaTransactionsCard } from './MonetaTransactionsCard';

export const UnbalancedAllocationDetails = () => {
  const { uuid } = useParams();

  const { data, error, isLoading } = useQuery<
    IOpsPortalUnbalancedAllocationDetails,
    AxiosError
  >([QueryKeys.APPLICATION_DETAILS, uuid], () =>
    fetchDataFromEndpoint(`${Endpoints.FetchUnbalancedAllocations}/${uuid}`),
  );

  return (
    <QueryStateRouter error={error} isLoading={isLoading}>
      {data ? (
        <>
          <DetailsCard data={data} />
          <MonetaTransactionsCard
            balance={data.moneta_balance}
            transactions={data.moneta_transactions}
          />
          <AllocationsCard
            allocations={data.allocations}
            balance={data.allocation_balance}
          />
        </>
      ) : (
        <NoDataAvailable />
      )}
    </QueryStateRouter>
  );
};
