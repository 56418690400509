import { useCallback, useState } from 'react';
import { SortingButton } from '@components/Table/Table.styles';

export const SortButton = ({ children, sort, toggleSort }) => {
  const [isHighlighted, setIsHighlighted] = useState(false);

  const highlight = useCallback(() => {
    setIsHighlighted(true);
  }, []);

  const unHighlight = useCallback(() => {
    setIsHighlighted(false);
  }, []);

  const sortIcon = {
    ascending: '▲',
    descending: '▼',
  };

  return (
    <SortingButton
      onBlur={unHighlight}
      onClick={toggleSort}
      onFocus={highlight}
      onMouseOut={unHighlight}
      onMouseOver={highlight}
      variant={isHighlighted ? ['primary', 'sm'] : ['transparent', 'sm']}
    >
      {children}
      <span aria-hidden="true">
        {isHighlighted && !sort ? sortIcon.ascending : sortIcon[sort]}
      </span>
    </SortingButton>
  );
};
