import { PoweredByLogo, ProfilePicker } from '@components';
import { useConfig, useOriginator } from '@hooks';
import { Menu } from './Menu';
import { NavWrapper, PoweredByLogoWrapper } from './Menu.styled';
import { Toggle } from './Toggle';

export const Aside = () => {
  const {
    config: {
      logo: { showPoweredBy },
      showProfilePicker,
    },
  } = useConfig();
  const { userProfiles } = useOriginator();

  return (
    <div
      className="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
      id="kt_aside"
    >
      <Toggle />

      {showProfilePicker && userProfiles.length > 1 ? (
        <ProfilePicker userProfiles={userProfiles} />
      ) : null}

      <NavWrapper>
        <Menu />
      </NavWrapper>

      {showPoweredBy ? (
        <PoweredByLogoWrapper className="aside-minimized-hidden">
          <PoweredByLogo justifyContent="left" />
        </PoweredByLogoWrapper>
      ) : null}
    </div>
  );
};
