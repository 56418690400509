import { ValueOf } from '@appTypes';
import { Originators, PathnamePrefixes } from '@utils/constants';

export type PathnamePrefix = ValueOf<typeof PathnamePrefixes> | '/IT';

export const getPathnamePrefixForOriginator = (
  originator: keyof typeof PathnamePrefixes,
  canUseNewLoginPage: boolean,
): PathnamePrefix => {
  if (originator === Originators.HEIDIPAY) {
    return '';
  }

  if (canUseNewLoginPage) {
    return '/IT';
  }

  return PathnamePrefixes[originator];
};
