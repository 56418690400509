import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const StepItem = styled(motion.li)`
  display: table;
  margin-block-end: 1.5rem;
  list-style: none;
`;

export const Steps = styled(motion.ol)`
  padding-inline-start: 0.5rem;
`;

export const TrackingContainer = styled(Grid);

interface ITrackingItem {
  align?: 'left' | 'right';
}

export const TrackingGridContainer = styled(Grid)`
  margin-top: 2rem;
`;

export const TrackingGridItem = styled(Grid)<ITrackingItem>`
  ${({ align = 'left' }) => `text-align: ${align};`}

  min-width: 50%;

  @media (max-width: 767px) {
    text-align: left;
  }
`;

export const SuccessMessage = styled.div`
  text-align: center;
  padding: 3em;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  > div {
    max-width: 900px;
    margin: auto;

    #lottie {
      svg {
        margin: -35%;
        transform: scale(0.5) !important;
      }
    }

    > div:last-of-type {
      font-size: 1.3em;
    }
  }
`;
