import {
  LoginGreetingHeader,
  LoginGreetingWrapper,
} from '@auth/pages/Auth.styles';
import { Logo } from '@components';
import { useFeatureFlags, useI18n } from '@hooks';
import { LogoVariants } from '@utils/constants';

export const LoginGreeting = () => {
  const { translate } = useI18n();
  const { canUseNewLoginPage } = useFeatureFlags();

  return (
    <LoginGreetingWrapper>
      <LoginGreetingHeader>
        {translate('GENERAL.WELCOME_TO')}
        <Logo
          fill={canUseNewLoginPage ? LogoVariants.DARK : LogoVariants.LIGHT}
        />
      </LoginGreetingHeader>
    </LoginGreetingWrapper>
  );
};
