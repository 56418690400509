import { useSearchParams } from 'react-router-dom';

export const useHeyLightQueryString = () => {
  const [searchParams] = useSearchParams();

  const canUseHeyLightLoginPage = searchParams.get('canUseHeyLight');

  if (canUseHeyLightLoginPage) {
    window.sessionStorage.setItem(
      'canUseHeyLightLoginPage',
      canUseHeyLightLoginPage,
    );
  }
};
