import { Fragment, ReactElement, isValidElement } from 'react';
import { Link } from 'react-router-dom';
import { StatusPill } from '@components';
import { DD, DL, DT } from '@components/Common.styles';
import { useI18n } from '@hooks';
import { RawStatuses } from '@utils/constants';

interface ILink {
  linkTo: string;
  text: string;
}

export type DataValue = string | ILink | ReactElement;

export interface IDataRow {
  key: string;
  value: DataValue;
}

interface IDataDisplayProps {
  dataList: IDataRow[];
}

export const DataDisplay = ({ dataList }: IDataDisplayProps) => {
  const { translate } = useI18n();

  const getRenderValue = (value: DataValue) => {
    if (
      typeof value === 'object' &&
      'linkTo' in value &&
      !isValidElement(value)
    ) {
      return <Link to={value.linkTo}>{value.text}</Link>;
    }
    if (value === 'blocked') {
      return (
        <StatusPill
          rawStatus={RawStatuses.BLOCKED}
          status={translate('OPS_PORTAL.STATUS.BLOCKED')}
        />
      );
    }
    return value;
  };

  return (
    <DL>
      {dataList.map(({ key, value }) => {
        const renderValue = getRenderValue(value);

        return (
          <Fragment key={key}>
            <DT>{key}</DT>
            <DD>{renderValue}</DD>
          </Fragment>
        );
      })}
    </DL>
  );
};
