import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { requestPassword } from '@auth/_redux';
import { useI18n, useOriginator } from '@hooks';
import { AuthPageHeader, ContentInnerWrapper } from './Auth.styles';
import { AuthForm } from './components';

export const ForgotPassword = () => {
  const { selectedLanguage, translate } = useI18n();
  const { originator } = useOriginator();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (values, { setStatus, setSubmitting }) => {
      requestPassword(values.email, selectedLanguage, originator)
        .then(() => {
          navigate('/auth/success', {
            state: {
              heading: translate('AUTH.PASSWORD_RESET_REQUEST_SUBMITTED'),
              text: translate(
                'AUTH.PLEASE_CHECK_YOUR_EMAIL_FOR_FURTHER_INSTRUCTIONS',
              ),
            },
            replace: true,
          });
        })
        .catch(() => {
          setStatus(translate('ERROR.UNEXPECTED_ERROR'));
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [navigate, originator, selectedLanguage, translate],
  );

  return (
    <ContentInnerWrapper>
      <AuthPageHeader>
        <h2>{translate('AUTH.FORGOT_PASSWORD')}</h2>
        <p>{translate('AUTH.ENTER_YOUR_EMAIL_TO_RESET_YOUR_PASSWORD')}</p>
        <p>{translate('AUTH.USE_PURCHASE_EMAIL_ADDRESS')}</p>
      </AuthPageHeader>

      <AuthForm
        fields={['email']}
        handleSubmit={handleSubmit}
        initialValues={{ email: '' }}
      />
    </ContentInnerWrapper>
  );
};
