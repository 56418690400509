import { createColumnHelper } from '@tanstack/react-table';
import { CollectionAgencies } from '@appTypes';
import { Link } from '@components/Common.styles';
import { Translate } from '@hooks/useI18n';
import { TabEventKeys } from '@utils/constants';
import { ICollectionsTableData } from './types.d';

export const getCollectionsTableColumns = (
  translate: Translate,
  collectionAgencies: CollectionAgencies,
) => {
  if (Object.keys(collectionAgencies).length === 0) {
    return [];
  }

  const columnHelper = createColumnHelper<ICollectionsTableData>();

  return [
    columnHelper.accessor('contractId', {
      header: translate('OPS_PORTAL.LABEL.PAYMENT_PLAN_REFERENCE'),
      cell: ({ row }) => (
        <Link
          state={{ activeKey: TabEventKeys.CONTRACT_PERFORMANCE }}
          to={`/all_contracts/${row.original.externalUUID}`}
        >
          {row.original.contractId}
        </Link>
      ),
      enableSorting: false,
    }),
    columnHelper.accessor('paymentDueDate', {
      header: translate('OPS_PORTAL.LABEL.CURRENT_ARREARS_DAY_ZERO'),
      enableSorting: false,
    }),
    columnHelper.accessor('customerName', {
      header: translate('TITLE.CUSTOMER_NAME'),
      enableSorting: false,
    }),
    columnHelper.accessor('merchantName', {
      header: translate('OPS_PORTAL.LABEL.MERCHANT_NAME'),
      enableSorting: false,
    }),
    columnHelper.accessor('language', {
      header: translate('LABEL.LANGUAGE'),
      enableSorting: false,
    }),
    columnHelper.accessor('amountInArrears', {
      header: translate('OPS_PORTAL.LABEL.ARREARS_AMOUNT'),
    }),
    columnHelper.accessor('lastNoteDate', {
      header: translate('OPS_PORTAL.LABEL.LAST_NOTE_DATE'),
      enableSorting: false,
    }),
    columnHelper.accessor('performanceStatus', {
      header: translate('OPS_PORTAL.LABEL.PERFORMANCE_STATUS'),
    }),
    columnHelper.accessor('arrearsManagedBy', {
      cell: ({ row }) => {
        const { arrearsManagedBy } = row.original;
        return collectionAgencies[arrearsManagedBy];
      },
      header: translate('OPS_PORTAL.TITLE.ARREARS_MANAGED_BY'),
      enableSorting: false,
    }),
  ];
};
