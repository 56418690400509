import { SyntheticEvent, useCallback, useState } from 'react';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import {
  BorderColorOutlined,
  PrintOutlined,
  UploadFileOutlined,
} from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';
import { QueryStateRouter } from '@components';
import { useI18n, useQueryOnClick } from '@hooks';
import { IModalProps, ISharedModalProps } from '@hooks/useModal/types';
import { downloadFile, fetchDataFromEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { DocumentLink } from './DocumentLink';
import { Instructions } from './Instructions';

export interface IUseDownloadCustomerAgreementModalProps
  extends ISharedModalProps {
  externalApplicationUuid: string;
  formId: string;
  onSubmit: (event: SyntheticEvent) => void;
  queryKey: string;
}

export const useDownloadCustomerAgreementModal = ({
  closeModal,
  externalApplicationUuid,
  queryKey,
}: IUseDownloadCustomerAgreementModalProps): IModalProps => {
  const { translate } = useI18n();
  const queryClient = useQueryClient();
  const { message } = useToast(translate);
  const [forceDownload, setForceDownload] = useState(false);

  const { execute: downloadAgreement, isLoading } = useQueryOnClick(
    [QueryKeys.DOWNLOAD_CUSTOMER_AGREEMENT],
    () =>
      fetchDataFromEndpoint(
        `${Endpoints.CustomerAgreement}/${externalApplicationUuid}`,
        {},
      ),
    {
      onError: () => {
        message.error('ERROR.FILE_DOWNLOAD', { timeout: 10000 });
      },
      onSuccess: (url: string) => downloadFile(url, forceDownload),
    },
  );

  const clickHandler = useCallback(
    (isButton: boolean) => {
      setForceDownload(isButton);
      downloadAgreement(null);
      queryClient.invalidateQueries([queryKey]);
      closeModal?.();
    },
    [closeModal, downloadAgreement, queryClient, queryKey],
  );

  const buttonClickHandler = useCallback(() => {
    clickHandler(true);
  }, [clickHandler]);

  const linkClickHandler = useCallback(
    e => {
      e.preventDefault();
      clickHandler(false);
    },
    [clickHandler],
  );

  const instructionSteps = [
    {
      avatar: <PrintOutlined />,
      shouldRepeatStep: true,
      translationKey: translate(
        'PAGE.ORDERS.DOWNLOAD_AGREEMENT.PRINT_TWO_COPIES',
      ),
    },
    {
      avatar: <BorderColorOutlined />,
      shouldRepeatStep: true,
      translationKey: translate(
        'PAGE.ORDERS.DOWNLOAD_AGREEMENT.SIGN_TWO_COPIES',
      ),
    },
    {
      avatar: <UploadFileOutlined />,
      shouldRepeatStep: false,
      translationKey: translate(
        'PAGE.ORDERS.DOWNLOAD_AGREEMENT.UPLOAD_AGREEMENT',
      ),
    },
  ];

  const modalContent = (
    <QueryStateRouter isLoading={isLoading}>
      <>
        <Instructions steps={instructionSteps} />
        <DocumentLink
          clickHandler={linkClickHandler}
          linkText={translate('BUTTON.OPEN_DOCUMENT_IN_A_NEW_TAB')}
        />
      </>
    </QueryStateRouter>
  );

  return {
    clickHandler: buttonClickHandler,
    modalContent,
    submitText: translate('BUTTON.DOWNLOAD_DOCUMENT'),
    title: translate('BUTTON.DOWNLOAD_CUSTOMER_AGREEMENT'),
  };
};
