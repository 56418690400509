/* eslint-disable no-param-reassign */
import { NoTableData } from '@components';

export const TableBody = ({
  flexRender,
  headerGroups,
  rowClasses = [],
  rows,
}) => (
  <tbody>
    {rows.length ? (
      rows.map(row => (
        <tr
          className={rowClasses.length ? rowClasses[row.index] : ''}
          key={row.id}
        >
          {row.getVisibleCells().map(cell => (
            <td key={cell.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))
    ) : (
      <NoTableData headerGroups={headerGroups} />
    )}
  </tbody>
);
