import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useI18n, usePortalError } from '@hooks';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { ISharedModalProps } from '@hooks/useModal/types';
import { postDataToEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { ApplicantBlockActions, Endpoints } from '@utils/enums';
import { ApplicantBlockForm } from './ApplicantBlockForm';
import { ICreateOrModifyBlockPayload, IRemoveBlockPayload } from './types';

interface IBlockApplicantModalProps extends ISharedModalProps {
  action: ApplicantBlockActions;
  applicantUuid?: string;
  consumerUuid?: string;
  contractUuid?: string;
}

interface IMutationFailure {
  details: string;
}

interface IMutationSuccess {
  is_blocked: boolean;
  message: string;
}

type MutationResponse = IMutationSuccess | IMutationFailure;

export const useApplicantBlockModal = ({
  action,
  applicantUuid,
  closeModal,
  consumerUuid,
  contractUuid,
}: IBlockApplicantModalProps) => {
  const { translate } = useI18n();

  const { handleAxiosError } = usePortalError();
  const queryClient = useQueryClient();
  const { message } = useToast(translate);

  const uuid = applicantUuid || consumerUuid || contractUuid;

  const { isLoading: isCreatingBlock, mutate: createApplicantBlock } =
    useMutation<
      MutationResponse,
      AxiosError<IError>,
      ICreateOrModifyBlockPayload
    >(
      requestData =>
        postDataToEndpoint({
          endpoint: Endpoints.CreateApplicantBlock,
          requestData,
        }),
      {
        onSuccess: () => {
          message.success('OPS_PORTAL.STATUS.APPLICANT_BLOCK_SUCCESSFUL');
          queryClient.invalidateQueries([QueryKeys.IS_BLOCKED, uuid]);
          queryClient.invalidateQueries([QueryKeys.BLOCKED_APPLICANTS]);
          if (closeModal) {
            closeModal();
          }
        },
        onError: error => {
          handleAxiosError(error);
        },
      },
    );

  const { isLoading: isModifyingBlock, mutate: modifyApplicantBlock } =
    useMutation<
      MutationResponse,
      AxiosError<IError>,
      ICreateOrModifyBlockPayload
    >(
      requestData =>
        postDataToEndpoint({
          endpoint: Endpoints.ModifyApplicantBlock,
          requestData,
        }),
      {
        onSuccess: () => {
          message.success(
            'OPS_PORTAL.STATUS.APPLICANT_MODIFY_BLOCK_SUCCESSFUL',
          );
          queryClient.invalidateQueries([QueryKeys.IS_BLOCKED, applicantUuid]);
          queryClient.invalidateQueries([QueryKeys.BLOCKED_APPLICANTS]);
          if (closeModal) {
            closeModal();
          }
        },
        onError: error => {
          handleAxiosError(error);
        },
      },
    );

  const { isLoading: isRemovingBlock, mutate: removeApplicantBlock } =
    useMutation<MutationResponse, AxiosError<IError>, IRemoveBlockPayload>(
      requestData =>
        postDataToEndpoint({
          endpoint: Endpoints.RemoveApplicantBlock,
          requestData,
        }),
      {
        onSuccess: () => {
          message.success('OPS_PORTAL.STATUS.APPLICANT_UNBLOCK_SUCCESSFUL');
          queryClient.invalidateQueries([QueryKeys.IS_BLOCKED, applicantUuid]);
          queryClient.invalidateQueries([QueryKeys.BLOCKED_APPLICANTS]);
          if (closeModal) {
            closeModal();
          }
        },
        onError: error => {
          handleAxiosError(error);
        },
      },
    );

  const filterValues = <
    T extends ICreateOrModifyBlockPayload | IRemoveBlockPayload,
  >(
    values: T,
  ) =>
    Object.keys(values).reduce((obj, key) => {
      const value = values[key as keyof typeof values];
      return value ? { ...obj, [key]: value } : obj;
    }, {} as T);

  const handleCreate = useCallback(
    values => {
      const filteredValues = filterValues(values);

      createApplicantBlock(filteredValues);
    },
    [createApplicantBlock],
  );

  const handleModify = useCallback(
    values => {
      const filteredValues = filterValues(values);

      modifyApplicantBlock(filteredValues);
    },
    [modifyApplicantBlock],
  );

  const handleRemove = useCallback(
    values => {
      const filteredValues = filterValues(values);

      removeApplicantBlock(filteredValues);
    },
    [removeApplicantBlock],
  );

  const isModifyApplicantBlock = action === ApplicantBlockActions.Modify;
  const isRemoveApplicantBlock = action === ApplicantBlockActions.Remove;

  let handleSubmit;

  if (isModifyApplicantBlock) {
    handleSubmit = handleModify;
  } else if (isRemoveApplicantBlock) {
    handleSubmit = handleRemove;
  } else {
    handleSubmit = handleCreate;
  }

  const isLoading = isCreatingBlock || isModifyingBlock || isRemovingBlock;

  const formID = `${action}-applicant-block`;

  const modalContent = (
    <ModalStatusRouter isLoading={isLoading}>
      <ApplicantBlockForm
        applicantUuid={applicantUuid}
        consumerUuid={consumerUuid}
        contractUuid={contractUuid}
        formID={formID}
        handleSubmit={handleSubmit}
        isModifyApplicantBlock={isModifyApplicantBlock}
        isRemoveApplicantBlock={isRemoveApplicantBlock}
      />
    </ModalStatusRouter>
  );

  const title = translate(
    `OPS_PORTAL.TITLE.${action.toUpperCase()}_APPLICANT_BLOCK`,
  );

  return {
    allowoverflow: true,
    formID,
    isLoading,
    modalContent,
    title,
  };
};
