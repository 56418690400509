import { Route } from 'react-router-dom';
import { PathnamePrefix } from '@hooks/useOriginator/utils/getPathnamePrefixForOriginator';
import { Originators, PathnamePrefixes } from '@utils/constants';
import { OriginatorHomeRouteNavigator } from './OriginatorHomeRouteNavigator';

interface IRouteFactoryProps {
  basePath: string;
  canUseNewLoginPage: boolean;
  component: JSX.Element | null;
  getPathnamePrefix: (
    originator: keyof typeof PathnamePrefixes,
    canUseNewLoginPage: boolean,
  ) => PathnamePrefix;
}

export const routeFactory = ({
  basePath,
  canUseNewLoginPage,
  component,
  getPathnamePrefix,
}: IRouteFactoryProps) =>
  Object.values(Originators)
    .map(originator => {
      const pathnamePrefix = getPathnamePrefix(originator, canUseNewLoginPage);
      const path = pathnamePrefix + basePath;

      if (!path) {
        return null;
      }

      if (!basePath) {
        return (
          <Route
            element={
              <OriginatorHomeRouteNavigator pathnamePrefix={pathnamePrefix} />
            }
            key={path}
            path={path}
          />
        );
      }

      return <Route element={component} key={path} path={path} />;
    })
    .filter(Boolean);
