import { useMemo } from 'react';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Card, ClientsideTable, QueryStateRouter } from '@components';
import { ModalKeys, useI18n, useModal } from '@hooks';
import { IOpsPortalNotes } from '@schemas/opsPortal/types/notes';
import { fetchDataFromEndpoint } from '@utils';
import { QueryKeys, TableNames } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { getNotesColumns } from './getNotesColumns';
import { getOptions } from './getOptions';
import { mapNotesTableData } from './mapNotesTableData';
import { INotesProps, INotesTableDataRow } from './types';

export const Notes = ({ uuid }: INotesProps) => {
  const { getLocaleDate, translate } = useI18n();

  const {
    data: notesData,
    error,
    isLoading,
  } = useQuery<IOpsPortalNotes, AxiosError>([QueryKeys.NOTES_DATA, uuid], () =>
    fetchDataFromEndpoint(`${Endpoints.FetchNotes}/${uuid}`),
  );

  const columns = useMemo(() => getNotesColumns(translate), [translate]);

  const tableData: INotesTableDataRow[] =
    error ?? !notesData
      ? []
      : mapNotesTableData({
          getLocaleDate,
          notesData,
          translate,
        });

  const dropdownOptions = getOptions(tableData);

  const AddNoteModal = useModal(ModalKeys.AddNote, { uuid });

  return (
    <>
      <Card
        buttons={AddNoteModal ? [AddNoteModal.ctaProps] : []}
        title={translate('OPS_PORTAL.TITLE.NOTES')}
      >
        <QueryStateRouter error={error} isLoading={isLoading}>
          <ClientsideTable
            columns={columns}
            data={tableData}
            tableName={TableNames.NOTES}
            tableSpecificProps={{
              createdByOptions: dropdownOptions,
            }}
          />
        </QueryStateRouter>
      </Card>
      {AddNoteModal?.Modal ?? null}
    </>
  );
};
