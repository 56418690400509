import { Controller } from 'react-hook-form';
import { createColumnHelper } from '@tanstack/react-table';
import { DatePickerRHF } from '@components';
import { Translate } from '@hooks/useI18n';
import { getDateLimits } from './getDateLimits';
import { IChangePaymentDateColumns } from './types';

interface IGetPaymentDateColumns {
  translate: Translate;
  watchedDates: string[];
}

export const getPaymentDateColumns = ({
  translate,
  watchedDates,
}: IGetPaymentDateColumns) => {
  const dateLimits = getDateLimits(watchedDates);
  const columnHelper = createColumnHelper<IChangePaymentDateColumns>();

  return [
    columnHelper.accessor('date', {
      header: translate('TITLE.DUE_DATE'),
      cell: ({ row }) => (
        <Controller
          name={`payment_dates[${row.index}]`}
          // eslint-disable-next-line react/jsx-no-bind
          render={({ field }) => (
            <DatePickerRHF
              disabled={new Date(field.value) < new Date()}
              isClearable={false}
              maxDate={dateLimits[row.index][1]}
              minDate={dateLimits[row.index][0]}
              name={field.name}
            />
          )}
        />
      ),
    }),
    columnHelper.accessor('amount', {
      header: translate('TITLE.PAYMENT_AMOUNT'),
    }),
  ];
};
