import { useCallback } from 'react';
import { Formik, Form } from 'formik';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { Dropdown, TextField } from '@components';
import { Button } from '@components/Common.styles.tsx';
import { useI18n } from '@hooks';
import { getErrorMessage, putDataToEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { getValidationSchema } from './getValidationSchema';

export const OrganisationForm = ({ data, industryChoices, setIsEditing }) => {
  const { translate } = useI18n();

  const {
    billing_address: billingAddress,
    legal_name: legalName,
    merchant_industry: merchantIndustry,
    primary_colour: primaryColour,
    short_name: shortName,
    sms_sender_id: smsSenderId,
    vat_number: vatNumber,
  } = data;

  // I realise this looks hideous, but it is a necessary evil, as
  // default values only replace undefined, not null.
  const initialFormikValues = {
    billing_address: billingAddress ?? '',
    legal_name: legalName ?? '',
    merchant_industry: merchantIndustry ?? 0,
    primary_colour: primaryColour ?? '',
    short_name: shortName ?? '',
    sms_sender_id: smsSenderId ?? '',
    vat_number: vatNumber ?? '',
  };

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(
    requestData => putDataToEndpoint({ endpoint: 'org/settings', requestData }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.ORGANISATION_INFO]);
        setIsEditing(false);
      },
      onError: error => {
        // eslint-disable-next-line no-console
        console.error(
          getErrorMessage({
            error,
            translate,
          }),
        );
      },
    },
  );

  const handleSubmit = useCallback(
    values => {
      mutate(values);
    },
    [mutate],
  );

  if (isLoading) {
    return <div>{translate('STATUS.SUBMITTING_CHANGES')}</div>;
  }

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema(initialFormikValues, translate)}
    >
      <Form className="form fv-plugins-bootstrap fv-plugins-framework">
        <TextField
          id="legal_name"
          label={translate('LABEL.LEGAL_NAME')}
          name="legal_name"
        />
        <TextField
          id="short_name"
          label={translate('LABEL.DISPLAY_NAME_GENERAL')}
          name="short_name"
          readOnly={true}
        />
        <TextField
          id="sms_sender_id"
          label={translate('LABEL.DISPLAY_NAME_SMS')}
          name="sms_sender_id"
        />
        <TextField
          id="billing_address"
          label={translate('LABEL.REGISTERED_ADDRESS')}
          name="billing_address"
        />
        <TextField
          id="vat_number"
          label={translate('LABEL.VAT_REGISTRATION_NUMBER')}
          name="vat_number"
        />
        <Dropdown
          id="merchant_industry"
          label={translate('LABEL.INDUSTRY')}
          name="merchant_industry"
          options={industryChoices}
        />
        <TextField
          id="primary_colour"
          label={translate('LABEL.COLOUR_CODE')}
          name="primary_colour"
        />

        <Button align="right" type="submit" variant="primary">
          {translate('BUTTON.SUBMIT')}
        </Button>
      </Form>
    </Formik>
  );
};
