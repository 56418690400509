import splashImage from '@assets/images/login-splash-compass.png';
import { getNavData } from '@hooks/useConfig/utils';
import { LogoTypes, OriginatorDisplayNames } from '@utils/constants';
import { ConfigFunc } from './types';

const { NavItems, NavSectionHeadingKeys } = getNavData();

export const configConsumerPagolight: ConfigFunc = ({ featureFlags }) => {
  const { isProdFeatureFlag } = featureFlags;

  return Object.freeze({
    canChangePassword: true,
    canEditAddress: false,
    canEditName: false,
    canEditPhone: false,
    logo: {
      altTxt: OriginatorDisplayNames.PAGOLIGHT,
      showPoweredBy: isProdFeatureFlag,
      type: LogoTypes.PNG,
      url: '/media/logos/logo-pagolight-<§:variant§>.png',
    },
    navigation: [
      {
        heading: undefined,
        items: [NavItems.ORDERS_CONSUMER_PAGO],
      },
      {
        heading: NavSectionHeadingKeys.HELP,
        items: [NavItems.CARD_CHANGE_GUIDE],
      },
      {
        heading: NavSectionHeadingKeys.ACCOUNT_MANAGEMENT,
        items: [NavItems.ACCOUNT_SETTINGS, NavItems.LOGOUT],
      },
    ],
    showCompassOrders: true,
    showFooter: false,
    showFooterPrivacyLink: false,
    showInvoiceDetails: false,
    showLanguageDropdown: false,
    showProfilePicker: true,
    theme: {
      bootstrapColors: {
        name: 'mediobanca',
      },
      colors: {
        primary: '#f70203',
      },
      components: {
        loginSidePanel: `
          background-position: 75% 10%;
          background-size: 65%;
          background-color: #E6E7E8;
          background-size: cover;
        `,
      },
      splashImage,
    },
  });
};
