import { useCallback } from 'react';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { ContentCopy } from '@mui/icons-material';
import { Link } from '@mui/material';

interface ICopyText {
  copyLabel: string;
  copyText: string;
  errorMessage: string;
  successMessage: string;
}

export const CopyText = ({
  copyLabel,
  copyText,
  errorMessage,
  successMessage,
}: ICopyText) => {
  const { message } = useToast();

  const handleCopyToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(copyText);
      message.success(successMessage);
    } catch (err) {
      message.error(errorMessage);
    }
  }, [copyText, errorMessage, message, successMessage]);

  return (
    <Link component="button" onClick={handleCopyToClipboard} variant="body2">
      {copyLabel}
      <ContentCopy fontSize="small" sx={{ ml: 0.5 }} />
    </Link>
  );
};
