import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Button } from '@components';
import { ButtonVariant } from '@utils/enums';
import { IConfirmProps } from './types';

export const ConfirmDialog = ({
  cancelButtonText,
  confirmation,
  dismiss,
  isOpen,
  okButtonText,
  proceed,
  title,
}: IConfirmProps) => (
  <Dialog open={isOpen}>
    {title ? <DialogTitle>{title}</DialogTitle> : null}
    <DialogContent>
      <DialogContentText>{confirmation}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={dismiss}
        variant={[ButtonVariant.Secondary, ButtonVariant.Sm]}
      >
        {cancelButtonText}
      </Button>
      <Button
        onClick={proceed}
        variant={[ButtonVariant.Danger, ButtonVariant.Sm]}
      >
        {okButtonText}
      </Button>
    </DialogActions>
  </Dialog>
);
