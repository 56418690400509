import { useCallback, useEffect, useState } from 'react';
import { useHasPermission, useI18n } from '@hooks';
import { ModalKeys } from './enums';
import { modalScopes } from './modalScopes';
import { SelectedModal } from './SelectedModal';
import { IModal, IModalProps } from './types';
import { useTriggerTranslationKey } from './useTriggerTranslationKey';

export const useModal = (
  key: ModalKeys,
  modalProps?: Partial<IModalProps>,
): IModal | null => {
  const hasPermission = useHasPermission(modalScopes[key]);
  const { translate } = useI18n();

  const [isModalShowing, setIsModalShowing] = useState(false);
  const { additionalButtonContent, onClose } = modalProps ?? {};

  const openModal = useCallback(() => setIsModalShowing(true), []);
  const closeModal = useCallback(() => {
    setIsModalShowing(false);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const [formStatusMessage, setFormStatusMessage] = useState('');

  useEffect(() => setFormStatusMessage(''), [isModalShowing]);

  const resolveForm = useCallback(
    message => setFormStatusMessage(message),
    [setFormStatusMessage],
  );

  const triggerTranslationKey = useTriggerTranslationKey({
    modalKey: key,
    props: modalProps,
  });

  const dependenciesFailed = Boolean(
    modalProps?.dependencies?.length && !modalProps.dependencies.every(Boolean),
  );

  const canUseModal = hasPermission && !dependenciesFailed;

  if (!canUseModal) {
    return null;
  }

  const ctaText = translate(triggerTranslationKey);

  return {
    ctaProps: {
      additionalButtonContent,
      clickHandler: openModal,
      key,
      text: ctaText,
      ...(modalProps && 'variant' in modalProps
        ? { variant: modalProps.variant }
        : {}),
    },
    Modal: (
      <SelectedModal
        modalKey={key}
        modalProps={{
          closeModal,
          formStatusMessage,
          isModalShowing,
          resolveForm,
          ...modalProps,
        }}
      />
    ),
  };
};
