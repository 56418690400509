import { FormattedMessage } from 'react-intl';
import { LinkThemed } from '@components/Common.styles';
import { Translate } from '@hooks/useI18n';

interface ICompassSupportTextAndLink {
  supportTextKey: string;
  translate: Translate;
}

export const CompassSupportTextAndLink = ({
  supportTextKey,
  translate,
}: ICompassSupportTextAndLink) => {
  return (
    <FormattedMessage
      id={supportTextKey}
      values={{
        link: (
          <LinkThemed
            href="https://pagolight.it/aiuto.html"
            rel="noreferrer"
            sx={{ fontWeight: '500' }}
            target="_blank"
          >
            {translate('LINK.CONTACT_SUPPORT')}
          </LinkThemed>
        ),
      }}
    />
  );
};
