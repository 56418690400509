import splashImage from '@assets/images/login-splash-compass.png';
import * as privacyPolicies from '@assets/privacyPolicies';
import { getNavData } from '@hooks/useConfig/utils';
import { LogoTypes, OriginatorDisplayNames } from '@utils/constants';
import { ConfigFunc } from './types';

const { NavItems, NavSectionHeadingKeys } = getNavData();

// This is the config that the pagolight login page defaults to on /pagolight/auth/login endpoint
// Prior to login we are not aware of user permissions so using env to feature toggle instead
export const configMerchantPagolight: ConfigFunc = ({
  featureFlags,
  merchantSettingGroup,
}) => {
  const { hideOrdersPage, showApplicationsPage } = merchantSettingGroup;
  const { isProdFeatureFlag } = featureFlags;

  return Object.freeze({
    canChangePassword: true,
    canEditAddress: false,
    canEditName: false,
    canEditOrgInfo: false,
    canEditPhone: false,
    logo: {
      altTxt: OriginatorDisplayNames.PAGOLIGHT,
      showPoweredBy: isProdFeatureFlag,
      type: LogoTypes.PNG,
      url: '/media/logos/logo-pagolight-<§:variant§>.png',
    },
    navigation: [
      {
        heading: NavSectionHeadingKeys.CUSTOMERS,
        items: [
          NavItems.CREATE_NEW_PLAN,
          ...(showApplicationsPage ? [NavItems.APPLICATIONS] : []),
          ...(hideOrdersPage
            ? []
            : [
                NavItems.ORDERS,
                NavItems.AWAITING_CONFIRMATION,
                NavItems.SETTLEMENTS,
                NavItems.MEDIOBANCA_RECONCILIATIONS,
              ]),
        ],
      },
      {
        heading: NavSectionHeadingKeys.ORGANISATION,
        items: [NavItems.ORG_INFO, NavItems.USERS],
      },
      {
        heading: NavSectionHeadingKeys.HELP,
        items: [NavItems.MERCHANT_GUIDE, NavItems.INSTORE_SALES_GUIDE],
      },
      {
        heading: NavSectionHeadingKeys.ACCOUNT_MANAGEMENT,
        items: [
          NavItems.ACCOUNT_SETTINGS,
          NavItems.MERCHANT_PRIVACY_POLICY,
          NavItems.LOGOUT,
        ],
      },
    ],
    privacyPolicy: privacyPolicies.Pagolight,
    showCompassOrders: true,
    showInvoiceDetails: true,
    showFooter: false,
    showFooterPrivacyLink: false,
    showLanguageDropdown: false,
    showOrgIndustry: false,
    showOrgPrimaryColour: false,
    showOrgSMS: false,
    showProfilePicker: true,
    theme: {
      bootstrapColors: {
        name: 'mediobanca',
      },
      colors: {
        primary: '#f70203',
      },
      components: {
        loginSidePanel: `
          background-position: 75% 10%;
          background-size: 65%;
          background-color: #E6E7E8;
          background-size: cover;
        `,
      },
      splashImage,
    },
  });
};
