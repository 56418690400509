import { addPlaceholderForEmptyValues } from '@utils';
import {
  GetLocaleDate,
  IEvent,
  ICommunicationEventFilterTags,
  IGenericEvent,
  IPaymentEventFilterTags,
} from './types';

export const getGenericEvents = (
  data: IGenericEvent[],
  getLocaleDate: GetLocaleDate,
): IEvent[] =>
  data.map(event =>
    addPlaceholderForEmptyValues({
      communicationDirection: (
        event.filter_tags as ICommunicationEventFilterTags
      )?.direction,
      communicationStatus: (event.filter_tags as ICommunicationEventFilterTags)
        ?.status,
      communicationType: (event.filter_tags as ICommunicationEventFilterTags)
        ?.comms_type,
      createdDate:
        event.timestamp &&
        getLocaleDate({ date: event.timestamp, includeTime: true }),
      description: event.description,
      model: event.model,
      model_uuid: event.model_uuid,
      paymentCaptureStatus: (event.filter_tags as IPaymentEventFilterTags)
        ?.capture_status,
      paymentProcessingStatus: (event.filter_tags as IPaymentEventFilterTags)
        ?.processing_status,
      title: event.title,
      type: event.type,
    }),
  );
