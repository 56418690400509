import { InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import { ValidationError } from '@components';
import { FormGroup, InputFile, Label } from '@components/Common.styles';

interface IFileUploadProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
}

export const FileUpload = ({ label, ...props }: IFileUploadProps) => {
  const { name } = props;
  const {
    formState: { errors },
    register,
  } = useFormContext();

  const error = errors[name]?.message;

  return (
    <FormGroup>
      <Label htmlFor={props.id}>{label}</Label>
      <InputFile {...props} {...register(name)} id={props.id} />
      {error ? <ValidationError error={error} /> : null}
    </FormGroup>
  );
};
