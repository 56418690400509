import { Helmet } from 'react-helmet';
import { useFeatureFlags, useOriginator } from '@hooks';
import {
  OriginatorDisplayNames,
  OriginatorDisplayNamesHeyLight,
  Originators,
} from '@utils/constants';

export const BrowserTabThemeController = () => {
  const { isHeidiPay, originator } = useOriginator();
  const { PUBLIC_URL } = process.env;
  const { canUseHeyLightBranding, isProdFeatureFlag } = useFeatureFlags();

  const FeatureFlagOriginatorDisplayName = canUseHeyLightBranding
    ? OriginatorDisplayNamesHeyLight
    : OriginatorDisplayNames;
  return (
    <Helmet>
      <title id="tab-id">
        {isProdFeatureFlag
          ? FeatureFlagOriginatorDisplayName[Originators.HEIDIPAY]
          : FeatureFlagOriginatorDisplayName[originator]}{' '}
        | Portal
      </title>
      <link
        href={`${PUBLIC_URL}/media/logos/favicon${
          isHeidiPay || isProdFeatureFlag ? '' : '_pagolight'
        }.ico`}
        id="tab-favicon"
        rel="icon"
        type="image/x-icon"
      />
      {canUseHeyLightBranding ? (
        <style>{`
          html, body {
            font-family: 'HeyLight Sans', sans-serif;
          }
        `}</style>
      ) : null}
    </Helmet>
  );
};
