import { GetLocaleDate, IGetLocaleCurrencyValueOptions } from './types';
import { getLocaleCurrencyValue, getLocaleDate } from './utils';
import translationsEnglish from './translations/en.json';

export const getLocaleCurrencyValueMock = ({
  currency,
  value,
}: IGetLocaleCurrencyValueOptions) =>
  getLocaleCurrencyValue({ currency, languageCode: 'en', value });

export const getLocaleDateMock: GetLocaleDate = ({ date, includeTime }) =>
  getLocaleDate({ date, includeTime, languageCode: 'en' });

export const translateMock = (id: string) =>
  translationsEnglish[id as keyof typeof translationsEnglish];
