import { useMemo } from 'react';
import { QueryKey } from '@tanstack/react-query';
import { CollectionAgencies } from '@appTypes';
import { ModalKeys, useModal, useOriginator } from '@hooks';
import { defaultDateOptions } from '@hooks/useModal/hooks/useChangePaymentDatesModal/constants';
import { useGetPaymentLinkQuery } from '@pages/ContractDetails/hooks/queries';
import {
  getLatestPaymentDate,
  getMonthlyPaymentDate,
} from '@pages/ContractDetails/utils';
import {
  IContractDetails,
  IDate,
} from '@schemas/opsPortal/types/contractDetails';
import { IOpsPortalMonetaContract } from '@schemas/opsPortal/types/monetaContract';
import { ContractStatuses, PaymentTypes } from '@utils/constants';

interface IUseContractModalsProps {
  collectionAgencies?: CollectionAgencies;
  contractData?: IContractDetails;
  contractDataQueryKey?: QueryKey;
  contractUuid?: string;
  externalContractUUID?: string;
  invalidateMonetaData?: () => void;
  isInternalOpsUser: boolean;
  isLiveContracts: boolean;
  isPaused: boolean;
  monetaContractData?: IOpsPortalMonetaContract;
  paymentsPausedTillDate?: IDate;
}

export const useContractModals = ({
  collectionAgencies,
  contractData,
  contractDataQueryKey,
  contractUuid,
  externalContractUUID,
  invalidateMonetaData,
  isInternalOpsUser,
  isLiveContracts,
  isPaused,
  monetaContractData,
  paymentsPausedTillDate,
}: IUseContractModalsProps) => {
  const { isHeidiPay } = useOriginator();

  const contractStatus = contractData?.status.toLowerCase();
  const isContractActive = contractStatus === ContractStatuses.ACTIVE;
  const isContractTerminated =
    contractStatus === ContractStatuses.TERMINATED ||
    contractStatus === ContractStatuses.TERMINATED_AND_PAID;

  const remainder = monetaContractData?.contract_remainder;
  const isContractOverpaid = remainder
    ? parseFloat(remainder?.amount) < 0
    : false;
  const hasRemainder = remainder && !isContractOverpaid;
  const isActiveWithRemainder = isContractActive && hasRemainder;

  const canTriggerPayment =
    hasRemainder && (isContractActive || isContractTerminated);

  const triggerPaymentModal = useModal(ModalKeys.TriggerPayment, {
    dependencies: [canTriggerPayment],
    remainderAmount: remainder,
    uuid: externalContractUUID,
  });

  const paymentModalProps = {
    contractUuid,
    data: contractData,
    dependencies: [contractData],
    monetaData: monetaContractData,
    uuid: externalContractUUID,
  };

  const latestPaymentDate = useMemo(
    () => getLatestPaymentDate(monetaContractData),
    [monetaContractData],
  );

  const changePaymentDateModal = useModal(ModalKeys.ChangePaymentDate, {
    ...paymentModalProps,
    currency: monetaContractData?.contract_remainder.currency,
    dependencies: [...paymentModalProps.dependencies, isActiveWithRemainder],
    latestPaymentDate,
    paymentDate:
      getMonthlyPaymentDate(monetaContractData) ?? defaultDateOptions[0],
    paymentSchedule: monetaContractData?.balances.filter(
      balance =>
        Number(balance.amount_due) > 0 &&
        balance.payment_type === 'Instalment' &&
        balance.latest_schedule,
    ),
    uuid: externalContractUUID,
  });

  const pausePaymentsModal = useModal(ModalKeys.PausePayments, {
    contractDataQueryKey,
    dependencies: [contractData, isLiveContracts],
    isPaused,
    paymentsPausedTillDate,
    uuid: externalContractUUID,
  });

  const emailUserModal = useModal(ModalKeys.EmailUser, {
    contractData,
    dependencies: [contractData],
    isInternalOpsUser,
  });

  const updateArrearsManagedByModal = useModal(
    ModalKeys.UpdateArrearsManagedBy,
    {
      arrearsManagedBy: contractData?.arrears_managed_by,
      collectionAgencies,
      contractDataQueryKey,
      dependencies: [contractData],
      externalContractUUID,
    },
  );

  const contractManualPaymentModal = useModal(
    ModalKeys.ContractManualPayments,
    {
      ...paymentModalProps,
      paymentType: PaymentTypes.MANUAL_PAYMENT,
    },
  );

  const contractPrepaymentModal = useModal(ModalKeys.ContractPrepayment, {
    ...paymentModalProps,
    dependencies: [...paymentModalProps.dependencies, isActiveWithRemainder],
    paymentType: PaymentTypes.PREPAYMENT,
  });

  const contractReimbursementModal = useModal(ModalKeys.ContractReimbursement, {
    ...paymentModalProps,
    paymentType: PaymentTypes.REIMBURSEMENT,
  });

  const contractAdjustmentsModal = useModal(
    ModalKeys.ContractAdjustments,
    paymentModalProps,
  );

  const downloadQrPdfModal = useModal(ModalKeys.DownloadQrPdfModal, {
    dependencies: [contractData],
    uuid: contractUuid,
  });

  const { canUsePaymentLink, paymentLinkData, paymentLinkDataLoading } =
    useGetPaymentLinkQuery({
      contractUuid,
    });

  const paymentLinkModal = useModal(ModalKeys.PaymentLink, {
    contractUuid,
    dependencies: [
      contractData,
      isActiveWithRemainder,
      canUsePaymentLink && !paymentLinkDataLoading,
    ],
    paymentLinkData,
    remainingBalance: contractData?.system_remainder,
  });

  const isRegulatedOrUnregulatedCredit = [
    'regulated credit',
    'unregulated credit',
  ].includes(contractData?.credit_type ?? '');

  const addCertificateOfInterestModal = useModal(
    ModalKeys.AddCertificateOfInterest,
    {
      externalContractUUID,
      contractData,
      dependencies: [contractData, isRegulatedOrUnregulatedCredit, isHeidiPay],
    },
  );

  const deleteFeesModal = useModal(ModalKeys.DeleteFees, {
    currency: contractData?.currency,
    dependencies: [contractData, monetaContractData?.late_fees?.length],
    externalContractUUID,
    invalidateMonetaData,
    lateFees: monetaContractData?.late_fees ?? [],
  });

  // This is the order to display these buttons.
  return [
    triggerPaymentModal,
    changePaymentDateModal,
    pausePaymentsModal,
    emailUserModal,
    updateArrearsManagedByModal,
    contractManualPaymentModal,
    contractPrepaymentModal,
    contractReimbursementModal,
    contractAdjustmentsModal,
    downloadQrPdfModal,
    paymentLinkModal,
    addCertificateOfInterestModal,
    deleteFeesModal,
  ].filter(Boolean);
};
