import styled from 'styled-components';
import { Button } from '@components/Common.styles';

interface IStyledComponentWithClassName {
  extraClassName?: string;
}

export const AuthPageHeader = styled.header`
  text-align: center;
  margin-block-end: 2.5rem;

  @media (min-width: 992px) {
    margin-block-end: 4rem;
  }

  h2 {
    font-size: 2rem;
  }
`;

export const AuthPageInnerWrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  background-color: white;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

export const AuthPageOuterWrapper = styled.div`
  display: flex;
  flex: 1 1;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

export const Content = styled.main`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContentInnerWrapper = styled.div`
  width: 100%;

  @media (max-width: 991.98px) {
    max-width: 350px;
  }

  @media (max-width: 575.98px) {
    max-width: 100%;
  }

  @media (min-width: 992px) {
    max-width: 400px;
  }
`;

export const ContentOuterWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1.75rem;
`;

export const Copyright = styled.p`
  margin-block-end: 0;
  white-space: nowrap;
`;

export const CTAWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FooterLink = styled.a.attrs({
  rel: 'noopener noreferrer',
  target: '_blank',
})`
  color: white;
  &:not(:first-child) {
    margin-inline-start: 2.5rem;
  }
`;

export const FormError = styled.p`
  margin-block-end: 2.5rem;
  padding: 1.5rem 2rem;
  background-color: #ffe2e5;
  border-radius: 0.42rem;
  color: #f64e60;
  font-weight: 500;
`;

export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.25rem;
`;

export const LoginFooter = styled.div`
  display: flex;
  flex: none;
  justify-content: space-between;
  background-color: rgba(51, 51, 51, 0.85);
  padding: 1.5rem;
  font-weight: bold;
  color: white;

  @media (max-width: 991.98px) {
    display: none;
  }
`;

export const LoginFooterNav = styled.nav`
  display: flex;
`;

export const LoginGreetingHeader = styled.h1`
  width: 175px;
  box-sizing: content-box;
  background-color: rgba(51, 51, 51, 0.85);
  border-radius: 0.5rem;
  padding: 1rem;
  text-align: right;
  font-size: 1.25rem;
  color: white;

  @media (max-width: 991.98px) {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    margin-block-end: 0;
    padding: 1rem;
    & img {
      margin-inline-start: 0.5rem;
    }
  }
`;

export const LoginGreetingWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: center;
  padding: 0 1.5rem;

  @media (max-width: 991.98px) {
    align-items: center;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const LoginSidePanel = styled.div`
  display: flex;
  flex: 0 0 auto;

  @media (min-width: 992px) {
    ${({ theme }) => theme.components?.loginSidePanel}
    background-image: ${({ theme }) => `url(${theme.splashImage})`};
    background-repeat: no-repeat;
    width: 100%;
    max-width: 600px;
  }

  @media (min-width: 992px) and (max-width: 1399.98px) {
    max-width: 400px;
  }

  @media (max-width: 991.98px) {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const MobileFooterInnerWraper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 576px) {
    flex-direction: row;
  }

  @media (min-width: 992px) {
    display: none;
  }
`;

export const MobileFooterLink = styled.a`
  color: #3f4254;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  &:not(:first-child) {
    margin-inline-start: 1rem;
  }
`;

export const MobileFooterNav = styled.nav`
  display: flex;
  margin-block: 0.5rem;
`;

export const SidePanelInnerWrapper = styled.div`
  display: flex;
  flex: 1 1;
  flex-direction: column;
`;

export const Spinner = styled.span.attrs(
  ({ extraClassName = '' }: IStyledComponentWithClassName) => ({
    className: `spinner spinner-white ${extraClassName}`,
  }),
)<IStyledComponentWithClassName>`
  margin-left: 0.75rem;
`;

export const SpinnerButton = styled(Button)`
  padding-inline: 2.25rem;
`;

export const SuccessPageWrapper = styled.div`
  text-align: center;
`;
