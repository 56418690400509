import { useCallback } from 'react';
import Picker, { registerLocale } from 'react-datepicker';
import { format as formatDate } from 'date-fns';
import { de, enGB, frCH, it } from 'date-fns/locale';
import { useField, useFormikContext } from 'formik';
import { ValidationError } from '@components';
import { FormGroup, InputText, Label } from '@components/Common.styles';
import { useI18n } from '@hooks';

interface IDatePickerProps {
  id: string;
  label: string;
  maxDate?: Date;
  minDate?: Date;
  showPopperArrow?: boolean;
}

export const DatePicker = ({
  id = 'date',
  label,
  maxDate,
  minDate,
  showPopperArrow = true,
}: IDatePickerProps) => {
  const { selectedLanguage, translate } = useI18n();

  registerLocale('de', de);
  registerLocale('en-GB', enGB);
  registerLocale('fr-CH', frCH);
  registerLocale('it', it);

  const locales = {
    de: 'de',
    en: 'en-GB',
    fr: 'fr-CH',
    it: 'it',
  };

  const { setFieldValue } = useFormikContext();
  const [field, metaField] = useField({ name: id });

  const pickedDate = (field.value && new Date(field.value)) || null;

  const handler = useCallback(
    date =>
      setFieldValue(field.name, (date && formatDate(date, 'yyyy-MM-dd')) || ''),
    [field.name, setFieldValue],
  );

  const options = {
    autoComplete: 'off',
    dateFormat: 'PP',
    id,
    isClearable: true,
    locale: locales[selectedLanguage],
    ...(minDate ? { minDate } : {}),
    ...(maxDate ? { maxDate } : {}),
    name: id,
    onChange: handler,
    selected: pickedDate,
    showPopperArrow,
  };

  return (
    <>
      <FormGroup>
        <Label htmlFor={id}>{label || translate('LABEL.DATE')}</Label>
        <InputText as={Picker} {...options} />
        {metaField.touched && metaField.error ? (
          <ValidationError error={metaField.error} />
        ) : null}
      </FormGroup>
    </>
  );
};
