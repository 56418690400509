import {
  IMapApplicationDataProps,
  IOverviewData,
} from '@pages/ApplicationDetails/types';
import {
  addPlaceholderForEmptyValues,
  dictionary,
  getTranslatedData,
  replaceSpacesAndCapitalise,
} from '@utils';
import {
  paymentMethodDictionary,
  riskStatusDictionary,
} from '@utils/dictionary';

export const mapApplicationData = ({
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
  isCompass,
  nextPayment,
  translate,
}: IMapApplicationDataProps) => {
  const {
    application_reference: applicationReference,
    contract_uuid: contractUUID,
    created: createdDate,
    credit_type: creditType,
    external_contract_id: praticaId,
    merchant_name: merchantName,
    merchant_order_reference: merchantOrderReference,
    number_of_installments: numberOfInstallments,
    op_product_name: productName,
    op_subproduct_name: subProductName,
    payment_method: paymentMethod,
    payment_plan_reference: paymentPlanId,
    risk_config_name: riskConfigName,
    status: activityStatus,
    store_id: storeId,
    uuid: applicationUuid,
    waterfall_config_name: waterfallConfigName,
  } = data;

  const { amount } = data.order_details ?? {};

  const {
    credit_status: creditStatus,
    current_phase: currentPhase,
    identity_status: identityStatus,
    payment_method_status: paymentMethodStatus,
  } = data.risk_details ?? {};

  const { amount: nextPaymentAmount } = nextPayment ?? {};

  const mappedData = {
    applicationReference,
    paymentPlanId,
    contractUUID,
    applicationUuid,
    merchantName,
    merchantOrderReference,
    productName,
    subProductName,
    storeId,
    ...(isCompass && { praticaId }),
    createdDate: createdDate && getLocaleDate({ date: createdDate }),
    applicationAmount:
      amount &&
      getLocaleCurrencyValue({
        currency: amount.currency,
        value: amount.amount,
      }),
    numberOfInstallments,
    monthlyPaymentAmount: nextPaymentAmount
      ? getLocaleCurrencyValue({
          currency: nextPaymentAmount.currency,
          value: nextPaymentAmount.amount,
        })
      : '',
    activityStatus: getTranslatedData({
      dict: dictionary.api_label,
      term: activityStatus,
      translate,
    }),
    currentPhase,
    creditStatus: getTranslatedData({
      dict: riskStatusDictionary,
      term: creditStatus,
      translate,
    }),
    identityStatus: getTranslatedData({
      dict: riskStatusDictionary,
      translate,
      term: identityStatus,
    }),
    paymentMethodStatus: getTranslatedData({
      dict: riskStatusDictionary,
      translate,
      term: paymentMethodStatus,
    }),
    paymentMethod: getTranslatedData({
      dict: paymentMethodDictionary,
      translate,
      term: paymentMethod,
    }),
    creditType:
      creditType &&
      translate(
        `OPS_PORTAL.CREDIT_TYPE.${replaceSpacesAndCapitalise(creditType)}`,
      ),
    riskConfigName,
    waterfallConfigName,
  };

  return addPlaceholderForEmptyValues<IOverviewData>(mappedData);
};
