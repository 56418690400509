import splashImage from '@assets/images/login-splash-img.jpg';
import * as privacyPolicies from '@assets/privacyPolicies';
import * as svgLogos from '@assets/svg';
import { getNavData } from '@hooks/useConfig/utils';
import { HotjarIds, LogoTypes, OriginatorDisplayNames } from '@utils/constants';
import { ConfigFunc } from './types';

const { NavItems, NavSectionHeadingKeys } = getNavData();

export const configMerchantHeidipay: ConfigFunc = ({
  merchantSettingGroup,
}) => {
  const { hideOrdersPage, showApplicationsPage } = merchantSettingGroup;

  return Object.freeze({
    canChangePassword: true,
    canEditAddress: false,
    canEditName: true,
    canEditOrgInfo: true,
    canEditPhone: false,
    hotjarId: HotjarIds.HEIDIPAY,
    logo: {
      component: svgLogos.HeidiPay,
      showPoweredBy: false,
      title: OriginatorDisplayNames.HEIDIPAY,
      type: LogoTypes.SVG,
    },
    navigation: [
      {
        heading: NavSectionHeadingKeys.CUSTOMERS,
        items: [
          NavItems.CREATE_NEW_PLAN,
          ...(showApplicationsPage
            ? [NavItems.MY_APPLICATIONS, NavItems.APPLICATIONS]
            : []),
          ...(hideOrdersPage
            ? []
            : [
                NavItems.MY_ORDERS,
                NavItems.ORDERS,
                NavItems.AWAITING_CONFIRMATION,
                NavItems.SETTLEMENTS,
              ]),
        ],
      },
      {
        heading: NavSectionHeadingKeys.ORGANISATION,
        items: [NavItems.ORG_INFO, NavItems.USERS],
      },
      {
        heading: NavSectionHeadingKeys.HELP,
        items: [NavItems.MERCHANT_GUIDE, NavItems.INSTORE_SALES_GUIDE],
      },
      {
        heading: NavSectionHeadingKeys.ACCOUNT_MANAGEMENT,
        items: [
          NavItems.ACCOUNT_SETTINGS,
          NavItems.MERCHANT_PRIVACY_POLICY,
          NavItems.LOGOUT,
        ],
      },
    ],
    privacyPolicy: privacyPolicies.HeidiPay,
    showCompassOrders: false,
    showFooter: true,
    showFooterPrivacyLink: false,
    showInvoiceDetails: false,
    showLanguageDropdown: true,
    showOrgIndustry: true,
    showOrgPrimaryColour: true,
    showOrgSMS: true,
    showProfilePicker: true,
    supportEmail: 'support@heidipay.com',
    theme: {
      bootstrapColors: {
        name: 'primary',
      },
      colors: {
        primary: '#52c3c2',
      },
      components: {
        loginSidePanel: `
          background-position: 65% 10%;
          background-size: 70%;
          background-size: cover;
        `,
      },
      splashImage,
    },
  });
};
