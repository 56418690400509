import {
  IPaymentMethod,
  IRiskStatus,
} from '@schemas/opsPortal/types/applicationDetails';
import { TableNames } from './constants';

const statusAwaitingDispatch = 'STATUS.AWAITING_DISPATCH';
const statusClosed = 'STATUS.CLOSED';

export const paymentMethodDictionary: Record<IPaymentMethod, string> = {
  card: 'OPS_PORTAL.PAYMENT_METHOD.CARD',
  direct_debit: 'OPS_PORTAL.PAYMENT_METHOD.DIRECT_DEBIT',
  swiss_qr: 'OPS_PORTAL.PAYMENT_METHOD.SWISS_QR',
};

export const riskStatusDictionary: Record<IRiskStatus, string> = {
  approved: 'OPS_PORTAL.STATUS.APPROVED',
  awaiting: 'OPS_PORTAL.STATUS.AWAITING',
  more_info: 'OPS_PORTAL.STATUS.MORE_INFORMATION_REQUIRED',
  rejected: 'OPS_PORTAL.STATUS.REJECTED',
};

export const dictionary = {
  api_label: {
    abandoned: 'STATUS.ABANDONED',
    active: 'STATUS.ACTIVE',
    Active: 'STATUS.ACTIVE',
    advance: 'TYPE.ADVANCE',
    ADVANCE: 'TYPE.ADVANCE',
    application_ongoing: 'STATUS.APPLICATION_ONGOING',
    'Application Ongoing': 'STATUS.APPLICATION_ONGOING',
    awaiting_confirmation: 'STATUS.AWAITING_CONFIRMATION',
    awaiting_dispatch: statusAwaitingDispatch,
    awaiting_signing: 'OPS_PORTAL.STATUS.AWAITING_SIGNING',
    awaiting_verification: 'OPS_PORTAL.STATUS.AWAITING_VERIFICATION',
    'Awaiting dispatch': statusAwaitingDispatch,
    'Awaiting Dispatch': statusAwaitingDispatch,
    BANK_TRANSFER: 'TYPE.BANK_TRANSFER',
    cancelled: 'STATUS.CANCELLED',
    Cancelled: 'STATUS.CANCELLED',
    CARD_PAYMENT: 'TYPE.CARD_PAYMENT',
    clawback: 'TYPE.CLAWBACK',
    CLAWBACK: 'TYPE.CLAWBACK',
    closed: statusClosed,
    Closed: statusClosed,
    collections: 'STATUS.COLLECTIONS',
    completed: statusClosed,
    confirmed: 'STATUS.CONFIRMED',
    CONSUMER: 'TYPE.CONSUMER',
    declined: 'STATUS.DECLINED',
    delayed: 'STATUS.DELAYED',
    ecommerce: 'LABEL.ECOMMERCE',
    EXTERNAL_FUNDER: 'OPS_PORTAL.STATUS.EXTERNAL_FUNDER',
    failed: 'STATUS.FAILED',
    false: 'OPS_PORTAL.STATUS.FALSE',
    fee: 'TYPE.FEE',
    FEE: 'TYPE.FEE',
    fully_refunded: 'STATUS.FULLY_REFUNDED',
    FUNDING_ENTITY: 'TYPE.FUNDING_ENTITY',
    HEIDI_BALANCE_SHEET: 'OPS_PORTAL.STATUS.HEIDI_BALANCE_SHEET',
    HEIDI_PAY: 'TYPE.HEIDI_PAY',
    in_store: 'LABEL.IN_STORE',
    initialised: 'STATUS.INITIALISED',
    Initialised: 'STATUS.INITIALISED',
    installment: 'TYPE.INSTALLMENT',
    INSTALLMENT: 'TYPE.INSTALLMENT',
    MEDIOBANCA: 'OPS_PORTAL.STATUS.MEDIOBANCA',
    MERCHANT: 'TITLE.MERCHANT',
    MERCHANT_UNFUNDED: 'OPS_PORTAL.STATUS.MERCHANT_UNFUNDED',
    'n/a': 'LABEL.NOT_AVAILABLE_ABBR',
    not_applicable: 'STATUS.OTHER',
    overdue: 'STATUS.OVERDUE',
    partially_refunded: 'STATUS.PARTIALLY_REFUNDED',
    pending_closure: 'STATUS.PENDING_CLOSURE',
    pending_termination: 'OPS_PORTAL.STATUS.PENDING_TERMINATION',
    termination_failed: 'OPS_PORTAL.STATUS.TERMINATION_FAILED',
    performing: 'STATUS.PERFORMING',
    prepayment: 'TYPE.PREPAYMENT',
    PREPAYMENT: 'TYPE.PREPAYMENT',
    refund: 'TYPE.REFUND',
    REFUND: 'TYPE.REFUND',
    sale: 'TYPE.SALE',
    SALE: 'TYPE.SALE',
    successful: 'OPS_PORTAL.STATUS.SUCCESSFUL',
    terminated: 'OPS_PORTAL.STATUS.TERMINATED',
    Terminated: 'OPS_PORTAL.STATUS.TERMINATED',
    terminated_and_paid: 'OPS_PORTAL.STATUS.TERMINATED_AND_PAID',
    'Terminated And Paid': 'OPS_PORTAL.STATUS.TERMINATED_AND_PAID',
    true: 'OPS_PORTAL.STATUS.TRUE',
    unknown: 'STATUS.UNKNOWN',
  },
  groups: {
    mp_admin: 'GROUPS.ADMIN',
    mp_sales_assistant: 'GROUPS.SALES_ASSISTANT',
    mp_sales_assistant_limited: 'GROUPS.SALES_ASSISTANT_LIMITED',
    mp_super_admin: 'GROUPS.SUPER_ADMIN',
    mpmediobanca_super_admin: 'GROUPS.SUPER_ADMIN',
    mp_mediamarkt_merchant: 'GROUPS.MEDIAMARKT_MERCHANT',
  },
  organisation: {
    billing_address: 'LABEL.REGISTERED_ADDRESS',
    legal_name: 'LABEL.LEGAL_NAME',
    industry: 'LABEL.INDUSTRY',
    primary_colour: 'LABEL.COLOUR_CODE',
    primary_language: 'LABEL.LANGUAGE',
    short_name: 'LABEL.DISPLAY_NAME_GENERAL',
    sms_sender_id: 'LABEL.DISPLAY_NAME_SMS',
    vat_number: 'LABEL.VAT_REGISTRATION_NUMBER',
  },
  sort_columns: {
    [TableNames.APPLICATIONS]: {
      activityStatus: 'status',
      amount: 'amount',
      applicationReference: 'application_reference',
      createdDate: 'created',
      language: 'language_code',
      merchant: 'merchant_name',
    },
    [TableNames.TERMINABLE_CONTRACTS]: {
      amount: 'amount',
      arrearsAmount: 'arrears_amount',
      consumerName: 'consumer_name',
      createdDate: 'created_date',
      merchantName: 'merchant_name',
      paymentPlanReference: 'payment_plan_reference',
      status: 'status',
    },
    [TableNames.AWAITING_DISPATCH]: {
      activityStatus: 'status',
      createdDate: 'created_date',
      merchantName: 'merchant__short_name',
      originalAmount: 'amount',
      paymentPlanId: 'payment_plan_reference',
    },
    [TableNames.BANK_ACCOUNT_RECONCILIATIONS]: {
      allocationStatus: 'allocation_status',
      allocationType: 'allocation_type',
      amount: 'amount',
    },
    [TableNames.CERTIFICATE_OF_INTEREST]: {
      startDate: 'start_date',
      endDate: 'end_date',
      createdDate: 'created_date',
    },
    [TableNames.COLLECTIONS]: {
      amountInArrears: 'arrears_amount',
      contractId: 'payment_plan_reference',
      language: 'language',
      merchantName: 'merchant__short_name',
    },
    [TableNames.COLLECTIONS_QUEUE]: {
      amountInArrears: 'arrears_amount',
      contractId: 'payment_plan_reference',
      language: 'language',
      merchantName: 'merchant__short_name',
    },
    [TableNames.COMMUNICATIONS]: {
      communicationTypeLabel: 'channel',
      sentAt: 'sent_at',
    },
    [TableNames.CONSUMERS]: {
      consumerEmail: 'email',
      consumerFirstName: 'first_name',
      consumerLastName: 'last_name',
      createdDate: 'created',
    },
    [TableNames.CONTRACTS]: {
      activityStatus: 'status',
      createdDate: 'created_date',
      merchantName: 'merchant__short_name',
      originalAmount: 'amount',
      paymentPlanId: 'payment_plan_reference',
    },
    [TableNames.CONTRACTS_BY_UUIDS]: {
      activityStatus: 'status',
      createdDate: 'created_date',
      merchantName: 'merchant__short_name',
      originalAmount: 'amount',
      paymentPlanId: 'payment_plan_reference',
    },
    [TableNames.MERCHANTS]: {
      country: 'country',
      createdDate: 'created_date',
      externalUUID: 'external_uuid',
      iban: 'iban',
      industryName: 'merchant_industry',
      merchantName: 'short_name',
    },
    [TableNames.PAUSED_CONTRACTS]: {
      externalUuid: 'external_uuid',
      pausedTill: 'payment_paused_till_date',
    },
    [TableNames.ORDERS]: {
      customerName: 'consumer_name',
      dateConfirmed: 'created_date',
      mediobancaExternalId: 'mediobanca_external_id',
      merchantReference: 'order_reference',
      orderValue: 'amount',
      paymentPlanReference: 'payment_plan_reference',
      status: 'merchant_contract_status_desc',
    },
    [TableNames.OVERPAID_CONTRACTS]: {
      date: 'date',
      amount: 'amount',
    },
    [TableNames.SETTLEMENTS]: {
      payee: 'payee__type',
      payor: 'payor__type',
      settlementAmount: 'amount',
      settlementDate: 'settlement_date',
      settlementRef: 'settlement_reference',
      settlementType: 'type',
    },
    [TableNames.UNBALANCED_ALLOCATIONS]: {
      latestTestDate: 'latest_test_date',
    },
    [TableNames.USERS]: {
      userID: 'email',
      userName: 'user_name',
      phoneNumber: 'phone_number',
      lastLogin: 'last_login',
    },
  },
  user: {
    email: 'LABEL.EMAIL_ADDRESS',
    first_name: 'LABEL.FIRST_NAME',
    last_name: 'LABEL.LAST_NAME',
    phone_number: 'LABEL.PHONE_NUMBER',
    address: 'LABEL.ADDRESS',
  },
};
