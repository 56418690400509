import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useI18n, usePortalError } from '@hooks';
import { IModalProps } from '@hooks/useModal/types';
import { postDataToEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { EarlySettlementQuoteCreateForm } from './EarlySettlementQuoteCreateForm';
import {
  IEarlySettlementCreateModal,
  IEarlySettlementCreateModalRequestData,
} from './types';

export const useEarlySettlementQuoteCreateModal = ({
  closeModal,
  contractUuid,
  remainingBalance,
}: IEarlySettlementCreateModal): IModalProps => {
  const { handleAxiosError } = usePortalError();
  const { translate } = useI18n();
  const queryClient = useQueryClient();
  const { message } = useToast(translate);

  const { isLoading, mutate } = useMutation<
    unknown,
    AxiosError<IError>,
    IEarlySettlementCreateModalRequestData
  >(
    ({
      amount,
      createFullEarlySettlement,
      earlySettlementDate,
      partialSettlementType,
    }) => {
      const requestData = {
        contract_uuid: contractUuid,
        early_settlement_date: earlySettlementDate,
      };

      if (createFullEarlySettlement) {
        return postDataToEndpoint({
          endpoint: Endpoints.CreateFullEarlySettlement,
          requestData,
        });
      }
      return postDataToEndpoint({
        endpoint: Endpoints.CreatePartialEarlySettlement,
        requestData: {
          ...requestData,
          partial_settlement_amount: {
            amount,
            currency: remainingBalance.currency,
          },
          partial_settlement_type: partialSettlementType,
        },
      });
    },
    {
      onSuccess: () => {
        message.success(
          'OPS_PORTAL.STATUS.EARLY_SETTLEMENT_QUOTE_SUCCESSFULLY_CREATED',
        );
        queryClient.invalidateQueries([QueryKeys.EARLY_SETTLEMENTS]);
        if (closeModal) {
          closeModal();
        }
      },
      onError: error => handleAxiosError(error),
    },
  );

  const formID = 'earlySettlementForm';

  const modalContent = (
    <EarlySettlementQuoteCreateForm
      formID={formID}
      handleSubmit={mutate}
      remainingBalance={remainingBalance}
    />
  );

  return {
    allowoverflow: true,
    formID,
    isDangerousSubmit: true,
    loadingOverlay: true,
    isLoading,
    modalContent,
    title: translate('OPS_PORTAL.TITLE.CREATE_EARLY_SETTLEMENT_QUOTE'),
  };
};
