import { FilterTypes, supportedLanguages } from '@utils/constants';

export const getLanguageDropdown = ({ queryParams, translate }) => {
  const languageOptions = [
    { id: '', name: '—' },
    ...supportedLanguages.map(languageCode => ({
      id: languageCode,
      name: translate(`OPS_PORTAL.LABEL.${languageCode.toUpperCase()}`),
    })),
  ];

  return {
    accessorKey: 'language',
    id: 'language',
    label: translate('LABEL.LANGUAGE'),
    options: languageOptions,
    type: FilterTypes.DROPDOWN,
    value: queryParams?.language,
  };
};
