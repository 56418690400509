import {
  ChangeCardDetailsSubkeys,
  ModalKeys,
  PausePaymentsSubkeys,
} from './enums';
import { modalTriggerTranslationKeys } from './modalTriggerTranslationKeys';
import {
  ChangeCardDetailsSubtranslations,
  IModalProps,
  PausePaymentsSubtranslations,
} from './types';

export type ITriggerTranslationKeyProps = {
  modalKey: ModalKeys;
  props?: Partial<IModalProps>;
};

export const useTriggerTranslationKey = ({
  modalKey,
  props,
}: ITriggerTranslationKeyProps) => {
  if (modalKey === ModalKeys.ChangeCardDetails && props) {
    const changeCardDetailsKey = modalTriggerTranslationKeys[
      modalKey
    ] as ChangeCardDetailsSubtranslations;
    const { isDirectDebit, isHeidiPay } = props;

    if (isHeidiPay || !isDirectDebit) {
      return changeCardDetailsKey[ChangeCardDetailsSubkeys.CardChange];
    }

    return changeCardDetailsKey[ChangeCardDetailsSubkeys.SwitchPaymentMethod];
  }

  if (modalKey === ModalKeys.PausePayments && props) {
    const pausePaymentsKey = modalTriggerTranslationKeys[
      modalKey
    ] as PausePaymentsSubtranslations;
    return pausePaymentsKey[
      props.isPaused
        ? PausePaymentsSubkeys.Paused
        : PausePaymentsSubkeys.NotPaused
    ];
  }

  return modalTriggerTranslationKeys[modalKey] as string;
};
