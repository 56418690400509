import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { actions, resetPassword } from '@auth/_redux';
import { useI18n } from '@hooks';
import { ValidationErrorKeys } from '@utils/enums';
import { AuthPageHeader, ContentInnerWrapper } from './Auth.styles';
import { AuthForm } from './components';

export const ResetPassword = () => {
  const { translate } = useI18n();
  const dispatch = useDispatch();
  const { token } = useParams();

  const handleSubmit = useCallback(
    ({ password, passwordConfirm }, { setStatus, setSubmitting }) => {
      if (token) {
        resetPassword(token, password, passwordConfirm)
          .then(({ data: { access } }) => {
            dispatch(actions.passwordReset(access));
          })
          .catch(() => {
            setStatus(translate(ValidationErrorKeys.InvalidLogin));
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    },
    [dispatch, token, translate],
  );

  return (
    <ContentInnerWrapper>
      <AuthPageHeader>
        <h2>{translate('AUTH.ENTER_YOUR_NEW_PASSWORD')}</h2>
      </AuthPageHeader>

      <AuthForm
        fields={['password', 'passwordconfirm']}
        handleSubmit={handleSubmit}
        initialValues={{ password: '', passwordconfirm: '' }}
      />
    </ContentInnerWrapper>
  );
};
