import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { Days } from '@appTypes';
import { useHasPermission, useI18n, usePortalError } from '@hooks';
import { IModalProps, ISharedModalProps } from '@hooks/useModal/types';
import { IBalances } from '@schemas/opsPortal/types/monetaContract';
import { postDataToEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { ChangePaymentDateForm } from './ChangePaymentDateForm';
import { IChangePaymentRequest } from './types';

interface IChangePaymentDatesModal extends ISharedModalProps {
  currency: string;
  latestPaymentDate: Date;
  paymentDate: Days | null;
  paymentSchedule?: IBalances;
  uuid?: string;
}

export const useChangePaymentDatesModal = ({
  closeModal,
  currency,
  latestPaymentDate,
  paymentDate,
  paymentSchedule,
  uuid,
}: IChangePaymentDatesModal): IModalProps => {
  const { translate } = useI18n();
  const { handleAxiosError } = usePortalError();
  const { message } = useToast(translate);
  const canSelectCustomPaymentDate = useHasPermission([
    'ops_portal.contract_date_change_individual',
  ]);
  const queryClient = useQueryClient();

  const postRequestPaymentDayChanged = (requestData: IChangePaymentRequest) => {
    const {
      custom_payment_day: customPaymentDay,
      requested_payment_day: requestedPaymentDay,
      uuid: interventionUuid,
    } = requestData;
    const updatedRequestPaymentDay = Number(
      requestedPaymentDay === 0 ? customPaymentDay : requestedPaymentDay,
    );

    return postDataToEndpoint({
      endpoint: `${Endpoints.ChangePaymentDay}/${uuid}`,
      requestData: {
        uuid: interventionUuid,
        // 0 is used for when the user selects a custom payment day
        requested_payment_day: updatedRequestPaymentDay,
      },
    });
  };

  const postRequestPaymentDatesChanged = (
    requestData: IChangePaymentRequest,
  ) => {
    const { payment_dates: paymentDates, uuid: interventionUuid } = requestData;

    return postDataToEndpoint({
      endpoint: `${Endpoints.ChangePaymentDates}/${uuid}`,
      requestData: { uuid: interventionUuid, payment_dates: paymentDates },
    });
  };

  const { isLoading, mutate } = useMutation<
    unknown,
    AxiosError<IError>,
    IChangePaymentRequest
  >(
    requestData =>
      requestData.override_dates
        ? postRequestPaymentDatesChanged(requestData)
        : postRequestPaymentDayChanged(requestData),
    {
      onSuccess: () => {
        message.success('OPS_PORTAL.STATUS.PAYMENT_DATE_SUCCESSFULLY_CHANGED');
        queryClient.invalidateQueries([QueryKeys.CONTRACT_DETAILS_DATA, uuid]);
        queryClient.invalidateQueries([QueryKeys.MONETA_CONTRACT_DATA, uuid]);
        if (closeModal) {
          closeModal();
        }
      },
      onError: error => handleAxiosError(error),
    },
  );

  const handleSubmit = useCallback(
    values => {
      mutate(values);
    },
    [mutate],
  );

  const formID = 'change-payment-date';

  const modalContent =
    paymentSchedule && paymentDate ? (
      <ChangePaymentDateForm
        canSelectCustomPaymentDate={canSelectCustomPaymentDate}
        currency={currency}
        formID={formID}
        handleSubmit={handleSubmit}
        latestPaymentDate={latestPaymentDate}
        paymentDate={paymentDate}
        paymentSchedule={paymentSchedule}
      />
    ) : (
      <div>{translate('STATUS.NO_DATA_AVAILABLE')}</div>
    );

  return {
    formID,
    isFormProcessing: isLoading,
    isLoading,
    modalContent,
    showFormButtons: !isLoading,
    title: translate('OPS_PORTAL.TITLE.CHANGE_PAYMENT_DATE'),
  };
};
