import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { DatePickerRHF } from '@components';
import { useI18n } from '@hooks';
import { ValidationErrorKeys } from '@utils/enums';
import { IDownloadSettlementsCsvFormProps } from './types';

export const DownloadSettlementsReportForm = ({
  formID,
  handleSubmit,
}: IDownloadSettlementsCsvFormProps) => {
  const { translate } = useI18n();

  const today = new Date();
  const oneYearAgo = new Date(today);
  oneYearAgo.setFullYear(today.getFullYear() - 1);
  oneYearAgo.setDate(oneYearAgo.getDate() + 1);

  const defaultValues = {
    startDate: oneYearAgo,
    endDate: today,
  };

  const validationSchema = Yup.object({
    startDate: Yup.date().required(translate(ValidationErrorKeys.Required)),
    endDate: Yup.date().required(translate(ValidationErrorKeys.Required)),
  });

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const { watch } = methods;

  const startDate = watch('startDate');
  const endDate = watch('endDate');

  return (
    <FormProvider {...methods}>
      <p>{translate('PAGE.SETTLEMENTS.DOWNLOAD_REPORT')}</p>
      <form id={formID} onSubmit={methods.handleSubmit(handleSubmit)}>
        <Box mb={2}>
          <DatePickerRHF
            isClearable={false}
            label={translate('LABEL.START_DATE')}
            maxDate={new Date(endDate)}
            name="startDate"
            showPopperArrow={false}
          />
          <DatePickerRHF
            isClearable={false}
            label={translate('LABEL.END_DATE')}
            maxDate={today}
            minDate={new Date(startDate)}
            name="endDate"
            showPopperArrow={false}
          />
        </Box>
      </form>
    </FormProvider>
  );
};
