import styled from 'styled-components';
import { useI18n } from '@hooks/useI18n';
import { IAmountObject, IDate } from '@schemas/opsPortal/types/contracts';
import { missingValuePlaceholder } from '@utils/constants';

interface IDateFeeLabelProps {
  amountPaid: IAmountObject;
  dueDate: IDate;
  totalAmount: IAmountObject;
}

export const DateFeeLabelDiv = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 16px;
  margin-left: 5px;
  margin-top: -26px;
  width: 350px;
`;

export const DateLabelSpan = styled.span`
  flex: 1;
  text-align: right;
  margin-right: 5px;
`;

export const AmountLabelSpan = styled.span`
  flex: 1;
  width: 100px;
  text-align: center;
`;

export const DateFeeLabel = ({
  amountPaid,
  dueDate,
  totalAmount,
}: IDateFeeLabelProps) => {
  const { getLocaleCurrencyValue, getLocaleDate } = useI18n();
  const feePaymentDate = getLocaleDate({ date: dueDate || '' });

  return (
    <DateFeeLabelDiv>
      <DateLabelSpan>{feePaymentDate}</DateLabelSpan>
      <AmountLabelSpan>
        {getLocaleCurrencyValue({
          currency: totalAmount.currency,
          value: totalAmount.amount,
        })}
      </AmountLabelSpan>
      <AmountLabelSpan>
        {amountPaid.amount === '0' || amountPaid.amount === '0.00'
          ? missingValuePlaceholder
          : getLocaleCurrencyValue({
              currency: amountPaid.currency,
              value: amountPaid.amount,
            })}
      </AmountLabelSpan>
    </DateFeeLabelDiv>
  );
};
