import { useField } from 'formik';
import { ValidationError } from '@components';
import { FormGroup, InputText, Label } from '@components/Common.styles';
import { useI18n } from '@hooks';

interface ITextField extends React.InputHTMLAttributes<HTMLInputElement> {
  containerClassName?: string;
  label: string;
  name: string;
  readOnly?: boolean;
}

export const TextField = ({
  containerClassName = '',
  label,
  readOnly,
  ...props
}: ITextField) => {
  const [field, meta] = useField(props);
  const { translate } = useI18n();
  const { className: propsClassName, ...remainingProps } = props;

  return (
    <FormGroup extraClassName={containerClassName}>
      <Label htmlFor={props.id}>
        {label + (readOnly ? ` (${translate('LABEL.CANNOT_BE_EDITED')})` : '')}
      </Label>
      <InputText
        extraClassName={propsClassName}
        readOnly={readOnly}
        {...field}
        {...remainingProps}
      />
      {meta.error ? <ValidationError error={meta.error} /> : null}
    </FormGroup>
  );
};
