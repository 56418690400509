import SVG from 'react-inlinesvg';
import { AccessController } from '@components';
import { useI18n } from '@hooks';
import { checkIsActivePath, toAbsoluteUrl } from '@utils';
import { IconWrapper, Item, Link, Text } from './Menu.styled';
import { IMenuItemProps } from './types';

export const MenuItem = ({
  currentPathname,
  item: { iconPath, link: href, scopes, target, translationKey },
}: IMenuItemProps) => {
  const { translate } = useI18n();

  return (
    <AccessController scopes={scopes}>
      <Item
        className={
          checkIsActivePath({ currentPathname, href }) ? 'menu-item-active' : ''
        }
      >
        <Link target={target} to={href}>
          <IconWrapper>
            <SVG src={toAbsoluteUrl(iconPath)} />
          </IconWrapper>
          <Text>{translate(translationKey)}</Text>
        </Link>
      </Item>
    </AccessController>
  );
};
