import { useMemo } from 'react';
import { Tabs } from '@components';
import { useI18n } from '@hooks';
import { ITabsComponentProps } from '@pages/ApplicationDetails/types';
import {
  generateTabData,
  mapApplicationData,
  mapApplicantData,
} from '@pages/ApplicationDetails/utils';
import { TabEventKeys } from '@utils/constants';

export const TabsComponent = ({
  data,
  isCompass,
  nextPayment,
}: ITabsComponentProps) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  const overviewData = useMemo(
    () =>
      mapApplicationData({
        data,
        getLocaleCurrencyValue,
        getLocaleDate,
        isCompass,
        nextPayment,
        translate,
      }),
    [
      data,
      getLocaleCurrencyValue,
      getLocaleDate,
      isCompass,
      nextPayment,
      translate,
    ],
  );

  const applicantData = useMemo(
    () =>
      mapApplicantData({
        data,
        getLocaleDate,
        translate,
      }),
    [data, getLocaleDate, translate],
  );

  const tabDataArray = useMemo(
    () =>
      generateTabData({
        applicantData,
        isCompass,
        overviewData,
        translate,
      }),
    [applicantData, isCompass, overviewData, translate],
  );

  return (
    <Tabs
      initialTabKey={TabEventKeys.APPLICATION_OVERVIEW}
      tabsData={tabDataArray}
      tabsId="contract-drilldown-tabs"
    />
  );
};
