import { useCallback, useState } from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useI18n, useOriginator } from '@hooks';
import { ModalStatusRouter } from '@hooks/useModal/components/index.ts';
import { getErrorMessage, postDataToEndpoint } from '@utils';
import { QueryKeys } from '@utils/constants';
import { EditPhoneForm } from './EditPhoneForm';
import { VerificationForm } from './VerificationForm';

export const useEditPhoneModal = ({ data, formStatusMessage, resolveForm }) => {
  const { translate } = useI18n();
  const { originator } = useOriginator();

  const [isVerification, setIsVerification] = useState(false);

  const queryClient = useQueryClient();

  const { isLoading: isEditLoading, mutate: editPhone } = useMutation(
    requestData =>
      postDataToEndpoint({
        endpoint: 'user/edit-phone',
        requestData: { ...requestData, originator },
      }),
    {
      onSuccess: () => {
        setIsVerification(true);
      },
      onError: error => {
        resolveForm(
          getErrorMessage({
            error,
            genericErrorMessageKey:
              'PAGE.ACCOUNT_SETTINGS.UNABLE_TO_EDIT_PHONE_NUMBER',
            translate,
          }),
        );
      },
    },
  );

  const { isLoading: isVerifyLoading, mutate: verifyPhone } = useMutation(
    requestData =>
      postDataToEndpoint({
        endpoint: 'user/verify-phone',
        requestData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.ACCOUNT_SETTINGS]);
        resolveForm(
          translate('PAGE.ACCOUNT_SETTINGS.PHONE_NUMBER_SUCCESSFULLY_EDITED'),
        );
        setIsVerification(false);
      },
      onError: error => {
        resolveForm(
          getErrorMessage({
            error,
            genericErrorMessageKey:
              'PAGE.ACCOUNT_SETTINGS.UNABLE_TO_VERIFY_PHONE_NUMBER',
            translate,
          }),
        );
        setIsVerification(false);
      },
    },
  );

  const handleEdit = useCallback(
    values => {
      editPhone(values);
    },
    [editPhone],
  );

  const handleVerify = useCallback(
    values => {
      verifyPhone(values);
    },
    [verifyPhone],
  );

  const formID = isVerification ? 'verify-phone' : 'edit-phone';

  const isLoading = isEditLoading || isVerifyLoading;

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={isLoading}
    >
      {isVerification ? (
        <VerificationForm formID={formID} handleSubmit={handleVerify} />
      ) : (
        <EditPhoneForm data={data} formID={formID} handleSubmit={handleEdit} />
      )}
    </ModalStatusRouter>
  );

  return {
    formID,
    isLoading,
    modalContent,
    title: translate('PAGE.ACCOUNT_SETTINGS.EDIT_PHONE_NUMBER'),
  };
};
